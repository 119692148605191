import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    padding: '0px 24px',
    width: '100%',
  },
  containerNoPadding: {
    width: '100%',
  },
  backToHome: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
  },
  backToHomeLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.primary.main,
    alignItems: 'center',
  },
  backIcon: {
    paddingRight: '10px',
  },
});

export function ReturnLink({ linkText, link, isDisplayed, variant, classes }) {
  if (isDisplayed) {
    return (
      <div
        className={
          variant === 'default' ? classes.container : classes.containerNoPadding
        }
      >
        <Link to={link} className={classes.backToHomeLink}>
          <ArrowBack className={classes.backIcon} />
          <Typography className={classes.backToHome}>{linkText}</Typography>
        </Link>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

ReturnLink.propTypes = {
  linkText: PropTypes.string,
  link: PropTypes.string,
  isDisplayed: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'noPadding']),
  classes: PropTypes.object.isRequired,
};

ReturnLink.defaultProps = {
  linkText: '',
  link: '',
  isDisplayed: false,
  variant: 'default',
};

export default withStyles(styles)(ReturnLink);
