import { Dialog, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';

/**
 * Custom Dialog component wrapper that makes the dialog fullscreen on mobile/small windows
 */
export default function CustomDialog(props) {
  const { children } = props;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <Dialog {...props} fullScreen={isSmallScreen}>
      {children}
    </Dialog>
  );
}

CustomDialog.propTypes = {
  children: PropTypes.node.isRequired,
};
