import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import history from './history';
import createRootReducer from './reducers';
import errorReporter from './middleware/errorReporter';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* This allows us to access the store anywhere in the app, even outside of react components, by importing this file */
const store = createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(routerMiddleware(history), errorReporter, thunk),
  ),
);
export default store;
