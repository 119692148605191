export default palette => ({
  htmlFontSize: 10,
  fontFamily: ['"Muli"', 'sans-serif'].join(', '),
  fontWeightExtraBold: 800,
  h1: {
    fontSize: '4.8rem',
    fontWeight: 'bold',
    color: palette.h1,
    letterSpacing: '1.4px',
  },
  h2: {
    fontSize: '3.2rem',
    fontWeight: 'bold',
    color: palette.h2,
    letterSpacing: '0.66px',
  },
  h3: {
    fontSize: '2.8rem',
    fontWeight: 'bold',
    color: palette.h3,
  },
  h4: {
    fontSize: '2.4rem',
    fontWeight: 'regular',
    color: palette.text.primary,
    letterSpacing: '0.79px',
  },
  h5: {
    fontSize: '2.4rem',
    fontWeight: 'bold',
    letterSpacing: '0.82px',
  },
  h6: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: palette.h6,
  },
  subtitle1: {
    fontSize: '1.8rem',
  },
  subtitle2: {},
  body1: {
    fontSize: '1.6rem',
    color: palette.text.primary,
  },
  body2: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: palette.text.primary,
  },
  button: {
    fontSize: '1.6rem',
    textTransform: 'none',
    letterSpacing: '0.5px',
  },
  caption: {
    fontSize: '1.4rem',
    color: palette.secondary.xtralight,
    lineHeight: 1.6,
  },
  overline: {},
});
