import PropTypes from 'prop-types';
import { withStyles, Typography, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import {
  getCurrencyFormat,
  getTransactionDisplayName,
} from '../../../common/utils';
import styles from './styles';

function HistoryLineItem(props) {
  const { classes, transaction, handleTransactionClick } = props;

  const handleClick = () => {
    handleTransactionClick(transaction);
  };

  return (
    <TableRow onClick={handleClick} hover className={classes.historyRow}>
      <TableCell className={classes.dateCell}>
        <Typography id="history-line-date">
          {moment(transaction.transactionDate).format('MMMM D, YYYY')}
        </Typography>
      </TableCell>
      <TableCell className={classes.nameCell} align="left">
        <Typography
          className={classes.nameText}
          variant="body2"
          id="history-line-name"
        >
          {getTransactionDisplayName(transaction)}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" id="history-line-amount">
          {getCurrencyFormat(transaction.amount)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

HistoryLineItem.propTypes = {
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  handleTransactionClick: PropTypes.func.isRequired,
};

HistoryLineItem.defaultProps = {};

export default withStyles(styles)(HistoryLineItem);
