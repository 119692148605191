import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography, withStyles } from '@material-ui/core';
import styles from './styles';

function MenuItem({
  classes,
  icon,
  label,
  active,
  linkPath,
  onClick,
  externalLink,
}) {
  const commonClasses = `
    ${classes.menuLink}
    ${!icon ? classes.noIcon : ''}
    ${active ? classes.active : ''}
  `;
  return (
    <div className={classes.wrap}>
      {externalLink ? (
        <a
          href={linkPath}
          target="_blank"
          rel="noopener noreferrer"
          className={`
            ${commonClasses}
            ${classes.externalLink}
          `}
          onClick={onClick}
          data-testid="menu-item-external"
        >
          {icon && <span>{icon}</span>}
          <Typography className={classes.label} component="span">
            {label}
          </Typography>
        </a>
      ) : (
        <Link className={commonClasses} to={linkPath || '#'} onClick={onClick}>
          {icon && <span>{icon}</span>}
          <Typography className={classes.label} component="span">
            {label}
          </Typography>
        </Link>
      )}
    </div>
  );
}

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  linkPath: PropTypes.string,
  onClick: PropTypes.func,
  externalLink: PropTypes.bool,
};

MenuItem.defaultProps = {
  icon: null,
  linkPath: null,
  onClick: () => {},
  externalLink: false,
};

export default withStyles(styles)(MenuItem);
