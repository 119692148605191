import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { LinearProgress, withStyles } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { withTranslation } from 'react-i18next';
import MyBasket from '../../components/basket/MyBasket';
import * as globals from '../../common/globals';
import {
  clearSelectedPaymentMethod,
  fetchSelectedPaymentMethod,
} from '../../actions/paymentMethod';
import { clearBasketItems, clearPaymentOptions } from '../../actions/basket';
import { enqueueSnackbar, enqueueDialog } from '../../actions/notifications';
import { submitPayment } from '../../actions/payment';
import AppTitle from '../../components/common/AppTitle';
import { clearGuestData } from '../../actions/guest';
import CustomThemeProvider from '../../styles/CustomThemeProvider';
import ProcessingOverlay from '../../components/common/ProcessingOverlay';
import ReturnLink from '../../components/common/ReturnLink';
import getCxpErrorMessage from '../../utils/ErrorUtils';
import RNWebViewHandler from '../../utils/RNWebViewHandler';

const myBasketThemeOptions = {
  overrides: {
    MyBasket: {
      stickyNode: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '560px',
        position: 'static !important', // theme breakpoint styles overwrite these passed in styles due to css specificity. TODO: refactor MyBasket component to not use breakpoint styles, and pass those in through the components instead
      },
      basketTopBar: {
        display: 'none',
      },
      content: {
        marginLeft: '25px',
        marginRight: '25px',
        width: 'auto',
      },
    },
  },
};

const styles = () => ({
  progressHolder: {
    width: '100%',
    position: 'absolute',
  },
  returnLink: {
    paddingTop: '24px',
  },
});

export class Review extends Component {
  constructor(props) {
    super(props);

    const { match, t } = this.props;

    this.state = {
      locationId: match.params.locId,
    };

    this.reCaptchaRef = createRef();
    this.t = t;
  }

  async componentDidMount() {
    const {
      fetchSelectedPaymentMethodConnect,
      enqueueSnackbarConnect,
      location,
    } = this.props;
    const { locationId } = this.state;

    try {
      await fetchSelectedPaymentMethodConnect(locationId);
      if (location?.state?.fromKiosk) {
        // if the user is coming from kiosk then trigger payment submission
        this.onSubmit();
      }
    } catch (e) {
      enqueueSnackbarConnect({
        variant: globals.NOTIFICATION_ERROR,
        message: e.message,
      });
    }
  }

  onReCaptchaChange = (token) => {
    if (token) {
      this.processPayment(token);
    }
  };

  onReCaptchaError = () => {
    const { enqueueSnackbarConnect } = this.props;

    enqueueSnackbarConnect({
      variant: globals.NOTIFICATION_ERROR,
      message: this.t('errors.reCaptchaFailure'),
    });
  };

  onSubmit = (event) => {
    event?.preventDefault();

    this.reCaptchaRef.current.reset();
    this.reCaptchaRef.current.execute();
  };

  processPayment = async (reCaptchaToken) => {
    const {
      history,
      clearBasketItemsConnect,
      clearPaymentOptionsConnect,
      clearSelectedPaymentMethodConnect,
      submitPaymentConnect,
      clearGuestDataConnect,
      location,
    } = this.props;
    const { locationId } = this.state;
    try {
      await submitPaymentConnect(locationId, reCaptchaToken);

      clearBasketItemsConnect(locationId);
      clearPaymentOptionsConnect(locationId);
      clearSelectedPaymentMethodConnect(locationId);
      clearGuestDataConnect();
      history.push(`/${locationId}/${globals.THANK_YOU_PATH}`);

      // if the it's coming from kiosk try to open the app
      if (location?.state?.fromKiosk && location?.state?.returnUrl) {
        window.location.href = location?.state?.returnUrl;
      }
    } catch (e) {
      if (location?.state?.fromKiosk && location?.state?.returnUrl) {
        const webView = new RNWebViewHandler();
        webView.sendMessage('error', e);
      }
      this.showErrorDialog(e);
    }
  };

  showErrorDialog = (e) => {
    const { enqueueDialogConnect } = this.props;
    const { locationId } = this.state;
    const cxpMessage = getCxpErrorMessage(e);
    const message = cxpMessage ?? this.t('review.submit.error.dialog.message');
    const title = cxpMessage
      ? this.t('error.location.base.title')
      : this.t('review.submit.error.dialog.title');
    enqueueDialogConnect({
      title,
      message,
      redirectUrl: `/${locationId}/${globals.BASKET_PATH}`,
    });
  };

  render() {
    const { locationId } = this.state;
    const {
      paymentMethod,
      isFetchingSelectedPaymentMethod,
      isSubmittingPayment,
      loc,
      classes,
      inStreamingApp,
    } = this.props;

    const siteKey = loc.isAutomation
      ? window.env.REACT_APP_AUTOMATION_RECAPTCHA_SITE_KEY
      : window.env.REACT_APP_RECAPTCHA_SITE_KEY;

    return (
      <>
        <AppTitle title={this.t('titles.review')} />
        <div>
          {isFetchingSelectedPaymentMethod || loc.isFetchingLocationDetails ? (
            <div className={classes.progressHolder}>
              <LinearProgress
                data-testid="linear-progress"
                className={classes.paymentProgress}
              />
            </div>
          ) : (
            <>
              <ProcessingOverlay open={isSubmittingPayment} />
              <CustomThemeProvider options={myBasketThemeOptions}>
                <div>
                  <MyBasket
                    className="basketWidget"
                    locationId={locationId}
                    onSubmit={this.onSubmit}
                    submitButtonText={this.t('review.submit')}
                    paymentMethod={paymentMethod}
                  />
                  <div className={classes.returnLink}>
                    <ReturnLink
                      isDisplayed={inStreamingApp}
                      link={`/${locationId}/home`}
                      linkText={this.t('expanded.backToHome')}
                    />
                  </div>
                </div>
              </CustomThemeProvider>
            </>
          )}
          {loc.hasLoadedLocationDetails && ( // we want to wait until we know what site key to use before we render the ReCaptcha otherwise we'll get async timeout errors from the old instance. The key is used to ensure the correct version is rendered
            <ReCAPTCHA
              key={siteKey}
              ref={this.reCaptchaRef}
              size="invisible"
              onChange={this.onReCaptchaChange}
              onErrored={this.onReCaptchaError}
              sitekey={siteKey}
            />
          )}
        </div>
      </>
    );
  }
}

Review.defaultProps = {
  paymentMethod: null,
};

Review.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loc: PropTypes.object.isRequired,
  fetchSelectedPaymentMethodConnect: PropTypes.func.isRequired,
  clearBasketItemsConnect: PropTypes.func.isRequired,
  clearPaymentOptionsConnect: PropTypes.func.isRequired,
  clearSelectedPaymentMethodConnect: PropTypes.func.isRequired,
  clearGuestDataConnect: PropTypes.func.isRequired,
  paymentMethod: PropTypes.object,
  enqueueSnackbarConnect: PropTypes.func.isRequired,
  submitPaymentConnect: PropTypes.func.isRequired,
  isSubmittingPayment: PropTypes.bool.isRequired,
  isFetchingSelectedPaymentMethod: PropTypes.bool.isRequired,
  enqueueDialogConnect: PropTypes.func.isRequired,
  inStreamingApp: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  paymentMethod: state.paymentMethod,
  isFetchingSelectedPaymentMethod:
    state.paymentMethod.isFetchingSelectedPaymentMethod,
  isSubmittingPayment: state.payment.isSubmittingPayment,
  loc: state.location,
  inStreamingApp: state.frame.inStreamingApp,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSelectedPaymentMethodConnect: (locationId) => {
    return dispatch(fetchSelectedPaymentMethod(locationId));
  },
  enqueueSnackbarConnect: (notification) => {
    return dispatch(enqueueSnackbar(notification));
  },
  clearBasketItemsConnect: (locationId) => {
    dispatch(clearBasketItems(locationId));
  },
  clearPaymentOptionsConnect: (locationId) => {
    dispatch(clearPaymentOptions(locationId));
  },
  clearSelectedPaymentMethodConnect: (locationId) => {
    dispatch(clearSelectedPaymentMethod(locationId));
  },
  submitPaymentConnect: (locationId, reCaptchaToken) => {
    return dispatch(submitPayment(locationId, reCaptchaToken));
  },
  clearGuestDataConnect: () => {
    dispatch(clearGuestData());
  },
  enqueueDialogConnect: (notification) => {
    return dispatch(enqueueDialog(notification));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
)(Review);
