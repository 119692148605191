import i18next from 'i18next';
import {
  SAVED_PAYMENT_METHODS_FETCH,
  SAVED_PAYMENT_METHODS_COMPLETE,
  SAVED_PAYMENT_METHODS_ERROR,
  SAVED_PAYMENT_METHODS_DELETE,
  SAVED_PAYMENT_METHODS_DELETE_ERROR,
  SAVED_PAYMENT_METHODS_DELETE_COMPLETE,
  USER_REQUIRED_ACTIONS_PAYMENT_METHOD_REMOVED,
  SAVED_PAYMENT_METHODS_ADD,
} from './actionTypes';
import PaymentService from '../api/PaymentService';

export function fetchSavedPaymentMethods(locationId) {
  return async (dispatch, getState) => {
    const { loggedIn } = getState().session;

    if (loggedIn) {
      dispatch({ type: SAVED_PAYMENT_METHODS_FETCH });

      let response;
      try {
        response = await PaymentService.fetchSavedPaymentMethods(locationId);
      } catch (error) {
        dispatch({
          type: SAVED_PAYMENT_METHODS_ERROR,
        });
        throw error;
      }

      if (response?.status === 200 && response?.data?.data) {
        const savedMethods = response.data.data;
        return dispatch({
          type: SAVED_PAYMENT_METHODS_COMPLETE,
          savedMethods,
        });
      }

      dispatch({ type: SAVED_PAYMENT_METHODS_ERROR });
      throw new Error(i18next.t('errors.somethingHappened'));
    }

    return dispatch({ type: SAVED_PAYMENT_METHODS_COMPLETE, savedMethods: [] });
  };
}

export function deleteSavedPaymentMethod(locationId, paymentMethodToken) {
  return async (dispatch) => {
    dispatch({ type: SAVED_PAYMENT_METHODS_DELETE });

    let response;
    try {
      response = await PaymentService.deleteSavedPaymentMethod(
        locationId,
        paymentMethodToken,
      );
    } catch (error) {
      // check to make sure the error isn't due to having active transactions. Should this be a util function?
      if (
        error.message !==
        i18next.t('paymentMethods.remove.failure.activeTransaction')
      ) {
        error.message = i18next.t('paymentMethods.remove.failure');
      }

      dispatch({
        type: SAVED_PAYMENT_METHODS_DELETE_ERROR,
      });
      throw error;
    }

    if (response && response.status === 200) {
      dispatch(fetchSavedPaymentMethods(locationId));
      dispatch({ type: USER_REQUIRED_ACTIONS_PAYMENT_METHOD_REMOVED });
      return dispatch({
        type: SAVED_PAYMENT_METHODS_DELETE_COMPLETE,
      });
    }

    dispatch({
      type: SAVED_PAYMENT_METHODS_DELETE_ERROR,
    });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function addPaymentMethod(newPaymentMethod) {
  return (dispatch, getState) => {
    const { savedMethods } = getState().savedPaymentMethods;
    // Do not add duplicated payment methods
    if (
      savedMethods.some(
        ({ paymentMethodToken }) =>
          paymentMethodToken === newPaymentMethod.paymentMethodToken,
      )
    ) {
      return null;
    }
    return dispatch({
      type: SAVED_PAYMENT_METHODS_ADD,
      newPaymentMethod,
    });
  };
}
