import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';

import styles from './styles';
import * as globals from '../../../../common/globals';

const variantIcon = {
  [globals.NOTIFICATION_SUCCESS]: CheckCircleIcon,
  [globals.NOTIFICATION_WARNING]: WarningIcon,
  [globals.NOTIFICATION_ERROR]: ErrorIcon,
  [globals.NOTIFICATION_INFO]: InfoIcon,
};

export function SnackbarContentWrapper(props) {
  const { message, onClose, classes, variant, ...other } = props;

  const Icon = variantIcon[variant];

  const renderMessage = () => (
    <Typography
      variant="body1"
      id="client-snackbar-message"
      className={classes.message}
    >
      {Icon && <Icon className={`${classes.icon} ${classes.iconVariant}`} />}
      {message}
    </Typography>
  );

  return (
    <SnackbarContent
      id="client-snackbar-content"
      className={classes[variant]}
      aria-describedby="client-snackbar-message"
      message={renderMessage()}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf([
    globals.NOTIFICATION_ERROR,
    globals.NOTIFICATION_INFO,
    globals.NOTIFICATION_SUCCESS,
    globals.NOTIFICATION_WARNING,
  ]),
};

SnackbarContentWrapper.defaultProps = {
  variant: null,
  message: null,
};

export default withStyles(styles)(SnackbarContentWrapper);
