import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CryptoJS, { AES } from 'crypto-js';
import { useDispatch } from 'react-redux';
import useQueryParams, { parseQueryParams } from '../common/useQueryParams';
import { enqueueSnackbar } from '../../actions/notifications';
import {
  CREDIT_CARD,
  GENERAL_ERROR_PATH,
  NOTIFICATION_ERROR,
  PAYMENT_PATH,
  REVIEW_PATH,
} from '../../common/globals';
import { overrideBasket, savePaymentOptions } from '../../actions/basket';
import {
  setPaymentIdToStorage,
  setSubmittedUserDetailsToStorage,
  setVancoCampaignsPaymentHeaderStorage,
} from '../../common/storageUtils';
import RNWebViewHandler from '../../utils/RNWebViewHandler';

function useDecryptParams() {
  const { token, payment } = useQueryParams();
  const { locId } = useParams();
  const [paymentData, setPaymentData] = useState(null);
  const [isDecrypting, setIsDecrypting] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const webViewHandler = new RNWebViewHandler();

  function decrypt(encryptedPayment, encryptedToken) {
    try {
      const decodedPayment = decodeURIComponent(encryptedPayment);
      const decodeToken = decodeURIComponent(encryptedToken);
      const trimmedToken = decodeToken.substring(0, 32);
      const bytes = AES.decrypt(decodedPayment, trimmedToken);
      const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
      const paymentInfo = JSON.parse(decryptedString);
      setPaymentData(paymentInfo);
      dispatch(overrideBasket(locId, paymentInfo.basket)); // clear basket and add all items
      dispatch(
        savePaymentOptions(locId, {
          ...paymentInfo.processingFee, // set processing fee
          selectedPaymentType: CREDIT_CARD, // always credit card
        }),
      );

      // set payment token and header that were created in the webview
      if (paymentInfo.contactInformation) {
        setSubmittedUserDetailsToStorage(locId, paymentInfo.contactInformation); // set contact details
      }
      setPaymentIdToStorage(locId, paymentInfo.paymentToken);
      setVancoCampaignsPaymentHeaderStorage(locId, paymentInfo.paymentHeader);
      setIsDecrypting(false);
      if (!paymentInfo.contactInformation || !paymentInfo.paymentMethod) {
        history.push({
          pathname: `/${locId}/${PAYMENT_PATH}`,
          state: { fromKiosk: true },
        });
        return;
      }
      history.push({
        pathname: `/${locId}/${REVIEW_PATH}`,
        state: { fromKiosk: true, returnUrl: paymentInfo.returnUrl },
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar(error, {
          variant: NOTIFICATION_ERROR,
          message: error.message,
        }),
      );
      setPaymentData(null);
      setIsDecrypting(false);
      history.push(`/${GENERAL_ERROR_PATH}`);
    }
  }
  const handleSubmitEvent = (event) => {
    const { detail: query } = event;
    const params = parseQueryParams(query);
    decrypt(params.payment, params.token);
  };
  useEffect(() => {
    let clearListener;
    // if paramenters were passed by URL start decrypting
    if (token && payment) {
      decrypt(payment, token);
    } else {
      // wait for them to be passed via webview message
      clearListener = webViewHandler.setMessageListener(
        'submitPayment',
        handleSubmitEvent,
      );
    }
    return () => {
      if (clearListener) {
        clearListener();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { paymentData, isDecrypting };
}

export default useDecryptParams;
