import { createTheme } from '@material-ui/core/styles';

export default () =>
  createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          color: '#212224',
          backgroundColor: '#f1f2f3',
          borderRadius: '6px',
          height: '20px',
          lineHeight: '20px',
          paddingBottom: '10px',
          paddingLeft: '12px',
          paddingRight: '12px',
          paddingTop: '10px',
          '&:focus': {
            outline: '2px solid #CBCDCF',
            borderRadius: '6px',
            background: '#fff',
            '&::placeholder': {
              paddingLeft: '2px',
            },
          },
          '&::placeholder': {
            color: '#676B70',
          },
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: '18px',
          position: 'absolute',
          width: '20px',
          height: '20px',
          color: '#5E6266',
        },
      },
    },
  });
