import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import buildTheme from './theme';
import { getColorFromCache } from '../common/storageUtils';
import { getLocationIdFromUrl } from '../utils/SessionUtils';

export default function CustomThemeProvider(props) {
  const { options, children } = props;
  let primaryColor = useSelector(state => state.location.primaryColor);
  let locationId = useSelector(state => state.location.id);
  if (!primaryColor) {
    if (!locationId) {
      locationId = getLocationIdFromUrl();
    }
    const storedColor = getColorFromCache(locationId);
    if (storedColor) {
      primaryColor = storedColor;
    }
  }
  const themeOptions = { ...options, primaryColor };
  const theme = buildTheme(themeOptions);
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

CustomThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.object,
};

CustomThemeProvider.defaultProps = {
  options: {},
};
