/* Combines the .env file variables for local development and the window environment variables used on the deploy server into one object
 * Any new environment variables should be added here as well.
 * */
window.env = {
  REACT_APP_IDS_DOMAIN: process.env.REACT_APP_IDS_DOMAIN,
  REACT_APP_IDS_CLIENT_ID: process.env.REACT_APP_IDS_CLIENT_ID,
  REACT_APP_IDS_AUDIENCE: process.env.REACT_APP_IDS_AUDIENCE,
  REACT_APP_FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  REACT_APP_FRONTEND_REDIRECT_URL: process.env.REACT_APP_FRONTEND_REDIRECT_URL,
  REACT_APP_BACKEND_API: process.env.REACT_APP_BACKEND_API,
  REACT_APP_MOCK_BACKEND_API: process.env.REACT_APP_MOCK_BACKEND_API,
  REACT_APP_TELEMETRY_INSTRUMENTATION_KEY:
    process.env.REACT_APP_TELEMETRY_INSTRUMENTATION_KEY,
  REACT_APP_IDS_TENANT: process.env.REACT_APP_IDS_TENANT,
  REACT_APP_CXP_URL: process.env.REACT_APP_CXP_URL,
  REACT_APP_RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_AUTOMATION_RECAPTCHA_SITE_KEY:
    process.env.REACT_APP_AUTOMATION_RECAPTCHA_SITE_KEY,
  REACT_APP_FAITHFORWARD_ORIGIN: process.env.REACT_APP_FAITHFORWARD_ORIGIN,
  REACT_APP_AZURE_AUTH_DOMAIN: process.env.REACT_APP_AZURE_AUTH_DOMAIN,
  REACT_APP_AZURE_AUTH_TENANT: process.env.REACT_APP_AZURE_AUTH_TENANT,
  REACT_APP_AZURE_AUTH_SIGNIN: process.env.REACT_APP_AZURE_AUTH_SIGNIN,
  REACT_APP_AZURE_AUTH_CLIENT_ID: process.env.REACT_APP_AZURE_AUTH_CLIENT_ID,
  REACT_APP_AZURE_AUTH_SIGNUP: process.env.REACT_APP_AZURE_AUTH_SIGNUP,
  ...window.env,
};
