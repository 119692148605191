const styles = (theme) => ({
  row: {
    paddingTop: '50px',
  },
  divider: {
    height: 26,
    margin: '4px',
    top: '3px',
    right: 30,
    position: 'absolute',
  },
  campaignRoot: {
    flexGrow: '1',
    margin: '0 auto',
    width: '100%',
  },
  siteInfo: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: 'auto auto',
    gridRowGap: '16px',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%',
    paddingTop: 20,
    paddingLeft: 24,
    paddingRight: 24,
    alignItems: 'center',
  },
  searchContainerSingle: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  siteInfoDeprecated: {
    paddingTop: 20,
  },
  shareSiteButton: {
    gridColumn: 2,
    '&>button': {
      padding: 0,
    },
  },
  searchContainer: {
    height: '40px',
    width: '100%',
    position: 'relative',
    gridColumnStart: 1,
  },
  searchContent: {
    fontSize: '1.6rem',
    lineHeight: '20px',
    width: '100%',
  },
  searchOption: {
    fontSize: '1.6rem',
    lineHeight: '24px',
    fontWeight: 500,
  },
  buttonLabelDivider: {},
  searchHide: {
    display: 'none',
  },
  searchIconButton: {
    height: '40px',
    width: '40px',
    top: '-1px',
    right: '-3px',
    position: 'absolute',
  },
  clearResultsContainer: {
    maxWidth: '100%',
    margin: '0 auto',
    paddingLeft: '20px',
  },
  closeIconButton: {
    height: '40px',
    width: '40px',
    top: '-1px',
    right: '33px',
    position: 'absolute',
  },

  [theme.breakpoints.down('xs')]: {
    layoutSwitchWithSearch: {
      float: 'right',
      marginTop: theme.spacing(1),
    },
    layoutSwitchWithoutSiteName: {
      display: 'inline-block',
    },
    siteInfo: {
      gridTemplateColumns: 'auto auto',
      gridTemplateRows: '1fr',
    },
    searchContainerSingle: {
      gridRow: 'end',
    },
    layoutSwitch: {
      gridRow: 2,
    },
  },
  [theme.breakpoints.up('sm')]: {
    campaignRoot: {
      width: '80%',
      maxWidth: '1392px',
    },
    siteInfo: {
      maxWidth: '1392px',
    },
    searchContainer: {
      width: '450px',
    },
    clearResultsContainer: {
      maxWidth: '80%',
    },
    searchContainerSingle: {
      display: 'contents',
    },
    layoutSwitchWithoutSiteName: {
      marginRight: '5%',
    },
  },
  campaignItem: {
    /* changed from flex: 1 to support IE 11 */
    padding: '20px',
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  cleanButton: {
    marginRight: '10px',
  },
  [theme.breakpoints.up('md')]: {
    /* 2 columns for md screens */
    campaignItem: {
      width: '50%',
    },
  },
  [theme.breakpoints.up('lg')]: {
    /* 3 columns for large screens */
    searchContainer: {
      width: '450px',
    },
    campaignItem: {
      width: '33.33%',
    },
    campaignRoot: {
      width: '100%',
    },
    clearResultsContainer: {
      maxWidth: '1392px',
    },
  },
  progressHolder: {
    width: '100%',
    height: '80vh',
  },
  campaignProgress: {
    width: '100%',
  },
  layoutSwitch: {
    gridColumn: 2,
  },
  layoutSwitchWithSearch: {
    float: 'right',
  },
});

export default styles;
