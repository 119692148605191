import PropTypes from 'prop-types';
import { Container, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import useStyles from './styles';
import ContinueButtons from '../../addDonation/continueButtons/ContinueButtons';
import * as globals from '../../../common/globals';
import AutocompleteDropdown from '../../common/AutocompleteDropdown';
import CampaignForm from './CampaignForm';
import useQueryParams from '../../../hooks/common/useQueryParams';
import { useHomeContext } from '../../../views/home/context';

function ExpressForm({
  formikProps,
  transactionDetails,
  basketIds,
  campaigns,
  onSelectTile,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentFundId = formikProps.values?.fundId;
  const isFormDisabled = !currentFundId;
  const campaignData = campaigns.map((c) => ({
    id: c.id,
    value: c.content?.title,
    type: c.type,
    amount: c.paymentOptions?.price ?? 0,
  }));
  const getSelectedCampaign = (fundId) =>
    campaignData.find((campaign) => campaign.id === fundId);
  const selectedCampaign = getSelectedCampaign(currentFundId);
  const hasBasketItems = basketIds?.length > 0;
  const { campaignId } = useQueryParams();
  const { setFormDirtyState } = useHomeContext();
  const [campaignFilterValue, setCampaignFilterValue] = useState('');
  const [defaultCampaignHandled, setDefaultCampaignHandled] = useState(false);

  const handleCampaignFilterChange = (value) => setCampaignFilterValue(value);

  const updateFormValues = useCallback(
    (selectedValue = '') => {
      if (campaigns.find((c) => c.id === selectedValue)) {
        setFormDirtyState(true);
        const newValues = structuredClone(formikProps.values);

        if (newValues.fundId) {
          newValues[newValues.fundId].isSelected = false;
        }
        if (selectedValue) {
          newValues[selectedValue].isSelected = true;
        }

        newValues.fundId = selectedValue;
        formikProps.setValues(() => newValues);
      }
    },
    [campaigns, formikProps, setFormDirtyState],
  );

  useEffect(() => {
    if (!defaultCampaignHandled && campaignId) {
      updateFormValues(campaignId);
      onSelectTile(campaignId);
      setDefaultCampaignHandled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, defaultCampaignHandled, onSelectTile]);

  const getSubmitText = (type) => {
    if (hasBasketItems) {
      return 'addDonation.continue';
    }

    return type === globals.CAMPAIGN_TYPE.GIFT
      ? 'addDonation.giveNow'
      : 'addDonation.payNow';
  };

  return (
    <Form style={{ height: '80%' }}>
      <Container maxWidth="sm" className={classes.container}>
        <Box>
          <AutocompleteDropdown
            name="fundId"
            label={t('transactions.action.selectOption')}
            className={classes.inputField}
            options={campaignData}
            readOnly={campaignData?.length < globals.FILTER_ITEMS_NUMBER}
            getOptionLabel={(option) => option?.value}
            getOptionSelected={(option, value) => value?.id === option?.id}
            onChange={(selectedValue) => {
              updateFormValues(selectedValue?.id);
              onSelectTile(selectedValue?.id);
            }}
            selectedValue={selectedCampaign}
            value={campaignFilterValue}
            onInputChange={handleCampaignFilterChange}
          />
          {transactionDetails.shortDescription && (
            <Typography className={classes.shortDescription}>
              {transactionDetails.shortDescription}
            </Typography>
          )}

          <Box>
            <CampaignForm
              formikProps={formikProps}
              transactionDetails={transactionDetails}
              isFormDisabled={isFormDisabled}
              selectedCampaignType={selectedCampaign?.type}
            />
          </Box>
        </Box>

        <ContinueButtons
          id="continueButtons"
          className={classes.continueButtons}
          formik={formikProps}
          type={selectedCampaign?.type ?? globals.CAMPAIGN_TYPE.GIFT}
          disabled={isFormDisabled}
          size="medium"
          secondaryVariant="outlined"
          isExpressLayout
          inBasket={basketIds.includes(currentFundId ?? false)}
          submitText={t(
            getSubmitText(selectedCampaign?.type ?? globals.CAMPAIGN_TYPE.GIFT),
          )}
          ignorePaymentSuffix
        />
      </Container>
    </Form>
  );
}

ExpressForm.propTypes = {
  formikProps: PropTypes.object.isRequired,
  transactionDetails: PropTypes.object.isRequired,
  basketIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectTile: PropTypes.func.isRequired,
};

export default ExpressForm;
