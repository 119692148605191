function transformRecurrenceToItem(recurrence) {
  return {
    transactionData: {
      [recurrence.selectedCampaign]: {
        amount: recurrence.amount,
        frequency: recurrence.frequency,
        includeProcessingFee: false,
        startDate: recurrence.startDate,
      },
    },
    campaignDetails: {
      id: recurrence.selectedCampaign,
    },
    recurrenceId: recurrence.recurrenceId,
  };
}
export const transformPendingRecurrence = (recurrences, pendingActions) => {
  const paymentMethodMap = {};

  Object.keys(recurrences).forEach((key) => {
    const currentTransaction = recurrences[key];
    if (!paymentMethodMap[currentTransaction.paymentToken]) {
      paymentMethodMap[currentTransaction.paymentToken] = {};
      paymentMethodMap[currentTransaction.paymentToken].items = [];
      paymentMethodMap[currentTransaction.paymentToken].paymentMethod =
        pendingActions.paymentMethods.find(
          (i) => i.paymentMethodToken === currentTransaction.paymentToken,
        );
    }
    paymentMethodMap[currentTransaction.paymentToken].items.push(
      transformRecurrenceToItem({ ...currentTransaction, recurrenceId: key }),
    );
  });
  return Object.keys(paymentMethodMap).map((token) => paymentMethodMap[token]);
};

export default { transformPendingRecurrence };
