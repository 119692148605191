import * as globals from '../../../common/globals';

export const sideMargins = 24;
export const itemSpacingMargin = 20;

export default function styles(theme) {
  return {
    footer: {
      flexShrink: '0',
      // need to use padding here for cross browser functionality
      padding: `100px ${sideMargins}px 30px ${sideMargins}px`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    footerText: {
      fontWeight: 600,
      fontSize: '1.4rem',
      whiteSpace: 'nowrap',
      color: theme.palette.grey['600'],
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    footerContainerOverflow: {
      flexDirection: 'column',
    },
    footerProfileOffset: {
      marginLeft: `${globals.LEFT_NAV_SIZE_LARGE}px`,
    },
    [theme.breakpoints.only('sm')]: {
      footerProfileOffset: {
        marginLeft: `${globals.LEFT_NAV_SIZE_SMALL}px`,
      },
    },
    [theme.breakpoints.down('xs')]: {
      footerProfileOffset: {
        marginLeft: '0px',
      },
    },
    undecoratedLink: {
      textDecoration: 'none',
      color: theme.palette.grey['600'],
    },
    itemContainer: {
      display: 'flex',
      placeContent: 'center',
    },
    itemContainerOverflow: {
      flexDirection: 'column',
      whiteSpace: 'normal',
    },
    item: {
      marginRight: `${itemSpacingMargin}px`,
    },
    itemOverflow: {
      marginTop: '20px',
    },
    itemBreak: {
      display: 'none',
      borderColor: 'rgba(228, 229, 230, 0.5)',
      marginTop: '30px',
      marginBottom: '20px',
      width: '100%',
    },
    itemBreakVisible: {
      display: 'inherit',
    },
    nameLink: {
      color: theme.palette.primary.main,
    },
  };
}
