import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Profile } from '@vancoplatform/web-auth';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import styles from './styles';
import usePageTitle from '../../../hooks/common/usePageTitle';

export function Settings({ classes }) {
  const [profileRendered, setProfileRendered] = useState(false);
  const idsPayerTenant = useSelector((state) => state.location.idsPayerTenant);
  const { t } = useTranslation();

  usePageTitle(t('titles.profileSettings'));
  const iframeRef = useCallback(
    (node) => {
      if (node !== null && idsPayerTenant && !profileRendered) {
        const profile = new Profile({
          element: node,
          url: window.env.REACT_APP_IDS_DOMAIN,
          tenant: idsPayerTenant,
          clientid: window.env.REACT_APP_IDS_CLIENT_ID,
        });
        setProfileRendered(true);
        profile.render();
      }
    },
    [profileRendered, idsPayerTenant],
  );

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h2" className={classes.title}>
          {t('app.profileSettings')}
        </Typography>
      </div>
      <div
        id="ids-profile-root"
        data-testid="ids-profile-root"
        ref={iframeRef}
      />
    </>
  );
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
