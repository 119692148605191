import { bool, func, object, objectOf, string } from 'prop-types';
import { Button, Typography, withStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import styles from './styles';

function SwitchAccount({ classes, user, onAction, isLogOut }) {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div>
        <Typography>{t('mpPayment.signed.as')}</Typography>
        <Typography
          className={classes.name}
        >{`${user.given_name?.toUpperCase()} ${user.family_name?.toUpperCase()}`}</Typography>
      </div>
      <Button
        className={classes.switchButton}
        data-testid="switch-button"
        onClick={onAction}
        color="primary"
      >
        {t(isLogOut ? 'app.logOut' : 'mpPayment.switch.account')}
      </Button>
    </div>
  );
}

SwitchAccount.propTypes = {
  classes: objectOf(string).isRequired,
  user: object.isRequired,
  onAction: func.isRequired,
  isLogOut: bool.isRequired,
};

export default withStyles(styles)(SwitchAccount);
