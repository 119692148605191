import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { getIn } from 'formik';
import FormHelperTextBottom from './FormHelperTextBottom/index';
import getCountText from '../../utils/text/getCountText';
import OutlinedTextField from './OutlinedTextField/index';

export default function TextFieldWithCharCount({
  field,
  form,
  maxLength,
  formClassName,
  helperTextClass,
  ...props
}) {
  const { ...other } = props;
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;
  return (
    <Grid item md={12} sm={12} xs={12} container>
      <Grid item xs={12} className={formClassName}>
        <OutlinedTextField
          id={name}
          name={name}
          value={field.value}
          disabled={isSubmitting}
          inputProps={{
            maxLength,
            'data-testid': { name },
          }}
          onChange={event =>
            form.setFieldValue(name, event.target.value, true)
          }
          onBlur={() => form.setFieldTouched(name, true, false)}
          error={showError}
          {...other}
        />
      </Grid>
      <Grid item xs={12}>
        <FormHelperTextBottom
          className={helperTextClass}
          data-testid={`description-counter-${name}`}
          left={showError && fieldError}
          right={
            errors.customButtonText && touched.customButtonText
              ? ''
              : getCountText(field.value ? field.value.length : 0, maxLength)
          }
        />
      </Grid>
    </Grid>
  );
}

TextFieldWithCharCount.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  maxLength: PropTypes.number.isRequired,
  helperTextClass: PropTypes.string,
  formClassName: PropTypes.string,
};

TextFieldWithCharCount.defaultProps = {
  formClassName: '',
  helperTextClass: '',
};
