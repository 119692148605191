export default function getKeysNotIn(
  obj: Record<string, unknown>,
  allowedKeys: string[]
) {
  const notAllowed: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (allowedKeys.indexOf(key) === -1) {
      notAllowed.push(key);
    }
  });
  return notAllowed;
}
