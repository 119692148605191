import { useRef, useEffect, useState } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Typography, withStyles } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { compose } from 'redux';
import validator from 'validator';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import * as globals from '../../../common/globals';
import PhoneNumberFormat from '../PhoneNumberFormat';
import styles, { sideMargins, itemSpacingMargin } from './styles';

export function Footer(props) {
  const { classes, loc, location, features } = props;

  const containerRef = useRef(null);

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const addressRef = useRef(null);
  const vancoRef = useRef(null);

  // overflow for the full footer including the static vanco items
  const [containerOverflow, setContainerOverflow] = useState(false);
  // overflow for just the organization items
  const [orgInfoOverflow, setOrgInfoOverflow] = useState(false);

  const { t } = useTranslation();

  const formatAddress = (address) => {
    let result = '';
    if (address.line1) {
      result += `${address.line1}, `;
    }
    if (address.line2) {
      result += `${address.line2}, `;
    }
    if (address.city) {
      result += `${address.city}, `;
    }
    if (address.state) {
      result += `${address.state} `;
    }
    if (address.zip) {
      result += address.zip;
    }
    return result;
  };

  const getElementWidth = (ref) => {
    if (ref) {
      return ref.scrollWidth + itemSpacingMargin;
    }
    return 0;
  };

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  /**
   * This hook checks to see if the footer contents are overflowing their container, and if so, adjusts the layout to compensate.
   * There's two breakpoints, one drops the vanco items below and the second switches the organization items into a column layout.
   */
  useEffect(() => {
    /**
     * Takes a list of footer dom nodes and computes their combined length, including calculations for extra whitespace
     */
    const getLineWidth = (nodes) => {
      const baseLength = nodes.reduce((length, node) => {
        return length + getElementWidth(node);
      }, 0);
      /* subtract the extra whitespace at the end of the last item, and account for the padding on the container since padding adds to the element size */
      return baseLength - itemSpacingMargin + 2 * sideMargins;
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    if (
      nameRef.current ||
      emailRef.current ||
      phoneRef.current ||
      addressRef.current ||
      vancoRef.current
    ) {
      /* checks if all the contents of the footer together are bigger than the container */
      if (
        getLineWidth([
          nameRef.current,
          emailRef.current,
          phoneRef.current,
          addressRef.current,
          vancoRef.current,
        ]) > containerRef.current.clientWidth
      ) {
        setContainerOverflow(true);
      } else {
        setContainerOverflow(false);
      }
      /* checks if the organization items alone are bigger than the container */
      if (
        getLineWidth([
          nameRef.current,
          emailRef.current,
          phoneRef.current,
          addressRef.current,
        ]) > containerRef.current.clientWidth
      ) {
        setOrgInfoOverflow(true);
      } else {
        setOrgInfoOverflow(false);
      }
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth, loc, location.pathname]);

  return (
    <Typography className={classes.footerText} component="div">
      <div
        className={`${classes.footer} ${
          containerOverflow ? classes.footerContainerOverflow : ''
        } ${
          location.pathname.includes(`/${globals.PROFILE_PATH}`)
            ? classes.footerProfileOffset
            : ''
        }`}
        ref={containerRef}
      >
        <div
          id="org-items"
          className={`${classes.itemContainer} ${
            orgInfoOverflow ? classes.itemContainerOverflow : ''
          }`}
        >
          {loc.parentOrgName && (
            <div className={classes.itemContainer}>
              {features.FilterFeature && <FilterList fontSize="small" />}
              {loc.homePageUrl ? (
                <a
                  id="footer-name"
                  ref={nameRef}
                  href={loc.homePageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${classes.item} ${classes.nameLink}`}
                >
                  {loc.parentOrgName}
                </a>
              ) : (
                <div id="footer-name" ref={nameRef} className={classes.item}>
                  {loc.parentOrgName}
                </div>
              )}
            </div>
          )}
          {loc.contact.email && (
            <div className={classes.itemContainer}>
              <div
                id="footer-email"
                ref={emailRef}
                className={`${classes.item} ${
                  orgInfoOverflow ? classes.itemOverflow : ''
                }`}
              >
                {loc.contact.email}
              </div>
            </div>
          )}
          {loc.contact.phone && validator.isInt(loc.contact.phone) && (
            <div className={classes.itemContainer}>
              <div
                id="footer-phone"
                ref={phoneRef}
                className={`${classes.item} ${
                  orgInfoOverflow ? classes.itemOverflow : ''
                }`}
              >
                <PhoneNumberFormat value={loc.contact.phone} />
              </div>
            </div>
          )}
          {loc.address && formatAddress(loc.address) && (
            <div className={classes.itemContainer}>
              <div
                id="footer-address"
                ref={addressRef}
                className={`${classes.item} ${
                  orgInfoOverflow ? classes.itemOverflow : ''
                }`}
              >
                {formatAddress(loc.address)}
              </div>
            </div>
          )}
        </div>
        <hr
          className={`${classes.itemBreak} ${
            containerOverflow ? classes.itemBreakVisible : ''
          }`}
        />
        <div className={classes.itemContainer}>
          <div
            ref={vancoRef}
            id="vanco-items"
            className={classes.itemContainer}
          >
            <span className={classes.item}>&copy; Vanco</span>
            <Link
              className={`${classes.item} ${classes.undecoratedLink}`}
              href="https://www.vancopayments.com/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.terms')}
            </Link>
            <Link
              className={`${classes.item} ${classes.undecoratedLink}`}
              href="https://www.vancopayments.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.privacy')}
            </Link>
          </div>
        </div>
      </div>
    </Typography>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  loc: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loc: state.location,
  features: state.features.features,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
)(Footer);
