import PropTypes from 'prop-types';
import { Button, CardMedia, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as globals from '../../../common/globals';
import styles from './styles';
import ErrorBody from './errorComponents/ErrorBody';

export function LocationError({ match, classes, location, notFound }) {
  const { locId } = match.params;
  const reasonRegex = /reason=([^&#]*)/;
  const reason = reasonRegex.exec(location.search)?.[1];
  const missingIdRegex = /missingId=([^&#]*)/;
  const missingId = missingIdRegex.exec(location.search)?.[1];

  const { t } = useTranslation();

  const renderError = () => {
    if (notFound) {
      return (
        <ErrorBody
          title={t('error.location.notFound.title')}
          body={t('error.location.notFound.body')}
        />
      );
    }
    if (reason) {
      switch (reason) {
        case globals.ERROR_SERVER:
          return (
            <ErrorBody
              title={t('error.location.server.title')}
              body={t('error.location.server.body')}
            />
          );
        case globals.ERROR_MISSING_CAMPAIGN:
          return (
            <ErrorBody
              title={t('error.location.missingCampaign.title')}
              body={
                missingId
                  ? t('error.location.missingCampaign.body', {
                      missingId,
                    })
                  : t('error.location.missingCampaign.noId')
              }
            />
          );
        default:
          return (
            <ErrorBody
              title={t('error.location.base.title')}
              body={t('error.location.base.body')}
            />
          );
      }
    }
    return (
      <ErrorBody
        title={t('error.location.base.title')}
        body={t('error.location.base.body')}
      />
    );
  };

  return (
    <div className={classes.errorPageRoot}>
      <CardMedia
        className={classes.errorImage}
        image="https://images.unsplash.com/photo-1531206715517-5c0ba140b2b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjczMTgyfQ&auto=format&fit=crop&w=1650&q=80"
      />
      <div className={classes.errorBody}>{renderError()}</div>
      <div className={classes.backToHome}>
        <Link to={`/${locId}/home`} id="home-link">
          <Button
            className={classes.homeButton}
            color="primary"
            variant="contained"
          >
            {t('thankYou.goHome')}
          </Button>
        </Link>
      </div>
    </div>
  );
}

LocationError.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  notFound: PropTypes.bool,
};

LocationError.defaultProps = {
  notFound: false,
};

export default withStyles(styles)(LocationError);
