import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFrameInfo } from '../../actions/frame';
import { fetchLocationTenant } from '../../actions/location';
import {
  checkIdsSessionState,
  checkStoredSession,
  processRenewAuth,
} from '../../actions/session';
import AuthService from '../../api/AuthService';
import {
  IDS_FROM_LOG_OUT,
  IDS_POST_REDIRECT,
  POCKET_PLATFORM_ORIGIN,
  TRANSACTIONS_ORIGIN,
} from '../../common/globals';
import {
  getCurrentPathNameWithParams,
  getSearchParamByKey,
  inIframe,
} from '../../common/utils';

export function useGetIdsSession(inStreamingApp, session, location, features) {
  const dispatch = useDispatch();
  const isSessionStateValid =
    typeof session.idsSessionState !== 'undefined' &&
    session.idsSessionState !== null;
  const isPostRedirect = window.location.pathname.includes(IDS_POST_REDIRECT);
  const isFromLogOut = getSearchParamByKey(IDS_FROM_LOG_OUT);
  const isInIframe = inIframe();
  const isPocketPlatform =
    new URLSearchParams(window.location.search).get(TRANSACTIONS_ORIGIN) ===
    POCKET_PLATFORM_ORIGIN;
  const requiresNonInteractiveLogin = !!(
    !session.idsNonInteractiveError &&
    !inStreamingApp &&
    session.isSessionLoaded &&
    !session.loggedIn &&
    !session.logOutRequired &&
    !isFromLogOut &&
    !isInIframe &&
    (!isPocketPlatform ||
      (features.hasLoadedFeatures && !features?.features?.LoginModal))
  );

  const nonInteractiveLoginComplete =
    !!session.loggedIn ||
    !!session.idsNonInteractiveError ||
    isInIframe ||
    (features?.hasLoadedFeatures && !features?.features?.LoginModal
      ? false
      : isPocketPlatform);

  useEffect(() => {
    if (inStreamingApp && !isSessionStateValid) {
      dispatch(processRenewAuth());
    }
  }, [dispatch, inStreamingApp, isSessionStateValid]);

  useEffect(() => {
    if (inStreamingApp && isSessionStateValid) {
      // delay updates
      setTimeout(() => {
        (async () => {
          await dispatch(checkIdsSessionState());
        })();
      }, 1000);
    }
  }, [
    dispatch,
    inStreamingApp,
    isSessionStateValid,
    session.lastTimeSessionChecked,
  ]);

  useEffect(() => {
    dispatch(checkStoredSession());
    dispatch(getFrameInfo());
  }, [dispatch]);

  useEffect(() => {
    if (!isPostRedirect && !inStreamingApp && requiresNonInteractiveLogin) {
      const fetchTenant = async () => {
        await dispatch(fetchLocationTenant());
      };

      const tenant = location.idsPayerTenant;
      if (tenant) {
        AuthService.nonInteractiveLogin(tenant, getCurrentPathNameWithParams());
      } else {
        fetchTenant();
      }
    }
  }, [
    dispatch,
    isPostRedirect,
    inStreamingApp,
    location.idsPayerTenant,
    requiresNonInteractiveLogin,
  ]);

  return nonInteractiveLoginComplete;
}

export default useGetIdsSession;
