import i18next from 'i18next';
import {
  SELECTED_PAYMENT_METHOD_FETCH,
  SELECTED_PAYMENT_METHOD_COMPLETE,
  SELECTED_PAYMENT_METHOD_ERROR,
  PAYMENT_METHOD_SAVE,
  PAYMENT_METHOD_SAVE_COMPLETE,
  PAYMENT_ID_FETCH,
  PAYMENT_ID_ERROR,
  PAYMENT_ID_COMPLETE,
  SET_HEXEA_OBJECT,
  PAYMENT_ID_CLEAR,
  PAYMENT_METHOD_SAVE_ERROR,
} from './actionTypes';
import * as storageUtils from '../common/storageUtils';
import PaymentService from '../api/PaymentService';
import hexeaPromise from '../hexea';
import { VP_CAMPAIGNS_PAYMENT_HEADER_NAME } from '../common/globals';

export function setHexeaObject(pcct) {
  return async (dispatch) => {
    const hexea = await hexeaPromise;
    dispatch({
      type: SET_HEXEA_OBJECT,
      hexeaObject: hexea(pcct),
    });
  };
}

export function fetchSelectedPaymentMethod(locationId) {
  return async (dispatch) => {
    dispatch({ type: SELECTED_PAYMENT_METHOD_FETCH });

    const paymentId = storageUtils.getPaymentIdFromStorage(locationId);

    if (paymentId) {
      let response;
      try {
        response = await PaymentService.fetchSavedPaymentMethod(paymentId);
      } catch (error) {
        dispatch({ type: SELECTED_PAYMENT_METHOD_ERROR });
        throw error;
      }

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        const paymentMethodDetails = response.data.data;

        return dispatch({
          type: SELECTED_PAYMENT_METHOD_COMPLETE,
          paymentMethodDetails,
        });
      }

      dispatch({ type: SELECTED_PAYMENT_METHOD_ERROR });
      throw new Error(i18next.t('errors.noPaymentMethod'));
    }

    dispatch({ type: SELECTED_PAYMENT_METHOD_ERROR });
    throw new Error(i18next.t('errors.noPaymentId'));
  };
}

export function saveSelectedPaymentMethod(
  paymentMethodDetails,
  paymentId,
  guestId,
) {
  return async (dispatch, store) => {
    dispatch({ type: PAYMENT_METHOD_SAVE });

    let response;
    try {
      response = await PaymentService.saveSelectedPaymentMethod(
        paymentMethodDetails,
        paymentId,
        guestId,
      );
    } catch (error) {
      dispatch({
        type: PAYMENT_METHOD_SAVE_ERROR,
      });
      throw error;
    }

    if (response && (response.status === 200 || response.status === 201)) {
      const { headers } = response;
      const { location } = store();
      storageUtils.setVancoCampaignsPaymentHeaderStorage(
        location.id,
        headers['vp-campaigns-payment-header'],
      );
      return dispatch({
        type: PAYMENT_METHOD_SAVE_COMPLETE,
      });
    }

    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function clearSelectedPaymentMethod(locationId) {
  return async (dispatch) => {
    storageUtils.clearPaymentIdFromStorage(locationId);
    storageUtils.clearVancoCampaignsPaymentHeaderStorage(locationId);
    return dispatch({
      type: PAYMENT_ID_CLEAR,
    });
  };
}

export function fetchPaymentID(locationId) {
  return async (dispatch) => {
    dispatch({ type: PAYMENT_ID_FETCH });

    let response;
    try {
      response = await PaymentService.getPaymentId(locationId);
    } catch (error) {
      dispatch({ type: PAYMENT_ID_ERROR });
      throw error;
    }
    if (response?.status === 201 && response?.data?.data) {
      const {
        data: {
          data: { payment },
        },
        headers,
      } = response;
      storageUtils.setPaymentIdToStorage(locationId, payment.id);
      storageUtils.setVancoCampaignsPaymentHeaderStorage(
        locationId,
        headers[VP_CAMPAIGNS_PAYMENT_HEADER_NAME],
      );
      return dispatch({ type: PAYMENT_ID_COMPLETE, payment });
    }

    dispatch({ type: PAYMENT_ID_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}
