export interface Cache<T> {
  get: (key: string) => T;
  has: (key: string) => boolean;
  set: (key: string, value: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class DummyCache implements Cache<any> {
  get() {
    return null;
  }
  has() {
    return false;
  }
  set() {
    return null;
  }
}
