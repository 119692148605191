import i18next from 'i18next';
import moment from 'moment';
import * as Yup from 'yup';
import * as globals from '../globals';
import { isValid } from '../../utils/text/stringValidation';
import { hasValidCharsOnly } from '../../utils/text/getSupportedSpecialChars';

const getGeneralSchema = (item) =>
  Yup.object().shape({
    isSelected: Yup.bool().required(),
    memoLine: Yup.string().when('isSelected', {
      is: true,
      then: (s) =>
        s.test(
          'memoLineIsRequired',
          (value) => {
            return !isValid(value?.value)
              ? i18next.t('formValidation.required')
              : i18next.t('errors.invalidChar');
          },
          (value) => {
            // no validation is required
            if (!item?.showTextField) return true;

            // always check if text is valid
            const isValidValue = isValid(value);

            // if its not required then text is not required either
            if (!isValidValue) {
              return !item?.responseRequired;
            }

            // always run char restriction verification
            // optional and required text must pass the char restriction
            return hasValidCharsOnly(value);
          },
        ),
    }),
  });

const createGiftsSchema = () =>
  Yup.object().shape({
    fundId: Yup.string().required().nullable(),
    amount: Yup.string().when('isSelected', {
      is: true,
      then: (s) =>
        s
          .required()
          .test(
            'isAmountTooLow',
            i18next.t('formValidation.amountTooLow'),
            (value) => {
              return parseFloat(value) >= globals.MIN_TRANSACTION_AMOUNT;
            },
          ),
    }),
    recurringOption: Yup.string().required(),
    frequency: Yup.string().when('isSelected', {
      is: true,
      then: (s) => s.required(),
    }),
    startDate: Yup.object()
      .nullable()
      .when('isSelected', {
        is: true,
        then: (s) =>
          s
            .nullable()
            .test(
              'isRequired',
              i18next.t('formValidation.required'),
              function test(value) {
                if (
                  this.parent.frequency ===
                  globals.PAYMENT_FREQUENCY_ONE_TIME_NOW
                )
                  return true;
                return !!value;
              },
            )
            .test(
              'isScheduled',
              i18next.t('formValidation.selectNewDate'),
              function test(value) {
                const today = moment()
                  .hour(12)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0);

                let valid = true;
                let message = '';

                // Note: we need to return true if value is falsey so that it fails the required validation
                if (
                  value &&
                  this.parent.frequency !==
                    globals.PAYMENT_FREQUENCY_ONE_TIME_NOW
                ) {
                  if (value.isBefore(today) || !value.isAfter(today)) {
                    valid = false;
                    message = i18next.t('formValidation.selectNewDate');
                  }
                }

                return valid || this.createError({ message });
              },
            )
            .test(
              'isDateOverOneYear',
              i18next.t('formValidation.selectLessThanOneYear'),
              (value) => {
                return value
                  ? value.isBefore(
                      moment()
                        .add(1, 'year')
                        .hour(12)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0),
                    )
                  : true;
              },
            ),
      }),
    endDate: Yup.object()
      .nullable()
      .when('isSelected', {
        is: true,
        then: (s) =>
          s.nullable().test('enddate', function validate(value) {
            const { startDate, recurringOption } = this.parent;
            // endDate is required for Installments
            const endDateNotSet = value === null || value === undefined;
            if (
              recurringOption ===
                globals.PAYMENT_FREQUENCY_OPTIONS.INSTALLMENT &&
              endDateNotSet
            )
              return this.createError({
                message: i18next.t('formValidation.required'),
              });
            // start date is a required field - so dont trigger errors for this field when start date is missing
            // since this field is optional allow for value to be null
            if (
              startDate === null ||
              startDate === undefined ||
              value === null ||
              value === undefined
            ) {
              return true;
            }
            // end date must be after - cannot be on the same day or before
            return startDate.isValid() && moment(value).isAfter(startDate)
              ? true
              : this.createError({
                  message: i18next.t('formValidation.endDateMustBeGreater', {
                    startDate: startDate.format('L'),
                  }),
                });
          }),
      }),
    installmentsValid: Yup.bool().test(
      'installmentsValid',
      function validate(value) {
        const { recurringOption } = this.parent;

        if (
          !value &&
          recurringOption === globals.PAYMENT_FREQUENCY_OPTIONS.INSTALLMENT
        )
          return this.createError({
            message: i18next.t('formValidation.installments'),
          });

        return true;
      },
    ),
  });

const createPaymentsSchema = () =>
  Yup.object().shape({
    quantity: Yup.number().when('isSelected', {
      is: true,
      then: (s) =>
        s
          .typeError(`${i18next.t('formValidation.quantity')}`)
          .required(`${i18next.t('formValidation.required')}`)
          .nullable()
          .test(
            'invalidQuantity',
            `${i18next.t('formValidation.quantity')}`,
            (value) => value > 0,
          ),
    }),
  });

const campaignTypeSchemaMap = {
  [globals.CAMPAIGN_TYPE.GIFT]: createGiftsSchema,
  [globals.CAMPAIGN_TYPE.PAYMENT]: createPaymentsSchema,
};

const createMigratedPaymentsSchema = (campaigns) => {
  return campaigns.reduce((map, item) => {
    const generalSchema = getGeneralSchema(item);
    return {
      ...map,
      [item.id]: (campaignTypeSchemaMap[item.type] ?? createGiftsSchema)(
        item,
      ).concat(generalSchema),
    };
  }, {});
};

export const createSchema = (campaigns) => {
  Yup.setLocale({
    mixed: {
      required: `${i18next.t('formValidation.required')}`,
    },
  });

  const schema = campaigns
    ? Yup.object().shape(createMigratedPaymentsSchema(campaigns))
    : Yup.object();

  return schema;
};

export default createSchema;
