import { object } from '@vancoplatform/utils';

const authorizeParams = [
  // oauth2
  'client_id',
  'response_type',
  'response_mode',
  'redirect_uri',
  'audience',
  'scope',
  'state',
  'nonce',
  'display',
  'prompt',
  'max_age',
  'ui_locales',
  'claims_locales',
  'id_token_hint',
  'login_hint',
  'acr_values',
  'claims',
  'registration',
  'request',
  'request_uri',
  'code_challenge',
  'code_challenge_method',

  // Custom
  'tenant',
  'sso',
  'redirect_mode',
  'color',
  'provider',
];

const signOnParams = ['provider', 'returnUrl', 'display', 'loginHint'];

export function oauthAuthorizeParams(params: Record<string, unknown>) {
  const notAllowed = object.getKeysNotIn(params, authorizeParams);

  if (notAllowed.length > 0) {
    console.warn(
      'Following parameters are not allowed on the `/oidc/authorize` endpoint: ' +
        `[${notAllowed.join(',')}]`
    );
  }

  return params;
}

export function oauthSignOnParams(params: Record<string, unknown>) {
  const notAllowed = object.getKeysNotIn(params, signOnParams);

  if (notAllowed.length > 0) {
    console.warn(
      'Following parameters are not allowed on the `/oidc/authorize` endpoint: ' +
        `[${notAllowed.join(',')}]`
    );
  }

  return params;
}
