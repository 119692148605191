import { Button, CardMedia, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from '../../views/error/location/styles';
import ErrorBody from '../../views/error/location/errorComponents/ErrorBody';

/**
 * The NoPaymentsSupported class is a functional component that renders a message indicating that no payment methods are supported. It receives a classes prop from its parent component, which is used to apply styles to the container div. The message displayed is obtained from a translation file using the Trans component from the react-i18next library.
 * @param {*} param0
 * @returns
 */
function NoPaymentsSupported({ classes, locId }) {
  const { t } = useTranslation();

  return (
    <div className={classes.errorPageRoot}>
      <CardMedia
        className={classes.errorImage}
        image="https://images.unsplash.com/photo-1531206715517-5c0ba140b2b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjczMTgyfQ&auto=format&fit=crop&w=1650&q=80"
      />
      <div className={classes.errorBody}>
        <ErrorBody
          title={t('errors.somethingWrong')}
          body={t('paymentMethods.alerts.nothingSupported')}
        />
      </div>
      <div className={classes.backToHome}>
        <Link to={`/${locId}/home`} id="home-link">
          <Button
            className={classes.homeButton}
            color="primary"
            variant="contained"
          >
            {t('thankYou.goHome')}
          </Button>
        </Link>
      </div>
    </div>
  );
}

NoPaymentsSupported.propTypes = {
  classes: PropTypes.object.isRequired,
  locId: PropTypes.string.isRequired,
};

export default withStyles(styles)(NoPaymentsSupported);
