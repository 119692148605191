import qs from 'qs';
import axios from 'axios';

import { assert, object, url } from '@vancoplatform/utils';
import * as parametersWhitelist from './helper/parameters-whitelist';
import { AuthorizationParameters, TenantInfo, UserClaims } from './types';

type WebAuthOptions = AuthorizationParameters & {
  domain: string;
  color?: string;
};

interface BaseOptions extends WebAuthOptions {
  rootUrl: string;
}

export type SignOnOptions = AuthorizationParameters & {
  tenant?: string;
  provider?: string;
  mode?: string;
};

class Authentication {
  baseOptions: BaseOptions;

  /**
   * Creates a new Identity Service Authentication API Client
   * @param {WebAuthOptions} options
   * @param {String} options.domain the IDS Domain
   * @param {String} options.clientId your client identifier
   * @param {String} options.redirectUri url that the Identity Service will redirect after Auth with
   * the Authorization Response
   * @param {String} options.responseType type of the response used by OAuth 2.0 flow. It can
   * be any space separated list of the values `code`, `token`, `id_token`.
   * {@link https://openid.net/specs/oauth-v2-multiple-response-types-1_0}
   * @param {String} options.redirectUri url that login.js will redirect to after Auth
   * @param {String} options.scope scopes to be requested during Auth
   * @param {String} options.audience Resource servers who will consume the access token issued
   * @param {String} options.color Hex representation of a color to override the IDS default primary theme color
   * afterAuth
   */
  constructor(options: WebAuthOptions) {
    assert.check(
      options,
      { type: 'object', message: 'options parameter is not valid' },
      {
        domain: { type: 'string', message: 'domain option is required' },
        clientId: { type: 'string', message: 'clientId option is required' },
        tenant: {
          optional: true,
          type: 'string',
          message: 'tenant is not valid',
        },
        responseType: {
          optional: true,
          type: 'string',
          message: 'responseType is not valid',
        },
        responseMode: {
          optional: true,
          type: 'string',
          message: 'responseMode is not valid',
        },
        redirectUri: {
          optional: true,
          type: 'string',
          message: 'redirectUri is not valid',
        },
        scope: {
          optional: true,
          type: 'string',
          message: 'scope is not valid',
        },
        audience: {
          optional: true,
          type: 'string',
          message: 'audience is not valid',
        },
        color: {
          optional: true,
          type: 'string',
          message: 'color is not valid',
        },
      }
    );

    this.baseOptions = {
      ...options,
      rootUrl: 'https://' + options.domain,
    };
  }

  buildAuthorizeUrl(options: AuthorizationParameters = {}) {
    assert.check(options, {
      type: 'object',
      message: 'options parameter is not valid',
    });

    const params = object
      .merge(this.baseOptions, [
        'clientId',
        'tenant',
        'responseType',
        'responseMode',
        'redirectUri',
        'scope',
        'audience',
        'acrValues',
        'color',
        'maxAge',
      ])
      .with(options);

    assert.check(
      params,
      { type: 'object', message: 'options parameter is not valid' },
      {
        clientId: { type: 'string', message: 'clientId option is required' },
        tenant: {
          optional: true,
          type: 'string',
          message: 'tenant is not valid',
        },
        redirectUri: {
          optional: true,
          type: 'string',
          message: 'redirectUri is not valid',
        },
        responseType: { type: 'string', message: 'responseType is required' },
        nonce: {
          type: 'string',
          message: 'nonce option is required',
          condition: (o) =>
            o.responseType.indexOf('code') === -1 &&
            o.responseType.indexOf('id_token') !== -1,
        },
        scope: {
          optional: true,
          type: 'string',
          message: 'scope is not valid',
        },
        audience: {
          optional: true,
          type: 'string',
          message: 'audience is not valid',
        },
        color: {
          optional: true,
          type: 'string',
          message: 'color is not valid',
        },
      }
    );

    const qString = qs.stringify(
      parametersWhitelist.oauthAuthorizeParams(object.toSnakeCase(params))
    );

    return url.join(this.baseOptions.rootUrl, 'oidc/authorize', '?' + qString);
  }

  /**
   * @method buildSignOnUrl
   * @param {String} options.redirectUri url that login.js will redirect to after Auth
   * (must be an auth server domain)
   * @param {String} options.provider provider that will be used to login
   */
  buildSignOnUrl(options: SignOnOptions) {
    assert.check(
      options,
      { type: 'object', message: 'options parameter is not valid' },
      {
        provider: {
          optional: true,
          type: 'string',
          message: 'provider is not valid',
        },
      }
    );

    const tenant = options.tenant;
    delete options.tenant;

    const qString = qs.stringify(object.toSnakeCase(options));

    return url.join(this.baseOptions.rootUrl, tenant, 'sign-on', '?' + qString);
  }

  buildLogoutUrl(options = {}) {
    assert.check(options, {
      type: 'object',
      message: 'options parameter is not valid',
    });

    let params = object.merge(this.baseOptions, ['clientId']).with(options);

    params = object.toSnakeCase(params);

    const qString = qs.stringify(params);

    return url.join(this.baseOptions.rootUrl, 'oidc/logout', `?${qString}`);
  }

  async userInfo(accessToken: string) {
    const userInfoUrl = url.join(this.baseOptions.rootUrl, 'api/userinfo');

    const response = await axios.get<UserClaims>(userInfoUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  }

  async tenantInfo(tenant: string) {
    const tenantInfoUrl = url.join(
      this.baseOptions.rootUrl,
      'api/tenants',
      tenant
    );
    const response = await axios.get<TenantInfo>(tenantInfoUrl);
    return response.data;
  }

  async exchange(options) {
    assert.check(options, {
      type: 'object',
      message: 'options parameter is not valid',
    });

    const params = object
      .merge(this.baseOptions, ['clientId', 'redirectUri'])
      .with(options, [
        'clientId',
        'redirectUri',
        'grantType',
        'code',
        'codeVerifier',
      ]);

    assert.check(
      params,
      { type: 'object', message: 'options parameter is not valid' },
      {
        clientId: { type: 'string', message: 'clientId option is required' },
        redirectUri: {
          type: 'string',
          message: 'redirectUri is not valid',
        },
      }
    );

    params.grantType = params.grantType || 'authorization_code';

    const exchangeUrl = url.join(this.baseOptions.rootUrl, 'oauth/token');

    try {
      const response = await axios.post(
        exchangeUrl,
        qs.stringify(object.toSnakeCase(params)),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      return response.data;
    } catch (err) {
      let error;
      if (err.response) {
        const { data } = err.response;
        error = new Error(data.error_description);
        error.error = data.error;
        error.errorDescription = data.error_description;
      } else {
        error = new Error('Unable to exchange code');
        error.error = 'invalid_code';
        error.errorDescription = 'Unable to exchange code';
      }
      throw error;
    }
  }
}

export default Authentication;
