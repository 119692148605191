/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Card,
  IconButton,
  Button,
  useMediaQuery,
  Dialog,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isIOS, isMacOs, isMobileOnly } from 'react-device-detect';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FacebookIcon } from '../../images/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../images/twitter.svg';
import { ReactComponent as WhatsappIcon } from '../../images/whatsapp.svg';
import { ReactComponent as EmailIcon } from '../../images/email.svg';
import { ReactComponent as SmsIcon } from '../../images/sms.svg';
import styles from './dialog/styles';
import useSocialShareData from '../../hooks/socialShareHooks/socialShareHooks';

function SocialShareDialog(props) {
  const { campaign, open, classes, handleClose, loc } = props;
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { siteUrl, callToActionText, onSMSClick, onCopy } = useSocialShareData(
    loc,
    campaign,
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialog }}
    >
      <div className={classes.dialogRoot}>
        <div id="title-header" className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography
              id="social-dialog-header"
              className={classes.headerText}
              variant="h6"
            >
              {`${t('socialShare.header')} ${campaign?.title || loc.name}`}
            </Typography>
            <Typography component="div" className={classes.subtitle}>
              <div id="social-dialog-subheader">
                {t('socialShare.subheader')}
              </div>
            </Typography>
          </div>
          <div className={classes.closeButton}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              id="social-share-close-button"
              data-testid="social-share-close-button"
              className={classes.closeIcon}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div id="social-share-options" className={classes.summary}>
          <Card className={classes.summaryCard}>
            <Typography
              component="div"
              id="line-item"
              className={classes.optionLineItem}
            >
              <FacebookShareButton
                className={classes.optionButton}
                url={siteUrl}
                quote={callToActionText}
              >
                <FacebookIcon className={classes.optionIcon} />
                <span className={classes.optionName}>
                  {t('socialShare.facebook')}
                </span>
              </FacebookShareButton>
            </Typography>
            <Typography
              component="div"
              id="line-item"
              className={classes.optionLineItem}
            >
              <TwitterShareButton
                className={classes.optionButton}
                url={siteUrl}
                title={callToActionText}
              >
                <TwitterIcon className={classes.optionIcon} />
                <span className={classes.optionName}>
                  {t('socialShare.twitter')}
                </span>
              </TwitterShareButton>
            </Typography>
            <Typography
              component="div"
              id="line-item"
              className={classes.optionLineItem}
            >
              <WhatsappShareButton
                className={classes.optionButton}
                url={siteUrl}
                title={callToActionText}
              >
                <WhatsappIcon className={classes.optionIcon} />
                <span className={classes.optionName}>
                  {t('socialShare.whatsApp')}
                </span>
              </WhatsappShareButton>
            </Typography>
            {(isMobileOnly || isIOS || isMacOs) && (
              <Typography
                component="div"
                id="line-item"
                className={classes.optionLineItem}
              >
                <Button className={classes.optionButton} onClick={onSMSClick}>
                  <SmsIcon className={classes.optionIcon} />
                  <span className={classes.optionName}>
                    {isSmallScreen
                      ? t('socialShare.sms.truncated')
                      : t('socialShare.sms')}
                  </span>
                </Button>
              </Typography>
            )}
            <Typography
              component="div"
              id="line-item"
              className={classes.optionLineItem}
            >
              <EmailShareButton
                className={classes.optionButton}
                url={siteUrl}
                subject={t('socialShare.emailSubject', {
                  orgName: loc.parentOrgName,
                })}
                body={callToActionText}
              >
                <EmailIcon className={classes.optionIcon} />
                <span className={classes.optionName}>
                  {t('socialShare.email')}
                </span>
              </EmailShareButton>
            </Typography>
          </Card>
        </div>
        <div className={classes.copyLink}>
          {!isSmallScreen && (
            <Typography className={classes.copyLinkText}>
              {t('socialShare.copyLink')}
            </Typography>
          )}
          <div className={classes.sectionGutter}>
            <div tabIndex={0} className={classes.codeContainer}>
              <Typography component="span" className={classes.url}>
                {siteUrl}
              </Typography>
              <Typography
                onClick={onCopy}
                component="span"
                className={classes.copyControl}
              >
                <InsertLinkIcon />
                {t('common.copy')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

SocialShareDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  campaign: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  loc: PropTypes.object.isRequired,
};

SocialShareDialog.defaultProps = {
  campaign: null,
};

const mapStateToProps = (state) => ({
  loc: state.location,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(SocialShareDialog);
