import PropTypes from 'prop-types';
import { useCallback } from 'react';

function ChildrenBlur({ children, onBlur, 'data-testid': testId, ...props }) {
  const handleBlur = useCallback(
    (e) => {
      const { currentTarget } = e;

      // Give browser time to focus the next element
      requestAnimationFrame(() => {
        // Check if the new focused element is a child of the original container
        if (!currentTarget.contains(document.activeElement)) {
          onBlur();
        }
      });
    },
    [onBlur],
  );

  return (
    <div {...props} data-testid={testId} onBlur={handleBlur}>
      {children}
    </div>
  );
}

ChildrenBlur.propTypes = {
  children: PropTypes.node.isRequired,
  onBlur: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
};

ChildrenBlur.defaultProps = {
  'data-testid': null,
};

export default ChildrenBlur;
