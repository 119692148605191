import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import AuthService from '../../api/AuthService';
import {
  IDS_STATE_TOKEN,
  MPPAYMENT_LOGIN__PATH,
} from '../../../common/globals';
import { getSearchParamByKey } from '../../../common/utils';

function PaymentLogin({ loggedIn }) {
  const { tenant, vpSessionId } = useParams();

  useEffect(() => {
    const stateToken = getSearchParamByKey(IDS_STATE_TOKEN);
    if (!loggedIn && tenant && vpSessionId) {
      const redirectUrl = `${window.location.pathname.substring(
        0,
        window.location.pathname.indexOf(MPPAYMENT_LOGIN__PATH),
      )}/${vpSessionId}`;
      AuthService.mpLogin(tenant, redirectUrl, stateToken);
    }
  }, [loggedIn, tenant, vpSessionId]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

PaymentLogin.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

export default PaymentLogin;
