import * as storageUtils from '../common/storageUtils';
import { CAMPAIGN_TYPE } from '../common/globals';
import { basketItemsMatch } from '../common/utils';
import {
  BASKET_SET_ITEMS,
  BASKET_SET_PAYMENT_OPTIONS,
  CLEAR_PAYMENT_OPTIONS,
  UPDATE_BASKET_PAYMENT_TYPE,
} from './actionTypes';
import { getPaymentId } from '../utils/CampaignUtils';

function updateOrAddPaymentItem(locationId, campaignId, createItem) {
  const items = storageUtils.getBasketItemsFromStorage(locationId);

  const existingItem = (items || []).find(
    (i) => Object.keys(i.transactionData)[0] === campaignId,
  );

  const newItems = items.filter(
    (i) => Object.keys(i.transactionData)[0] !== campaignId,
  );
  const item = createItem(existingItem);
  return [...newItems, ...item];
}
function getPaymentItem(newItem) {
  const campaignId = newItem.campaignDetails.id;
  // Payments will allow you to add different memolines to transactions
  const paymentId = getPaymentId(newItem);
  return {
    ...newItem,
    transactionData: {
      [paymentId]: {
        ...newItem.transactionData[campaignId],
      },
    },
  };
}
function combinePaymentItems(existingItem, newItem) {
  const newTransactionData = getPaymentItem(newItem);

  if (!existingItem) {
    return [newTransactionData];
  }

  const paymentId = getPaymentId(newItem);
  const transactionData = existingItem.transactionData[paymentId];

  if (!transactionData) {
    return [existingItem, newTransactionData];
  }

  return [
    {
      campaignDetails: {
        ...existingItem.campaignDetails,
        ...newItem.campaignDetails,
      },
      transactionData: {
        ...existingItem.transactionData,
        [paymentId]: {
          ...transactionData,
          ...newTransactionData.transactionData[paymentId],
          quantity:
            newTransactionData.transactionData[paymentId].quantity +
            transactionData.quantity,
          amount:
            parseFloat(newTransactionData.transactionData[paymentId].amount) +
            parseFloat(transactionData.amount),
        },
      },
    },
  ];
}

export function updatePaymentItemQuantity(locationId, campaignId, quantity) {
  const combineItems = (existingItem) => {
    if (!existingItem) {
      throw new Error('Failed to find an existing payment item to update');
    }

    const transactionData = existingItem.transactionData[campaignId];
    return [
      {
        ...existingItem,
        transactionData: {
          ...existingItem.transactionData,
          [campaignId]: {
            ...transactionData,
            quantity,
            amount: parseFloat(transactionData.itemAmount) * quantity,
          },
        },
      },
    ];
  };
  const items = updateOrAddPaymentItem(locationId, campaignId, combineItems);
  storageUtils.setBasketItemsToStorage(locationId, items);

  return { type: BASKET_SET_ITEMS, items };
}

export function addBasketItem(locationId, item) {
  let items;
  if (item.campaignDetails.type === CAMPAIGN_TYPE.GIFT) {
    items = storageUtils.getBasketItemsFromStorage(locationId);
    items.push(item);
  } else {
    const combineItems = (existingItem) =>
      combinePaymentItems(existingItem, item);
    items = updateOrAddPaymentItem(
      locationId,
      getPaymentId(item),
      combineItems,
    );
  }

  storageUtils.setBasketItemsToStorage(locationId, items);
  return { type: BASKET_SET_ITEMS, items };
}

export function removeBasketItem(locationId, item) {
  const items = storageUtils
    .getBasketItemsFromStorage(locationId)
    .filter((storedItem) => !basketItemsMatch(storedItem, item));
  storageUtils.setBasketItemsToStorage(locationId, items);
  return { type: BASKET_SET_ITEMS, items };
}

export function clearBasketItems(locationId) {
  storageUtils.clearBasketItemsFromStorage(locationId);
  return { type: BASKET_SET_ITEMS, items: [] };
}

export function loadBasketContents(locationId) {
  const items = storageUtils.getBasketItemsFromStorage(locationId);
  return { type: BASKET_SET_ITEMS, items };
}

export function savePaymentOptions(locationId, paymentOptions) {
  const newOptions = storageUtils.setPaymentOptionsToStorage(
    locationId,
    paymentOptions,
  );
  return {
    type: BASKET_SET_PAYMENT_OPTIONS,
    paymentOptions: newOptions,
  };
}

export function updateSelectedPaymentType(locationId, selectedPaymentType) {
  const options = storageUtils.getPaymentOptionsFromStorage(locationId);

  if (options?.paymentType)
    storageUtils.setPaymentOptionsToStorage(locationId, {
      ...options,
      paymentType: selectedPaymentType,
    });

  return {
    type: UPDATE_BASKET_PAYMENT_TYPE,
    selectedPaymentType,
  };
}

export function clearPaymentOptions(locationId) {
  storageUtils.clearPaymentOptionsFromStorage(locationId);
  return {
    type: CLEAR_PAYMENT_OPTIONS,
  };
}

export function fetchPaymentOptions(locationId) {
  return {
    type: BASKET_SET_PAYMENT_OPTIONS,
    paymentOptions: storageUtils.getPaymentOptionsFromStorage(locationId),
  };
}
// clears basket then fills it with the provided items
export function overrideBasket(locationId, items = []) {
  return (dispatch) => {
    dispatch(clearBasketItems(locationId));
    items.forEach((item) => {
      dispatch(addBasketItem(locationId, item));
    });
  };
}
