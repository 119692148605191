import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useFeatures from 'hooks/common/useFeatures';
import useHandleAzureRedirect from 'hooks/azureAuthHooks/useAzureAuth';
import IDSLoginDialog from './IDSLoginDialog';
import { HOME_PATH } from '../../common/globals';
import { actionNeededUrlRemoved } from '../../actions/user';

export function IDSLoginWrapper({ returnTo, location }) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { AzureClientAuth: isAzureAuthEnabled } = useFeatures();
  const { handleAzureRedirect } = useHandleAzureRedirect();

  const onClose = () => {
    setIsOpen(false);
    dispatch(actionNeededUrlRemoved());
    history.push(`/${location.id}/${HOME_PATH}`);
  };

  useEffect(() => {
    if (location.idsPayerTenant) {
      if (isAzureAuthEnabled) {
        handleAzureRedirect(false);
      } else {
        setIsOpen(true);
      }
    }
  }, [location.idsPayerTenant, isAzureAuthEnabled, handleAzureRedirect]);

  return (
    <IDSLoginDialog
      id="login-dialog"
      open={isOpen}
      onClose={onClose}
      onParentLogin={onClose}
      returnTo={returnTo}
    />
  );
}

IDSLoginWrapper.propTypes = {
  returnTo: PropTypes.string,
  location: PropTypes.object.isRequired,
};

IDSLoginWrapper.defaultProps = {
  returnTo: null,
};

const mapStateToProps = (state) => {
  const { location } = state;
  return {
    location,
  };
};

export default connect(mapStateToProps, null)(IDSLoginWrapper);
