import {
  SELECTED_PAYMENT_METHOD_FETCH,
  SELECTED_PAYMENT_METHOD_COMPLETE,
  SELECTED_PAYMENT_METHOD_ERROR,
  PAYMENT_ID_FETCH,
  PAYMENT_ID_COMPLETE,
  PAYMENT_ID_ERROR,
  PAYMENT_ID_CLEAR,
  SET_HEXEA_OBJECT,
  PAYMENT_METHOD_SAVE,
  PAYMENT_METHOD_SAVE_COMPLETE,
  PAYMENT_METHOD_SAVE_ERROR,
} from '../actions/actionTypes';

import {
  SESSION_DATA_FETCH,
  SESSION_DATA_COMPLETE,
  SESSION_DATA_ERROR,
} from '../mp/actions/actionTypes'

const initialState = {
  cardType: null,
  accountType: null,
  last4: null,
  hexeaObject: null,
  isFetchingPaymentId: false,
  paymentId: '',
  isFetchingSelectedPaymentMethod: false,
  isSelectedPaymentMethodLoaded: false,
  isSavingPaymentMethod: false,
};

export default function paymentMethod(state = initialState, action = {}) {
  switch (action.type) {
    case SET_HEXEA_OBJECT: {
      return { ...state, hexeaObject: action.hexeaObject };
    }

    case SELECTED_PAYMENT_METHOD_FETCH: {
      return {
        ...state,
        isFetchingSelectedPaymentMethod: true,
        isSelectedPaymentMethodLoaded: false,
      };
    }
    case SELECTED_PAYMENT_METHOD_COMPLETE: {
      if (!action.paymentMethodDetails) {
        return {
          ...state,
          isFetchingSelectedPaymentMethod: false,
        };
      }

      const cardType = action.paymentMethodDetails.brand;
      const { accountType } = action.paymentMethodDetails;
      const last4 =
        action.paymentMethodDetails.last4 ||
        action.paymentMethodDetails.accountNumberLast4;
      return {
        ...state,
        cardType,
        accountType,
        last4,
        isFetchingSelectedPaymentMethod: false,
        isSelectedPaymentMethodLoaded: true,
      };
    }
    case SELECTED_PAYMENT_METHOD_ERROR: {
      return {
        ...state,
        isFetchingSelectedPaymentMethod: false,
        isSelectedPaymentMethodLoaded: true,
      };
    }

    case PAYMENT_ID_FETCH: {
      return { ...state, isFetchingPaymentId: true };
    }

    case PAYMENT_ID_COMPLETE: {
      return {
        ...state,
        isFetchingPaymentId: false,
        paymentId: action.payment.id,
      };
    }

    case PAYMENT_ID_CLEAR: {
      return {
        ...state,
        paymentId: '',
      };
    }

    case PAYMENT_ID_ERROR: {
      return { ...state, isFetchingPaymentId: false };
    }

    case PAYMENT_METHOD_SAVE: {
      return {
        ...state,
        isSavingPaymentMethod: true,
        isSelectedPaymentMethodLoaded: false,
      };
    }

    case PAYMENT_METHOD_SAVE_COMPLETE: {
      return { ...state, isSavingPaymentMethod: false };
    }

    case PAYMENT_METHOD_SAVE_ERROR: {
      return { ...state, isSavingPaymentMethod: false };
    }

    // SESSION_DATA cases have been temporarily added 
    // they will be extracted when standalone is requested
    case SESSION_DATA_FETCH: {
      return { ...state, isFetchingSessiondata: true };
    }

    case SESSION_DATA_COMPLETE: {
      const { data: sessionData } = action;
      return {
        ...state,
        isFetchingSessiondata: false,
        sessionData,
      };
    }

    case SESSION_DATA_ERROR: {
      return { ...state, isFetchingSessiondata: false };
    }

    default:
      return state;
  }
}
