export default function pick<T, K extends keyof T>(
  obj: T,
  keys: K[]
): Pick<T, K> {
  return Object.assign(
    {},
    ...keys
      .filter((key) => obj && Object.prototype.hasOwnProperty.call(obj, key))
      .map((key) => {
        return { [key]: obj[key] };
      })
  );
}
