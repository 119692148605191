export default () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    borderRadius: '8px',
    minWidth: '350px', // required for the date picker to have enough space
  },
  // expressGiftTileForm: {
  //   width: 'auto',
  //   flexBasis: 'content',
  // },
  // inIframe: {
  //   boxShadow: 'none',
  //   border: '1px solid #DEE1E3',
  // },
  // cardContent: {
  //   width: '100%',
  // },
});
