import 'iframe-resizer/js/iframeResizer';
import { assert } from '@vancoplatform/utils';
import { isSameOrigin } from '../helper/window';
import EmbeddedIframeHandler from '../helper/embeddedIframe';
import { WebAuthOptions } from '../types';

const CHECK_SESSION_FRAME_ID = 'check-session-iframe';

interface BaseOptions extends WebAuthOptions {
  rootUrl: string;
  url: string;
}

class CheckSession {
  baseOptions: BaseOptions;
  helper: EmbeddedIframeHandler;
  rendered: boolean;
  renderPromise: Promise<unknown>;
  /**
   * Creates a iframe of the Vanco app switcher drawer
   * @param {Object} options.element the dom element in which to render the iframe
   * @param {String} options.domain the URL to the Identity Server (domain)
   */
  constructor(options: WebAuthOptions) {
    assert.check(
      options,
      { type: 'object', message: 'options parameter is not valid' },
      {
        domain: { type: 'string', message: 'domain option is required' },
      }
    );

    this.baseOptions = {
      ...options,
      rootUrl: `https://${options.domain}`,
      url: `https://${options.domain}/checksession.html`,
    };

    this.helper = new EmbeddedIframeHandler({
      hidden: true,
      url: this.baseOptions.url,
      id: CHECK_SESSION_FRAME_ID,
    });

    this.rendered = false;
  }

  render() {
    if (!this.rendered) {
      this.rendered = true;
      this.helper.mount(document.body);
      this.renderPromise = new Promise((resolve) => {
        this.helper.onFrame('load', (ev) => {
          resolve(ev);
        });
      });
    }
    return this.renderPromise;
  }

  destroy() {
    this.helper.unmount();
  }

  checkSession(options) {
    const mes = options.clientId + ' ' + options.sessionState;

    const targetOrigin = this.baseOptions.rootUrl;
    const win = (
      document.getElementById(CHECK_SESSION_FRAME_ID) as HTMLIFrameElement
    ).contentWindow;
    win.postMessage(mes, targetOrigin);

    return new Promise((resolve) => {
      const listener = (e) => {
        const targetOrigin = this.baseOptions.rootUrl;
        if (isSameOrigin(e.origin, targetOrigin)) {
          window.removeEventListener('message', listener, false);
          resolve(e.data);
        }
      };

      window.addEventListener('message', listener, false);
    });
  }
}

export default CheckSession;
