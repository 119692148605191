export default function styles(theme) {
  return {
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderTop: '1px solid #DEE1E3',
      borderBottom: '1px solid #DEE1E3',
    },
    editButton: {
      color: '#5E6266',
      cursor: 'pointer',
      marginRight: theme.spacing(2),
    },
    contactHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    contactBody: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  };
}
