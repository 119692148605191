import { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ScheduledTransactionCard from '../../../../components/transactions/scheduled/ScheduledTransactionCard';
import MobileScheduledTransactionCard from '../../../../components/transactions/scheduled/mobile/MobileScheduledTransactionCard';
import ConfirmationDialog from '../../../../components/common/ConfirmationDialog';
import {
  deleteScheduledTransaction,
  fetchScheduledTransactions,
} from '../../../../actions/transactions';
import { enqueueSnackbar } from '../../../../actions/notifications';
import * as globals from '../../../../common/globals';
import styles from './styles';
import EditTransactionModal from '../editTransactionModal/EditTransactionModal';
import { fetchSavedPaymentMethods } from '../../../../actions/savedPaymentMethods';

export function ScheduledTransactions(props) {
  const {
    classes,
    transactions,
    //* global state
    locationId,
    paymentMethods,
    areSavedMethodsLoaded,
    features,
    //* actions
    deleteScheduledTransactionConnect,
    enqueueSnackbarConnect,
    fetchSavedPaymentMethodsConnect,
    fetchScheduledTransactionsConnect,
  } = props;

  const [transactionIdToDelete, setTransactionIdToDelete] = useState(null);
  const [transactionIdToEdit, setTransactionIdToEdit] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [showCantCloseDialog, setShowCantCloseDialog] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const { t } = useTranslation();

  const confirmTransactionCancel = (transactionId) => {
    setTransactionIdToDelete(transactionId);
    setIsConfirmationOpen(true);
  };

  const loadPaymentMethods = async () => {
    if (!areSavedMethodsLoaded) {
      try {
        await fetchSavedPaymentMethodsConnect(locationId);
      } catch (e) {
        enqueueSnackbarConnect({
          variant: globals.NOTIFICATION_ERROR,
          message: e.message,
        });
      }
    }
  };

  const handleTransactionEdit = (transactionId) => {
    const transaction = {
      ...transactions[transactionId][0],
      fundId: transactions[transactionId][0].campaignId,
      memoLine: {},
    };

    setTransactionIdToEdit(transaction);
    setIsEditOpen(true);
    loadPaymentMethods();
  };

  const handleConfirmationClose = () => {
    setTransactionIdToDelete(null);
    setIsConfirmationOpen(false);
  };

  const handleEditClose = (canClose) => {
    if (!canClose) {
      setShowCantCloseDialog(true);
      return;
    }

    setTransactionIdToEdit(null);
    setIsEditOpen(false);
  };

  const handleTransactionCancel = async () => {
    const transactionId = transactionIdToDelete;
    handleConfirmationClose();

    try {
      await deleteScheduledTransactionConnect(locationId, transactionId);

      enqueueSnackbarConnect({
        variant: globals.NOTIFICATION_SUCCESS,
        message: t('transactions.scheduled.cancel.success'),
      });
    } catch (e) {
      enqueueSnackbarConnect({
        variant: globals.NOTIFICATION_ERROR,
        message: t('transactions.scheduled.cancel.failure'),
      });
    }
  };

  const handleEditTransactionCompleted = (showSuccessMessage = true) => {
    if (showSuccessMessage)
      enqueueSnackbarConnect({
        variant: globals.NOTIFICATION_SUCCESS,
        message: t('transactions.scheduled.edit.success'),
      });

    setTransactionIdToEdit(null);
    fetchScheduledTransactionsConnect(locationId);
  };

  const handleDiscardEditTransaction = () => {
    handleEditClose(true);
    setShowCantCloseDialog(false);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <>
      {Object.keys(transactions).length === 0 ? (
        <div id="scheduled-missing-root" className={classes.missingRoot}>
          <Typography
            id="scheduled-missing-title"
            variant="h5"
            className={classes.missingTitle}
          >
            {t('transactions.scheduled.missingTitle')}
          </Typography>
          <Typography
            id="scheduled-missing-text"
            className={classes.missingText}
          >
            {t('transactions.scheduled.missingText')}
          </Typography>
        </div>
      ) : (
        Object.keys(transactions).map((transactionId) =>
          isSmallScreen ? (
            <MobileScheduledTransactionCard
              className={classes.transactionCard}
              key={transactionId}
              transactions={transactions[transactionId]}
              transactionId={transactionId}
              onCancel={confirmTransactionCancel}
              onEdit={handleTransactionEdit}
              features={features}
            />
          ) : (
            <ScheduledTransactionCard
              className={classes.transactionCard}
              key={transactionId}
              transactions={transactions[transactionId]}
              transactionId={transactionId}
              onCancel={confirmTransactionCancel}
              onEdit={handleTransactionEdit}
              features={features}
            />
          ),
        )
      )}
      <ConfirmationDialog
        id="confirm-delete-dialog"
        isOpen={isConfirmationOpen}
        title={t('transactions.scheduled.cancel.confirmation.title')}
        message={t('transactions.scheduled.cancel.confirmation.message')}
        submitButtonText={t(
          'transactions.scheduled.cancel.confirmation.submitButtonText',
        )}
        cancelButtonText={t(
          'transactions.scheduled.cancel.confirmation.cancelButtonText',
        )}
        onClose={handleConfirmationClose}
        onSubmit={handleTransactionCancel}
      />
      {transactionIdToEdit && (
        <EditTransactionModal
          isOpen={isEditOpen}
          onClose={handleEditClose}
          onSubmit={handleEditTransactionCompleted}
          transaction={transactionIdToEdit}
          paymentMethods={paymentMethods}
          areSavedMethodsLoaded={areSavedMethodsLoaded}
        />
      )}
      <ConfirmationDialog
        id="cant-close-dialog"
        isOpen={showCantCloseDialog}
        title={t('transactions.scheduled.cancel.saveBeforeClose')}
        message={t('transactions.scheduled.cancel.saveBeforeClose.message')}
        submitButtonText={t(
          'transactions.scheduled.cancel.saveBeforeClose.discard',
        )}
        cancelButtonText={t(
          'transactions.scheduled.cancel.saveBeforeClose.back',
        )}
        onClose={() => setShowCantCloseDialog(false)}
        onSubmit={handleDiscardEditTransaction}
      />
    </>
  );
}

ScheduledTransactions.propTypes = {
  locationId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  transactions: PropTypes.object,
  deleteScheduledTransactionConnect: PropTypes.func.isRequired,
  enqueueSnackbarConnect: PropTypes.func.isRequired,
  fetchSavedPaymentMethodsConnect: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  areSavedMethodsLoaded: PropTypes.bool.isRequired,
  features: PropTypes.object.isRequired,
  fetchScheduledTransactionsConnect: PropTypes.func.isRequired,
};

ScheduledTransactions.defaultProps = { transactions: {}, paymentMethods: [] };

const mapStateToProps = (state) => {
  return {
    locationId: state.location.id,
    paymentMethods: state.savedPaymentMethods.savedMethods,
    areSavedMethodsLoaded: state.savedPaymentMethods.areSavedMethodsLoaded,
    features: state.features.features,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteScheduledTransactionConnect: (locationId, transactionId) => {
      return dispatch(deleteScheduledTransaction(locationId, transactionId));
    },
    enqueueSnackbarConnect: (notification) => {
      return dispatch(enqueueSnackbar(notification));
    },
    fetchSavedPaymentMethodsConnect: (locationId) => {
      return dispatch(fetchSavedPaymentMethods(locationId));
    },
    fetchScheduledTransactionsConnect: (locationId) =>
      dispatch(fetchScheduledTransactions(locationId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ScheduledTransactions);
