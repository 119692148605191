export default function styles() {
  return {
    container: {
      alignItems: 'center',
      background: '#fafafa',
      borderRadius: 10,
      boxShadow:
        '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.16);',
      padding: '20px 22px',
    },
    dateText: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
}
