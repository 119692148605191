export default () => ({
  root: {
    width: 32,
    height: 32,
    borderRadius: 8,
    position: 'absolute',
    display: 'inline-block',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  initials: {
    textAlign: 'center',
    color: 'white',
    marginTop: 4,
  },
});
