import { connect } from 'react-redux';
import Home from './Home';

const mapStateToProps = (state) => {
  // The operations with this basketIds array could be optimized
  // by turning it into a lookup table object instead, but it's
  // simpler to conceptualize around and extend an array so I'm leaving it as that for now.
  const basketIds = state.basket.items.map((item) => item.campaignDetails.id);
  return {
    basketIds,
    iframe: state.frame.inIframe,
    loc: state.location,
    features: state.features.features,
  };
};

export default connect(mapStateToProps, null)(Home);
