/**
 * Creates a script tag to fetch the hexea code dynamically. This is required to support fetching different hexea sources for each environment.
 * We can import hexeaPromise in our other files and await its resolution in order to ensure we've properly loaded hexea
 * @returns {Promise<unknown>} The Promise that will resolve into the hexea object/function
 */
const loadHexea = () =>
  new Promise(resolve => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = () => {
      resolve(window.Hexea);
    };
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(script);
    script.src = window.env.REACT_APP_CXP_URL;
  });

const hexeaPromise = loadHexea();
export default hexeaPromise;
