import { isNumber } from 'lodash';
import moment from 'moment';
import roundAwayFromZero from 'utils/Rounding';
import {
  MIN_TRANSACTION_AMOUNT,
  PAYMENT_FREQUENCY_ANNUALLY,
  PAYMENT_FREQUENCY_BI_WEEKLY,
  PAYMENT_FREQUENCY_MONTHLY,
  PAYMENT_FREQUENCY_QUARTERLY,
  PAYMENT_FREQUENCY_TWICE_A_MONTH,
  PAYMENT_FREQUENCY_WEEKLY,
} from '../../common/globals';

export function useInstallments(
  totalAmount,
  frequency,
  startDate,
  endDate,
  showProcessingFees,
  processingFee,
) {
  let quantity = 0;
  let isSameRate = false;
  let firstInstallment = 0;
  let rate = 0;
  let isValidData = false;
  let hasAllRequiredValues = false;
  let totalNumber = Number(totalAmount ?? Number(0));
  const isTotalValid = isNumber(totalNumber);

  const setDefaults = () => {
    quantity = 0;
    isSameRate = true;
    firstInstallment = 0;
    rate = 0;
    isValidData = false;
  };

  const calculateFirstInstallment = (
    InstallQuantity,
    InstallRate,
    InstallTotal,
  ) => {
    isSameRate =
      InstallTotal.toFixed(2) === (InstallQuantity * InstallRate).toFixed(2);

    if (!isSameRate)
      firstInstallment = roundAwayFromZero(
        InstallTotal - (InstallRate * InstallQuantity).toFixed(2) + InstallRate,
      );
    return firstInstallment;
  };

  const verifyMinumunInstallmentValue = () => {
    if (
      rate < MIN_TRANSACTION_AMOUNT ||
      (!isSameRate && firstInstallment < MIN_TRANSACTION_AMOUNT)
    ) {
      isValidData = false;
    } else {
      isValidData = true;
    }
  };

  function calculateInstallmets() {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    let tempQuantity = 1;
    switch (frequency) {
      case PAYMENT_FREQUENCY_ANNUALLY:
        tempQuantity = endMoment.diff(startMoment, 'years');
        break;
      case PAYMENT_FREQUENCY_QUARTERLY:
        tempQuantity = endMoment.diff(startMoment, 'months') / 3;
        break;
      case PAYMENT_FREQUENCY_MONTHLY:
        tempQuantity = endMoment.diff(startMoment, 'months');
        break;
      case PAYMENT_FREQUENCY_TWICE_A_MONTH:
      case PAYMENT_FREQUENCY_BI_WEEKLY:
        tempQuantity = endMoment.diff(startMoment, 'weeks') / 2;
        break;
      case PAYMENT_FREQUENCY_WEEKLY:
        tempQuantity = endMoment.diff(startMoment, 'weeks');
        break;
      default:
        break;
    }

    const total = Number(totalAmount);
    quantity = Math.floor(tempQuantity) + 1 || 1;
    rate = roundAwayFromZero(total / quantity);
    firstInstallment = calculateFirstInstallment(quantity, rate, total);

    if (showProcessingFees && processingFee) {
      // Perform a calculation to exactly divide the amounts by applying the fees
      totalNumber = roundAwayFromZero(
        totalNumber * (processingFee / 100) + totalNumber,
      );
      rate = roundAwayFromZero(rate * (processingFee / 100) + rate);

      const tempFirstInstallment = firstInstallment.toFixed(2);
      firstInstallment = calculateFirstInstallment(quantity, rate, totalNumber);
      if (isSameRate) {
        firstInstallment = roundAwayFromZero(
          tempFirstInstallment * (processingFee / 100) + tempFirstInstallment,
        );
      } else {
        firstInstallment = roundAwayFromZero(
          totalNumber - rate * (quantity - 1),
        );
      }
    }
    verifyMinumunInstallmentValue();
  }

  hasAllRequiredValues = Boolean(
    isTotalValid && frequency && startDate && endDate,
  );
  if (hasAllRequiredValues) {
    calculateInstallmets();
  } else {
    setDefaults();
  }

  return {
    totalAmount: isTotalValid ? totalNumber?.toFixed(2) : Number(0).toFixed(2),
    quantity,
    isSameRate,
    firstInstallment: firstInstallment.toFixed(2),
    rate,
    isValidData,
    hasAllRequiredValues,
  };
}

export default useInstallments;
