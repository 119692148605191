// eslint-disable-next-line import/prefer-default-export
export const userActionRequiredTransformToFrontend = (response) => {
  const details = response.data.data;

  return {
    pendingActions: details.actionRequired?.map((t) => {
      return {
        id: t.id,
        fundId: t.id,
        amount: t.amount,
        frequency: t.selectedFrequency,
        selectedCampaign: t.selectedCampaign,
        startDate: t.nextDate,
      };
    }),
    campaigns: details.campaigns,
    paymentMethods: details.paymentMethods,
    status: response.status,
  };
};
