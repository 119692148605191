import {
  getOrRenewAccessToken,
  endSession,
  fetchCsrfToken,
} from '../actions/session';
import * as globals from '../common/globals';
import CsrfService from './CsrfService';
import { getInitPromise } from '../common/initializeApplication';
import * as storageUtils from '../common/storageUtils';
import { isCsrfError, getApiErrorMessage } from '../common/errorUtils';
import FeaturesService from './FeaturesService';

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

// cookieless endpoints
const cookieLessEndpoints = [
  /^(\/p|p)ayments/,
  /^users\/locations\/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)?\/scheduledpayments\/\w+\?paymentId=\w+/,
];

// vanco payment token endpoints
const vancoPaymentTokenEndpoints = [
  /^(\/p|p)ayments\//,
  /^users\/locations\/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)?\/scheduledpayments\/\w+\?paymentId=\w+/,
];

// endpoints to not send csrf token to.
const csrfBlackList = [
  CsrfService.getCsrfUrl(),
  FeaturesService.getFeaturesUrl(),
];

const notExistsInBlacklist = (url) => {
  return !csrfBlackList.includes(url);
};

export const checkIfCookieLessIsRequired = (url) => {
  return cookieLessEndpoints.some((c) => url.match(c));
};

export const checkIfVancoPaymentTokenIsRequired = (url) => {
  return vancoPaymentTokenEndpoints.some((c) => url.match(c));
};

function getCsrfToken(url, store) {
  const {
    session: { csrfToken },
  } = store;
  if (notExistsInBlacklist(url) && csrfToken) {
    return csrfToken;
  }
  return null;
}

function getCookielessHeaders(url, store) {
  const { location } = store;
  if (checkIfCookieLessIsRequired(url)) {
    const vancoCampaignsPaymentToken =
      storageUtils.getVancoCampaignsPaymentHeaderStorage(location.id);
    return {
      [globals.COOKIELESS_HEADER_NAME]: true,
      // verify if we need to add the vanco payment token
      ...(vancoCampaignsPaymentToken &&
        checkIfVancoPaymentTokenIsRequired(url) && {
          [globals.VP_CAMPAIGNS_PAYMENT_HEADER_NAME]:
            vancoCampaignsPaymentToken,
        }),
    };
  }
  return {};
}

export const createRequestInterceptor = (store) => ({
  fulfilled: async (config) => {
    let newConfig = { ...config };

    if (!notExistsInBlacklist(config.url)) {
      /*
       * If it does exist in the blacklist
       * i.e. csrf and featureFlags endpoints
       */
      return newConfig;
    }

    await getInitPromise();

    const csrfToken = getCsrfToken(config.url, store.getState());

    if (csrfToken) {
      newConfig = {
        ...newConfig,
        headers: {
          ...newConfig.headers,
          [globals.CSRF_HEADER_NAME]: csrfToken,
        },
      };
    }

    const cookielessHeaders = getCookielessHeaders(
      config.url,
      store.getState(),
    );

    if (Object.keys(cookielessHeaders).length > 0) {
      newConfig = {
        ...newConfig,
        headers: {
          ...newConfig.headers,
          ...cookielessHeaders,
        },
      };
    }

    const accessToken = await store.dispatch(getOrRenewAccessToken());

    if (accessToken) {
      return {
        ...newConfig,
        headers: {
          ...newConfig.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    return newConfig;
  },
  rejected: (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
});

// We modify the error message here to try and provide a clearer description of what went wrong rather than the default Axios error message.
// It could be argued that it would be better to create a new error object from the existing one.
/* eslint-disable no-param-reassign */
export const createResponseInterceptor = (store) => ({
  fulfilled: (response) => response,
  rejected: async (error) => {
    const { status } = error.response ? error.response : {}; // Handles the case where the error's response object is undefined
    const newMessage = getApiErrorMessage(error);
    if (newMessage) {
      error.message = newMessage;
    }
    if (status === UNAUTHORIZED) {
      store.dispatch(endSession());
    }
    // handles corner case where csrf token expires while page is still open
    if (status === FORBIDDEN && isCsrfError(error)) {
      await store.dispatch(fetchCsrfToken());
    }
    return Promise.reject(error);
  },
});

export default {
  createRequestInterceptor,
  createResponseInterceptor,
};
