import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useGetCampaigns } from '../../../hooks';
import { getOtheropportunitiesLabel } from '../../../utils/CampaignUtils';
import ReturnLink from '../../common/ReturnLink';

function ReturnToOpportunities({ variant, useWordMore }) {
  const { locId } = useParams();
  const { data: campaigns = [], status } = useGetCampaigns(locId);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (status !== 'success') return <></>;

  const label = getOtheropportunitiesLabel(campaigns, useWordMore);

  return (
    <ReturnLink
      isDisplayed
      link={`/${locId}/home`}
      linkText={label}
      variant={variant}
    />
  );
}

ReturnToOpportunities.propTypes = {
  variant: PropTypes.oneOf(['default', 'noPadding']),
  useWordMore: PropTypes.bool,
};

ReturnToOpportunities.defaultProps = {
  variant: 'noPadding',
  useWordMore: false,
};

export default ReturnToOpportunities;
