import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Dialog, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dequeueDialog } from '../../../actions/notifications';
import DialogContentWrapper from './contentWrapper/DialogContentWrapper';
import styles from './styles';

export function AppDialog(props) {
  const { notification, dequeueDialogConnect, classes } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!notification.key);
  }, [notification.key]);

  const handleExited = () => {
    dequeueDialogConnect();
  };

  const handleClose = () => {
    const { redirectUrl } = notification;

    setOpen(false);

    if (redirectUrl) {
      history.replace(redirectUrl);
    }
  };

  return (
    <Dialog
      id="client-dialog"
      classes={{ paper: classes.dialogRoot }}
      open={open}
      key={notification.key}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <DialogContentWrapper
        id="client-dialog-wrapper"
        onClose={handleClose}
        title={
          notification.title && typeof notification.title !== 'string'
            ? t('errors.error')
            : notification.title
        }
        message={
          notification.message && typeof notification.message !== 'string'
            ? t('errors.somethingWrong')
            : notification.message
        }
      />
    </Dialog>
  );
}

AppDialog.propTypes = {
  notification: PropTypes.object,
  dequeueDialogConnect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

AppDialog.defaultProps = {
  notification: {},
};

const mapStateToProps = (state) => {
  return {
    notification: state.notifications.dialogQueue[0],
  };
};

const mapDispatchToProps = (dispatch) => ({
  dequeueDialogConnect: () => {
    dispatch(dequeueDialog());
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AppDialog);
