import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles, Typography, Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './styles';
import MigratedTransactionsForm from './form';
import {
  submitUserRequiredActions,
  patchRequiredAction,
} from '../../../../actions/user';
import {
  TRANSACTIONS_PATH,
  TRANSACTIONS_THANKS_PATH,
  NOTIFICATION_ERROR,
} from '../../../../common/globals';
import { enqueueSnackbar } from '../../../../actions/notifications';

export function MigratedTransactions(props) {
  const {
    classes,
    requiredActions,
    dbaName,
    location,
    isFetchingRequiredActions,
    isSubmittingActions,
    submitUserRequiredActionsConnect,
    enqueueSnackbarConnect,
    patchRequiredActionConnect,
  } = props;

  const history = useHistory();
  const reCaptchaRef = useRef(null);
  const formValuesRef = useRef(null);
  const formActionsRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (formActionsRef.current) {
      formActionsRef.current.setSubmitting(isSubmittingActions);
    }
  }, [isSubmittingActions]);

  function goToThankYou() {
    history.push(`${TRANSACTIONS_PATH}/${TRANSACTIONS_THANKS_PATH}`);
  }

  const handleSubmit = (values, actions) => {
    formValuesRef.current = values;
    formActionsRef.current = actions;
    reCaptchaRef.current.reset();
    reCaptchaRef.current.execute();
  };

  const handleReCaptchaChange = async (token) => {
    formActionsRef.current.setSubmitting(true);
    if (!token) return;
    const results = await submitUserRequiredActionsConnect(
      formValuesRef.current,
      token,
    );
    if (results.success) {
      goToThankYou();
    } else {
      enqueueSnackbarConnect({
        variant: NOTIFICATION_ERROR,
        message: t('review.submit.error.dialog.message'),
      });
    }
    formActionsRef.current.setSubmitting(false);
  };

  const handleReCaptchaError = () => {
    enqueueSnackbarConnect({
      variant: NOTIFICATION_ERROR,
      message: t('errors.reCaptchaFailure'),
    });
  };

  const siteKey = location.isAutomation
    ? window.env.REACT_APP_AUTOMATION_RECAPTCHA_SITE_KEY
    : window.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {requiredActions?.pendingActions?.length === 0 ? (
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.text}>
              {t('transactions.action.no.needed')}
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <>
          <Card className={classes.card}>
            <CardContent>
              <Typography>
                {t('transactions.action.message', {
                  name: `"${dbaName}"`,
                })}
              </Typography>
            </CardContent>
          </Card>

          {!isFetchingRequiredActions && (
            <MigratedTransactionsForm
              transactions={requiredActions.pendingActions}
              campaigns={requiredActions.campaigns}
              paymentMethods={requiredActions.paymentMethods}
              onSubmit={handleSubmit}
              patchRequiredActionConnect={patchRequiredActionConnect}
            />
          )}
          <ReCAPTCHA
            key={siteKey}
            ref={reCaptchaRef}
            size="invisible"
            onChange={handleReCaptchaChange}
            onErrored={handleReCaptchaError}
            sitekey={siteKey}
          />
        </>
      )}
    </>
  );
}

MigratedTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
  requiredActions: PropTypes.object,
  dbaName: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  isSubmittingActions: PropTypes.bool,
  isFetchingRequiredActions: PropTypes.bool.isRequired,
  submitUserRequiredActionsConnect: PropTypes.func.isRequired,
  enqueueSnackbarConnect: PropTypes.func.isRequired,
  patchRequiredActionConnect: PropTypes.func.isRequired,
};

MigratedTransactions.defaultProps = {
  requiredActions: {},
  isSubmittingActions: false,
};

const mapStateToProps = (state) => {
  return {
    location: state.location,
    dbaName: state.location.parentOrgName,
    isSubmittingActions: state.user.isSubmittingActions,
    isFetchingRequiredActions: state.user.isFetchingRequiredActions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitUserRequiredActionsConnect: (items, reCaptchaToken) => {
      return dispatch(submitUserRequiredActions(items, reCaptchaToken));
    },
    enqueueSnackbarConnect: (notification) => {
      return dispatch(enqueueSnackbar(notification));
    },
    patchRequiredActionConnect: (id, state) => {
      return dispatch(patchRequiredAction(id, state));
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(MigratedTransactions);
