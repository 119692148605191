import { useTranslation } from 'react-i18next';
import SimpleLayout from '../../components/common/simpleLayout/SimpleLayout';

export function VerificationSuccess() {
  const { t } = useTranslation();

  return (
    <SimpleLayout>
      <SimpleLayout.Banner src="https://images.unsplash.com/photo-1531206715517-5c0ba140b2b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjczMTgyfQ&auto=format&fit=crop&w=1650&q=80" />
      <SimpleLayout.Title>{t('verification.success.title')}</SimpleLayout.Title>
      <SimpleLayout.Content>
        {t('verification.success.body')}
      </SimpleLayout.Content>
    </SimpleLayout>
  );
}

export default VerificationSuccess;
