import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export function LinkButton({ history, staticContext, to, children, ...rest }) {
  const navigate = () => {
    history.push(to);
  };

  return (
    <Button onClick={navigate} {...rest}>
      {children}
    </Button>
  );
}

LinkButton.propTypes = {
  history: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

LinkButton.defaultProps = {
  staticContext: null,
};

export default withRouter(LinkButton);
