import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { func, objectOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styles';

function EmptySearch({ classes, searchText, onClear }) {
  const { t } = useTranslation();

  return (
    <div data-testid="empty-search" className={classes.root}>
      <Typography component="span">{t('search.no.match')}</Typography>
      <Typography component="span" variant="body2">
        {` “${searchText}”`}
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.verticalGutterLarge}
      >
        <Typography>{t('search.refine')}</Typography>
        <Button
          onClick={onClear}
          className={classes.clearButton}
          color="primary"
        >
          <Typography component="span" className={classes.buttonText}>
            {t('search.browse.all')}
          </Typography>
        </Button>
        <span>.</span>
      </Grid>

      <div className={classes.hints}>
        <Typography variant="body2">{t('search.hints.title')}</Typography>

        <ul className={classes.list}>
          <li>
            <Typography className={classes.verticalGutter}>
              {t('search.hint.first')}
            </Typography>
          </li>
          <li>
            <Typography>{t('search.hint.second')}</Typography>
          </li>
          <li>
            <Typography>{t('search.hint.third')}</Typography>
          </li>
        </ul>
      </div>
    </div>
  );
}

EmptySearch.propTypes = {
  classes: objectOf(string).isRequired,
  searchText: string.isRequired,
  onClear: func.isRequired,
};

export default withStyles(styles)(EmptySearch);
