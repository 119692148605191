import PropTypes from 'prop-types';
import { withStyles, Button, Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Modal from './modal';
import {
  ACH,
  CREDIT_CARD,
  CREDIT_CARD_TOKENIZE,
  ACH_TOKENIZE,
  NOTIFICATION_ERROR,
  ACCOUNT_CHECKING,
  CARD_TYPE_AMEX,
  CARD_TYPE_MASTERCARD,
  CARD_TYPE_VISA,
  CARD_TYPE_DISCOVER,
} from '../../common/globals';
import CreditCardPaymentMethod from '../paymentMethod/CreditCardPaymentMethod';
import ACHPaymentMethod from '../paymentMethod/ACHPaymentMethod';
import NachaConfirmation from '../paymentMethod/NachaConfirmation';
import { createSchema } from '../../common/validation/PaymentMethodValidationUtils';
import { savePaymentOptions } from '../../actions/basket';
import { enqueueSnackbarAndLogError } from '../../actions/notifications';
import { fetchPaymentID } from '../../actions/paymentMethod';
import { getOrRenewAccessToken } from '../../actions/session';
import { userDetailsTransformToHexeaBillingInfo } from '../../api/transform/UserDetailsTransform';
import { addUserRequiredActionsPaymentMethod } from '../../actions/user';
import { renderCardIcon } from '../../common/utils';
import { paymentMethodTransformToBackend } from '../../api/transform/PaymentMethodTransform';
import AddressInfo from './AddressInfo';
import { addPaymentMethod } from '../../actions/savedPaymentMethods';

const styles = () => ({
  title: {
    '& h6': {
      color: '#222222',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
  formItemGroup: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  cardIcon: {
    marginRight: '10px',
  },
  submitButton: {
    fontWeight: 'bold',
  },
});

export function AddPaymentMethodDialog(props) {
  const {
    onClose,
    enqueueSnackbarAndLogErrorConnect,
    fetchPaymentIdConnect,
    getOrRenewAccessTokenConnect,
    addUserRequiredActionsPaymentMethodConnect,
    addPaymentMethodConnect,
    isOpen,
    classes,
    supportedPaymentTypes,
    hexea,
    locationId,
    user,
  } = props;

  const { t } = useTranslation();
  const [streetAddress1, setStreetAddress1] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [state, setState] = useState({
    selectedPaymentType: supportedPaymentTypes.creditCard ? CREDIT_CARD : ACH,
    hasScheduledItems: false,
    selectedMethod: {
      [CREDIT_CARD]: '',
      [ACH]: '',
    },
    selectedMethodDetails: {
      [CREDIT_CARD]: null,
      [ACH]: null,
    },
    cardInfo: {},
    infoValidity: {
      cardInfo: false,
    },
    paymentMethod: hexea ? hexea.paymentMethod(CREDIT_CARD_TOKENIZE) : null,
    showErrors: false,
    canr: false,
    isIDSDialogOpen: false,
    editContactInformation: false,
    isConfirmModalOpen: false,
    isSubmitting: false,
  });

  const allowedCardBrands =
    (supportedPaymentTypes.creditCard && [
      CARD_TYPE_VISA,
      CARD_TYPE_MASTERCARD,
      CARD_TYPE_DISCOVER,
      CARD_TYPE_AMEX,
    ]) ||
    [];

  useEffect(() => {
    fetchPaymentIdConnect(locationId);
  }, [fetchPaymentIdConnect, locationId]);

  const handlePaymentTypeChange = (event, value) => {
    let paymentMethod;
    if (value === CREDIT_CARD) {
      paymentMethod = hexea.paymentMethod(CREDIT_CARD_TOKENIZE);
    }
    setState((prevState) => {
      return {
        ...prevState,
        paymentMethod,
        selectedPaymentType: value,
      };
    });
  };
  useEffect(() => {
    const updateStreet = () => {
      const streets = user?.address?.street_address?.split('\n');
      const street1 = streets?.length >= 1 ? streets[0] : '';
      const street2 = streets?.length > 1 ? streets[1] : '';
      setStreetAddress1(street1);
      setStreetAddress2(street2);
    };
    updateStreet();
  }, [user]);

  const handleInputChange = (node) => (name, value) => {
    setState((prevState) => {
      return { ...prevState, [node]: { ...prevState[node], [name]: value } };
    });
  };

  const handleValidityChange = (child) => (valid) => {
    setState((prevState) => {
      return {
        ...prevState,
        infoValidity: { ...prevState.infoValidity, [child]: valid },
      };
    });
  };

  const handleClose = () => {
    if (!state.isSubmitting) onClose();
  };

  const handleSubmit = async (values) => {
    setState((prevState) => {
      return { ...prevState, isSubmitting: true };
    });
    try {
      const accessToken = await getOrRenewAccessTokenConnect();
      if (!accessToken) {
        throw new Error(t('errors.session.expired'));
      }

      if (
        // card or ach are selected as the payment type, and card info in CXP form fields is valid if selected
        (state.selectedPaymentType === CREDIT_CARD &&
          state.infoValidity.cardInfo) ||
        state.selectedPaymentType === ACH
      ) {
        // We want to keep this payment method
        const customValues = { ...values, billingInfo: { saveMethod: true } };
        const { addressInfo } = values;

        customValues.billingInfo = {
          zipCode: addressInfo.zipCode,
          ...(state.selectedPaymentType === ACH && {
            city: addressInfo.city,
            state: addressInfo.state,
            addressLine1: addressInfo.addressLine1,
          }),
        };

        const billingData = userDetailsTransformToHexeaBillingInfo(
          user,
          customValues,
          state.selectedPaymentType,
        );

        billingData.userToken = accessToken;
        const tokenizeDetails = await hexea.tokenize(
          state.selectedPaymentType === ACH
            ? hexea.paymentMethod(ACH_TOKENIZE)
            : state.paymentMethod,
          billingData,
        );
        const { error } = tokenizeDetails;
        if (!error) {
          const newPaymentMethod = paymentMethodTransformToBackend(
            tokenizeDetails.paymentMethod,
          );
          addUserRequiredActionsPaymentMethodConnect(newPaymentMethod);
          addPaymentMethodConnect(newPaymentMethod);
          onClose(newPaymentMethod);
        } else {
          throw new Error(error.detailed_error_message || error.message);
        }

        enqueueSnackbarAndLogErrorConnect(
          {},
          {
            variant: 'success',
            message: t('transactions.action.add.success'),
          },
        );
      }
    } catch (e) {
      enqueueSnackbarAndLogErrorConnect(e, {
        variant: NOTIFICATION_ERROR,
        message: e.message,
      });
      setState((prevState) => {
        return { ...prevState, isSubmitting: false };
      });
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          [CREDIT_CARD]: {
            zipCode: '',
          },
          [ACH]: {
            accountNumber: '',
            routingNumber: '',
            accountType: ACCOUNT_CHECKING,
            nacha: false,
          },
          addressInfo: {
            addressLine1: streetAddress1,
            addressLine2: streetAddress2,
            city: user.address?.locality ?? '',
            state: user.address?.region ?? '',
            zipCode: user.address?.postal_code ?? '',
          },
        }}
        validationSchema={createSchema(
          state.selectedPaymentType,
          true,
          {
            loggedIn: true,
            skipBillingInfo: true,
          },
          state.selectedPaymentType === ACH,
        )}
      >
        {(formikProps) => (
          <Form>
            <Modal.Title
              className={classes.title}
              isLoading={formikProps.isSubmitting}
            >
              {t('transactions.action.add.paymentMethod')}
            </Modal.Title>
            <Modal.Content>
              <div className={classes.paymentMethodRoot}>
                <div className={classes.paymentMethod}>
                  {state.selectedPaymentType && (
                    <Tabs
                      value={state.selectedPaymentType}
                      onChange={handlePaymentTypeChange}
                      indicatorColor="primary"
                    >
                      {supportedPaymentTypes.creditCard && (
                        <Tab
                          value={CREDIT_CARD}
                          label={t('payment.creditOrDebitCard')}
                        />
                      )}
                      {supportedPaymentTypes.ach && (
                        <Tab
                          value={ACH}
                          label={t('transactions.history.bankAccount')}
                        />
                      )}
                    </Tabs>
                  )}
                  {state.selectedPaymentType === CREDIT_CARD && (
                    <div className={classes.formItemGroup}>
                      {allowedCardBrands.includes(CARD_TYPE_VISA) &&
                        renderCardIcon(CARD_TYPE_VISA, classes.cardIcon)}
                      {allowedCardBrands.includes(CARD_TYPE_MASTERCARD) &&
                        renderCardIcon(CARD_TYPE_MASTERCARD, classes.cardIcon)}
                      {allowedCardBrands.includes(CARD_TYPE_DISCOVER) &&
                        renderCardIcon(CARD_TYPE_DISCOVER, classes.cardIcon)}
                      {allowedCardBrands.includes(CARD_TYPE_AMEX) &&
                        renderCardIcon(CARD_TYPE_AMEX, classes.cardIcon)}
                    </div>
                  )}
                  {state.selectedPaymentType === CREDIT_CARD && (
                    <CreditCardPaymentMethod
                      className="creditCardWidget"
                      paymentMethod={state.paymentMethod}
                      onUpdate={handleInputChange('cardInfo')}
                      setValid={handleValidityChange('cardInfo')}
                      showErrors={state.showErrors}
                    />
                  )}
                  {state.selectedPaymentType === ACH && (
                    <>
                      <ACHPaymentMethod className="ACHWidget" />
                      <NachaConfirmation
                        name={`${ACH}.nacha`}
                        id="nacha"
                        {...formikProps}
                      />
                      <AddressInfo className="ACHWidget2" />
                    </>
                  )}
                </div>
              </div>
              <Modal.Actions>
                {onClose && (
                  <Button
                    className={classes.cancelButton}
                    color="primary"
                    onClick={onClose}
                    disabled={formikProps.isSubmitting}
                  >
                    {t('common.cancel')}
                  </Button>
                )}
                <Button
                  className={classes.submitButton}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={formikProps.isSubmitting}
                >
                  {t('common.save')}
                </Button>
              </Modal.Actions>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

AddPaymentMethodDialog.propTypes = {
  onClose: PropTypes.func,
  enqueueSnackbarAndLogErrorConnect: PropTypes.func.isRequired,
  fetchPaymentIdConnect: PropTypes.func.isRequired,
  getOrRenewAccessTokenConnect: PropTypes.func.isRequired,
  addUserRequiredActionsPaymentMethodConnect: PropTypes.func.isRequired,
  addPaymentMethodConnect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  supportedPaymentTypes: PropTypes.object.isRequired,
  hexea: PropTypes.object,
  locationId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

AddPaymentMethodDialog.defaultProps = {
  onClose: null,
  isOpen: false,
  hexea: null,
};

export const mapStateToProps = (state) => {
  return {
    hexea: state.paymentMethod.hexeaObject,
    supportedPaymentTypes: state.location.paymentMethods,
    isFetchingLocationDetails: state.location.isFetchingLocationDetails,
    isFetchingSavedMethods: state.savedPaymentMethods.isFetchingSavedMethods,
    areSavedMethodsLoaded: state.savedPaymentMethods.areSavedMethodsLoaded,
    user: state.user,
    isSavingUserDetails: state.user.isSavingUserDetails,
    locationId: state.location.id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentIdConnect: (locationId) => {
    return dispatch(fetchPaymentID(locationId));
  },
  savePaymentOptionsConnect: (locationId, paymentOptions) => {
    return dispatch(savePaymentOptions(locationId, paymentOptions));
  },
  enqueueSnackbarAndLogErrorConnect: (error, notification) => {
    return dispatch(enqueueSnackbarAndLogError(error, notification));
  },
  getOrRenewAccessTokenConnect: () => {
    return dispatch(getOrRenewAccessToken());
  },
  addUserRequiredActionsPaymentMethodConnect: (newPaymentMethod) => {
    return dispatch(addUserRequiredActionsPaymentMethod(newPaymentMethod));
  },
  addPaymentMethodConnect: (newPaymentMethod) => {
    return dispatch(addPaymentMethod(newPaymentMethod));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AddPaymentMethodDialog);
