import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import * as globals from '../../../common/globals';

import { renderCardIcon } from '../../../common/utils';
import MethodDropdown from '../../../components/paymentMethod/MethodDropdown';
import NachaConfirmation from '../../../components/paymentMethod/NachaConfirmation';
import IDSLoginDialog from '../IDSLoginDialog';
import BillingInfo from './BillingInfo';
import CreditCardPaymentMethod from '../../../components/paymentMethod/CreditCardPaymentMethod';
import FormikCheckbox from '../../../components/common/FormikCheckbox';
import ACHPaymentMethod from '../../../components/paymentMethod/ACHPaymentMethod';

export function LoggedInWrapper({
  classes,
  selectedPaymentType,
  supportedPaymentTypes,
  savedPaymentMethods,
  isLoggedIn,
  setSelected,
  selectedMethod,
  formikProps,
  userDetailsComplete,
  onUpdate,
  setValid,
  paymentMethod,
  showErrors,
  isIDSDialogOpen,
  toggleIDSDialog,
  editContactInformation,
  features,
}) {
  const [state, setState] = useState({
    methods: [],
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (savedPaymentMethods.savedMethods) {
      const methods = savedPaymentMethods.savedMethods?.filter(
        (method) => method.paymentMethodType === selectedPaymentType,
      );
      setState({
        ...state,
        methods,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedPaymentMethods, selectedPaymentType]);

  const allowedCardBrands =
    (supportedPaymentTypes.creditCard &&
      supportedPaymentTypes.creditCard.allowedCardBrands) ||
    [];

  const newMethodByType = {
    [globals.CREDIT_CARD]: t('accountPayment.newCard'),
    [globals.ACH]: t('accountPayment.newAccount'),
  };

  const newMethodLabel = newMethodByType[selectedPaymentType];

  const onParentLogin = () => {
    setState({ ...state, isIDSDialogOpen: false });
  };

  return (
    <>
      {selectedPaymentType === globals.CREDIT_CARD && (
        <div className={classes.formItemGroup}>
          {allowedCardBrands.includes(globals.CARD_TYPE_AMEX) &&
            renderCardIcon(globals.CARD_TYPE_AMEX, classes.cardIcon)}
          {allowedCardBrands.includes(globals.CARD_TYPE_MASTERCARD) &&
            renderCardIcon(globals.CARD_TYPE_MASTERCARD, classes.cardIcon)}
          {allowedCardBrands.includes(globals.CARD_TYPE_VISA) &&
            renderCardIcon(globals.CARD_TYPE_VISA, classes.cardIcon)}
          {allowedCardBrands.includes(globals.CARD_TYPE_DISCOVER) &&
            renderCardIcon(globals.CARD_TYPE_DISCOVER, classes.cardIcon)}
        </div>
      )}

      {isLoggedIn && state.methods.length > 0 && (
        <>
          <MethodDropdown
            data-testid="dropdown"
            id="dropdown"
            textFieldClassName={classes.dropdown}
            paymentMethods={state.methods}
            setSelected={setSelected(selectedPaymentType)}
            selectedMethod={selectedMethod[selectedPaymentType]}
            methodType={selectedPaymentType}
            dropdownLabel={t('accountPayment.selectMethod')}
            newMethodLabel={newMethodLabel}
          />
          {selectedPaymentType === globals.ACH &&
            selectedMethod[selectedPaymentType] !==
              globals.NEW_PAYMENT_METHOD && (
              <NachaConfirmation
                name={`${globals.ACH}.nacha`}
                data-testid="nacha"
                id="nacha"
                {...formikProps}
              />
            )}
        </>
      )}

      {(selectedMethod[selectedPaymentType] === globals.NEW_PAYMENT_METHOD ||
        !(isLoggedIn && state.methods.length > 0)) && (
        <>
          {onUpdate &&
            paymentMethod.field &&
            selectedPaymentType === globals.CREDIT_CARD && (
              <CreditCardPaymentMethod
                className="creditCardWidget"
                data-testid="creditCardWidget"
                id="creditCardWidget"
                paymentMethod={paymentMethod}
                onUpdate={onUpdate('cardInfo')}
                setValid={setValid('cardInfo')}
                showErrors={showErrors}
              />
            )}
          {selectedPaymentType === globals.ACH && (
            <>
              <ACHPaymentMethod className="ACHWidget" />
              <NachaConfirmation
                name={`${globals.ACH}.nacha`}
                data-testid="nacha"
                id="nacha"
                {...formikProps}
              />
            </>
          )}
          {isLoggedIn && (
            <Field
              name="billingInfo.saveMethod"
              component={FormikCheckbox}
              data-testid="save-method"
              id="save-method"
              label={t('billingInfo.savePaymentMethod')}
              {...formikProps}
            />
          )}
        </>
      )}

      {((!userDetailsComplete && !features.MPPocketPlatformIntegration) ||
        (features.MPPocketPlatformIntegration && editContactInformation)) && (
        <BillingInfo
          data-testid="billing-info"
          id="billing-info"
          hideEmail={isLoggedIn}
        />
      )}

      <IDSLoginDialog
        id="payment-method-login-dialog"
        open={isIDSDialogOpen}
        onClose={toggleIDSDialog}
        onParentLogin={onParentLogin}
        frame={{ inStreamingApp: false }}
      />
    </>
  );
}

LoggedInWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedPaymentType: PropTypes.string.isRequired,
  supportedPaymentTypes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  savedPaymentMethods: PropTypes.object,
  setSelected: PropTypes.func.isRequired,
  selectedMethod: PropTypes.object.isRequired,
  formikProps: PropTypes.object.isRequired,
  userDetailsComplete: PropTypes.bool,
  paymentMethod: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  isIDSDialogOpen: PropTypes.bool.isRequired,
  toggleIDSDialog: PropTypes.func.isRequired,
  editContactInformation: PropTypes.bool.isRequired,
  features: PropTypes.object.isRequired,
};

LoggedInWrapper.defaultProps = {
  isLoggedIn: false,
  savedPaymentMethods: {},
  userDetailsComplete: false,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.session.loggedIn,
    sessionData: state.paymentMethod.sessionData,
    hexea: state.paymentMethod.hexeaObject,
    paymentId: state.paymentMethod.paymentId,
    savedPaymentMethods: state.savedPaymentMethods ?? {},
    userDetailsComplete: state.user.detailsComplete,
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(LoggedInWrapper);
