import PropTypes from 'prop-types';
import {
  Badge,
  IconButton,
  Grid,
  withStyles,
  Button,
  Typography,
} from '@material-ui/core';
import { MenuOutlined, ShoppingBasketOutlined } from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';
import PersonOutline from '@material-ui/icons/PersonOutline';
import { useTranslation } from 'react-i18next';
import * as globals from '../../common/globals';
import EmbeddedHeader from '../common/EmbeddedHeader';
import HeaderName from './HeaderName';

const styles = (theme) => ({
  container: {
    flexWrap: 'nowrap',
    color: theme.palette.text.secondary,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  titleColumn: {
    maxWidth: '100%',
    flexShrink: 1,
    minWidth: 0,
    marginTop: -2,
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  buttonColumn: {
    maxWidth: '100%',
    flexShrink: 0,
    flexGrow: 0,
  },
  icon: {
    color: 'inherit',
  },
  headerIcon: {
    marginRight: '10px',
    color: 'inherit',
  },
  headerButtonText: {
    textWrap: 'nowrap',
    fontWeight: 'bold',
  },
});

export function HeaderMobile({
  loc,
  transactionCount,
  classes,
  toggleMobileMenu,
  children,
  iframe,
  showEmbeddedView,
  showActionNeededUI,
  loggedIn,
  showIDSDialog,
}) {
  const { t } = useTranslation();

  const renderLoggedInMenu = () => (
    <Button
      id="profile-button"
      component={Link}
      to={`/${loc.id}/${globals.PROFILE_PATH}`}
    >
      <PersonOutline className={classes.headerIcon} />
      <Typography className={classes.headerButtonText}>
        {t('app.profile')}
      </Typography>
    </Button>
  );

  const renderLoggedOutMenu = () => (
    <Button
      id="login-button"
      className={classes.headerButton}
      onClick={() => showIDSDialog(false)}
    >
      <Typography className={classes.headerButtonText}>
        {t('app.logIn')}
      </Typography>
    </Button>
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid className={classes.wrapper}>
        {!showEmbeddedView && !showActionNeededUI && (
          <Grid item className={classes.buttonColumn}>
            <IconButton
              id="menu-button"
              className={classes.icon}
              aria-label="Menu"
              onClick={toggleMobileMenu}
            >
              <MenuOutlined />
            </IconButton>
            {children}
          </Grid>
        )}
        {showEmbeddedView && <Grid />}
        {!iframe && (
          <Grid item className={classes.titleColumn} id="header-name">
            <HeaderName loc={loc} isMobileView />
          </Grid>
        )}
      </Grid>
      <Grid className={classes.wrapper}>
        <Grid item>
          {loggedIn ? renderLoggedInMenu() : renderLoggedOutMenu()}
        </Grid>
        <Grid item className={classes.buttonColumn}>
          {showEmbeddedView && (
            <EmbeddedHeader transactionCount={transactionCount} loc={loc} />
          )}
          {!showEmbeddedView && !showActionNeededUI && (
            <IconButton
              id="basket-button"
              className={classes.icon}
              component={Link}
              aria-label="Basket"
              to={`/${loc.id}/${globals.BASKET_PATH}`}
            >
              <Badge
                id="header-basket-badge"
                badgeContent={transactionCount}
                color="primary"
                overlap="rectangular"
              >
                <ShoppingBasketOutlined />
              </Badge>
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

HeaderMobile.propTypes = {
  transactionCount: PropTypes.number.isRequired,
  loc: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
  iframe: PropTypes.bool.isRequired,
  showEmbeddedView: PropTypes.bool.isRequired,
  showActionNeededUI: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  showIDSDialog: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(HeaderMobile));
