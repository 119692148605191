import postRobot from 'post-robot';
import { FRAME_SEND, FRAME_COMPLETE, FRAME_ERROR } from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export function getFrameInfo() {
  return async dispatch => {
    dispatch({ type: FRAME_SEND });

    let inStreamingApp = false;
    postRobot
      .send(
        window.parent,
        'isStreamApp',
        { name: 'Vanco Online' },
        { timeout: 10000 },
      )
      .then(data => {
        // TODO - verify origin?
        if (data || (!!origin && origin.includes('stream'))) {
          inStreamingApp = true;
        }
        return dispatch({
          type: FRAME_COMPLETE,
          data: { inStreamingApp },
        });
      })
      .catch(e => {
        if (e.message.includes('No handler')) {
          return dispatch({
            type: FRAME_COMPLETE,
            data: { inStreamingApp },
          });
        }
        return dispatch({
          type: FRAME_ERROR,
          error: e.message,
        });
      });
  };
}
