export default function styles(theme) {
  return {
    defaultColor: '#3041FA',
    paymentMethod: {
      paddingTop: '5vh',
      marginLeft: '10vw',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      maxWidth: '500px',
      width: '88vw',
    },
    paymentMethodTitle: {
      lineHeight: '1.23',
      letterSpacing: '0.6px',
      marginBottom: '20px',
    },
    paymentMethodJoin: {
      display: 'flex',
      '& > a': {
        marginLeft: '8px',
      },
    },
    paymentMethodSubTitle: {
      lineHeight: '1.23',
      letterSpacing: '0.6px',
    },
    progressHolder: {
      width: '100%',
      position: 'absolute',
      alignSelf: 'baseline',
    },
    paymentProgress: {
      width: '100%',
    },
    paymentMethodRoot: {
      display: 'flex',
    },
    button: {
      padding: 0,
      width: 'fit-content',
      display: 'inline',
      fontWeight: 'bold',
      lineHeight: '1.5',
      '&:hover': {
        textDecoration: 'underline',
        background: 'transparent',
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.down('sm')]: {
      paymentMethodRoot: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      paymentMethod: {
        marginLeft: '6vw',
        marginRight: '6vw',
      },
    },
    horizontalGutter: {
      marginTop: theme.spacing(3),
    },
    secureMessage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& svg': {
        fontSize: 20,
        color: '#5E6266',
      },
      paddingBottom: theme.spacing(5),
    },
  };
}
