import { bool, func, objectOf, string } from 'prop-types';
import { Button, Grid, withStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styles from './styles';

function FormButtons({ classes, onBack, onSubmit, hasErrors }) {
  const { t } = useTranslation();

  return (
    <Grid container className={classes.container}>
      {onBack && (
        <Button
          variant={hasErrors ? 'contained' : 'outlined'}
          color="primary"
          onClick={onBack}
          className={classes.backButton}
          data-testid="back-button"
        >
          <ArrowBackIcon className={classes.backIcon} />
          {t('common.back')}
        </Button>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        disabled={hasErrors}
        className={onBack ? classes.submitButton : classes.submitButtonFull}
        data-testid="submit-button"
        type="submit"
      >
        {t('common.submit')}
      </Button>
    </Grid>
  );
}

FormButtons.propTypes = {
  classes: objectOf(string).isRequired,
  onBack: func,
  onSubmit: func.isRequired,
  hasErrors: bool.isRequired,
};

FormButtons.defaultProps = {
  onBack: undefined,
};

export default withStyles(styles)(FormButtons);
