import PropTypes from 'prop-types';
import usePageTitle from '../../../hooks/common/usePageTitle';

// Component to help Class components to suscribe to usePageTitle hook
function AppTitle({ title = '' }) {
  usePageTitle(title);
  return null;
}

AppTitle.propTypes = {
  title: PropTypes.string,
};

AppTitle.defaultProps = {
  title: '',
};

export default AppTitle;
