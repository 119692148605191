import {
  BASKET_SET_ITEMS,
  BASKET_SET_PAYMENT_OPTIONS,
  CLEAR_PAYMENT_OPTIONS,
  UPDATE_BASKET_PAYMENT_TYPE,
} from '../actions/actionTypes';

const initialState = {
  items: [],
  selectedPaymentType: null,
  applyProcessingFee: false,
  processingFee: null,
  isBasketLoaded: false,
};

export default function basket(state = initialState, action = {}) {
  switch (action.type) {
    case BASKET_SET_ITEMS:
      return { ...state, items: action.items, isBasketLoaded: true };
    case BASKET_SET_PAYMENT_OPTIONS:
      return {
        ...state,
        selectedPaymentType:
          action.paymentOptions.paymentType,
        applyProcessingFee:
          action.paymentOptions.applyProcessingFee !== undefined
            ? action.paymentOptions.applyProcessingFee
            : state.applyProcessingFee,
        processingFee:
          action.paymentOptions.processingFee,
      };
    case CLEAR_PAYMENT_OPTIONS:
      return {
        ...state,
        selectedPaymentType: null,
        applyProcessingFee: false,
        processingFee: null,
      };
    case UPDATE_BASKET_PAYMENT_TYPE:
      return {
        ...state,
        selectedPaymentType: action.selectedPaymentType,
      };
    default:
      return state;
  }
}
