import {
  url as urlHelpers,
  object,
  assert,
  inIframe,
} from '@vancoplatform/utils';
import qs from 'qs';
import { isSameOrigin, getOrigin, getHostAndPath } from '../helper/window';
import EmbeddedIframeHandler from '../helper/embeddedIframe';
import TransactionManager from './transaction-manager';
import { storageAvailable } from '../helper/storage';
import WebAuth from '.';
import {
  AuthorizationParameters,
  EmbeddedAuthorizeOptions,
  ParseHashResponse,
  WebAuthOptions,
} from '../types';
import Authentication from '../Authentication';

export default class Embedded {
  baseOptions: WebAuthOptions;
  client: Authentication;
  webAuth: WebAuth;
  transactionManager: TransactionManager;

  constructor(webAuth: WebAuth, options: WebAuthOptions) {
    this.baseOptions = options;
    this.client = webAuth.client;
    this.webAuth = webAuth;

    this.transactionManager = new TransactionManager({
      ...this.baseOptions.transaction,
      useFallbackStorage: !storageAvailable(),
    });
  }

  async authorize<TAppState = unknown>(
    options: EmbeddedAuthorizeOptions<TAppState>,
    element: HTMLElement
  ): Promise<ParseHashResponse<TAppState>> {
    let params: AuthorizationParameters = object
      .merge(this.baseOptions, [
        'clientId',
        'scope',
        'audience',
        'tenant',
        'responseType',
        'redirectUri',
        'state',
        'nonce',
        'maxAge',
        'usePKCE',
      ])
      .with(object.blacklist(options, ['iframeOptions', 'theme', 'onReady']));

    assert.check(
      params,
      { type: 'object', message: 'options parameter is not valid' },
      {
        responseType: {
          type: 'string',
          message: 'responseType option is required',
        },
      }
    );

    if (options.theme) {
      assert.check(options.theme, {
        type: 'object',
        message: 'theme option is not valid',
      });
    }

    // If no redirectUri is provided, just fall back to using the current location
    params.redirectUri = params.redirectUri || getHostAndPath();

    // Perform a sanity check to verify that the origin of the current window
    // matches the origin of the redirect uri. If this is not the case, we
    // will be unable to receive the authorization response.
    const origin = urlHelpers.extractOrigin(params.redirectUri);
    if (!isSameOrigin(origin)) {
      return Promise.reject(
        `The provided redirect uri "${
          params.redirectUri
        }" must have the same origin as the current window "${getOrigin()}"`
      );
    }

    params.scope = params.scope || 'openid profile email';
    params.redirectMode = params.redirectMode || 'relay';
    params.responseMode = 'fragment';
    if (params.redirectMode === 'redirect' && inIframe()) {
      console.warn(
        "Cannot use redirectMode = 'redirect' from inside an iFrame. Falling back to 'relay'"
      );
      params.redirectMode = 'relay_redirect';
    }

    if (params.redirectMode === 'redirect') {
      // Load the tenant details to check if we need to automatically add a provider parameter
      // We can only auto-redirect to an sso provider from redirectMode="redirect".
      if (!params.provider && params.tenant) {
        try {
          const tenantInfo = await this.client.tenantInfo(params.tenant);
          if (
            !tenantInfo.signInWithEmail &&
            !tenantInfo.signInWithPhone &&
            !tenantInfo.signInWithUsername &&
            tenantInfo.ssoProviders.length === 1
          ) {
            // If we only sign in via an sso provider, and there is also only a single sso provider,
            // add the provider parameter to send users directly there.
            params.provider = tenantInfo.ssoProviders[0].provider;
          }
        } catch (e) {
          console.error(
            `Unable to fetch tenant details for tenant ${params.tenant}`,
            e
          );
        }
      }

      // We can't handle prompt none, or a provider parameter in redirect redirect mode
      if (params.prompt === 'none' || params.provider) {
        this.webAuth.authorize(params);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return new Promise(() => {});
      }
    } else if (
      params.redirectMode === 'relay' ||
      params.redirectMode === 'relay_redirect'
    ) {
      // We cannot automatically redirect to an sso provider from the relay or relay_redirect redirect modes.
      delete params.provider;
    }

    params = await this.transactionManager.process(params);

    const url = this.client.buildAuthorizeUrl(params);

    const handler = new EmbeddedIframeHandler({
      ...options.iframeOptions,
      url,
    });

    return await new Promise<ParseHashResponse<TAppState>>(
      (resolve, reject) => {
        handler.mount(element);

        handler.on('getTransaction', () => {
          const transaction: Record<string, unknown> = {
            clientId: params.clientId,
            redirectUri: params.redirectUri,
          };

          return {
            ...transaction,
            ...(this.transactionManager.getStoredTransaction(params.state) ||
              {}),
          };
        });

        handler.on('getTheme', () => {
          if (typeof options.onReady === 'function') {
            options.onReady();
          }

          return options.theme || {};
        });

        handler.on('signOn', (event) => {
          delete event.data.redirect_mode;
          window.location.href = this.client.buildAuthorizeUrl(event.data);
        });

        handler.on(
          'authorize',
          (event: { data: ParseHashResponse<TAppState> }) => {
            resolve(event.data);
            handler.unmount();
          }
        );

        handler.on('redirect', (event: { data: unknown }) => {
          window.location.replace(
            `${params.redirectUri}#${qs.stringify(event.data)}`
          );
          const originAndPath =
            window.location.origin +
            window.location.pathname +
            window.location.search;
          if (
            originAndPath === params.redirectUri ||
            (params.redirectUri.endsWith('/') &&
              originAndPath ===
                params.redirectUri.substring(
                  0,
                  params.redirectUri.length - 1
                )) ||
            (!params.redirectUri.endsWith('/') &&
              originAndPath === `${params.redirectUri}/`)
          ) {
            window.location.reload();
          }
        });

        handler.on('error', (event) => {
          reject(event.data);
          handler.unmount();
        });
      }
    );
  }
}
