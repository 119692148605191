const getOrganizationInitials = (name) => {
  return (name ?? '')
    .split(' ')
    .map((word) =>
      word.length > 0 && word.toUpperCase() !== 'THE' ? word[0] : '',
    )
    .join('')
    .slice(0, 1)
    .toUpperCase();
};

export default getOrganizationInitials;
