import PropTypes from 'prop-types';
import { withStyles, CircularProgress, Typography, Dialog } from '@material-ui/core';

const styles = () => ({
  dialogRoot: {
    minWidth: '250px',
    minHeight: '150px',
    textAlign: 'center',
    padding: '24px 0',
    "& .MuiPaper-rounded": {
      borderRadius: "20px",
    }
  },
  progress: {
    marginTop: '16px',
  },
});

export function LoadingDialog({
  classes,
  isLoading,
  message,
}) {
  return (
    <Dialog
      data-testid="loading-dialog"
      open={isLoading}
      maxWidth={false}
      className={classes.dialogRoot}
      fullScreen={false}
    >
      <div className={classes.dialogRoot}>
        <Typography variant='h4'>{message}</Typography>
        <CircularProgress className={classes.progress} />
      </div>

    </Dialog>
  );
}

LoadingDialog.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

LoadingDialog.defaultProps = {
  classes: {},
  message: null,
};


export default withStyles(styles)(LoadingDialog);
