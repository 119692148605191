import { useSelector } from 'react-redux';

export default () => {
  return useSelector(({ features }) => {
    return {
      ...features.features,
      hasLoadedFeatures: features.hasLoadedFeatures,
    };
  });
};
