import { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import {
  HomeOutlined,
  PersonOutline,
  ExitToAppOutlined,
  CloseOutlined,
} from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as globals from '../../common/globals';
import { endSession } from '../../actions/session';
import HeaderName from './HeaderName';
import useFeatures from '../../hooks/common/useFeatures';
import LanguageToggle from './LanguageToggle/LanguageToggle';
import { useHeaderMobileDrawerStyles } from './styles';
import ReturnToOpportunities from '../navigation/returnToOpportunities/ReturnToOpportunities';

function HeaderMobileDrawer({
  loc,
  loggedIn,
  isMobileMenuOpen,
  toggleMobileMenu,
  allowLogin,
  iframe,
  showIDSDialog,
  endSessionConnect,
  match,
  showOpportunitiesLink,
}) {
  const classes = useHeaderMobileDrawerStyles();
  const { t } = useTranslation();
  const { MultiLanguageSupport } = useFeatures();

  /**
   * Function to logout the user from IDS.
   */
  const handleLogout = (event) => {
    event.preventDefault();
    endSessionConnect();
  };

  const renderLanguageToggle = () => {
    if (!MultiLanguageSupport) {
      return null;
    }
    return (
      <>
        <Divider variant="middle" />
        <LanguageToggle classes={classes} isMobileView />
        <Divider variant="middle" />
      </>
    );
  };

  const renderLoggedInMenu = () => {
    return (
      <Fragment>
        {showOpportunitiesLink && (
          <List component="div" disablePadding>
            <div className={classes.subMenu}>
              <ReturnToOpportunities />
            </div>
          </List>
        )}
        <ListItem
          id="profile-button"
          button
          component={Link}
          to={`/${loc.id}/${globals.PROFILE_PATH}`}
          className={classes.drawerListItem}
        >
          <ListItemIcon className={classes.icon}>
            <PersonOutline />
          </ListItemIcon>
          {t('app.profile')}
        </ListItem>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            className={classes.subMenu}
            to={`${match.url}/${globals.PROFILE_PATH}/${globals.TRANSACTIONS_PATH}`}
          >
            {t('app.transactions')}
          </ListItem>
          <ListItem
            button
            component={Link}
            className={classes.subMenu}
            to={`${match.url}/${globals.PROFILE_PATH}/${globals.PAYMENT_PATH}`}
          >
            {t('app.paymentMethods')}
          </ListItem>

          <ListItem button className={classes.subMenu}>
            <a
              href={`https://${window.env.REACT_APP_IDS_DOMAIN}/${loc.idsPayerTenant}/profile`}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="external-link"
            >
              {t('app.profileSettings')}
            </a>
          </ListItem>
        </List>
        {renderLanguageToggle()}
        {allowLogin && (
          <div>
            <Divider variant="middle" />
            <ListItem
              id="logout-button"
              button
              className={classes.drawerListItem}
              onClick={handleLogout}
            >
              <ListItemIcon className={classes.icon}>
                <ExitToAppOutlined />
              </ListItemIcon>
              {t('app.logOut')}
            </ListItem>
          </div>
        )}
      </Fragment>
    );
  };

  const renderLoggedOutMenu = () => {
    return (
      <Fragment>
        {renderLanguageToggle()}
        <ListItem
          id="signup-button"
          button
          onClick={() => showIDSDialog(true)}
          className={classes.subMenu}
        >
          {t('app.signUp')}
        </ListItem>
        <ListItem
          id="login-button"
          button
          onClick={() => showIDSDialog(false)}
          className={classes.subMenu}
        >
          {t('app.logIn')}
        </ListItem>
      </Fragment>
    );
  };

  return (
    <Drawer
      anchor="top"
      open={isMobileMenuOpen}
      onClose={toggleMobileMenu}
      variant="temporary"
    >
      <div
        tabIndex={0}
        role="button"
        onClick={toggleMobileMenu}
        onKeyDown={toggleMobileMenu}
      >
        <Typography component="div">
          <List component="nav" disablePadding>
            <ListItem className={classes.closeListItem}>
              <IconButton className={classes.icon} aria-label="Close">
                <CloseOutlined />
              </IconButton>
            </ListItem>
            <Divider variant="middle" />
            {!iframe && (
              <>
                <ListItem
                  button
                  component={Link}
                  to={`/${loc.id}/home`}
                  className={classes.drawerListItem}
                >
                  <ListItemText
                    disableTypography
                    primary={<HeaderName loc={loc} />}
                  />
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                  button
                  component={Link}
                  to={`/${loc.id}/home`}
                  className={classes.drawerListItem}
                >
                  <ListItemIcon className={classes.icon}>
                    <HomeOutlined />
                  </ListItemIcon>
                  {t('app.home')}
                </ListItem>
                <Divider variant="middle" />
              </>
            )}
            {(allowLogin || loggedIn) &&
              (loggedIn ? renderLoggedInMenu() : renderLoggedOutMenu())}
          </List>
        </Typography>
      </div>
    </Drawer>
  );
}

HeaderMobileDrawer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  showIDSDialog: PropTypes.func.isRequired,
  loc: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
  allowLogin: PropTypes.bool.isRequired,
  endSessionConnect: PropTypes.func.isRequired,
  iframe: PropTypes.bool.isRequired,
  showOpportunitiesLink: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { frame } = state;
  return { iframe: frame.inIframe };
};

const mapDispatchToProps = (dispatch) => ({
  endSessionConnect: () => {
    dispatch(endSession());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(HeaderMobileDrawer));
