import PropTypes from 'prop-types';
import { withStyles, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Modal from './modal';

const styles = () => ({
  title: {
    '& h6': {
      color: '#222222',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
  errorText: {
    color: '#222222',
    paddingBottom: 12,
  },
  cancelButton: {},
  submitButton: {
    fontWeight: 'bold',
  },
});

export function ConfirmationDialog(props) {
  const {
    title,
    message,
    submitButtonText,
    cancelButtonText,
    onSubmit,
    onClose,
    isOpen,
    classes,
  } = props;
  const { t } = useTranslation();

  // Consider using a render prop for the "message" content to allow more content options
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Title className={classes.title}>{title}</Modal.Title>
      <Modal.Content>
        <Typography className={classes.errorText}>{message}</Typography>
      </Modal.Content>
      <Modal.Actions>
        {onClose && (
          <Button
            className={classes.cancelButton}
            color="primary"
            onClick={onClose}
          >
            {cancelButtonText || t('common.cancel')}
          </Button>
        )}
        <Button
          className={classes.submitButton}
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          {submitButtonText || t('common.submit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  title: PropTypes.node.isRequired,
  message: PropTypes.string,
  submitButtonText: PropTypes.node,
  cancelButtonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  message: '',
  submitButtonText: '',
  cancelButtonText: '',
  onClose: null,
  isOpen: false,
};

export default withStyles(styles)(ConfirmationDialog);
