import { bool, number, oneOf } from 'prop-types';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getCurrencyFormat } from '../../../common/utils';
import { CAMPAIGN_TYPE } from '../../../common/globals';

// eslint-disable-next-line react/function-component-definition
const SubmitButton = ({ type, amount, inBasket, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      fullWidth
      {...rest}
    >
      <>
        {t('addDonation.addToBasket')}
        {type === CAMPAIGN_TYPE.PAYMENT && ` — ${getCurrencyFormat(amount)}`}
      </>
    </Button>
  );
};

SubmitButton.propTypes = {
  type: oneOf([CAMPAIGN_TYPE.GIFT, CAMPAIGN_TYPE.PAYMENT]).isRequired,
  inBasket: bool.isRequired,
  amount: number.isRequired,
};

export default SubmitButton;
