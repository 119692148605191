import isArray from '../assert/isArray';
import camelToSnake from './camelToSnake';

export default function toSnakeCase<T>(
  object: T,
  exceptions: Array<keyof T> = []
) {
  if (typeof object !== 'object' || isArray(object) || object === null) {
    return object;
  }

  return Object.keys(object).reduce<Record<string, unknown>>((p, key) => {
    const newKey =
      exceptions.indexOf(key as keyof T) === -1 ? camelToSnake(key) : key;
    p[newKey] = toSnakeCase((object as Record<string, unknown>)[key]);
    return p;
  }, {});
}
