export default theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: '375px',
    padding: '16px 20px 16px 20px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  amount: {
    fontWeight: '700',
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  itemBreak: {
    borderTop: '1px solid',
    borderColor: theme.palette.itemBreak,
    height: '1px',
    width: '100%',
    margin: '0',
  },
  cancelButton: {
    color: theme.palette.primary.main,
    display: 'flex',
    minHeight: '50px',
    fontWeight: '700',
  },
  inputField: {
    margin: '10px 0px 12px 0px',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputHelperText: {
    margin: '3px 12px 0',
    maxWidth: '100%',
  },
});
