import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useState } from 'react';
import styles from './styles';
import DraftTransactionCard from '../../../../../components/transactions/draft/DraftTransactionCard';
import createSchema from '../../../../../common/validation/MigratedPaymentsValidationUtils';
import FormikPersist from '../../../../../components/common/FormikPersist';
import { MIGRATED_TRANSACTIONS_STORAGE_NAME } from '../../../../../common/globals';
import ConfirmationDialog from '../../../../../components/common/ConfirmationDialog';
import { MIGRATED_TRANSACTIONS_PATCH_ABANDON } from '../../../../../actions/actionTypes';
import AddPaymentMethodDialog from '../../../../../components/common/AddPaymentMethodDialog';

function MigratedTransactionsForm({
  classes,
  transactions,
  campaigns,
  paymentMethods,
  patchRequiredActionConnect,
  onSubmit,
}) {
  const [newPaymentMethodModal, setNewPaymentMethodModal] = useState(false);
  const onClosePaymentMethodModal = () => setNewPaymentMethodModal(false);

  function getValidationSchema() {
    return createSchema(transactions);
  }
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { t } = useTranslation();

  const getInitialFormValues = () => {
    // Look at local storage, if nothing found, then use transactions data.
    const savedForm = window.localStorage.getItem(
      MIGRATED_TRANSACTIONS_STORAGE_NAME,
    );
    const parsedForm = savedForm ? JSON.parse(savedForm) : null;
    // eslint-disable-next-line no-return-assign
    const data = transactions.reduce(
      // eslint-disable-next-line no-return-assign
      (acc, curr) => (
        (acc[curr.id] = {
          fundId:
            parsedForm && parsedForm[curr.id]
              ? parsedForm[curr.id]?.id
              : curr.id,
          // eslint-disable-next-line no-nested-ternary
          selectedCampaign:
            // eslint-disable-next-line no-nested-ternary
            parsedForm && parsedForm[curr.id]
              ? parsedForm[curr.id]?.selectedCampaign
              : campaigns.find((c) => c.id === curr.selectedCampaign)
                ? curr.selectedCampaign
                : null,
          paymentToken:
            parsedForm && parsedForm[curr.id]
              ? parsedForm[curr.id]?.paymentToken
              : null,
          amount:
            parsedForm && parsedForm[curr.id]
              ? parsedForm[curr.id]?.amount
              : curr.amount,
          frequency:
            parsedForm && parsedForm[curr.id]
              ? parsedForm[curr.id]?.frequency
              : curr.frequency,
          // eslint-disable-next-line no-nested-ternary
          startDate:
            // eslint-disable-next-line no-nested-ternary
            parsedForm && parsedForm[curr.id]
              ? parsedForm[curr.id].startDate
                ? moment(parsedForm[curr.id].startDate)
                : null
              : curr.startDate
                ? moment(curr.startDate)
                : null,
          // eslint-disable-next-line no-sequences
        }),
        acc
      ),
      {},
    );

    return data;
  };

  const handleConfirmationOpen = (id) => {
    setDeleteId(id);
    setIsConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    // setTransactionIdToDelete(null);
    setIsConfirmationOpen(false);
  };

  const submitDelete = () => {
    patchRequiredActionConnect(deleteId, MIGRATED_TRANSACTIONS_PATCH_ABANDON);
    setIsConfirmationOpen(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        onSubmit={onSubmit}
        initialValues={getInitialFormValues()}
        validationSchema={getValidationSchema()}
      >
        {(formikProps) => {
          return (
            <Form className={classes.form}>
              {transactions.length > 0 &&
                transactions.map((transaction) => (
                  <DraftTransactionCard
                    className={classes.transactionCard}
                    key={transaction.id}
                    transaction={transaction}
                    onDelete={handleConfirmationOpen} // TODO OV-38884
                    onNewPaymentMethod={() => setNewPaymentMethodModal(true)}
                    campaigns={campaigns}
                    paymentMethods={paymentMethods}
                    formikProps={formikProps}
                    disabled={formikProps.isSubmitting}
                  />
                ))}
              <Button
                data-testid="submit-actions-button"
                type="submit"
                color="primary"
                variant="contained"
                className={classes.submitButton}
                disabled={
                  !!Object.keys(formikProps.errors).length ||
                  formikProps.isSubmitting
                }
              >
                {t('addDonation.save')}
              </Button>
              <FormikPersist name={MIGRATED_TRANSACTIONS_STORAGE_NAME} />
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        id="confirm-delete-dialog"
        data-testid="confirm-delete-dialog"
        title={t('transactions.action.delete')}
        message={t('transactions.action.confirm.delete.question')}
        submitButtonText={t('transactions.action.confirm.delete')}
        onSubmit={(e) => submitDelete(e)}
        isOpen={isConfirmationOpen}
        onClose={handleConfirmationClose}
      />
      <AddPaymentMethodDialog
        isOpen={newPaymentMethodModal}
        onClose={onClosePaymentMethodModal}
      />
    </>
  );
}

MigratedTransactionsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  patchRequiredActionConnect: PropTypes.func.isRequired,
};

export default withStyles(styles)(MigratedTransactionsForm);
