import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FAITH_FORWARD_EVENT_TYPE,
  POST_MESSAGE_EVENT_NAME,
} from '../../common/globals';

// Map event types in order to support multiple events in the future
const eventsMessageMapper = {
  [FAITH_FORWARD_EVENT_TYPE.DONATE_CLICKED_MESSAGE]: (history, locationId) => {
    window.open(`/${locationId}/home`, '_tab');
  },
};

const useIFrameListener = ({ iFrameRef = null, locationId = '' }) => {
  const history = useHistory();

  useEffect(() => {
    const postMessageListener = (event) => {
      if (!iFrameRef?.current || !locationId) {
        return;
      }
      // Always verify the origin for security purposes
      if (event.source !== iFrameRef.current.contentWindow) {
        return;
      }
      const action = eventsMessageMapper?.[event.data?.type];
      if (
        event.origin !== window.env.REACT_APP_FAITHFORWARD_ORIGIN ||
        !action
      ) {
        return;
      }
      action(history, locationId);
    };

    // Add event listener
    window.addEventListener(POST_MESSAGE_EVENT_NAME, postMessageListener);

    return () => {
      // Remove event listener
      window.removeEventListener(POST_MESSAGE_EVENT_NAME, postMessageListener);
    };
  }, [iFrameRef, locationId, history]);
};

export default useIFrameListener;
