export default theme => ({
  dateCell: {
    width: '200px',
    color: theme.palette.secondary.light,
    paddingLeft: '0px',
    paddingTop: '0px',
  },
  nameCell: {
    maxWidth: '150px',
    border: 'none',
    paddingLeft: '0px',
    paddingBottom: '0px',
    paddingRight: '0px',
  },
  nameText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  amountCell: {
    paddingRight: '0px',
  },
});
