import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  withStyles,
  CardActionArea,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import AddButton from '@material-ui/icons/Add';
import { useState } from 'react';
import styles from './styles';
import CustomDropdown from '../../common/CustomDropdown';
import CardDropdown from '../../addDonation/transactionItems/CardDropdown';
import OpenAmount from '../../addDonation/transactionItems/OpenAmount';
import filterNonOneNowFrequencies from '../../../common/frequenciesUtils';

const transformTileToTransaction = (tile, transaction) => {
  return {
    ...transaction,
    frequencies: filterNonOneNowFrequencies(
      tile.donationOptions.paymentFrequencies,
    ),

    // EndDate and memoLine are out of the scope for now.
    memoLine: {},
  };
};

function DraftTransactionCard(props) {
  const {
    className,
    classes,
    transaction,
    onDelete,
    onNewPaymentMethod,
    campaigns,
    paymentMethods,
    formikProps,
    disabled,
  } = props;
  const defaultTile = { memoLine: {}, fundId: {} };
  const currentTile =
    transaction.selectedCampaign && campaigns && campaigns.length > 0
      ? campaigns.find((t) => t.id === transaction.selectedCampaign)
      : undefined;
  const [transactionDetails, setTransactionDetails] = useState(
    currentTile
      ? transformTileToTransaction(currentTile, transaction)
      : defaultTile,
  );

  const { t } = useTranslation();

  const tilesData = campaigns.map((c) => {
    return { id: c.id, value: c.content.title };
  });

  const handleCancelTransactionButtonClick = () => {
    onDelete(transaction.fundId);
  };

  const onChangeTile = (value) => {
    // Need to set Frequencies based on selected Tile/Fund
    setTransactionDetails(
      transformTileToTransaction(
        campaigns.find((c) => c.id === value),
        transaction,
      ),
    );
  };

  return (
    <Card className={`${classes.root} ${className}`}>
      <CardContent className={classes.content}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Field
              id="transaction-tile"
              name={`${transaction.fundId}.selectedCampaign`}
              component={CustomDropdown}
              data={tilesData}
              className={classes.inputField}
              helperTextClass={classes.inputHelperText}
              textFieldLabel={t('transactions.action.giveTo')}
              onChange={onChangeTile}
            />

            {paymentMethods.length === 0 && (
              <Typography variant="body1">
                {t('transactions.history.paymentMethod')}
              </Typography>
            )}

            {paymentMethods.length > 0 ? (
              <Field
                id="transaction-tile"
                name={`${transaction.fundId}.paymentToken`}
                component={CardDropdown}
                data={paymentMethods}
                className={classes.inputField}
                helperTextClass={classes.inputHelperText}
                textFieldLabel={t('transactions.history.paymentMethod')}
                onNewPaymentMethod={onNewPaymentMethod}
              />
            ) : (
              <Button color="primary" onClick={onNewPaymentMethod}>
                <AddButton className={classes.addIcon} />
                {t('transactions.action.add.new.paymentMethod')}
              </Button>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={4}>
            {transactionDetails.id && (
              <OpenAmount
                id="OpenAmount"
                transactionDetails={transactionDetails}
                formikProps={formikProps}
                startDateLabel={t('addDonation.nextDate')}
                hideMultiTab
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.itemBreak} />
      <CardActionArea
        className={classes.cancelButton}
        color="primary"
        disabled={disabled}
        onClick={handleCancelTransactionButtonClick}
      >
        <Typography variant="body2">
          {t('transactions.action.delete')}
        </Typography>
      </CardActionArea>
    </Card>
  );
}

DraftTransactionCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onNewPaymentMethod: PropTypes.func.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  formikProps: PropTypes.object.isRequired,
};

DraftTransactionCard.defaultProps = {
  className: '',
};

export default withStyles(styles)(DraftTransactionCard);
