import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useMsal } from '@azure/msal-react';
import AzureAuthService from 'api/AzureAuthService';
import AuthService from '../../../../api/AuthService';
import {
  idsNonInteractiveError,
  initSession,
} from '../../../../actions/session';
import { enqueueSnackbarAndLogError } from '../../../../actions/notifications';
import * as globals from '../../../../common/globals';

export default function RedirectHandler({ routerProps }) {
  const [to, setTo] = useState(null);

  const dispatch = useDispatch();

  const isB2Credirect =
    routerProps.location.pathname === globals.AZURE_POST_REDIRECT;

  const { instance: msalInstance } = useMsal();

  useEffect(() => {
    function showErrorSnackbar(e) {
      dispatch(
        enqueueSnackbarAndLogError(e, {
          variant: globals.NOTIFICATION_ERROR,
          message: `${i18next.t('errors.IDSLogin')} ${
            e.message || e.errorDescription
          }`,
        }),
      );
    }

    async function parseUrl() {
      try {
        const result = await AuthService.parseHash();
        if (result) {
          await dispatch(initSession(result));
          setTo(result.appState.returnTo);
        } else {
          // check if value is base64
          let decodedValue = null;
          try {
            decodedValue = atob(
              new URLSearchParams(routerProps.location.search).get(
                globals.IDS_STATE,
              ),
            );
          } catch (e) {
            // eslint-disable-next-line no-empty
          }

          // Check for redirect state from the logout
          const redirectState =
            decodedValue !== null
              ? JSON.parse(decodedValue)
              : JSON.parse(
                  new URLSearchParams(routerProps.location.search).get(
                    globals.IDS_STATE,
                  ),
                );

          const locationId = redirectState?.[globals.REDIRECT_ID_STORAGE_NAME];
          if (locationId) {
            /*
             * Prevented a non interactive authentication when coming from logout.
             * The line was commented since dispatch(idsNonInteractiveError(globals.IDS_NON_REQUIRED));
             * it was skipping nonInteractiveLogin when user had a MP session still valid by using
             * [x] Keep me logged in 'checkbox'
             */
            setTo(`/${locationId}/${globals.HOME_PATH}`);
          } else {
            const sessionId = redirectState?.[globals.SESSION_ID_STORAGE_NAME];
            if (sessionId) {
              setTo(`/${globals.MPPAYMENT_PATH}/${sessionId}`);
            } else {
              setTo(`/${globals.GENERAL_ERROR_PATH}`);
            }
          }
        }
      } catch (e) {
        if (e.appState && e.appState.returnTo) {
          if (
            e.error === 'invalid_token' &&
            e.errorDescription === '`state` does not match.'
          ) {
            // This is a correlation error. We can recover from this by re-attempting the authorization
            AuthService.login(e.appState.idsPayerTenant, e.appState.returnTo);
          } else if (
            e.error === globals.IDS_LOGIN_REQUIRED ||
            e.error === globals.IDS_INTERACTION_REQUIRED
          ) {
            // MP user will require an interactive authentication
            dispatch(idsNonInteractiveError(e.error));
            setTo(e.appState.returnTo);
          } else {
            // eslint-disable-next-line no-console
            console.error(e);
            // Some other error
            showErrorSnackbar(e);
            setTo(e.appState.returnTo);
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(e);
          // without our appState back, we can't return to the correct location.
          showErrorSnackbar(e);
          setTo(`/${globals.GENERAL_ERROR_PATH}`);
        }
      }
    }

    async function handelAzureRedirect() {
      try {
        const result = await AzureAuthService.handleLoginEvents(msalInstance);
        await dispatch(initSession(result));
        setTo(result.appState.returnTo);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        showErrorSnackbar(e);
        setTo(`/${globals.GENERAL_ERROR_PATH}`);
      }
    }

    if (isB2Credirect) {
      handelAzureRedirect();
    } else {
      parseUrl();
    }

    // Remove event subscription to AB2C in unmount event.
    return () => {
      if (isB2Credirect) {
        AzureAuthService.unsubscribeRedirectEvents();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (to) {
    return <Redirect to={to} />;
  }

  return null;
}

RedirectHandler.propTypes = {
  routerProps: PropTypes.object.isRequired,
};
