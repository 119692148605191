export default theme => ({
  missingRoot: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  missingTitle: {
    fontWeight: '800',
  },
  missingText: {
    textAlign: 'center',
    marginTop: '10px',
  },
  text: {
    color: '#8D8D8D',
  },
  card: {
    width: '100%',
  },
  [theme.breakpoints.down('xs')]: {
    transactionCard: {
      width: '100%',
    },
  },
});
