export default theme => ({
  dateCell: {
    width: '200px',
    color: theme.palette.secondary.light,
  },
  nameCell: {
    maxWidth: '100px',
  },
  nameText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  historyRow: {
    cursor: 'pointer',
  },
});
