import i18next from 'i18next';
import {
  TRANSACTIONS_SCHEDULED_FETCH,
  TRANSACTIONS_SCHEDULED_COMPLETE,
  TRANSACTION_HISTORY_FETCH,
  TRANSACTION_HISTORY_COMPLETE,
  TRANSACTION_HISTORY_ERROR,
  TRANSACTIONS_SCHEDULED_ERROR,
  TRANSACTIONS_SCHEDULED_DELETE,
  TRANSACTIONS_SCHEDULED_DELETE_ERROR,
  TRANSACTIONS_SCHEDULED_DELETE_COMPLETE,
} from './actionTypes';
import TransactionService from '../api/TransactionService';

export function fetchScheduledTransactions(locationId) {
  return async (dispatch) => {
    dispatch({ type: TRANSACTIONS_SCHEDULED_FETCH });
    let response;

    try {
      response = await TransactionService.getScheduledTransactions(locationId);
    } catch (e) {
      dispatch({ type: TRANSACTIONS_SCHEDULED_ERROR });
      throw e;
    }

    if (response.status === 200 && response.data) {
      return dispatch({
        type: TRANSACTIONS_SCHEDULED_COMPLETE,
        transactions: response.data,
      });
    }
    dispatch({ type: TRANSACTIONS_SCHEDULED_ERROR });

    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function fetchTransactionHistory(locationId) {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_HISTORY_FETCH });
    let response;
    try {
      response = await TransactionService.getTransactionHistory(locationId);
    } catch (e) {
      dispatch({ type: TRANSACTION_HISTORY_ERROR });
      throw e;
    }

    if (response.status === 200 && response.data) {
      return dispatch({
        type: TRANSACTION_HISTORY_COMPLETE,
        history: response.data,
      });
    }
    dispatch({ type: TRANSACTION_HISTORY_ERROR });

    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function deleteScheduledTransaction(locationId, paymentId) {
  return async (dispatch) => {
    dispatch({ type: TRANSACTIONS_SCHEDULED_DELETE });

    let response;
    try {
      response = await TransactionService.deleteScheduledTransaction(
        locationId,
        paymentId,
      );
    } catch (e) {
      dispatch(fetchScheduledTransactions(locationId));
      dispatch({ type: TRANSACTIONS_SCHEDULED_DELETE_ERROR });
      throw e;
    }

    if (response.status === 200) {
      dispatch(fetchScheduledTransactions(locationId));
      return dispatch({ type: TRANSACTIONS_SCHEDULED_DELETE_COMPLETE });
    }

    dispatch({ type: TRANSACTIONS_SCHEDULED_DELETE_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}
