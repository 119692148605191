import { useEffect } from 'react';
import { debounce } from 'lodash';

// Suscribes a component to the window resize event
const useWindowResizeHandler = (onResize = () => {}) => {
  const onWindowResize = debounce(onResize, 100);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useWindowResizeHandler;
