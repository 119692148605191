import PropTypes from 'prop-types';
import { Button, Typography, withStyles } from '@material-ui/core';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import AddButton from '@material-ui/icons/Add';
import styles from './styles';
import AddPaymentMethodDialog from '../../../../../components/common/AddPaymentMethodDialog';
import OpenAmount from '../../../../../components/addDonation/transactionItems/OpenAmount';
import CardDropdown from '../../../../../components/addDonation/transactionItems/CardDropdown';
import EditableCampaignDropdown from '../../../../../components/addDonation/transactionItems/EditableCampaignDropDown';

function EditTransactionsForm({
  classes,
  transaction,
  paymentMethods,
  formikProps,
  campaigns,
}) {
  const [transactionDetails, setTransactionDetails] = useState(
    transaction ?? {},
  );
  const [newPaymentMethodModal, setNewPaymentMethodModal] = useState(false);
  const onNewPaymentMethod = () => setNewPaymentMethodModal(true);
  const { t } = useTranslation();

  const onClosePaymentMethodModal = ({ paymentMethodToken = '' } = {}) => {
    const cardFieldName = `${transaction.fundId}.paymentToken`;
    formikProps.setFieldValue(cardFieldName, paymentMethodToken, true);
    formikProps.setFieldTouched(cardFieldName, true, false);
    formikProps.setFieldValue(
      `${transaction.fundId}.paymentMethodUpdated`,
      true,
      false,
    );
    setNewPaymentMethodModal(false);
  };

  const handleCampaignIdChange = (newCampaignId) => {
    const { values: formValues, setFieldValue } = formikProps;

    const { paymentFrequencies } = campaigns.find(
      (c) => c.id === newCampaignId,
    );

    const currentCampaignId = transaction?.campaignId ?? null;

    const currentFrequency = formValues?.[currentCampaignId]?.frequency ?? '';

    const defaultFrequenciesIncluded =
      Boolean(currentFrequency) &&
      paymentFrequencies.includes(currentFrequency);

    /*
     * If the default frequency is not in the campaign paymentFrequencies
     * either the MUI input and/or Formik will clean the frequency dropdown
     * automatically.
     * Either way, we need to clean up de formik value manually
     * to avoid inconsistencies.
     * Formik should not re-validate these changes
     */
    const frequency = defaultFrequenciesIncluded ? currentFrequency : '';
    setFieldValue(`${transactionDetails.fundId}.frequency`, frequency, false);

    setTransactionDetails({
      ...transactionDetails,
      frequencies: paymentFrequencies,
    });
  };

  return (
    <>
      <Field
        id="donation-tile"
        name="editableCampaignId"
        component={EditableCampaignDropdown}
        campaigns={campaigns}
        className={classes.inputField}
        helperTextClass={classes.inputHelperText}
        textFieldLabel={t('transactions.action.giveTo')}
        onChange={handleCampaignIdChange}
      />

      <OpenAmount
        id="OpenAmount"
        transactionDetails={transactionDetails}
        formikProps={formikProps}
        startDateLabel={t('addDonation.nextDate')}
        hideMultiTab
        isEditModal
      />

      <Typography className={classes.subTitle}>
        {t('payment.paymentMethod')}
      </Typography>

      <div>
        {paymentMethods.length > 0 ? (
          <Field
            id="transaction-tile"
            name={`${transaction.fundId}.paymentToken`}
            component={CardDropdown}
            data={paymentMethods}
            className={classes.inputField}
            helperTextClass={classes.inputHelperText}
            textFieldLabel={t('transactions.history.paymentMethod')}
            onNewPaymentMethod={onNewPaymentMethod}
          />
        ) : (
          <Button color="primary" onClick={onNewPaymentMethod}>
            <AddButton className={classes.addIcon} />
            {t('transactions.action.add.new.paymentMethod')}
          </Button>
        )}
      </div>

      <AddPaymentMethodDialog
        isOpen={newPaymentMethodModal}
        onClose={onClosePaymentMethodModal}
      />
    </>
  );
}

EditTransactionsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  formikProps: PropTypes.object.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(EditTransactionsForm);
