import { isValid } from './stringValidation';

export const validChars = () => `!@#$^&*()-_+=[],.?/:;'`;

export const hasValidCharsOnly = (currentValue) => {
  if (!isValid(currentValue, true)) {
    return false;
  }

  // ensure special chars are escaped
  const escapedSpecialChars = validChars().replace(
    // eslint-disable-next-line no-useless-escape
    /[-\/\\^$*+?.()|[\]{}]/g,
    '\\$&',
  );
  // the ^ character within the [] will match anything not between the brackets
  // space that follows after the } is required
  const regex = new RegExp(`[^a-zA-Z0-9${escapedSpecialChars}\n ]`);

  const matchFound = currentValue.match(regex);
  return matchFound === null;
};
