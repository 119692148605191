import { OutlinedInput } from '@material-ui/core';
import { useFormControl } from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

const cxpFieldStyles = {
  base: {
    fontSize: '16px',
    lineHeight: '19px',
    fontFamily: ['Muli', 'sans-serif'].join(', '),
    '::placeholder': {
      color: '#a0a0a0',
    },
  },
  invalid: {
    color: '#e65f4b',
    ':focus': {
      color: '#3d3d3d',
    },
  },
};

function CxpPaymentMethodField(props) {
  const {
    field,
    id,
    onFocus,
    onBlur,
    onFilled,
    onEmpty,
    onChange,
    paymentMethod,
    labelWidth,
    inputProps,
  } = props;

  const muiFormControl = useFormControl();
  const fieldRef = useRef(null);
  const [fieldRendered, setFieldRendered] = useState(false);

  useEffect(() => {
    if (paymentMethod && !fieldRendered) {
      const cxpField = paymentMethod.field(field, fieldRef.current, {
        style: cxpFieldStyles,
        hideIcon: true,
        placeholder: ' ', // Hides the default CXP placeholders
      });

      setFieldRendered(true);

      cxpField?.on('change', (event) => {
        // You'll need to display event.error.message if it is set.
        // This could be used to set <FormControl error={true}>
        onChange(event);

        if (!event.empty) {
          if (muiFormControl && muiFormControl.onFilled) {
            muiFormControl.onFilled();
          }
          if (onFilled) {
            onFilled();
          }
        } else {
          if (muiFormControl && muiFormControl.onEmpty) {
            muiFormControl.onEmpty();
          }
          if (onEmpty) {
            onEmpty();
          }
        }
      });

      cxpField?.on('focus', () => {
        if (muiFormControl && muiFormControl.onFocus) {
          muiFormControl.onFocus();
        }
        if (onFocus) {
          onFocus();
        }
      });

      cxpField?.on('blur', () => {
        if (muiFormControl && muiFormControl.onBlur) {
          muiFormControl.onBlur();
        }
        if (onBlur) {
          onBlur();
        }
      });
    }
  }, [
    field,
    muiFormControl,
    onBlur,
    onChange,
    onEmpty,
    onFilled,
    onFocus,
    paymentMethod,
    fieldRendered,
    labelWidth,
  ]);

  return (
    <OutlinedInput
      id={id}
      data-testid={id}
      inputRef={fieldRef}
      labelWidth={labelWidth}
      inputComponent="div"
      {...inputProps}
    />
  );
}

CxpPaymentMethodField.propTypes = {
  id: PropTypes.string.isRequired,
  /**
   * Indicates which payment method field this input will collect
   */
  field: PropTypes.string.isRequired,

  /**
   * The Hexea.js paymentMethod object. Obtained by calling `hexea.paymentMethod(...)`
   */
  paymentMethod: PropTypes.shape({
    field: PropTypes.func.isRequired,
  }).isRequired,

  /**
   * Handle a change event. Will be used to pass validation errors
   * back from hexea.js
   */
  onChange: PropTypes.func,

  /**
   * Additional event handlers
   */
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onFilled: PropTypes.func,
  onEmpty: PropTypes.func,

  /**
   * Required by the OutlinedInput component. If this component is used inside a PaymentMethodFormField
   * component it will be computed automatically
   */
  labelWidth: PropTypes.number.isRequired,

  /**
   * Pass input props to custom outlined input
   */
  inputProps: PropTypes.object,
};

CxpPaymentMethodField.defaultProps = {
  onFocus: null,
  onBlur: null,
  onFilled: null,
  onEmpty: null,
  onChange: null,
  inputProps: {},
};

export default CxpPaymentMethodField;
