import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Grid,
  Card,
  Button,
  FormControlLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ShoppingBasketOutlined, LockOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import BasketItem from './BasketItem';
import PaymentMethodSummary from '../paymentMethod/PaymentMethodSummary';
import IOSSwitch from '../common/IOSSwitch';
import {
  removeBasketItem,
  savePaymentOptions,
  fetchPaymentOptions,
  updatePaymentItemQuantity,
} from '../../actions/basket';
import * as globals from '../../common/globals';
import { getTransactionCount, isIE11 } from '../../common/utils';
import { useTrackRecurrence } from '../../hooks/googleAnalyticHooks/googleAnalyticHooks';

const styles = (theme) => ({
  emptyMessage: {
    marginTop: '25px',
    marginBottom: '25px',
  },
  stickyNode: {},
  ie11Sticky: {},
  basket: {
    maxWidth: '600px',
  },
  content: {
    paddingBottom: '25px',
    width: '80%',
    margin: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    basket: {
      width: '88vw',
      margin: 'auto',
      marginTop: '3vh',
      position: 'static',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  [theme.breakpoints.up('md')]: {
    stickyNode: {
      alignSelf: 'baseline',
      marginTop: '5vh',
      left: '60vw',
      maxWidth: '500px',
      minWidth: '300px',
      minHeight: '150px',
      width: '35vw',
      top: '10vh',
      position: 'sticky',
    },
    ie11Sticky: {
      position: 'fixed',
    },
  },
  title: {
    lineHeight: '1.35',
    letterSpacing: '0.4px',
    display: 'flex',
  },
  basketTopBar: {
    marginTop: '25px',
    marginBottom: '18px',
  },
  basketIcon: {
    marginRight: '10px',
  },
  editLink: {
    lineHeight: '1.23',
    letterSpacing: '0.2px',
    textDecoration: 'none',
  },
  basketItem: {
    width: '80%',
    margin: 'auto',
  },
  buttonContainer: {
    marginTop: '25px',
    marginBottom: '10px',
  },
  checkoutButton: {
    width: '100%',
  },
  itemBreak: {
    borderColor: 'rgba(228, 229, 230, 0.5)',
    marginTop: '20px',
  },
  processingFeesSwitch: {
    fontSize: '18px',
  },
  paymentsCaptionContainer: {
    '& svg': {
      fill: theme.typography.caption.color,
      height: '18px',
    },
  },
  captionContainer: {
    marginTop: '22px',
    padding: '0px 24px',
  },
  captionLink: {
    color: theme.palette.primary.main,
  },
  inIframe: {
    boxShadow: 'none',
    border: '1px solid #DEE1E3',
  },
});

export function MyBasket(props) {
  const {
    fetchPaymentOptionsConnect,
    savePaymentOptionsConnect,
    removeBasketItemConnect,
    updatePaymentItemQuantityConnect,
    enableProcessingFees,
    applyProcessingFee,
    processingFee,
    classes,
    items,
    onSubmit,
    submitButtonText,
    paymentMethod,
    transactionCount,
    isSubmit,
    showSecurePaymentsMessage,
    iframe,
    locationId,
    loggedIn,
    history,
  } = props;
  const empty = !(transactionCount > 0);
  const displayFee = enableProcessingFees && Boolean(processingFee);
  const sendEventToGA = useTrackRecurrence();

  const { t } = useTranslation();

  useEffect(() => {
    /*
     * If a processingFee is passed into this component, we need to update the store
     * so that our data doesn't fall out of sync.
     */
    if (enableProcessingFees && processingFee) {
      savePaymentOptionsConnect(locationId, {
        applyProcessingFee,
        processingFee,
      });
    } else {
      fetchPaymentOptionsConnect(locationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemEdit = (item) => {
    history.push(
      `/${locationId}/${globals.CAMPAIGN_PATH}/${item.campaignDetails.id}`,
    );
  };

  const onItemDelete = (item) => {
    const { frequency } =
      item.transactionData[Object.keys(item.transactionData)[0]];

    sendEventToGA(loggedIn, frequency, 'delete');
    removeBasketItemConnect(locationId, item);
  };

  const onApplyProcessingFeesChange = (event) => {
    savePaymentOptionsConnect(locationId, {
      applyProcessingFee: event.target.checked,
      processingFee,
    });
  };

  const getKeyString = (campaign) =>
    Object.keys(campaign.transactionData)[0] || campaign.campaignDetails.id;

  const createKey = (campaign) => {
    const keyString = getKeyString(campaign);
    const transactionData = campaign.transactionData
      ? campaign.transactionData[keyString]
      : null;
    return transactionData
      ? `${keyString}-${transactionData?.frequency}-${transactionData?.startDate}`
      : keyString;
  };

  const updateQty = (quantity, campaign) => {
    const keyString = getKeyString(campaign);
    updatePaymentItemQuantityConnect(locationId, keyString, quantity);
  };

  return (
    <div
      className={`${classes.stickyNode} ${isIE11() ? classes.ie11Sticky : ''}`}
    >
      <Card className={`${classes.basket} ${iframe ? classes.inIframe : ''}`}>
        <div className={classes.content}>
          <div className={classes.basketTopBar}>
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <div className={classes.title}>
                <ShoppingBasketOutlined className={classes.basketIcon} />
                <Typography variant="h6">{t('app.basket')}</Typography>
              </div>
              {!empty && (
                <Badge
                  badgeContent={transactionCount}
                  color="primary"
                  overlap="rectangular"
                />
              )}
            </Grid>
            <hr className={classes.itemBreak} />
          </div>

          {items &&
            items.map((campaign) => (
              // need to iterate over "transaction" items here to pull out fund field details
              // return campaign.transactionItems.map(item => {
              <Fragment key={createKey(campaign)}>
                <BasketItem
                  className={classes.basketItem}
                  key={createKey(campaign)}
                  locationId={locationId}
                  item={campaign}
                  onEdit={onItemEdit}
                  onDelete={onItemDelete}
                  showProcessingFees={applyProcessingFee}
                  processingFee={processingFee}
                  onChangeQuantity={(qty) => updateQty(qty, campaign)}
                />
              </Fragment>
            ))}
          {empty && (
            <Grid container justifyContent="center" id="empty-message">
              <Typography className={classes.emptyMessage} variant="body1">
                {t('basket.emptyMessage')}
              </Typography>
            </Grid>
          )}

          {displayFee && (
            <div className={classes.processingFeesSwitch}>
              <hr
                className={classes.itemBreak}
                style={{ marginTop: '28px', marginBottom: '28px' }}
              />
              <FormControlLabel
                control={
                  <IOSSwitch
                    color="primary"
                    checked={applyProcessingFee}
                    onChange={onApplyProcessingFeesChange}
                  />
                }
                label={t('basket.processingFee')}
              />
            </div>
          )}

          {paymentMethod && (
            <div>
              <hr
                className={classes.itemBreak}
                style={{ marginTop: '40px', marginBottom: '30px' }}
              />
              <PaymentMethodSummary
                locationId={locationId}
                paymentMethod={paymentMethod}
              />
            </div>
          )}
          <Grid
            container
            className={classes.buttonContainer}
            justifyContent="center"
          >
            <Button
              id="checkout-button"
              className={classes.checkoutButton}
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={empty}
              type={isSubmit ? 'submit' : 'button'}
            >
              {submitButtonText}
            </Button>
          </Grid>
          {showSecurePaymentsMessage && (
            <Grid
              container
              className={classes.paymentsCaptionContainer}
              justifyContent="center"
            >
              <LockOutlined />
              <Typography variant="caption">
                {t('basket.paymentsProcessedSecurely')}
              </Typography>
            </Grid>
          )}
        </div>
      </Card>
      {paymentMethod && (
        <div className={classes.captionContainer}>
          <Typography variant="caption">
            {t('basket.recaptchaCaption')}{' '}
            <a
              href="https://policies.google.com/privacy"
              className={classes.captionLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('basket.privacyPolicy')}
            </a>{' '}
            {t('basket.and')}{' '}
            <a
              href="https://policies.google.com/terms"
              className={classes.captionLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('basket.termsOfService')}
            </a>{' '}
            {t('basket.apply')}
          </Typography>
        </div>
      )}
    </div>
  );
}

MyBasket.propTypes = {
  classes: PropTypes.object.isRequired,
  locationId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func,
  isSubmit: PropTypes.bool,
  showSecurePaymentsMessage: PropTypes.bool,
  submitButtonText: PropTypes.string.isRequired,
  paymentMethod: PropTypes.object,
  transactionCount: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  removeBasketItemConnect: PropTypes.func.isRequired,
  enableProcessingFees: PropTypes.bool,
  processingFee: PropTypes.number,
  applyProcessingFee: PropTypes.bool.isRequired,
  savePaymentOptionsConnect: PropTypes.func.isRequired,
  fetchPaymentOptionsConnect: PropTypes.func.isRequired,
  updatePaymentItemQuantityConnect: PropTypes.func.isRequired,
  iframe: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool,
};

MyBasket.defaultProps = {
  paymentMethod: null,
  enableProcessingFees: false,
  processingFee: null,
  isSubmit: false,
  showSecurePaymentsMessage: true,
  onSubmit: () => {},
  loggedIn: false,
};

const mapStateToProps = (state, ownProps) => ({
  items: state.basket.items,
  applyProcessingFee: state.basket.applyProcessingFee,
  processingFee: Object.prototype.hasOwnProperty.call(ownProps, 'processingFee')
    ? ownProps.processingFee
    : state.basket.processingFee,
  transactionCount: getTransactionCount(state.basket.items),
  iframe: state.frame.inIframe,
  loggedIn: state.session?.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  removeBasketItemConnect: (locationId, item, removeAllForOrg) => {
    dispatch(removeBasketItem(locationId, item, removeAllForOrg));
  },
  savePaymentOptionsConnect: (locationId, paymentOptions) => {
    dispatch(savePaymentOptions(locationId, paymentOptions));
  },
  fetchPaymentOptionsConnect: (locationId) => {
    dispatch(fetchPaymentOptions(locationId));
  },
  updatePaymentItemQuantityConnect: (locationId, campaignId, quantity) => {
    dispatch(updatePaymentItemQuantity(locationId, campaignId, quantity));
  },
});

export default compose(
  withRouter,
  withStyles(styles, { name: 'MyBasket' }),
  connect(mapStateToProps, mapDispatchToProps),
)(MyBasket);
