import postRobot from 'post-robot/dist/post-robot.ie';
import iframeResizer from 'iframe-resizer/js/iframeResizer';
import { url } from '@vancoplatform/utils';

const cssText =
  'background: transparent;\n' +
  'border: 0px none transparent;\n' +
  'overflow-x: hidden;\n' +
  'overflow-y: auto;\n' +
  'width: 1px;\n' +
  'min-width: 100%;\n' +
  'margin: 0;\n' +
  'padding: 0;\n' +
  '-webkit-tap-highlight-color: transparent;\n' +
  '-webkit-touch-callout: none;\n';

const hiddenCssText = 'display: none;\n';

export default class EmbeddedIframeHandler {
  url: string;
  origin: string;
  id: string | undefined;
  hidden: boolean;
  frame: HTMLIFrameElement | null;
  element: any;
  listener: any;

  constructor(options: { url: string; id?: string; hidden?: boolean }) {
    this.url = options.url;
    this.origin = url.extractOrigin(options.url);
    this.id = options.id;
    this.hidden = options.hidden || false;
    this.frame = null;
  }

  mount(element: any) {
    this.element = element;

    this.frame = document.createElement('iframe');
    this.frame.setAttribute('frameBorder', '0');
    this.frame.setAttribute('allowtransparency', 'true');
    if (this.id) {
      this.frame.setAttribute('id', this.id);
    }
    this.frame.style.cssText = this.hidden ? hiddenCssText : cssText;
    this.frame.src = this.url;

    this.element.appendChild(this.frame);

    const postRobotOpts = {
      window: this.frame.contentWindow,
      domain: this.origin,
    };
    this.listener = postRobot.listener(postRobotOpts);

    iframeResizer(
      {
        checkOrigin: [this.origin],
      },
      this.frame
    );
    return this.frame;
  }

  on(...params: any[]) {
    return this.listener.on(...params);
  }

  onFrame(
    type: any,
    listener: (this: HTMLIFrameElement, ev: any) => any,
    options?: boolean | AddEventListenerOptions | undefined
  ) {
    if (this.frame != null) {
      this.frame.addEventListener(type, listener, options);
    }
  }

  unmount() {
    if (this.frame != null) {
      (this.frame as any).iFrameResizer.removeListeners();
    }
    this.element.removeChild(this.frame);
  }
}
