import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import LeftNav from '../../components/navigation/leftNav/LeftNav';
import Transactions from './transactions/Transactions';
import Payment from './payment/Payment';
import Settings from './settings/Settings';
import * as globals from '../../common/globals';
import LocationError from '../error/location/LocationError';
import ThankYou from './transactions/thanks/Thanks';

const styles = (theme) => ({
  page: {
    marginLeft: `${globals.LEFT_NAV_SIZE_LARGE}px`,
  },
  [theme.breakpoints.down('xs')]: {
    leftNav: {
      display: 'none',
    },
    page: {
      marginLeft: '0px',
    },
  },
  [theme.breakpoints.only('sm')]: {
    page: {
      marginLeft: `${globals.LEFT_NAV_SIZE_SMALL}px`,
    },
  },
});

export function Profile(props) {
  const { match, classes, user } = props;

  const isFIPUser = user?.identities && user.identities.length > 0;

  const isActionNeededUIOnly =
    (user.hasActionNeededURL && !user.requiredActionsLoaded) ||
    (user.requiredActionsLoaded && user.showActionNeededUI);

  return (
    <>
      {!isActionNeededUIOnly && (
        <div className={classes.leftNav}>
          <LeftNav showProfileSettings={!isFIPUser} />
        </div>
      )}

      <div className={isActionNeededUIOnly ? '' : classes.page}>
        <Switch>
          <Route
            exact
            path={`/:locId/${globals.PROFILE_PATH}`}
            render={() => (
              <Redirect to={`${match.url}/${globals.TRANSACTIONS_PATH}`} />
            )}
          />
          <Route
            exact
            path={`${match.url}/${globals.TRANSACTIONS_PATH}`}
            render={(transactionsProps) => (
              <Transactions
                {...transactionsProps}
                locationId={match.params.locId}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/${globals.TRANSACTIONS_PATH}/${globals.TRANSACTIONS_THANKS_PATH}`}
            render={(thankYouProps) => <ThankYou {...thankYouProps} />}
          />
          <Route
            exact
            path={`${match.url}/${globals.PAYMENT_PATH}`}
            render={(paymentProps) => <Payment {...paymentProps} />}
          />
          <Route
            exact
            path={`${match.url}/${globals.SETTINGS_PATH}`}
            render={(settingsProps) => <Settings {...settingsProps} />}
          />
          <Route
            path={`/:locId/${globals.PROFILE_PATH}/*`}
            render={(errorProps) => <LocationError {...errorProps} notFound />}
          />
        </Switch>
      </div>
    </>
  );
}

Profile.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles))(Profile);
