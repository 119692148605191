import { TRANSACTIONS_ORIGIN } from '../common/globals';
import * as storageUtils from '../common/storageUtils';
import isValidOrigin from '../utils/OriginUtils';
import { getLocationIdFromUrl } from '../utils/SessionUtils';
import { ORIGIN_SET } from './actionTypes';

/**
 * Checks the current origin from a query parameter, or the stored one from the same location.
 */
export function setCurrentOrigin() {
  return (dispatch, getState) => {
    const { id } = getState().location;
    const locationId = id || getLocationIdFromUrl();
    let origin = new URLSearchParams(window.location.search).get(TRANSACTIONS_ORIGIN);
    if(origin && isValidOrigin(origin)){
      storageUtils.setOriginToStorage(locationId, origin);
    }else{
      origin = storageUtils.getOriginFromStorage(locationId);
    }

    dispatch({ type: ORIGIN_SET, data: origin });
  };
}

export default setCurrentOrigin;