import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import i18next from 'i18next';
import * as globals from '../common/globals';

export const getDefaultFrequency = (item, enableDefaultFrequency) => {
  if (!item) {
    return '';
  }

  // recurring and has a recurring default
  if (enableDefaultFrequency) {
    return (
      item.donationOptions?.defaultRecurringFrequency ||
      (item.donationOptions?.defaultOneTimeFrequency ?? '')
    );
  }

  return (
    item.donationOptions?.defaultOneTimeFrequency ||
    (item.donationOptions?.defaultRecurringFrequency ?? '')
  );
};

// Initializes the initialValues object used by Formik. Either populates with existing data if editing a basket item,
// or creates a new object if we're adding a new item
export const getInitialValues = ({
  campaignDetails,
  transactionData,
  enableDefaultFrequency,
  initialAmount,
}) => {
  if (isEmpty(campaignDetails)) {
    return {};
  }

  if (campaignDetails.type === globals.CAMPAIGN_TYPE.PAYMENT) {
    return campaignDetails.transactionItems.reduce((map, item) => {
      const newMap = map;
      newMap[item.fundId] = {
        itemAmount: item.price,
        amount: item.price,
        frequency: globals.PAYMENT_FREQUENCY_ONE_TIME_NOW,
        quantity: 1,
        memoLine: '',
      };

      return newMap;
    }, {});
  }

  return campaignDetails.transactionItems.reduce((map, item) => {
    const newMap = map;
    if (transactionData[item.fundId]) {
      newMap[item.fundId] = cloneDeep(transactionData[item.fundId]);
      // The startDate is stored as a string in the basket items object so we convert it to a moment object
      if (
        item.type === globals.TRANSACTION_ITEM_TYPES.OPEN_AMOUNT &&
        newMap[item.fundId].startDate
      ) {
        newMap[item.fundId].startDate = moment(newMap[item.fundId].startDate);
        newMap[item.fundId].endDate = newMap[item.fundId].endDate
          ? moment(newMap[item.fundId].endDate)
          : null;
        newMap[item.fundId].showEndDate = newMap[item.fundId].endDate;
      }
    } else {
      switch (item.type) {
        case globals.TRANSACTION_ITEM_TYPES.OPEN_AMOUNT:
          newMap[item.fundId] = {
            amount: initialAmount ?? '',
            frequency: getDefaultFrequency(item, enableDefaultFrequency),
            recurringOption: enableDefaultFrequency
              ? globals.PAYMENT_FREQUENCY_OPTIONS.RECURRING
              : globals.PAYMENT_FREQUENCY_OPTIONS.ONCE,
            startDate: null,
            endDate: null,
            memoLine: '',
            showEndDate: item.showEndDate,
            installmentsValid: false,
          };
          break;
        default:
      }
    }
    return newMap;
  }, {});
};

// This sets the inital touched object which is required to get Formik to properly validate
// existing basket data on component mount
export const getInitialTouched = ({ campaignDetails, transactionData }) => {
  if (
    isEmpty(campaignDetails) ||
    campaignDetails.type === globals.CAMPAIGN_TYPE.PAYMENT
  ) {
    return {};
  }

  return campaignDetails.transactionItems.reduce((map, item) => {
    const newMap = map;
    const data = transactionData[item.fundId];

    switch (item.type) {
      case globals.TRANSACTION_ITEM_TYPES.OPEN_AMOUNT:
        newMap[item.fundId] = {
          amount: !!data?.amount,
          frequency: !!data?.frequency,
          startDate:
            data?.frequency === globals.PAYMENT_FREQUENCY_ONE_TIME_NOW
              ? false
              : !!data?.startDate,
        };
        break;
      default:
    }
    return newMap;
  }, {});
};

export const getBasketQuantity = (campaignId, { transactionData = {} }) => {
  const transaction = transactionData[campaignId];
  if (isEmpty(transaction)) {
    return 0;
  }

  return transaction.quantity;
};

export const isPayment = (campaign) => {
  return campaign && campaign.type === globals.CAMPAIGN_TYPE.PAYMENT;
};

export const getPaymentId = (newItem) => {
  const campaignId = newItem.campaignDetails.id;
  return `${campaignId}${
    newItem.transactionData[campaignId].memoLine
      ? `-${newItem.transactionData[campaignId].memoLine}`
      : ''
  }`;
};

export const hasError = (item, basketData) => {
  if (item.campaignDetails.type === globals.CAMPAIGN_TYPE.PAYMENT) {
    const {
      campaignDetails: { id },
      transactionData = {},
    } = item;
    const paymentId = getPaymentId(item);
    const basketQuantity = getBasketQuantity(paymentId, basketData);
    const itemQuantity = transactionData[id]?.quantity || 0;

    return basketQuantity + itemQuantity > globals.MAX_PAYMENT_ITEMS;
  }

  return false;
};

export const getErrorMessage = (item, basketData) => {
  if (item.campaignDetails.type === globals.CAMPAIGN_TYPE.PAYMENT) {
    const paymentId = getPaymentId(item);
    const total = getBasketQuantity(paymentId, basketData);
    return total >= globals.MAX_PAYMENT_ITEMS
      ? i18next.t('basket.errorModal.maxSize')
      : i18next.t('basket.errorModal.extraItems', {
          extraItems: globals.MAX_PAYMENT_ITEMS - total,
        });
  }
  return null;
};

export const mapGiftDetails = (selectedCampaign) => {
  if (!selectedCampaign) {
    return {
      defaultOneTimeFrequency: '',
      defaultRecurringFrequency: '',
      frequencies: [],
      fundId: 'InitialValue',
      isTextFieldResponseType: false,
      memoFields: [],
      memoLine: {
        showTextField: false,
        responseRequired: false,
        textField: '',
      },
      oneTimePaymentFrequencies: [],
      recurringPaymentFrequencies: [],
      required: true,
      showEndDate: false,
      textField: '',
      title: '',
      type: '',
      shortDescription: '',
      allowInstallments: false,
    };
  }
  return {
    defaultOneTimeFrequency:
      selectedCampaign.donationOptions?.defaultOneTimeFrequency,
    defaultRecurringFrequency:
      selectedCampaign.donationOptions?.defaultRecurringFrequency,
    frequencies: selectedCampaign.donationOptions?.paymentFrequencies,
    fundId: selectedCampaign.id,
    isTextFieldResponseType: selectedCampaign.isTextFieldResponseType,
    memoFields: selectedCampaign.memoFields,
    memoLine: {
      showTextField: selectedCampaign.showTextField,
      responseRequired: selectedCampaign.responseRequired,
      textField: selectedCampaign.textField,
    },
    oneTimePaymentFrequencies:
      selectedCampaign.donationOptions?.oneTimePaymentFrequencies,
    recurringPaymentFrequencies:
      selectedCampaign.donationOptions?.recurringPaymentFrequencies,
    required: true,
    showEndDate: selectedCampaign.showEndDate,
    textField: selectedCampaign.textField,
    title: selectedCampaign.content.title,
    type: selectedCampaign.type,
    shortDescription: selectedCampaign.content.shortDescription,
    allowInstallments: selectedCampaign.allowInstallments,
  };
};

export const mapPaymentDetails = (selectedCampaign) => {
  if (!selectedCampaign) {
    return {
      fundId: '',
      title: '',
      required: false,
      memoLine: {},
      showEndDate: false,
      type: '',
      price: 0,
      isTextFieldResponseType: false,
      memoFields: [],
      textField: '',
    };
  }
  return {
    fundId: selectedCampaign.id,
    title: selectedCampaign.content?.title,
    shortDescription: selectedCampaign.content?.shortDescription,
    required: true,
    memoLine: {
      showTextField: selectedCampaign.showTextField,
      responseRequired: selectedCampaign.responseRequired,
      textField: selectedCampaign.textField,
    },
    showEndDate: selectedCampaign.showEndDate,
    type: globals.TRANSACTION_ITEM_TYPES.PAYMENT_FIXED_AMOUNT,
    price: selectedCampaign.paymentOptions?.price,
    isTextFieldResponseType: selectedCampaign.isTextFieldResponseType,
    memoFields: selectedCampaign.memoFields,
    textField: selectedCampaign.textField,
  };
};

export const getTransactionDetailsFromCampaign = (selectedCampaign) => {
  const detailsMap = {
    [globals.CAMPAIGN_TYPE.GIFT]: mapGiftDetails,
    [globals.CAMPAIGN_TYPE.PAYMENT]: mapPaymentDetails,
  };

  return (
    detailsMap[selectedCampaign?.type] || detailsMap[globals.CAMPAIGN_TYPE.GIFT]
  )(selectedCampaign);
};

const getGiftInitialValues = (
  campaign,
  enableDefaultFrequency,
  campaignId,
  initialAmount = '',
) => {
  return {
    [campaign.id]: {
      fundId: campaign.id,
      amount: campaignId === campaign.id ? initialAmount : '',
      recurringOption: enableDefaultFrequency
        ? globals.PAYMENT_FREQUENCY_OPTIONS.RECURRING
        : globals.PAYMENT_FREQUENCY_OPTIONS.ONCE,
      frequency: getDefaultFrequency(campaign, enableDefaultFrequency),
      startDate: null,
      endDate: null,
      memoLine: '',
      isSelected: campaignId === campaign.id,
      showEndDate: campaign.showEndDate,
    },
  };
};

// eslint-disable-next-line no-unused-vars
const getPaymentInitialValues = (campaign, _, campaignId) => {
  return {
    [campaign.id]: {
      fundId: campaign.id,
      amount: campaign.paymentOptions?.price || 0,
      itemAmount: campaign.paymentOptions?.price || 0,
      frequency: globals.PAYMENT_FREQUENCY_ONE_TIME_NOW,
      quantity: '',
      memoLine: '',
      isSelected: campaignId === campaign.id,
    },
  };
};

export const getExpressInitialValues = (
  campaigns,
  enableDefaultFrequency,
  campaignId = '',
  initialAmount = '',
) => {
  if (!campaigns) return {};

  const initialValuesMapType = {
    [globals.CAMPAIGN_TYPE.GIFT]: getGiftInitialValues,
    [globals.CAMPAIGN_TYPE.PAYMENT]: getPaymentInitialValues,
  };
  const data = campaigns.reduce(
    (acc, curr) => ({
      ...acc,
      ...(initialValuesMapType[curr.type] ?? getGiftInitialValues)(
        curr,
        enableDefaultFrequency,
        campaignId,
        initialAmount,
      ),
    }),
    {},
  );
  return {
    ...data,
    fundId: campaigns.find((c) => c.id === campaignId) ? campaignId : '',
  };
};

export const hasGift = (campaigns) => {
  return (
    campaigns && campaigns.some((c) => c.type === globals.CAMPAIGN_TYPE.GIFT)
  );
};

export const hasPayment = (campaigns) => {
  return (
    campaigns && campaigns.some((c) => c.type === globals.CAMPAIGN_TYPE.PAYMENT)
  );
};

export const getOtheropportunitiesLabel = (campaigns, useWordMore = false) => {
  let label = i18next.t('expanded.backToHome');
  if (campaigns && Array.isArray(campaigns)) {
    const hasGifts = hasGift(campaigns);
    const hasPayments = hasPayment(campaigns);
    if (hasGifts && hasPayments) {
      label = i18next.t('back.both.opportunities', {
        more: useWordMore ? `${i18next.t('common.more')} ` : '',
      });
    } else if (hasGifts) {
      label = i18next.t('back.giving.opportunities', {
        more: useWordMore ? `${i18next.t('common.more')} ` : '',
      });
    } else if (hasPayments)
      label = i18next.t('back.payment.opportunities', {
        more: useWordMore ? `${i18next.t('common.more')} ` : '',
      });
  }
  return label;
};
