import PropTypes from 'prop-types';
import {
  withStyles,
  Button,
  Typography,
  Card,
  IconButton,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  getCurrencyFormat,
  getTransactionDisplayName,
} from '../../../../common/utils';
import styles from './styles';
import CustomDialog from '../../../common/CustomDialog';
import * as globals from '../../../../common/globals';

function HistoryDialog(props) {
  const { transaction, open, classes, handleClose, fullScreen } = props;

  const { t } = useTranslation();

  const getMethodText = (methodType) => {
    switch (methodType) {
      case globals.CREDIT_CARD_TOKENIZE:
        return t('transactions.history.card');
      case globals.ACH_TOKENIZE:
        return t('transactions.history.bankAccount');
      default:
        return '';
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialog }}
    >
      <div className={classes.dialogRoot}>
        {fullScreen && (
          <Button
            id="mobile-history-close-button"
            className={classes.backHeader}
            variant="text"
            startIcon={<ArrowBack />}
            onClick={handleClose}
          >
            <Typography variant="h6">
              {t('transactions.history.back')}
            </Typography>
          </Button>
        )}
        <div id="title-header" className={classes.headerContainer}>
          <div className={classes.headerText}>
            <Typography
              id="history-dialog-header-name"
              className={classes.headerName}
              variant={fullScreen ? 'h5' : 'h6'}
            >
              {transaction.displayName}
            </Typography>
            <Typography component="div" className={classes.subtitle}>
              <div id="history-dialog-date" data-testid="history-dialog-date">
                {moment(transaction.transactionDate).format('MMMM D, YYYY')}
              </div>
              <div id="history-dialog-id">
                {t('transactions.history.transactionNumber', {
                  id: transaction.paymentId,
                })}
              </div>
            </Typography>
          </div>
          {!fullScreen && (
            <div className={classes.closeButton}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                id="history-close-button"
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </div>
        <div id="transaction-summary" className={classes.summary}>
          <Card className={classes.summaryCard}>
            <Typography variant="h6">
              {t('transactions.history.summary')}
            </Typography>
            <hr className={classes.itemBreak} />
            <Typography
              component="div"
              id="line-item"
              className={classes.lineItem}
            >
              <div
                id="history-dialog-summary-name"
                data-testid="history-dialog-summary-name"
              >
                {getTransactionDisplayName(transaction)}
              </div>
              <div
                className={classes.amount}
                id="history-dialog-summary-amount"
              >
                {transaction.amount
                  ? getCurrencyFormat(transaction.amount)
                  : ''}
              </div>
            </Typography>
            <Typography
              variant="body2"
              component="div"
              id="line-total"
              className={classes.lineItem}
            >
              {t('transactions.history.total')}
              <div
                className={classes.amount}
                id="history-dialog-summary-total"
                data-testid="history-dialog-summary-total"
              >
                {transaction.amount
                  ? getCurrencyFormat(transaction.amount)
                  : ''}
              </div>
            </Typography>
          </Card>
        </div>
        <div id="transaction-method" className={classes.summary}>
          <Card className={classes.summaryCard}>
            <Typography variant="h6">
              {t('transactions.history.paymentMethod')}
            </Typography>
            <Typography
              component="div"
              id="line-item"
              className={classes.methodLineItem}
            >
              <div
                id="history-dialog-method"
                data-testid="history-dialog-method"
              >{`${getMethodText(transaction.paymentMethodType)} ${
                transaction.last4 || ''
              }`}</div>
            </Typography>
          </Card>
        </div>
      </div>
    </CustomDialog>
  );
}

HistoryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  transaction: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(HistoryDialog);
