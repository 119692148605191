import getLocationFromUrl from './getLocationFromUrl';

export default function getOriginFromUrl(url: string) {
  if (!url) {
    return undefined;
  }
  const parsed = getLocationFromUrl(url);
  if (parsed == null) {
    return undefined;
  }
  let origin = `${parsed.protocol}//${parsed.hostname}`;
  if (parsed.port) {
    origin += ':' + parsed.port;
  }
  return origin;
}
