import {
  CSRF_COMPLETE,
  CSRF_ERROR,
  CSRF_FETCH,
  SESSION_INIT_COMPLETE,
  SESSION_LOGIN,
  SESSION_LOGOUT,
  SESSION_LOGOUT_INIT,
  IDS_SESSION_SAVE,
  IDS_SESSION_CHECK_UPDATE,
  IDS_NON_INTERACTIVE_LOGIN_ERROR,
  IDS_NON_INTERACTIVE_LOGIN_ERROR_HANDLED,
} from '../actions/actionTypes';

const initialState = {
  loggedIn: false,
  isSessionLoaded: false,
  sessionDetails: {
    accessToken: '',
  },
  csrfToken: '',
  isFetchingCsrf: false,
  initComplete: false,
  idsSessionState: undefined,
  idsNonInteractiveError: '',
  idsNonInteractiveErrorHandled: false,
  logOutRequired: false,
};

export default function session(state = initialState, action = {}) {
  switch (action.type) {
    case SESSION_LOGIN:
      // mpPocketPlatformInitialLogin is only assigned once during Pocket Platform initial login
      return {
        ...state,
        loggedIn: true,
        isSessionLoaded: true,
        sessionDetails: action.payload,
        logOutRequired: false,
        mpPocketPlatformInitialLogin:
          action.payload.appState?.mpPocketPlatformInitialLogin,
      };
    case SESSION_LOGOUT:
      return {
        ...state,
        loggedIn: false,
        isSessionLoaded: true,
        sessionDetails: initialState.sessionDetails,
        csrfToken: '',
        logOutRequired: true,
      };
    case SESSION_LOGOUT_INIT:
      return {
        ...state,
        loggedIn: false,
        isSessionLoaded: true,
        sessionDetails: initialState.sessionDetails,
        csrfToken: '',
      };
    case CSRF_FETCH:
      return {
        ...state,
        isFetchingCsrf: true,
      };
    case CSRF_COMPLETE:
      return {
        ...state,
        isFetchingCsrf: false,
        csrfToken: action.csrfToken,
      };
    case CSRF_ERROR:
      return {
        ...state,
        isFetchingCsrf: false,
        csrfToken: '',
      };
    case SESSION_INIT_COMPLETE:
      return {
        ...state,
        initComplete: true,
      };
    case IDS_SESSION_SAVE:
      return {
        ...state,
        idsSessionState: action.payload,
      };

    case IDS_SESSION_CHECK_UPDATE:
      return {
        ...state,
        lastTimeSessionChecked: action.payload,
      };

    case IDS_NON_INTERACTIVE_LOGIN_ERROR:
      return {
        ...state,
        idsNonInteractiveError: action.payload,
      };

    case IDS_NON_INTERACTIVE_LOGIN_ERROR_HANDLED:
      return {
        ...state,
        idsNonInteractiveErrorHandled: true,
      };

    default:
      return state;
  }
}
