import { LOCATION_CHANGE } from 'connected-react-router';
import {
  SAVED_PAYMENT_METHODS_FETCH,
  SAVED_PAYMENT_METHODS_COMPLETE,
  SAVED_PAYMENT_METHODS_ERROR,
  SAVED_PAYMENT_METHODS_DELETE,
  SAVED_PAYMENT_METHODS_DELETE_COMPLETE,
  SAVED_PAYMENT_METHODS_DELETE_ERROR,
  SAVED_PAYMENT_METHODS_ADD,
} from '../actions/actionTypes';
import { savedPaymentMethodsTransformToFrontend } from '../api/transform/SavedPaymentMethodTransform';

const initialState = {
  savedMethods: [],
  isFetchingSavedMethods: false,
  isDeletingSavedMethod: false,
  areSavedMethodsLoaded: false,
};

export default function savedPaymentMethods(state = initialState, action = {}) {
  let newSavedMethods;
  switch (action.type) {
    case SAVED_PAYMENT_METHODS_FETCH:
      return {
        ...state,
        isFetchingSavedMethods: true,
        areSavedMethodsLoaded: false,
      };
    case SAVED_PAYMENT_METHODS_COMPLETE:
      return {
        ...state,
        savedMethods: savedPaymentMethodsTransformToFrontend(
          action.savedMethods,
        ),
        isFetchingSavedMethods: false,
        areSavedMethodsLoaded: true,
      };
    case SAVED_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        isFetchingSavedMethods: false,
        areSavedMethodsLoaded: true,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        areSavedMethodsLoaded: false,
      };
    case SAVED_PAYMENT_METHODS_DELETE:
      return { ...state, isDeletingSavedMethod: true };
    case SAVED_PAYMENT_METHODS_DELETE_COMPLETE:
      return {
        ...state,
        isDeletingSavedMethod: false,
      };
    case SAVED_PAYMENT_METHODS_DELETE_ERROR:
      return { ...state, isDeletingSavedMethod: false };
    case SAVED_PAYMENT_METHODS_ADD:
      newSavedMethods =
        state.savedMethods?.length > 0
          ? [...state.savedMethods, action.newPaymentMethod]
          : [action.newPaymentMethod];
      return {
        ...state,
        savedMethods: newSavedMethods,
      };
    default:
      return state;
  }
}
