import i18next from 'i18next';
import {
  LOCATION_DETAILS_FETCH,
  LOCATION_DETAILS_COMPLETE,
  LOCATION_DETAILS_ERROR,
  LOCATION_TENANT_FETCH,
  LOCATION_TENANT_COMPLETE,
  LOCATION_TENANT_ERROR,
} from './actionTypes';
import LocationService from '../api/LocationService';
import { setColorToCache } from '../common/storageUtils';
import { getLocationIdFromUrl } from '../utils/SessionUtils';

export function fetchLocationDetails(locationId) {
  return async (dispatch) => {
    dispatch({ type: LOCATION_DETAILS_FETCH, id: locationId });

    let response;
    try {
      response = await LocationService.getLocationDetails(locationId);
    } catch (error) {
      dispatch({ type: LOCATION_DETAILS_ERROR });
      throw error;
    }

    if (response?.status === 200 && response.data) {
      if (response.data.primaryColor) {
        setColorToCache(locationId, response.data.primaryColor);
      }
      return dispatch({ type: LOCATION_DETAILS_COMPLETE, data: response.data });
    }

    dispatch({ type: LOCATION_DETAILS_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function fetchLocationTenant() {
  return async (dispatch) => {
    const locationId = getLocationIdFromUrl();
    if (!locationId) {
      dispatch({ type: LOCATION_TENANT_ERROR });
      return null;
    }

    dispatch({ type: LOCATION_TENANT_FETCH, id: locationId });

    let response;
    try {
      response = await LocationService.getLocationTenant(locationId);
    } catch (error) {
      dispatch({ type: LOCATION_TENANT_ERROR });
      throw error;
    }

    if (response?.status === 200 && response.data) {
      await dispatch({ type: LOCATION_TENANT_COMPLETE, data: response.data });
      return response.data.data;
    }

    dispatch({ type: LOCATION_TENANT_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}
