import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, connect } from 'react-redux';
import postRobot from 'post-robot';
import { useTranslation } from 'react-i18next';
import AuthService from '../api/AuthService';
import { enqueueSnackbarAndLogError } from '../../actions/notifications';
import * as globals from '../../common/globals';
import CustomDialog from '../../components/common/CustomDialog';

const styles = () => ({
  dialogRoot: {
    minWidth: '380px',
    minHeight: '500px',
  },
  closeButton: {
    marginTop: '15px',
    marginLeft: '15px',
    color: '#222222', // May need to add to theme if we standardize our close buttons
  },
});

export function IDSLoginDialog({
  onClose,
  returnTo,
  open,
  renderHeader,
  classes,
  isSignup,
  frame,
  onParentLogin,
}) {
  const iframeRef = useRef(null);
  const [isRendered, setRendered] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loginFromParent = useCallback(() => {
    postRobot
      .send(window.parent, 'logIn', { name: 'Vanco Online' }, { timeout: 5000 })
      .catch((e) => {
        dispatch(
          enqueueSnackbarAndLogError(e, {
            variant: globals.NOTIFICATION_ERROR,
            message: `${t('errors.IDSLogin')} ${
              e.message || e.errorDescription
            }`,
          }),
        );
      });
    onParentLogin();
  }, [dispatch, onParentLogin, t]);

  useEffect(() => {
    if (open && frame.inStreamingApp) loginFromParent();
  }, [open, frame.inStreamingApp, loginFromParent]);

  const embedIframe = () => {
    if (open && !isRendered) {
      setRendered(true);
      AuthService.embedLogin(iframeRef.current, isSignup, returnTo);
    }
  };

  const onExited = () => {
    setRendered(false);
  };

  return (
    <CustomDialog
      data-testid="ids-login-dialog"
      id="ids-login-dialog"
      onClose={onClose}
      open={open && !frame.inStreamingApp}
      maxWidth={false}
      TransitionProps={{ onEntered: embedIframe, onExited }}
    >
      <div className={classes.dialogRoot}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
          data-testid="close-dialog"
          id="close-dialog"
        >
          <CloseIcon />
        </IconButton>
        {renderHeader()}
        <div id="ids-login-dialog-root" ref={iframeRef} />
      </div>
    </CustomDialog>
  );
}

IDSLoginDialog.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  returnTo: PropTypes.string,
  onParentLogin: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  renderHeader: PropTypes.func,
  isSignup: PropTypes.bool,
  frame: PropTypes.object.isRequired,
};

IDSLoginDialog.defaultProps = {
  classes: {},
  renderHeader: () => {},
  isSignup: false,
  returnTo: null,
};

const mapStateToProps = (state) => {
  const { frame } = state;
  return {
    frame,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(IDSLoginDialog));
