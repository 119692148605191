import {
  CAMPAIGN_DETAILS_COMPLETE,
  CAMPAIGN_DETAILS_FETCH,
  CAMPAIGN_DETAILS_ERROR,
} from '../actions/actionTypes';
import transformCampaignDetails from '../api/transform/CampaignTransform';

const initialState = {
  campaignDetails: {},
  isFetchingCampaignDetails: false,
};

export default function campaigns(state = initialState, action  = {}) {
  switch (action.type) {
    case CAMPAIGN_DETAILS_FETCH:
      return { ...state, isFetchingCampaignDetails: true };
    case CAMPAIGN_DETAILS_COMPLETE:
      return {
        ...state,
        isFetchingCampaignDetails: false,
        campaignDetails: transformCampaignDetails(action.campaignDetails),
      };
    case CAMPAIGN_DETAILS_ERROR:
      return {
        ...state,
        campaignDetails: {},
        isFetchingCampaignDetails: false,
      };
    default:
      return state;
  }
}
