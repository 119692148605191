export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '32px',
    paddingRight: '58px',
    paddingLeft: '58px',
    flexDirection: 'column',
  },
  title: {
    letterSpacing: '-0.38px',
  },
  titleWithoutTab: {
    letterSpacing: '-0.38px',
    marginBottom: theme.spacing(4),
  },
  tabs: {
    marginTop: '25px',
    marginBottom: '35px',
  },
  progressHolder: {
    width: '100%',
  },
  transactionsProgress: {
    width: '100%',
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      paddingLeft: '5vw',
      paddingRight: '5vw',
    },
  },
});
