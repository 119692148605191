/* eslint-disable class-methods-use-this */
import BaseService from '../../api/BaseService';
import { paymentMethodTransformToBackend } from '../../api/transform/PaymentMethodTransform';

class PaymentService extends BaseService {
  /**
   * Get paymentId that scopes this transaction
   * @param pcct
   * @returns {*}
   */
  getPaymentId(pcct) {
    const data = {
      data: {
        location: {
          id: pcct,
        },
      },
    };
    return this.http.post('payments', data);
  }

  getSessionData(sessionId) {
    return this.http.get(`/paymentproxy/vpsession/${sessionId}`);
  }

  fetchUpdatedJWT(url, success, transactionId) {
    const redirecturl = `${url}&success=${success}&transactionId=${transactionId}`;
    return this.http.get(redirecturl);
  }

  makePayment(paymentMethod, paymentId, guestId, includeProcessingFee) {
    const transformed = paymentMethodTransformToBackend(paymentMethod);
    const data = {
      data: {
        paymentMethodType: transformed.paymentMethodType,
        paymentMethodToken: transformed.paymentMethodToken,
        last4: transformed.last4,
        brand: transformed.brand,
        accountType: transformed.accountType,
        uuid: guestId || null,
        includeProcessingFee,
      },
    };

    return this.http.post(`/paymentproxy/createtransaction/${paymentId}`, data);
  }

  fetchSavedPaymentMethod(paymentId) {
    return this.http.get(`payments/${paymentId}/paymentMethods`);
  }

  fetchSavedPaymentMethods(pcct) {
    return this.http.get(`paymentproxy/paymentmethods/${pcct}`);
  }

  deleteSavedPaymentMethod(pcct, paymentMethodToken) {
    return this.http.delete(
      `users/locations/${pcct}/paymentMethods/${paymentMethodToken}`,
    );
  }
}

export default new PaymentService();
