import {
  ORIGIN_SET
} from '../actions/actionTypes';

const INITIAL_STATE = {
  origin: undefined,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ORIGIN_SET:
      return {
        ...state,
        origin: action.data,
      };

    default:
      return state;
  }
};
