export default theme => ({
  errorPageRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorImage: {
    width: '100%',
    height: '25vh',
  },
  errorBody: {
    paddingTop: '10vh',
    paddingBottom: '20px',
  },
  homeButton: {
    width: '100%',
  },
  backToHome: {
    width: '30%',
  },
  [theme.breakpoints.down('sm')]: {
    backToHome: {
      width: '60%',
    },
  },
});
