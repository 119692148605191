import BaseService from './BaseService';
import { paymentMethodTransformToBackend } from './transform/PaymentMethodTransform';
import { paymentSubmitTransformToBackend } from './transform/PaymentTransform';

class PaymentService extends BaseService {
  /**
   * Get paymentId that scopes this transaction
   * @param locationId
   * @returns {*}
   */
  getPaymentId(locationId) {
    const data = {
      data: {
        location: {
          id: locationId,
        },
      },
    };

    return this.http.post('payments', data);
  }

  saveSelectedPaymentMethod(paymentMethod, paymentId, guestId) {
    const transformed = paymentMethodTransformToBackend(paymentMethod);
    const data = {
      data: {
        paymentMethodType: transformed.paymentMethodType,
        paymentMethodToken: transformed.paymentMethodToken,
        last4: transformed.last4,
        brand: transformed.brand,
        accountType: transformed.accountType,
        uuid: guestId || null,
      },
    };

    return this.http.put(`payments/${paymentId}/paymentMethods`, data);
  }

  fetchSavedPaymentMethod(paymentId) {
    return this.http.get(`payments/${paymentId}/paymentMethods`);
  }

  fetchSavedPaymentMethods(locationId) {
    return this.http.get(`users/locations/${locationId}/paymentMethods`);
  }

  deleteSavedPaymentMethod(locationId, paymentMethodToken) {
    return this.http.delete(
      `users/locations/${locationId}/paymentMethods/${paymentMethodToken}`,
    );
  }

  submitPayment(
    paymentId,
    basketItems,
    applyProcessingFee,
    email,
    reCaptchaToken,
    user,
    installmentFF = false,
    origin = null,
  ) {
    const data = {
      data: paymentSubmitTransformToBackend(
        basketItems,
        applyProcessingFee,
        email,
        reCaptchaToken,
        user,
        origin,
        installmentFF,
      ),
    };

    return this.http.put(`payments/${paymentId}`, data);
  }
}

export default new PaymentService();
