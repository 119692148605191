export default theme => ({
  missingRoot: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  missingText: {
    textAlign: 'center',
    marginTop: '10px',
  },
  historyRoot: {
    width: '100%',
  },
  [theme.breakpoints.down('xs')]: {
    historyPaper: {
      boxShadow: 'none',
      background: 'inherit',
    },
  },
});
