export default (theme) => ({
  expandedContainer: {
    /* offset negative margin from spacing prop, https://material-ui.com/components/grid/#limitations */
    padding: '20px',
  },
  // The below class is required to have the page display correctly in Internet Explorer
  expandedItem: {
    width: '100%',
    flex: '1 1 auto',
  },
  singleColumn: {
    width: '100%',
    flex: '1 1 auto',
    maxWidth: 576,
  },
  expandedImageItem: {
    width: '100%',
  },
  image: {
    paddingTop: '56.25%',
    borderRadius: '8px',
  },
  title: {
    fontSize: '26px',
    fontWeight: 'bold',
    letterSpacing: '1.4px',
  },
  readMore: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    paddingLeft: 5,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  backToHome: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
  },
  progressHolder: {
    width: '100%',
    height: '80vh',
  },
  expandedProgress: {
    width: '100%',
  },
  descriptionContainer: {
    // The segoe ui emoji font must be specified here in order to support Internet Explorer
    fontFamily: `${theme.typography.fontFamily}, segoe ui emoji`,
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      textDecoration: 'underline',
    },
  },
  shareSiteButtonContainer: {
    border: '1px solid #BCC0C4',
    backgroundColor: '#F6F6F7',
    padding: 8,
    marginTop: 20,
    borderRadius: 8,
    minWidth: '350px', // Match min width from AddDonation component
  },
  shareSiteHeader: {
    fontWeight: 'bold',
    margin: 8,
  },
  shareSiteText: {
    marginLeft: 8,
  },
  shareSiteButton: {
    margin: '15px 8px 8px 8px',
    backgroundColor: 'white',
  },
  [theme.breakpoints.down('sm')]: {
    expandedItem: {
      maxWidth: 576,
    },
    shareSiteButton: {
      marginTop: 25,
    },
  },
});
