import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import StateDropdown from './StateDropdown';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  formItem: {
    marginBottom: '20px',
  },
  flexFormItem: {
    flexGrow: '1',
  },
  stateField: {
    flexGrow: '3',
    flexBasis: '75%',
  },
  secondFormItem: {
    marginLeft: '16px',
    flexGrow: '1',
  },
  formItemGroup: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  sectionHeading: {
    marginBottom: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    formItemGroup: {
      flexDirection: 'column',
    },
    flexFormItem: {
      marginBottom: '20px',
    },
    stateField: {
      marginBottom: '20px',
      flexBasis: 'auto',
      flexGrow: '1',
    },
    secondFormItem: {
      marginLeft: '0px',
    },
  },
});

export function BillingInfo(props) {
  const { classes, hideEmail } = props;
  const { t } = useTranslation();

  return (
    <Typography component="div" className={classes.container}>
      <Typography variant="h6" className={classes.sectionHeading}>
        {t('billingInfo.contactInfo')}
      </Typography>
      <span className={classes.formItemGroup}>
        <Field
          name="billingInfo.firstName"
          autoComplete="section-billing given-name"
          component={TextField}
          id="first-name"
          label={t('billingInfo.firstName')}
          variant="outlined"
          className={classes.flexFormItem}
        />
        <Field
          name="billingInfo.lastName"
          autoComplete="section-billing family-name"
          component={TextField}
          id="last-name"
          label={t('billingInfo.lastName')}
          variant="outlined"
          className={classes.secondFormItem}
        />
      </span>
      <Field
        name="billingInfo.addressLine1"
        autoComplete="section-billing address-line1"
        component={TextField}
        id="address-1"
        className={classes.formItem}
        label={t('billingInfo.addressLine1')}
        variant="outlined"
      />
      <Field
        name="billingInfo.addressLine2"
        autoComplete="section-billing address-line2"
        component={TextField}
        id="address-2"
        className={classes.formItem}
        label={t('billingInfo.addressLine2')}
        variant="outlined"
      />
      <Field
        name="billingInfo.city"
        autoComplete="section-billing address-level2"
        component={TextField}
        id="city"
        className={classes.formItem}
        label={t('billingInfo.city')}
        variant="outlined"
      />
      <span className={classes.formItemGroup}>
        <Field
          name="billingInfo.state"
          component={StateDropdown}
          className={classes.stateField}
        />
        <Field
          name="billingInfo.zipCode"
          autoComplete="section-billing postal-code"
          component={TextField}
          id="zip-code"
          inputProps={{ inputMode: 'numeric' }}
          label={t('billingInfo.zipCode')}
          variant="outlined"
          className={classes.secondFormItem}
        />
      </span>
      {!hideEmail && (
        <Field
          name="billingInfo.email"
          autoComplete="section-billing email"
          component={TextField}
          id="email"
          inputProps={{ inputMode: 'email' }}
          className={classes.formItem}
          label={t('billingInfo.email')}
          variant="outlined"
        />
      )}
      <Field
        name="billingInfo.phoneNumber"
        autoComplete="section-billing tel"
        component={TextField}
        id="phone"
        inputProps={{ inputMode: 'tel' }}
        className={classes.formItem}
        label={t('billingInfo.phoneNumber')}
        variant="outlined"
      />
    </Typography>
  );
}

BillingInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  hideEmail: PropTypes.bool,
};

BillingInfo.defaultProps = {
  hideEmail: false,
};

export default withStyles(styles)(BillingInfo);
