let storage: Storage | null = null;
try {
  storage = localStorage;
} catch (e) {
  console.log(e);
}

export const storageAvailable = () => storage != null;

export function getItem(key: string) {
  let value = null;
  if (storage === null) return null;
  try {
    value = storage.getItem(key);
  } catch (e) {
    console.log('Access to local storage is denied');
    return null;
  }

  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

export function removeItem(key: string) {
  if (storage === null) return null;
  try {
    return storage.removeItem(key);
  } catch (e) {
    console.log('Access to local storage is denied');
    return null;
  }
}

export function setItem(key: string, value: unknown) {
  const json = JSON.stringify(value);
  if (storage === null) return null;
  try {
    return storage.setItem(key, json);
  } catch (e) {
    console.log('Access to local storage is denied');
    return null;
  }
}
