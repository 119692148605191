import { PropTypes, node } from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';

import { useModalContext } from '../context';

export function ModalActions ({ children, className }) {
  const { classes } = useModalContext();

  return (
    <DialogActions className={`${classes.actions} ${className}`}>
      {children}
    </DialogActions>
  );
}

ModalActions.propTypes = {
  children: node.isRequired,
  className: PropTypes.string,
};

ModalActions.defaultProps = {
  className: '',
};

export default ModalActions;
