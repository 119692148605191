import {
  GUEST_DATA_SAVE,
  GUEST_DATA_SAVE_COMPLETE,
  GUEST_DATA_SAVE_ERROR,
  GUEST_DATA_CLEAR,
} from "./actionTypes";

export function saveGuestDetailsInit() {
  return { type: GUEST_DATA_SAVE }
}

export function saveGuestDetailsComplete(id) {
  return {
    type: GUEST_DATA_SAVE_COMPLETE,
    id
  }
}

export function saveGuestDetailsError() {
  return { type: GUEST_DATA_SAVE_ERROR }
}

export function clearGuestData() {
  return { type: GUEST_DATA_CLEAR }
}
