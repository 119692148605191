import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { TextField, MenuItem, withStyles } from '@material-ui/core';

const styles = () => ({
  menu: {
    height: '280px',
  },
  expressMenu: {
    height: '470px',
  },
});

export function CustomDropdown({
  textFieldLabel,
  field,
  form,
  data,
  helperTextClass,
  classes,
  onChange,
  isExpressLayout,
  ...props
}) {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const handleOnChange = (event) => {
    form.setFieldValue(field.name, event.target.value, true);
    form.setFieldTouched(field.name, true, false);
    onChange(event.target.value);
  };

  return (
    <TextField
      name={name}
      id={name}
      label={textFieldLabel}
      FormHelperTextProps={{ classes: { root: helperTextClass } }}
      select
      variant="outlined"
      disabled={isSubmitting}
      error={showError}
      helperText={showError ? fieldError : ''}
      value={field.value || ''}
      onChange={handleOnChange}
      SelectProps={{
        MenuProps: {
          className: isExpressLayout ? classes.expressMenu : classes.menu,
        },
      }}
      data-testid="custom-select"
      {...props}
    >
      {data.map((d) => (
        <MenuItem key={d.id} value={d.id}>
          {d.value}
        </MenuItem>
      ))}
    </TextField>
  );
}

CustomDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  textFieldLabel: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  helperTextClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isExpressLayout: PropTypes.bool,
};

CustomDropdown.defaultProps = {
  helperTextClass: '',
  isExpressLayout: false,
};

export default withStyles(styles)(CustomDropdown);
