import { Typography, withStyles } from '@material-ui/core';
import { objectOf, string } from 'prop-types';
import getOrganizationInitials from '../../../utils/text/getOrganizationInitials';

import styles from './styles';

function LogoContainer({ classes, imageUrl, backgroundColor, name }) {
  const backgroundImage = imageUrl
    ? { backgroundImage: `url(${imageUrl})` }
    : {};

  return (
    <div
      data-testid="image-or-background-color"
      className={classes.root}
      style={{
        backgroundColor,
        ...backgroundImage,
      }}
    >
      {!imageUrl && (
        <Typography
          variant="body2"
          className={classes.initials}
          data-testid="name-initial"
        >
          {getOrganizationInitials(name)}
        </Typography>
      )}
    </div>
  );
}

LogoContainer.propTypes = {
  classes: objectOf(string).isRequired,
  imageUrl: string,
  backgroundColor: string.isRequired,
  name: string,
};

LogoContainer.defaultProps = {
  imageUrl: null,
  name: '',
};

export default withStyles(styles)(LogoContainer);
