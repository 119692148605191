/* eslint-disable react/function-component-definition */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as globals from '../../common/globals';
import MyBasket from '../../components/basket/MyBasket';
import Campaign from '../../components/Campaign';
import BasketLoginDialog from './BasketLoginDialog';
import { useGetTopCampaigns } from '../../hooks';
import RNWebViewHandler from '../../utils/RNWebViewHandler';
import usePageTitle from '../../hooks/common/usePageTitle';
import useFIPLogin from '../../hooks/transactionsHooks/useFIPLogin';
import useBasketLoginDialog from '../../hooks/transactionsHooks/useBasketLoginDialog';

const useStyles = makeStyles((theme) => ({
  basketCampaignRoot: {
    maxWidth: '496px',
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '5vh',
    marginLeft: '10vw',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  basketTitle: {
    lineHeight: '1.23',
    letterSpacing: '0.6px',
  },
  backToHomeLink: {
    textDecoration: 'none',
  },
  backToHomeText: {
    paddingTop: '10px',
    lineHeight: '1.23',
    letterSpacing: '0.2px',
    display: 'inline-block',
    color: theme.palette.primary.main,
  },
  campaignItem: {
    paddingTop: '20px',
    flex: '1',
  },
  progressHolder: {
    width: '100%',
    height: '80vh',
  },
  basketProgress: {
    width: '100%',
  },
  basketRoot: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    basketRoot: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
    basketCampaignRoot: {
      margin: 'auto',
    },
  },
}));

const Basket = ({ match, history }) => {
  const {
    params: { locId },
  } = match;

  const classes = useStyles();
  const { t } = useTranslation();

  const { items, iframe } = useSelector(({ basket, frame }) => ({
    items: basket?.items,
    iframe: frame?.inIframe,
  }));

  usePageTitle(t('titles.basket'));

  const { data: topCampaigns = [], status } = useGetTopCampaigns(locId);

  const { shouldForceLogin } = useFIPLogin();

  const { isIDSDialogOpen, toggleIDSDialog, closeIDSDialog } =
    useBasketLoginDialog();

  const { isInWebview, sendMessage } = new RNWebViewHandler();

  const onSubmit = (event) => {
    event.preventDefault();

    if (shouldForceLogin) {
      toggleIDSDialog();
      return;
    }

    if (isInWebview) {
      sendMessage('basket', items);
    }

    history.push(`/${locId}/${globals.PAYMENT_PATH}`);
  };

  return (
    <div className={classes.basketRoot}>
      {status === 'loading' ? (
        <div className={classes.progressHolder}>
          <LinearProgress
            id="basket-progress"
            className={classes.basketProgress}
          />
        </div>
      ) : (
        <div className={classes.basketCampaignRoot}>
          <Grid item className={classes.basketTitle}>
            <Typography variant="h2">{t('basket.moreWaysToGive')}</Typography>
          </Grid>
          <Grid item>
            <Link className={classes.backToHomeLink} to={`/${locId}/home`}>
              <div className={classes.backToHomeText}>
                <Typography variant="body2">{t('basket.seeAll')}</Typography>
              </div>
            </Link>
          </Grid>
          <div data-testid="basket-campaigns">
            {topCampaigns.map(
              (oc) => (
                <Grid
                  key={oc.id}
                  data-testid={`basket-item-${oc.id}`}
                  className={classes.campaignItem}
                  item
                >
                  <Campaign
                    key={oc.id}
                    locationId={locId}
                    campaignId={oc.id}
                    title={oc.content.title}
                    snippet={oc.content.shortDescription}
                    imageUrl={oc.content.detail.image.url}
                    buttonText={oc.content.detail.callToActionText}
                    iframe={iframe}
                  />
                </Grid>
              ),
              this,
            )}
          </div>
        </div>
      )}
      <MyBasket
        id="basket-widget"
        locationId={locId}
        onSubmit={onSubmit}
        submitButtonText={t('basket.continueToPayment')}
      />
      <BasketLoginDialog
        id="basket-login-dialog"
        open={isIDSDialogOpen}
        onClose={toggleIDSDialog}
        returnTo={`/${locId}/${globals.PAYMENT_PATH}`}
        closeIDSDialog={closeIDSDialog}
      />
    </div>
  );
};

Basket.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Basket;
