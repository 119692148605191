import i18next from 'i18next';
import '../i18n';
import TelemetryService from '../api/TelemetryService';

const errorCodes = {
  7044: i18next.t('paymentMethods.remove.failure.activeTransaction'),
  7143: i18next.t('review.submit.failure.duplicationTransaction'),
  10001: i18next.t('errors.csrfInvalid'),
  10002: i18next.t('transactions.scheduled.cancel.error'),
  10003: i18next.t('transactions.scheduled.update.error'),
  10004: i18next.t('errors.guestUserBackend'),
};

/**
 * Parses error responses from the API to try and drill down to a single error message that can be used by all downstream components.
 * TODO: This may be mixing concerns between what to display to the user and what message best describes the error for logging/telemetry. Keep this in mind when designing further changes and refactor if necessary.
 * @param {Error} error - The error to be parsed.
 * @returns {String} - The message parsed from the response. If none can be found, returns an empty string.
 */
export function getApiErrorMessage(error) {
  let message = '';

  // look through error array for a matching code
  if (error?.response?.data?.errors?.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const e of error.response.data.errors) {
      const { code } = e;
      if (errorCodes[code]) {
        message = errorCodes[code];
        break;
      }
    }
  }
  if (!message) {
    message = error?.response?.data?.message || '';
  }

  return message;
}

export function logError(e) {
  TelemetryService.handleException(e);
}

// Is this too fine-grained for this file? Wanted to keep it in here since it references an error code directly
export function isCsrfError(error) {
  const errors = error?.response?.data?.errors;
  if (Array.isArray(errors)) {
    let csrfError = false;
    errors.forEach((e) => {
      if (e.code === '10001') {
        csrfError = true;
      }
    });
    return csrfError;
  }

  return false;
}

export function isInviteUserError(error) {
  const errors = error?.response?.data?.errors;
  if (Array.isArray(errors)) {
    let userError = false;
    errors.forEach((e) => {
      if (e.code === '10004') {
        userError = true;
      }
    });
    return userError;
  }

  return false;
}
