import { useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  LinearProgress,
  withStyles,
  Typography,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import Masonry from 'react-masonry-component';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import Campaign from '../../components/Campaign';
import { useGetCampaigns } from '../../hooks';
import SocialShareDialog from '../../components/common/SocialShareDialog';
import SocialShareButton from '../../components/common/SocialShareButton';
import Search from '../../components/Search/Search';
import EmptySearch from '../../components/common/EmptySearch';
import styles from './styles';
import RNWebViewHandler from '../../utils/RNWebViewHandler';
import * as globals from '../../common/globals';
import usePageTitle from '../../hooks/common/usePageTitle';
import ExpressTiles from '../../components/expressLayout/ExpressTiles';
import LayoutSwitch from '../../components/common/layoutSwitch/LayoutSwitch';
import {
  getLayoutViewFromStorage,
  setLayoutViewToStorage,
} from '../../common/storageUtils';
import HomeContext from './context';
import PreventLeavePageModal from '../../components/common/preventLeavePageModal/PreventLeavePageModal';

function ExpressLayout({
  locId,
  enableDefaultFrequency,
  campaigns,
  basketIds,
}) {
  return (
    <ExpressTiles
      locationId={locId}
      enableDefaultFrequency={enableDefaultFrequency}
      campaigns={campaigns}
      basketIds={basketIds}
    />
  );
}

function DetailLayout({
  isOrganizationFound,
  searchedText,
  campaigns,
  basketIds,
  iframe,
  loc,
  handleShareCampaignClick,
  classes,
  handleCleanAndReset,
  locId,
}) {
  return !isOrganizationFound && !!searchedText ? (
    <EmptySearch searchText={searchedText} onClear={handleCleanAndReset} />
  ) : (
    <Masonry
      id="campaign-root"
      data-testid="campaign-root"
      className={classes.campaignRoot}
      options={{
        transitionDuration: 0,
        fitWidth: false,
        itemSelector: `#campaign-item`,
        columnWidth: `#campaign-item`,
        percentPosition: true,
      }}
    >
      {campaigns.map((oc) => (
        <Grid
          key={oc.id}
          id="campaign-item"
          className={classes.campaignItem}
          item
        >
          <Campaign
            key={oc.id}
            locationId={locId}
            campaignId={oc.id}
            title={oc.content.title}
            snippet={oc.content.shortDescription}
            imageUrl={oc.content.detail.image.url}
            inBasket={basketIds.includes(oc.id)}
            buttonText={oc.content.detail.callToActionText}
            iframe={iframe}
            // Social share logic on Campaign component can be removed on VOSearch FF cleanup.
            onShareClick={
              loc.allowSocialSharing ? handleShareCampaignClick : null
            }
          />
        </Grid>
      ))}
    </Masonry>
  );
}

export function Home({ classes, match, basketIds, iframe, loc, features }) {
  const {
    params: { locId },
  } = match;
  const { t } = useTranslation();
  usePageTitle(t('titles.home'));

  const { data: orgCampaigns = [], status } = useGetCampaigns(locId);
  const [state, setState] = useState({
    shareDialogOpen: false,
    selectedCampaign: null,
    isSearchFilterEmtpy: true,
    orgCampaignsFiltered: [],
    searchedText: '',
    isOrganizationFound: false,
    resetKey: 0,
    selectedLayout: loc?.defaultView || '',
    isFormDirty: false,
    showPreventModal: false,
  });

  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down('xs'),
  );

  const hideSiteName = !loc.displayName;
  const { isInWebview } = new RNWebViewHandler();
  const history = useHistory();
  const isExpressLayout =
    features.VOExpressView &&
    state.selectedLayout === globals.LOCATION_PAGE_LAYOUTS.EXPRESS;
  const setFormDirtyState = useCallback(
    (value) => {
      setState((prevState) => ({
        ...prevState,
        isFormDirty: value,
      }));
    },
    [setState],
  );
  const onClosePreventModal = () => {
    setState((prevState) => ({
      ...prevState,
      showPreventModal: false,
    }));
  };
  const onConfirmSwitchLayout = () => {
    setState((prevState) => ({
      ...prevState,
      showPreventModal: false,
      isFormDirty: false,
      selectedLayout: globals.LOCATION_PAGE_LAYOUTS.DETAIL,
    }));
    setLayoutViewToStorage(locId, globals.LOCATION_PAGE_LAYOUTS.DETAIL);
  };
  const contextProviderProps = useMemo(
    () => ({ setFormDirtyState }),
    [setFormDirtyState],
  );

  useEffect(() => {
    if (loc?.defaultView) {
      const storedLayout = getLayoutViewFromStorage(locId);
      const currentLayout =
        storedLayout && storedLayout !== loc?.defaultView
          ? storedLayout
          : loc?.defaultView;
      setLayoutViewToStorage(locId, currentLayout);
      setState((prevState) => ({
        ...prevState,
        selectedLayout: currentLayout,
      }));
    }
  }, [loc.defaultView, locId]);

  const openShareDialog = () => {
    setState((prevState) => ({
      ...prevState,
      selectedCampaign: null,
      shareDialogOpen: true,
    }));
  };
  const closeShareDialog = () => {
    setState((prevState) => ({
      ...prevState,
      shareDialogOpen: false,
    }));
  };

  const handleShareCampaignClick = (campaign) => {
    setState((prevState) => ({
      ...prevState,
      selectedCampaign: campaign,
      shareDialogOpen: true,
    }));
  };

  const handleCleanFilter = () => {
    setState((prevState) => ({
      ...prevState,
      searchedText: '',
      orgCampaignsFiltered: [],
      isOrganizationFound: false,
      isSearchFilterEmtpy: !prevState.isSearchFilterEmtpy,
    }));
  };

  const handleCleanAndReset = () => {
    handleCleanFilter();
    setState((prevState) => ({
      ...prevState,
      resetKey: prevState.resetKey === 0 ? 1 : 0,
    }));
  };

  const handleSetIsSearchFilterEmtpy = (value) => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterEmtpy: value,
    }));
  };

  const handleSetSelectedLayout = (value) => {
    if (state.isFormDirty) {
      setState((prevState) => ({
        ...prevState,
        showPreventModal: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedLayout: value,
      }));
      setLayoutViewToStorage(locId, value);
    }
  };

  const handleSearchFilter = (event, newValue) => {
    const textFiltered = newValue;
    if (textFiltered) {
      const orgCampaignFound = orgCampaigns.filter((d) =>
        d.content.title.toLowerCase().includes(textFiltered.toLowerCase()),
      );
      if (orgCampaignFound.length === 1) {
        history.push(`/${locId}/campaign/${orgCampaignFound[0].id}`);
      } else if (orgCampaignFound.length > 0) {
        setState((prevState) => ({
          ...prevState,
          orgCampaignsFiltered: orgCampaignFound,
          isOrganizationFound: true,
        }));
      } else if (orgCampaignFound.length === 0) {
        setState((prevState) => ({
          ...prevState,
          orgCampaignsFiltered: [],
          isOrganizationFound: false,
        }));
      }
      setState((prevState) => ({
        ...prevState,
        searchedText: textFiltered,
      }));
    } else if (!textFiltered) {
      handleCleanFilter();
    }
  };

  const renderSearchBox = () => {
    return (
      <div className={classes.searchContainerSingle}>
        <Search
          key={state.resetKey}
          searchOptions={orgCampaigns.map((x) => x.content.title)}
          onChange={handleSearchFilter}
          classes={classes}
          labels={{
            search: t('common.search'),
            clearResults: t('search.label.clearResults'),
          }}
          isInputEmpty={state.isSearchFilterEmtpy}
          onClean={handleCleanAndReset}
          changeIsInputEmpty={handleSetIsSearchFilterEmtpy}
        />
      </div>
    );
  };

  /**
   *  if the page is in a webview display only the tiles that allow 'One time, now' frequency
   *  this will be removed when the guest recurrence feature is implemented
   * TODO: remove this validation when guest recurrence is implemented
   *  */
  const filterNonRealtimeTiles = (allCampaigns) => {
    if (!isInWebview) return allCampaigns;
    return allCampaigns.filter(
      (c) =>
        c.donationOptions?.paymentFrequencies?.includes(
          globals.PAYMENT_FREQUENCY_ONE_TIME_NOW,
        ) || c.type === globals.CAMPAIGN_TYPE.PAYMENT,
    );
  };

  const campaigns = state.orgCampaignsFiltered.length
    ? state.orgCampaignsFiltered
    : filterNonRealtimeTiles(orgCampaigns);

  if (status === 'loading') {
    return (
      <div className={classes.progressHolder}>
        <LinearProgress
          id="campaign-progress"
          data-testid="campaign-progress"
          className={classes.campaignProgress}
        />
      </div>
    );
  }

  return (
    <>
      <div className={classes.siteInfo}>
        {!hideSiteName && <Typography variant="h5">{loc.name}</Typography>}
        {loc.allowSocialSharing && (
          <div className={classes.shareSiteButton}>
            <SocialShareButton
              onClick={openShareDialog}
              small={isExtraSmallScreen}
            />
          </div>
        )}
        {!isExpressLayout && renderSearchBox()}
        {features.VOExpressView && (
          <LayoutSwitch
            selectedLayout={state.selectedLayout}
            onChange={handleSetSelectedLayout}
            className={classes.layoutSwitch}
          />
        )}
      </div>
      {isExpressLayout ? (
        <HomeContext.Provider value={contextProviderProps}>
          <ExpressLayout
            locId={locId}
            enableDefaultFrequency={loc.enableDefaultFrequency}
            campaigns={campaigns}
            basketIds={basketIds}
            selectedLayout={state.selectedLayout}
            onChange={handleSetSelectedLayout}
            className={classes.layoutSwitch}
            shouldOpenOnLeave={state.isFormDirty}
            OnCancel={state.isFormDirty}
          />
        </HomeContext.Provider>
      ) : (
        <DetailLayout
          isOrganizationFound={state.isOrganizationFound}
          searchedText={state.searchedText}
          campaigns={campaigns}
          basketIds={basketIds}
          iframe={iframe}
          loc={loc}
          handleShareCampaignClick={handleShareCampaignClick}
          locId={locId}
          handleCleanAndReset={handleCleanAndReset}
          classes={classes}
        />
      )}
      {state.isOrganizationFound ? (
        <div className={classes.clearResultsContainer}>
          <Button variant="contained" onClick={handleCleanAndReset}>
            <CloseIcon className={classes.cleanButton} />
            {t('search.label.clearResults')}
          </Button>
        </div>
      ) : null}
      {loc.allowSocialSharing && (
        <SocialShareDialog
          open={state.shareDialogOpen}
          campaign={state.selectedCampaign}
          handleClose={closeShareDialog}
        />
      )}
      {isExpressLayout && (
        <PreventLeavePageModal
          showPreventModal={isExpressLayout && state.showPreventModal}
          onClose={onClosePreventModal}
          onConfirm={onConfirmSwitchLayout}
        />
      )}
    </>
  );
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  basketIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  iframe: PropTypes.bool.isRequired,
  loc: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
};

ExpressLayout.propTypes = {
  locId: PropTypes.string.isRequired,
  enableDefaultFrequency: PropTypes.bool.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  basketIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DetailLayout.propTypes = {
  isOrganizationFound: PropTypes.bool.isRequired,
  searchedText: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  basketIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  iframe: PropTypes.bool.isRequired,
  loc: PropTypes.object.isRequired,
  locId: PropTypes.string.isRequired,
  handleShareCampaignClick: PropTypes.func.isRequired,
  handleCleanAndReset: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
