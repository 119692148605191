import BaseService from './BaseService';
import { Api } from './Api';
import {
  transformTransactionHistory,
  transformScheduledTransactions,
} from './transform/TransactionTransform';

/**
 * Service for handling calls related to transaction history and scheduled transactions. For the service related to submitting transactions see PaymentService.js
 */
class TransactionService extends BaseService {
  /* allows us to mock out backend calls for this service by adding (true) to the default export below */
  constructor(mock) {
    super();
    if (mock) {
      this.http = new Api({
        baseUrl: `${window.env.REACT_APP_MOCK_BACKEND_API}/`,
      });
    }
  }

  async getScheduledTransactions(locationId) {
    const response = await this.http.get(
      `users/locations/${locationId}/scheduledpayments`,
    );

    return transformScheduledTransactions(response); // TODO: add transform
  }

  async getTransactionHistory(locationId) {
    const response = await this.http.get(
      `users/locations/${locationId}/${'transactionthistory'}`,
    );
    return transformTransactionHistory(response);
  }

  deleteScheduledTransaction(locationId, paymentId) {
    return this.http.delete(
      `users/locations/${locationId}/scheduledpayments/${paymentId}`,
    );
  }

  updateScheduledTransaction(locationId, paymentToken, paymentId, data) {
    return this.http.put(
      `users/locations/${locationId}/scheduledpayments/${paymentToken}?paymentId=${paymentId}`,
      { data },
    );
  }
}

export default new TransactionService();
