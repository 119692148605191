import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';

/* eslint-disable react/prop-types */
function withConfirmationModal(BasketItem) {
  if (!window.ReactNativeWebView) {
    return BasketItem;
  }
  return function ItemWrapper({
    classes,
    item,
    locationId,
    onDelete,
    onChangeQuantity,
    showProcessingFees,
    processingFee,
  }) {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { t } = useTranslation();

    const confirmDelete = () => {
      onDelete(item);
    };
    const dismissModal = () => {
      setShowConfirmationModal(false);
    };

    return (
      <>
        <BasketItem
          classes={classes}
          item={item}
          locationId={locationId}
          onChangeQuantity={onChangeQuantity}
          showProcessingFees={showProcessingFees}
          processingFee={processingFee}
          onDelete={() => {
            setShowConfirmationModal(true);
          }}
        />
        {showConfirmationModal && (
          <ConfirmationDialog
            isOpen={showConfirmationModal}
            title={t('kioskBasket.remove.confirm.title')}
            message={t('kioskBasket.remove.confirm.message')}
            submitButtonText={t('kioskBasket.remove.confirm.yes')}
            cancelButtonText={t('kioskBasket.remove.confirm.no')}
            onSubmit={confirmDelete}
            onClose={dismissModal}
          />
        )}
      </>
    );
  };
}
export default withConfirmationModal;
