import { ENQUEUE_SNACKBAR, DEQUEUE_SNACKBAR, ENQUEUE_DIALOG, DEQUEUE_DIALOG } from '../actions/actionTypes';

const defaultState = {
  snackbarQueue: [],
  dialogQueue: [],
};

export default (state = defaultState, action = {}) => {
  let newQueue;
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      newQueue = [...state.snackbarQueue];
      newQueue.push({
        ...action.notification,
        key: new Date().getTime() + Math.random(),
      });
      return {
        ...state,
        snackbarQueue: newQueue,
      };

    case DEQUEUE_SNACKBAR:
      newQueue = [...state.snackbarQueue];
      newQueue.shift();
      return {
        ...state,
        snackbarQueue: newQueue,
      };

    case ENQUEUE_DIALOG:
      newQueue = [...state.dialogQueue];
      newQueue.push({
        ...action.notification,
        key: new Date().getTime() + Math.random(),
      });
      return {
        ...state,
        dialogQueue: newQueue,
      };

    case DEQUEUE_DIALOG:
      newQueue = [...state.dialogQueue];
      newQueue.shift();
      return {
        ...state,
        dialogQueue: newQueue,
      };

    default:
      return state;
  }
};
