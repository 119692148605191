import { shape, func, bool } from 'prop-types';
import { withStyles, Typography, Divider } from '@material-ui/core';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Dropdown from '../../common/Dropdown';
import { getCurrencyFormat } from '../../../common/utils';
import {
  LOCATION_PAGE_LAYOUTS,
  MAX_PAYMENT_ITEMS,
} from '../../../common/globals';
import TextFieldWithCharCount from '../../common/TextFieldWithCharCount';
import MemoFieldsDropdown from './MemoFieldsDropdown';
import { useFixedAmountStyles } from './styles';

const stylesOverrides = () => ({
  price: {
    color: '#222222',
    fontSize: 18,
    marginBottom: 40,
  },
  quantity: {
    color: '#222222',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 0,
  },
  inputField: {
    height: 60,
    width: 76,
    marginBottom: 12,
    background: '#FFFFFF',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  memoInputField: {
    margin: '10px 0px 12px 0px',
    width: '100%',
    background: '#FFFFFF',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  dividerStyleFix: {
    margin: 0,
    marginBottom: '12px',
  },
  dateTitle: {
    color: '#212224',
    fontSize: '16px',
    lineHeight: '24px',
  },
  inputHelperText: {
    margin: '3px 12px 0',
    maxWidth: '100%',
  },
});

const options = Array.from(Array(MAX_PAYMENT_ITEMS).keys()).map((item) => ({
  value: item + 1,
  text: (item + 1).toString(),
}));

export function PaymentFixedAmount({
  details,
  values,
  setFieldValue,
  features,
  isExpressLayout,
}) {
  const { t } = useTranslation();
  const classes = useFixedAmountStyles({
    layout: isExpressLayout
      ? LOCATION_PAGE_LAYOUTS.EXPRESS
      : LOCATION_PAGE_LAYOUTS.DETAIL,
  });
  const onQuantityChange = (e) => {
    if (!details.fundId) {
      return;
    }
    setFieldValue(`${details.fundId}`, {
      ...values[details.fundId],
      quantity: e.target.value,
      amount: (
        parseFloat(values[details.fundId].itemAmount) * e.target.value
      ).toString(),
    });
  };

  const getLabelText = () => {
    let formattedText = details.memoLine.textField;
    formattedText += details.memoLine.responseRequired
      ? ` (${t('addDonation.responseRequired')})`
      : ` (${t('addDonation.responseOptional')})`;
    return formattedText;
  };

  const showMemoFieldsDropDown =
    features?.TileMemoDropdown &&
    !details.isTextFieldResponseType &&
    details.memoFields?.length > 0;

  const orderedMemoFields = details.memoFields?.sort((a, b) =>
    a.ordinal > b.ordinal ? 1 : -1,
  );

  const getPriceDescription = () => {
    if (isExpressLayout) {
      return (
        <Typography className={classes.price}>
          {t('accountPayment.priceEach', {
            price: getCurrencyFormat(details.price),
          })}
        </Typography>
      );
    }
    return (
      <Typography className={classes.price}>
        {getCurrencyFormat(details.price)}
      </Typography>
    );
  };

  return (
    <>
      {getPriceDescription()}
      {!isExpressLayout && (
        <Typography className={classes.quantity}>
          {t('app.quantity')}
        </Typography>
      )}
      <Field
        id="donation-quantity"
        data-testid="donation-quantity"
        label={isExpressLayout ? t('accountPayment.selectQuantity') : null}
        aria-label={t('accountPayment.selectQuantity')}
        name={`${details.fundId}.quantity`}
        component={Dropdown}
        options={options}
        onChange={onQuantityChange}
        className={classes.inputField}
        helperTextClass={classes.inputHelperText}
      />

      {details.memoLine.showTextField && !showMemoFieldsDropDown && (
        <>
          <Divider variant="middle" className={classes.dividerStyleFix} />

          <Typography className={classes.dateTitle} variant="caption">
            {getLabelText()}
          </Typography>
          <Field
            id="donation-memoline"
            name={`${details.fundId}.memoLine`}
            variant="outlined"
            autoComplete="off"
            maxLength={50}
            maxRows={2}
            multiline
            className={classes.memoInputField}
            helperTextClass={classes.inputHelperText}
            component={TextFieldWithCharCount}
          />
        </>
      )}
      {details.memoLine.showTextField && showMemoFieldsDropDown && (
        <>
          <Divider variant="middle" className={classes.dividerStyleFix} />
          <Field
            id="memo-fields"
            textFieldLabel={`${t('accountPayment.select')} ${getLabelText()}`}
            name={`${details.fundId}.memoLine`}
            component={MemoFieldsDropdown}
            maxLength={50}
            memoFields={orderedMemoFields}
            className={classes.memoInputField}
            helperTextClass={classes.inputHelperText}
          />
        </>
      )}
    </>
  );
}

PaymentFixedAmount.propTypes = {
  details: shape({}).isRequired,
  values: shape({}).isRequired,
  setFieldValue: func.isRequired,
  features: shape({}).isRequired,
  isExpressLayout: bool,
};

PaymentFixedAmount.defaultProps = {
  isExpressLayout: false,
};

const mapStateToProps = (state) => ({
  features: state.features?.features,
});

export default compose(
  withStyles(stylesOverrides),
  connect(mapStateToProps),
)(PaymentFixedAmount);
