import PropTypes from 'prop-types';

import OpenAmount from '../../addDonation/transactionItems/OpenAmount';
import PaymentFixedAmount from '../../addDonation/transactionItems/PaymentFixedAmount';
import { CAMPAIGN_TYPE } from '../../../common/globals';

function CampaignForm({
  // Default form view should be GIFT
  selectedCampaignType = CAMPAIGN_TYPE.GIFT,
  formikProps,
  transactionDetails,
  isFormDisabled,
}) {
  const renderCampaignTypeForm = () => {
    const campaignTypeFormMap = {
      [CAMPAIGN_TYPE.GIFT]: () => (
        <OpenAmount
          id="OpenAmount"
          formikProps={formikProps}
          transactionDetails={transactionDetails}
          isExpressLayout
          isFormDisabled={isFormDisabled}
        />
      ),
      [CAMPAIGN_TYPE.PAYMENT]: () => (
        <PaymentFixedAmount
          details={transactionDetails}
          isExpressLayout
          {...formikProps}
        />
      ),
    };
    const formComponent = campaignTypeFormMap[selectedCampaignType];
    if (!formComponent) {
      return null;
    }
    return formComponent();
  };
  return renderCampaignTypeForm();
}

CampaignForm.propTypes = {
  selectedCampaignType: PropTypes.string,
  formikProps: PropTypes.object,
  transactionDetails: PropTypes.object,
  isFormDisabled: PropTypes.bool,
};

export default CampaignForm;
