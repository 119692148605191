import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { getIn } from 'formik';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates/lib';
import Clear from '@material-ui/icons/Clear';
import moment from 'moment';
import ChildrenBlur from './ChildrenBlur';

export default class DatePicker extends Component {
  constructor(props) {
    super(props);

    const {
      field: { value },
    } = this.props;

    this.state = {
      dateString: value ? value.format('MM/DD/YYYY') : '',
      datePickerCanClose: true,
      displayDatePicker: false,
    };

    this.datePickerRef = createRef();
  }

  componentDidUpdate({ field: { value: prevValue } }) {
    const {
      resetValue,
      field: { value },
    } = this.props;
    const { dateString } = this.state;
    if (resetValue) {
      this.clearPicker();
    }
    if (prevValue !== value && dateString !== value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        dateString: value ? value.format('MM/DD/YYYY') : '',
      });
    }
  }

  clearPicker = () => {
    const { form, field, resetCallBack } = this.props;
    // needed callback to ensure parent could react to this action
    if (resetCallBack !== null) {
      resetCallBack();
    }

    this.setState({
      dateString: '',
    });

    form.setFieldValue(field.name, null, true);
    form.setFieldTouched(field.name, true, false);
    this.datePickerRef.current.querySelector('input').focus();
  };

  // TODO: consider removing this method once TDP-641 gets closed
  // used only for QA automation testing
  handleTextFieldDateChange = (event) => {
    const { value } = event.target;
    const date = moment(value, 'MM/DD/YYYY', true);
    const { field, form } = this.props;

    // only allow strictly parsed dates for QA automation, or autocomplete
    if (date.isValid()) {
      form.setFieldValue(field.name, date, true);
      form.setFieldTouched(field.name, true, false);

      this.setState({
        dateString: date.format('MM/DD/YYYY'),
        datePickerCanClose: true,
        displayDatePicker: false,
      });
    }
  };

  handleDateChange = (date) => {
    const { field, form } = this.props;

    if (date) {
      form.setFieldValue(field.name, date, true);
      form.setFieldTouched(field.name, true, false);

      this.setState({
        dateString: date.format('MM/DD/YYYY'),
        datePickerCanClose: true,
        displayDatePicker: false,
      });
    }
  };

  handleDateFocusChange = (focused) => () => {
    const { datePickerCanClose } = this.state;

    if (datePickerCanClose) {
      this.setState({ displayDatePicker: focused });

      // Allow to display validation error when loosing focus
      const { field, form } = this.props;
      form.setFieldTouched(field.name, true, false);
    }
  };

  /* must disable duplicate props rule due to similarly named props */
  /* eslint-disable react/jsx-no-duplicate-props */
  render() {
    const {
      id,
      label,
      inputClass,
      helperTextClass,
      field,
      form,
      earliestAllowedDate,
      latestAllowedDate,
      initialVisibleMonth,
      allowedDates,
      'data-testid': testId,
    } = this.props;
    const { dateString, displayDatePicker } = this.state;

    const { name } = field;
    const { touched, errors, isSubmitting } = form;
    const fieldError = getIn(errors, name);
    const showError = getIn(touched, name) && !!fieldError;

    return (
      <ChildrenBlur
        onBlur={this.handleDateFocusChange(false)}
        data-testid={testId}
      >
        <TextField
          id={id}
          label={label}
          placeholder="MM/DD/YY"
          variant="outlined"
          disabled={isSubmitting}
          error={showError && !displayDatePicker}
          helperText={showError && !displayDatePicker ? fieldError : ''}
          FormHelperTextProps={{ classes: { root: helperTextClass } }}
          className={inputClass}
          value={dateString}
          onChange={this.handleTextFieldDateChange}
          inputProps={{ inputMode: 'none', 'data-testid': `${testId}-input` }}
          InputProps={{
            endAdornment: field.value ? (
              <InputAdornment position="end">
                <IconButton onClick={this.clearPicker} disabled={isSubmitting}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onFocus={this.handleDateFocusChange(true)}
          autoComplete="off"
          ref={this.datePickerRef}
        />
        {displayDatePicker && !isSubmitting && (
          <div
            className="datepicker-container"
            onMouseEnter={() => this.setState({ datePickerCanClose: false })}
            onMouseLeave={() => this.setState({ datePickerCanClose: true })}
            data-testid="dayPicker"
          >
            <DayPickerSingleDateController
              date={field.value}
              onDateChange={this.handleDateChange}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              isOutsideRange={(day) => {
                const beforeAndAfter =
                  day.isBefore(earliestAllowedDate) ||
                  day.isAfter(latestAllowedDate);
                if (allowedDates && allowedDates.length > 0) {
                  return beforeAndAfter || !allowedDates.includes(day.date());
                }
                return beforeAndAfter;
              }}
              focused
              initialVisibleMonth={initialVisibleMonth}
            />
          </div>
        )}
      </ChildrenBlur>
    );
  }
}

DatePicker.propTypes = {
  value: PropTypes.objectOf(moment),
  label: PropTypes.string,
  inputClass: PropTypes.string,
  helperTextClass: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  earliestAllowedDate: PropTypes.objectOf(moment).isRequired,
  latestAllowedDate: PropTypes.objectOf(moment).isRequired,
  id: PropTypes.string,
  initialVisibleMonth: PropTypes.func,
  allowedDates: PropTypes.arrayOf(PropTypes.number),
  resetCallBack: PropTypes.func,
  resetValue: PropTypes.bool,
  'data-testid': PropTypes.string,
};

DatePicker.defaultProps = {
  value: null,
  label: '',
  inputClass: '',
  helperTextClass: '',
  id: '',
  initialVisibleMonth: null,
  allowedDates: null,
  resetCallBack: null,
  resetValue: false,
  'data-testid': '',
};
