import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Hidden,
  Grid,
  withWidth,
  withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import useHandleAzureRedirect from 'hooks/azureAuthHooks/useAzureAuth';
import IDSLoginDialog from '../common/IDSLoginDialog';
import HeaderMobile from './HeaderMobile';
import HeaderMobileDrawer from './HeaderMobileDrawer';
import HeaderDesktop from './HeaderDesktop';
import { getTransactionCount } from '../../common/utils';
import useWindowResizeHandler from '../../hooks/common/useWindowResizeHandler';
import { PROFILE_PATH } from '../../common/globals';
import useFeatures from '../../hooks/common/useFeatures';
import LoadingDialog from '../../mp/components/LoadingDialog';

const styles = (theme) => ({
  toolbarColumn: {
    width: '100%',
    maxWidth: '1440px',
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  inIframe: {
    backgroundColor: theme.palette.white,
    boxShadow: 'none',
    borderBottom: '1px solid #DEE1E3',
  },
});

export function Header({
  loc,
  session,
  transactionCount,
  classes,
  allowLogin,
  iframe,
  showEmbeddedView,
  features,
  showActionNeededUI,
  requiredActionsLoaded,
  hasActionNeededURL,
  setMainLayoutTopOffset,
}) {
  const { t } = useTranslation();

  const [state, setState] = useState({
    isIDSDialogOpen: false,
    isMobileMenuOpen: false,
    isSignup: false,
  });

  const { AzureClientAuth: isAzureAuthEnabled } = useFeatures();

  const headerRef = useRef(null);
  const { pathname } = useLocation();

  const setMainLayoutOffset = useCallback(() => {
    if (headerRef?.current) {
      setMainLayoutTopOffset(headerRef.current.clientHeight);
    }
  }, [setMainLayoutTopOffset]);

  // Suscribe to window resize event to handle if the user switches from
  // vertical view to horizontal view on the phone
  useWindowResizeHandler(setMainLayoutOffset);

  useEffect(() => {
    setMainLayoutOffset();
  }, [headerRef, loc, session, setMainLayoutOffset]);

  const closeIDSDialog = () => {
    setState((prevState) => ({ ...prevState, isIDSDialogOpen: false }));
  };

  const { inProgress, handleAzureRedirect } = useHandleAzureRedirect();

  const showIDSDialog = (isSignup) => {
    // Handle redirect to Ab2c when feature flag is on, otherwise continue witn previous IDS flow.
    if (isAzureAuthEnabled) {
      handleAzureRedirect(isSignup);
    } else {
      setState((prevState) => ({
        ...prevState,
        isIDSDialogOpen: true,
        isSignup,
      }));
    }
  };

  const onParentLogin = () => {
    setState((prevState) => ({ ...prevState, isIDSDialogOpen: false }));
  };

  const toggleMobileMenu = () => {
    setState((prevState) => ({
      ...prevState,
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }));
  };

  const isActionNeededUIOnly =
    (hasActionNeededURL && !requiredActionsLoaded) ||
    (requiredActionsLoaded && showActionNeededUI);
  const showOpportunitiesLink = iframe && pathname.includes(`/${PROFILE_PATH}`);

  /**
   * Render mobile header on screens < 600px wide
   * Render desktop header on screens >= 600px wide
   */
  return (
    <AppBar
      position="fixed"
      color="inherit"
      className={iframe ? classes.inIframe : ''}
      ref={headerRef}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} className={classes.toolbarColumn}>
          <Toolbar className={classes.toolbar}>
            <Hidden smUp>
              <HeaderMobile
                loc={loc}
                transactionCount={transactionCount}
                toggleMobileMenu={toggleMobileMenu}
                iframe={iframe}
                showEmbeddedView={showEmbeddedView}
                features={features}
                showActionNeededUI={isActionNeededUIOnly}
                loggedIn={session.loggedIn}
                showIDSDialog={showIDSDialog}
              >
                <HeaderMobileDrawer
                  loc={loc}
                  loggedIn={session.loggedIn}
                  isMobileMenuOpen={state.isMobileMenuOpen}
                  toggleMobileMenu={toggleMobileMenu}
                  showIDSDialog={showIDSDialog}
                  allowLogin={allowLogin}
                  iframe={iframe}
                  showOpportunitiesLink={showOpportunitiesLink}
                  features={features}
                />
              </HeaderMobile>
            </Hidden>
            <Hidden xsDown>
              <HeaderDesktop
                loc={loc}
                loggedIn={session.loggedIn}
                transactionCount={transactionCount}
                showIDSDialog={showIDSDialog}
                allowLogin={allowLogin}
                iframe={iframe}
                showEmbeddedView={showEmbeddedView}
                features={features}
                showActionNeededUI={isActionNeededUIOnly}
                showOpportunitiesLink={showOpportunitiesLink}
              />
            </Hidden>
            {isAzureAuthEnabled && inProgress !== 'none' && (
              <LoadingDialog isLoading message={t('common.processing')} />
            )}
            {!isAzureAuthEnabled &&
              !session.loggedIn &&
              loc.hasLoadedLocationDetails && (
                <IDSLoginDialog
                  id="ids-login-dialog"
                  open={state.isIDSDialogOpen}
                  onClose={closeIDSDialog}
                  onParentLogin={onParentLogin}
                  isSignup={state.isSignup}
                />
              )}
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
}

Header.propTypes = {
  loc: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  transactionCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  allowLogin: PropTypes.bool.isRequired,
  iframe: PropTypes.bool.isRequired,
  showEmbeddedView: PropTypes.bool.isRequired,
  features: PropTypes.object.isRequired,
  showActionNeededUI: PropTypes.bool.isRequired,
  requiredActionsLoaded: PropTypes.bool.isRequired,
  hasActionNeededURL: PropTypes.bool.isRequired,
  setMainLayoutTopOffset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { location, session, frame } = state;
  return {
    loc: location,
    session,
    transactionCount: getTransactionCount(state.basket.items),
    iframe: frame.inIframe,
    features: state.features.features,
    showActionNeededUI: state.user.showActionNeededUI,
    requiredActionsLoaded: state.user.requiredActionsLoaded,
    hasActionNeededURL: state.user.hasActionNeededURL,
  };
};

export default connect(mapStateToProps)(
  withWidth()(withStyles(styles)(Header)),
);
