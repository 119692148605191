import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Typography, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import styles from './styles';

export function Footer(props) {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <Typography className={classes.footerText} component="div">
      <div className={classes.itemContainer}>
        <span className={classes.item}>&copy; Vanco</span>
        <Link
          className={`${classes.item} ${classes.undecoratedLink}`}
          href="https://www.vancopayments.com/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer.terms')}
        </Link>
        <Link
          className={`${classes.item} ${classes.undecoratedLink}`}
          href="https://www.vancopayments.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer.privacy')}
        </Link>
      </div>
    </Typography>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles), connect(null))(Footer);
