import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Helper class to automatically scroll the window to the top on route changes.
 * copied from https://reacttraining.com/react-router/web/guides/scroll-restoration/scroll-to-top
 * @return {null}
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
