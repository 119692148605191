import PropTypes from 'prop-types';
import {
  Backdrop,
  withStyles,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  processingBackdrop: {
    backgroundColor: 'rgba(255, 255, 255, .95)',
    zIndex: theme.zIndex.modal,
    opacity: '.95',
  },
  processingProgress: {
    zIndex: theme.zIndex.modal,
    position: 'absolute',
    color: theme.palette.primary.main,
  },
  processingIcon: {
    zIndex: theme.zIndex.modal,
    position: 'absolute',
    height: '25px',
    width: '25px',
    color: '#DDDDDD',
  },
  processingVisuals: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  processingText: {
    fontWeight: '600',
    paddingTop: '35px',
  },
  processingContent: {
    display: 'flex',
    flexDirection: 'column',
  },
});

function ProcessingOverlay({ open, classes }) {
  const { t } = useTranslation();
  return (
    <Backdrop
      open={open}
      className={classes.processingBackdrop}
      data-testid="processing-overlay"
    >
      <div className={classes.processingContent}>
        <div className={classes.processingVisuals}>
          <CircularProgress
            className={classes.processingProgress}
            size={64}
            thickness={2.7}
          />
          <LockOutlinedIcon className={classes.processingIcon} />
        </div>
        <Typography className={classes.processingText}>
          {t('payment.processing')}
        </Typography>
      </div>
    </Backdrop>
  );
}

ProcessingOverlay.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProcessingOverlay);
