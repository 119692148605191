import BaseService from '../../api/BaseService';
import {
  userDetailsTransformToBackend,
  userDetailsTransformToFrontend,
} from './transform/UserDetailsTransform';

class UserService extends BaseService {
  async saveUserDetails(userDetails, tenant, sessionId) {
    const response = await this.http.put(
      `paymentproxy/users?tenant=${tenant}&sessionId=${sessionId}`,
      userDetailsTransformToBackend(userDetails, false), // This process does not allow guest scheduled transactions yet.
    );
    return userDetailsTransformToFrontend(response);
  }
}

export default new UserService();
