import { bool, func, object } from 'prop-types';
import {
  Badge,
  Button,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Reply } from '@material-ui/icons';
import withRNWebviewHide from './hoc/withRNWebviewHide';

const styles = () => ({
  fullWidth: {
    width: '100%',
  },
  iconOnly: {
    minWidth: 40,
    width: 40,
    height: 40,
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  outlined: {
    border: '1px solid #BCC0C4',
  },
  shareSiteIcon: {
    transform: 'scale(-1,1)',
    color: '#5E6266',
  },
  shareSiteText: {
    marginLeft: 5,
  },
  shareSiteIconBubble: {
    border: '1px solid #BCC0C4',
    width: 40,
    height: 40,
    borderRadius: 100,
    background: 'white',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      background: '#f9f9f9',
    },
  },
  small: {
    display: 'block',
  },
});
function SocialShareButton(props) {
  const { classes, onClick, fullWidth, iconOnly, outlined, small } = props;

  const { t } = useTranslation();

  const tooltipText = t('socialShare.buttonTooltip');

  const getButtonClasses = () => {
    let classNames = '';
    if (iconOnly) classNames += `${classes.iconOnly} `;
    if (outlined) classNames += `${classes.outlined} `;
    if (fullWidth) classNames += `${classes.fullWidth} `;
    if (small) classNames += `${classes.small} `;
    return classNames;
  };

  return (
    <Button
      id="social-share-button"
      aria-label="Share"
      onClick={onClick}
      className={getButtonClasses()}
    >
      {iconOnly && (
        <Tooltip title={tooltipText} placement="bottom">
          <div className={classes.shareSiteIconBubble}>
            <Badge
              id="social-share-badge"
              className={classes.shareSiteIcon}
              overlap="rectangular"
            >
              <Reply />
            </Badge>
          </div>
        </Tooltip>
      )}
      {!iconOnly && (
        <>
          <Badge
            id="social-share-badge"
            className={classes.shareSiteIcon}
            overlap="rectangular"
          >
            <Reply />
          </Badge>
          <Typography className={classes.shareSiteText}>
            {t('socialShare.buttonText.fullWidth')}
          </Typography>
        </>
      )}
    </Button>
  );
}

SocialShareButton.propTypes = {
  classes: object.isRequired,
  onClick: func.isRequired,
  fullWidth: bool,
  iconOnly: bool,
  outlined: bool,
  small: bool,
};

SocialShareButton.defaultProps = {
  fullWidth: false,
  iconOnly: false,
  outlined: false,
  small: false,
};

export default withRNWebviewHide(withStyles(styles)(SocialShareButton));
