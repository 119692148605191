import validator from 'validator';
import i18next from 'i18next';
import * as Yup from 'yup';
import * as globals from '../globals';

const getBillingInfoSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required()
      .test(
        'firstName-test',
        i18next.t('formValidation.nameLength'),
        // Yup requires a function expression rather than an arrow function to use the special this values such as parent
        function test(value = '') {
          const lastName = this.parent.lastName || '';
          return value.length + lastName.length <= 29;
        },
      ),
    lastName: Yup.string()
      .required()
      .test(
        'lastName-test',
        i18next.t('formValidation.nameLength'),
        function test(value = '') {
          const firstName = this.parent.firstName || '';
          return value.length + firstName.length <= 29;
        },
      ),
    addressLine1: Yup.string().required(),
    addressLine2: Yup.string().notRequired(),
    city: Yup.string().required(),
    zipCode: Yup.string()
      .required()
      .test(
        'zipCode-length',
        i18next.t('formValidation.zipcodeLength'),
        (value = '') => {
          return value.length === 5;
        },
      ),
    state: Yup.string().required(),
    phoneNumber: Yup.string()
      .notRequired()
      .test(
        'phoneNumber-test',
        i18next.t('formValidation.phoneNumber'),
        (value) => !value || validator.isMobilePhone(value),
      ),
  });
};

const getSaveMethodSchema = (hasScheduledItems) => {
  return Yup.object().shape({
    saveMethod: Yup.bool()
      .required()
      .test(
        'saveMethod-test',
        i18next.t('formValidation.saveMethodScheduledItems'),
        (value) => {
          return value || (!value && !hasScheduledItems);
        },
      ),
  });
};

const getEmailSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required().email(i18next.t('formValidation.email')),
  });
};

export const buildBillingInfoSchema = (
  loggedIn,
  hasScheduledItems,
  profileComplete,
  allowGuestScheduledTransactions, // Remove on allowGuestScheduledTransactions FF clean up
) => {
  if (loggedIn) {
    if (profileComplete) {
      // user is logged in and we have all info, so we only need to validate the save method checkbox
      return getSaveMethodSchema(hasScheduledItems);
    }
    // user is logged in so we don't need to validate email
    return getBillingInfoSchema().concat(
      getSaveMethodSchema(hasScheduledItems),
    );
  }
  if (hasScheduledItems && allowGuestScheduledTransactions) {
    return getBillingInfoSchema()
      .concat(getEmailSchema())
      .concat(getSaveMethodSchema(hasScheduledItems));
  }
  // validate all data including email, but not saving the payment method since they're not logged in
  return getBillingInfoSchema().concat(getEmailSchema());
};

/**
 * Creates a Yup schema to be used with Formik for validation. Unfortunately the schema must be defined in the function since
 * i18next won't be initialized if it's defined as a constant outside it. We could create a work around for that, but it probably isn't necessary.
 * @param {string} type - the payment type to create a schema for. Should be of a type defined in paymentMethodMap
 * @param {object} [options] - additional options used for building the schema
 * @param {boolean} [options.loggedIn] - if the user is logged in. If falsy, adds email to the billing info schema
 * @param {string} [options.cardBrand] - the card brand used for validating the cvv
 * @param {boolean} [options.profileComplete] - if the user already has all required contact info in their profile
 * @param {boolean} [options.hasScheduledItems] - if the user already has and scheduled items in their basket
 * @returns {*}
 */
export const createSchema = (type, isNew, options, enableAddress = false) => {
  Yup.setLocale({
    mixed: {
      required: `${i18next.t('formValidation.required')}`,
    },
  });

  const addressInfoSchema = Yup.object().shape({
    addressLine1: Yup.string().required(),
    addressLine2: Yup.string().notRequired(),
    city: Yup.string().required(),
    zipCode: Yup.string()
      .required()
      .test(
        'zipCode-length',
        i18next.t('formValidation.zipcodeLength'),
        (value = '') => {
          return value.length === 5;
        },
      ),
    state: Yup.string().required(),
  });

  const achSchema = Yup.object().shape({
    accountNumber: Yup.string()
      .max(17, i18next.t('formValidation.accountLength'))
      .test(
        'account-alphanumeric-test',
        i18next.t('formValidation.accountLength'),
        (value = '') => validator.isAlphanumeric(value),
      )
      .required(),
    routingNumber: Yup.string()
      .test(
        'routing-length-test',
        i18next.t('formValidation.routingLength'),
        (value = '') => validator.isInt(value) && value.length === 9,
      )
      .test(
        'routing-start-test',
        i18next.t('formValidation.routingStartValue'),
        (value = '') => '0123'.includes(value[0]),
      )
      .required(),
    nacha: Yup.bool().oneOf([true], i18next.t('formValidation.nacha')),
  });

  const savedAchSchema = Yup.object().shape({
    nacha: Yup.bool().oneOf([true], i18next.t('formValidation.nacha')),
  });

  const cardSchema = Yup.object().shape({
    zipCode: Yup.string()
      .required()
      .test(
        'zipCode-length',
        i18next.t('formValidation.zipcodeLength'),
        (value = '') => {
          return value.length === 5;
        },
      ),
  });

  const paymentMethodMap = {
    [globals.CREDIT_CARD]: cardSchema,
    [globals.ACH]: !options.loggedIn || isNew ? achSchema : savedAchSchema,
  };

  return Yup.object().shape({
    [type]: paymentMethodMap[type],
    billingInfo:
      (options.loggedIn && !isNew) || options.skipBillingInfo
        ? undefined
        : buildBillingInfoSchema(
            options && options.loggedIn,
            options && options.hasScheduledItems,
            options && options.profileComplete,
            options && options.allowGuestScheduledTransactions,
          ),
    ...(enableAddress && { addressInfo: addressInfoSchema }),
  });
};
