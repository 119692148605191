import Axios from 'axios';

export class Api {
  static http;

  constructor(options = {}) {
    const baseUrl = options.baseUrl || `${window.env.REACT_APP_BACKEND_API}/`;
    this.http = Axios.create({
      baseURL: baseUrl,
      withCredentials: false,
    });
  }

  get(url) {
    return this.http.get(url);
  }

  getWithParams(url, params) {
    return this.http.get(url, { params });
  }

  post(url, data, config?) {
    return this.http.post(url, data, config);
  }

  put(url, data, config?) {
    return this.http.put(url, data, config);
  }

  patch(url, data, config?) {
    return this.http.patch(url, data, config);
  }

  delete(url, data) {
    return this.http.delete(url, data);
  }

  addRequestInterceptor(interceptor) {
    this.http.interceptors.request.use(
      interceptor.fulfilled,
      interceptor.rejected,
    );
  }

  addResponseInterceptor(interceptor) {
    this.http.interceptors.response.use(
      interceptor.fulfilled,
      interceptor.rejected,
    );
  }
}

export default new Api();
