let checked = false;
let result: boolean;

export function inIframe() {
  if (!checked) {
    try {
      result = window.self !== window.top;
    } catch (e) {
      result = true;
    }
  }
  checked = true;
  return result;
}
