import { useMsal } from '@azure/msal-react';
import AzureAuthService from 'api/AzureAuthService';

export default function useHandleAzureRedirect() {
  const { instance: msalInstance, inProgress } = useMsal();

  const handleAzureRedirect = async (isSignup) => {
    try {
      await AzureAuthService.initialize(msalInstance);
      if (isSignup) {
        await AzureAuthService.signUpAsync();
      } else {
        await AzureAuthService.loginAsync();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Azure redirect failed:', e);
    }
  };

  return { inProgress, handleAzureRedirect };
}
