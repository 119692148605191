import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Button,
  Typography,
  withStyles,
  FormControlLabel,
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import BasketItem from './BasketItem';
// import IOSSwitch from '../common/IOSSwitch';
import { savePaymentOptions, fetchPaymentOptions } from '../../actions/basket';
import { isIE11 } from '../../../common/utils';
import IOSSwitch from '../../../components/common/IOSSwitch';

const defaultColor = '#3041FA';
const styles = (theme) => ({
  stickyNode: {},
  ie11Sticky: {},
  basket: {
    maxWidth: '500px',
  },
  content: {
    paddingBottom: '25px',
    width: '80%',
    margin: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    basket: {
      width: '88vw',
      margin: 'auto',
      marginTop: '3vh',
      position: 'static',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  [theme.breakpoints.up('md')]: {
    stickyNode: {
      alignSelf: 'baseline',
      marginTop: '5vh',
      left: '60vw',
      maxWidth: '500px',
      minWidth: '300px',
      minHeight: '150px',
      width: '35vw',
      top: '10vh',
      position: 'sticky',
      marginLeft: '5vw',
      marginRight: '5vw',
    },
    ie11Sticky: {
      position: 'fixed',
    },
  },
  title: {
    lineHeight: '1.35',
    letterSpacing: '0.4px',
    display: 'flex',
  },
  basketTopBar: {
    marginTop: '25px',
    marginBottom: '18px',
  },
  basketItem: {
    width: '80%',
    margin: 'auto',
  },
  buttonContainer: {
    marginTop: '25px',
    marginBottom: '10px',
  },
  checkoutButton: {
    width: '100%',
    backgroundColor: defaultColor,
  },
  itemBreak: {
    borderColor: 'rgba(228, 229, 230, 0.5)',
    marginTop: '20px',
  },
  processingFeesSwitch: {
    fontSize: '18px',
  },
  paymentsCaptionContainer: {
    '& svg': {
      fill: theme.typography.caption.color,
      height: '18px',
    },
  },
  captionContainer: {
    marginTop: '22px',
    padding: '0px 24px',
  },
  captionLink: {
    color: defaultColor,
  },
  inIframe: {
    boxShadow: 'none',
    border: '1px solid #DEE1E3',
  },
});

export function MyBasket(props) {
  const {
    pcct,
    fetchPaymentOptionsConnect,
    savePaymentOptionsConnect,
    enableProcessingFees,
    applyProcessingFee,
    processingFee,
    selectedPaymentType,
    classes,
    onSubmit,
    submitButtonText,
    isSubmit,
    showSecurePaymentsMessage,
    iframe,
    sessionData,
    isSubmitting,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    // If a processingFee is passed into this component, we need to selectedPaymentTypeupdate the store
    // so that our data doesn't fall out of sync.
    if (enableProcessingFees && processingFee) {
      savePaymentOptionsConnect({
        paymentType: selectedPaymentType,
        applyProcessingFee,
        processingFee,
      });
    } else {
      fetchPaymentOptionsConnect(pcct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (enableProcessingFees) {
      savePaymentOptionsConnect({
        paymentType: selectedPaymentType,
        applyProcessingFee,
        processingFee,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingFee]);

  const onApplyProcessingFeesChange = (event) => {
    savePaymentOptionsConnect({
      paymentType: selectedPaymentType,
      applyProcessingFee: event.target.checked,
      processingFee,
    });
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!sessionData) return <></>;

  return (
    <div
      className={`${classes.stickyNode} ${isIE11() ? classes.ie11Sticky : ''}`}
    >
      <Card className={`${classes.basket} ${iframe ? classes.inIframe : ''}`}>
        <div className={classes.content}>
          <div className={classes.basketTopBar}>
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <div className={classes.title}>
                <Typography variant="h6">{t('mpbasket.title')}</Typography>
              </div>
            </Grid>
            <hr className={classes.itemBreak} />
          </div>

          {sessionData && (
            <BasketItem
              className={classes.basketItem}
              key={sessionData.invoiceId}
              pcct={pcct}
              item={sessionData}
              showProcessingFees={applyProcessingFee}
              processingFee={processingFee}
            />
          )}

          {enableProcessingFees && !!processingFee && (
            <div className={classes.processingFeesSwitch}>
              <hr
                className={classes.itemBreak}
                style={{ marginTop: '28px', marginBottom: '28px' }}
              />
              <FormControlLabel
                control={
                  <IOSSwitch
                    color="primary"
                    checked={applyProcessingFee}
                    onChange={onApplyProcessingFeesChange}
                  />
                }
                label={t('basket.processingFee')}
              />
            </div>
          )}

          <hr className={classes.itemBreak} />

          <Grid
            container
            className={classes.buttonContainer}
            justifyContent="center"
          >
            <Button
              id="checkout-button"
              className={classes.checkoutButton}
              color="primary"
              variant="contained"
              onClick={onSubmit}
              type={isSubmit ? 'submit' : 'button'}
              disabled={isSubmitting}
            >
              {submitButtonText}
            </Button>
          </Grid>
          {showSecurePaymentsMessage && (
            <Grid
              container
              className={classes.paymentsCaptionContainer}
              justifyContent="center"
            >
              <LockOutlined />
              <Typography variant="caption">
                {t('mpbasket.paymentsProcessedSecurely')}
              </Typography>
            </Grid>
          )}
        </div>
      </Card>
    </div>
  );
}

MyBasket.propTypes = {
  classes: PropTypes.object.isRequired,
  pcct: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  isSubmit: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  showSecurePaymentsMessage: PropTypes.bool,
  submitButtonText: PropTypes.string.isRequired,
  enableProcessingFees: PropTypes.bool,
  processingFee: PropTypes.number,
  applyProcessingFee: PropTypes.bool.isRequired,
  savePaymentOptionsConnect: PropTypes.func.isRequired,
  fetchPaymentOptionsConnect: PropTypes.func.isRequired,
  iframe: PropTypes.bool.isRequired,
  sessionData: PropTypes.object,
  selectedPaymentType: PropTypes.string.isRequired,
};

MyBasket.defaultProps = {
  enableProcessingFees: false,
  processingFee: null,
  isSubmit: false,
  isSubmitting: false,
  showSecurePaymentsMessage: true,
  onSubmit: () => {},
  sessionData: null,
};

const mapStateToProps = (state, ownProps) => ({
  applyProcessingFee: state.basket.applyProcessingFee,
  processingFee: Object.prototype.hasOwnProperty.call(ownProps, 'processingFee')
    ? ownProps.processingFee
    : state.basket.processingFee,
  iframe: state.frame.inIframe,
});

const mapDispatchToProps = (dispatch) => ({
  savePaymentOptionsConnect: (paymentOptions) => {
    dispatch(savePaymentOptions(paymentOptions));
  },
  fetchPaymentOptionsConnect: (pcct) => {
    dispatch(fetchPaymentOptions(pcct));
  },
});

export default compose(
  withRouter,
  withStyles(styles, { name: 'MyBasket' }),
  connect(mapStateToProps, mapDispatchToProps),
)(MyBasket);
