import { EventType } from '@azure/msal-browser';
import { getCurrentPathNameWithParams } from '../common/utils';
import store from '../store';
import * as globals from '../common/globals';

// This AuthService communicates with Azure AD B2C
export class AzureAuthService {
  static msalInstance;

  static callbackId;

  config = {
    auth: {
      authority: `${window.env.REACT_APP_AZURE_AUTH_DOMAIN}/${window.env.REACT_APP_AZURE_AUTH_TENANT}/${window.env.REACT_APP_AZURE_AUTH_SIGNIN}`,
      knownAuthorities: [window.env.REACT_APP_AZURE_AUTH_DOMAIN],
      clientId: window.env.REACT_APP_AZURE_AUTH_CLIENT_ID,
      responseType: 'code',
      navigateToLoginRequestUrl: false,
      redirectUri: globals.AZURE_POST_REDIRECT,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  };

  get b2cConfig() {
    return this.config;
  }

  // Validate msal instance and initialize it in case is not already.
  async initialize(instance) {
    this.msalInstance = this.msalInstance ?? instance;
    if (!this.msalInstance.controller.initialized) {
      await this.msalInstance.initialize();
    }
  }

  // Register event callbacks for login flow events.
  #registerCallbacks(resolve, reject, promise) {
    this.callbackId = this.msalInstance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
      ) {
        this.setActiveAccount(event.payload.account);
        resolve(this.#adaptLoginResult(event.payload));
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        reject(event.error ?? 'error');
      }
    });

    // if no login event is handled after certain time, reject and send an error.
    setTimeout(reject, 5000, Error('Azure auth timeout error'));
    return promise;
  }

  // Transform payload from login to compatible session detail object
  // eslint-disable-next-line class-methods-use-this
  #adaptLoginResult(payload) {
    return {
      accessToken: payload.accessToken,
      idToken: payload.idToken,
      idTokenPayload: payload.account.idTokenClaims,
      appState: JSON.parse(payload.state),
      expiresIn: payload.expiresOn || 3600, // hardcoded value for now since we still need to define the scopes for the acces token
      tokenType: payload.tokenType,
      scope: payload.scopes.join(' '),
      b2cAccount: payload.account,
    };
  }

  // Setup instance and handlers for login responses.
  async handleLoginEvents(instance) {
    await this.initialize(instance);

    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    return this.#registerCallbacks(resolve, reject, promise);
  }

  async unsubscribeRedirectEvents() {
    if (this.callbackId && this.msalInstance?.controller.initialized) {
      this.msalInstance.removeEventCallback(this.callbackId);
    }
  }

  // Check if the user is already signed in, if not, then run the login process.
  async loginAsync() {
    const account = this.getActiveAccount();
    const { idsPayerTenant } = store.getState().location;
    if (!account) {
      const options = {
        state: JSON.stringify({
          returnTo: getCurrentPathNameWithParams(),
          idsPayerTenant,
        }),
      };
      await this.msalInstance.loginRedirect(options);
    }
  }

  async signUpAsync() {
    const { idsPayerTenant } = store.getState().location;
    const options = {
      state: JSON.stringify({
        returnTo: getCurrentPathNameWithParams(),
        idsPayerTenant,
      }),
      authority: `${window.env.REACT_APP_AZURE_AUTH_DOMAIN}/${window.env.REACT_APP_AZURE_AUTH_TENANT}/${window.env.REACT_APP_AZURE_AUTH_SIGNUP}`,
    };
    await this.msalInstance.loginRedirect(options);
  }

  // Set the active account in the MSAL Instance
  setActiveAccount(account) {
    this.msalInstance.setActiveAccount(account);
  }

  // Get the active account in the MSAL Instance
  getActiveAccount() {
    return this.msalInstance.getActiveAccount();
  }
}

export default new AzureAuthService();
