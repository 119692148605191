import { Badge, Button, Grid, Typography } from '@material-ui/core';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import PersonOutline from '@material-ui/icons/PersonOutline';
import ShoppingBasketOutlined from '@material-ui/icons/ShoppingBasketOutlined';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as globals from '../../common/globals';
import EmbeddedHeader from '../common/EmbeddedHeader';
import HeaderName from './HeaderName';
import useFeatures from '../../hooks/common/useFeatures';
import LanguageToggle from './LanguageToggle/LanguageToggle';
import { useHeaderDesktopStyles } from './styles';
import ReturnToOpportunities from '../navigation/returnToOpportunities/ReturnToOpportunities';

function HeaderDesktop({
  loc,
  loggedIn,
  transactionCount,
  allowLogin,
  iframe,
  showEmbeddedView,
  showActionNeededUI,
  showIDSDialog,
  showOpportunitiesLink,
}) {
  const classes = useHeaderDesktopStyles();
  const { MultiLanguageSupport } = useFeatures();
  const { t } = useTranslation();
  const renderLoggedInMenu = () => {
    if (showActionNeededUI) return null;

    return (
      <>
        {!iframe && (
          <Button
            id="home-button"
            className={classes.headerButton}
            component={Link}
            to={`/${loc.id}/home`}
          >
            <HomeOutlined className={classes.headerIcon} />
            <Typography className={classes.headerButtonText}>
              {t('app.home')}
            </Typography>
          </Button>
        )}
        <Button
          id="profile-button"
          className={classes.headerButton}
          component={Link}
          to={`/${loc.id}/${globals.PROFILE_PATH}`}
        >
          <PersonOutline className={classes.headerIcon} />
          <Typography className={classes.headerButtonText}>
            {t('app.profile')}
          </Typography>
        </Button>
      </>
    );
  };

  const renderLoggedOutMenu = () => {
    if (showActionNeededUI) return null;

    return (
      <>
        <Button
          id="signup-button"
          className={classes.headerButton}
          onClick={() => showIDSDialog(true)}
        >
          <Typography className={classes.headerButtonText}>
            {t('app.signUp')}
          </Typography>
        </Button>
        <Button
          id="login-button"
          className={classes.headerButton}
          onClick={() => showIDSDialog(false)}
        >
          <Typography className={classes.headerButtonText}>
            {t('app.logIn')}
          </Typography>
        </Button>
      </>
    );
  };

  const renderLanguageToggle = () => {
    if (!MultiLanguageSupport) return null;
    return <LanguageToggle classes={classes} />;
  };

  return (
    <Grid
      container
      justifyContent={iframe ? 'flex-end' : 'space-between'}
      alignItems="center"
      className={classes.container}
    >
      {showOpportunitiesLink && <ReturnToOpportunities />}
      {!iframe && (
        <Grid item className={classes.titleColumn}>
          <Link
            id="header-name"
            to={`/${loc.id}/home`}
            className={showActionNeededUI ? classes.disabled : ''}
          >
            <HeaderName loc={loc} />
          </Link>
        </Grid>
      )}
      <Grid item className={classes.buttonColumn}>
        {showEmbeddedView && (
          <EmbeddedHeader transactionCount={transactionCount} loc={loc} />
        )}
        {!showEmbeddedView && (
          <Grid container justifyContent="flex-end">
            {(allowLogin || loggedIn) &&
              (loggedIn ? renderLoggedInMenu() : renderLoggedOutMenu())}
            {!showActionNeededUI && (
              <Button
                className={classes.headerButton}
                component={Link}
                to={`/${loc.id}/${globals.BASKET_PATH}`}
              >
                <Badge
                  id="header-basket-badge"
                  badgeContent={transactionCount}
                  color="primary"
                  className={classes.headerIcon}
                  overlap="rectangular"
                >
                  <ShoppingBasketOutlined />
                </Badge>
                <Typography className={classes.headerButtonText}>
                  {t('app.basket')}
                </Typography>
              </Button>
            )}
            {renderLanguageToggle()}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

HeaderDesktop.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  showIDSDialog: PropTypes.func.isRequired,
  transactionCount: PropTypes.number.isRequired,
  loc: PropTypes.object.isRequired,
  allowLogin: PropTypes.bool.isRequired,
  iframe: PropTypes.bool.isRequired,
  showEmbeddedView: PropTypes.bool.isRequired,
  showActionNeededUI: PropTypes.bool.isRequired,
  showOpportunitiesLink: PropTypes.bool.isRequired,
};

export default HeaderDesktop;
