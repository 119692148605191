export default theme => ({
  transactionCard: {
    width: '100%',
    maxWidth: '800px',
    marginBottom: '25px',
  },
  noTransactionCard: {
    padding: '42px 0 43px 52px',
    fontWeight: 'bold',
  },
  missingRoot: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  missingTitle: {
    fontWeight: '800',
  },
  missingText: {
    textAlign: 'center',
    marginTop: '10px',
  },
  [theme.breakpoints.down('xs')]: {
    transactionCard: {
      width: '100%',
    },
  },
});
