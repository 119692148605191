import { string } from '@vancoplatform/utils';

const crypto = window.crypto || window.msCrypto;

export function supportsSha256() {
  return typeof crypto !== 'undefined' && typeof crypto.subtle !== 'undefined';
}

function textEncode(str: string) {
  if (window.TextEncoder) {
    return new TextEncoder().encode(str);
  }
  const utf8 = decodeURIComponent(encodeURIComponent(str));
  const result = new Uint8Array(utf8.length);
  for (let i = 0; i < utf8.length; i++) {
    result[i] = utf8.charCodeAt(i);
  }
  return result;
}

function hashEncode(arrayBuffer: Iterable<number>) {
  const hash = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
  const b64u = string.stringToBase64Url(hash); // url-safe base64 variant
  return b64u;
}

export function computeChallenge(str: string) {
  const buffer = textEncode(str);
  const res = crypto.subtle.digest('SHA-256', buffer);

  if (res.then) {
    return res.then((arrayBuffer: Iterable<number>) => hashEncode(arrayBuffer));
  } else if (res.result) {
    return Promise.resolve(hashEncode(res.result));
  }
}
