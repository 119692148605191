// eslint-disable-next-line import/prefer-default-export
export function useTrackRecurrence() {
  const pushEvent = (loggedIn, recurrency, action) => {
    // when debugging locally, please replace window by process.
    if (window.env?.REACT_APP_GA_ENABLED?.toString()?.toLowerCase() !== 'true')
      return '';

    const userType = loggedIn ? 'logged_in' : 'guest';
    const recurrencyTYpe =
      recurrency === 'OneTimeNow' ? 'realtime' : 'scheduled';
    const actionType = action === 'delete' ? 'deleted' : 'added';
    const sufix = action === 'delete' ? 'from_basket' : 'to_basket';

    const msg = `${userType}_${actionType}_${recurrencyTYpe}_${sufix}`;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: msg });
    return msg;
  };

  return pushEvent;
}
