export default function roundAwayFromZero(number, decimalPlaces) {
  const decimals = decimalPlaces || 2;
  const power = 10 ** decimals;
  const numberNoDecimal = +(decimals ? number * power : number).toFixed(8);
  const integerNoDecimal = Math.ceil(numberNoDecimal);
  const remainingDecimal = numberNoDecimal - integerNoDecimal;
  const maximumDecimals = 1e-8;
  const roundedNumber =
    // eslint-disable-next-line no-nested-ternary
    remainingDecimal > 0.5 - maximumDecimals &&
    remainingDecimal < 0.5 + maximumDecimals
      ? integerNoDecimal % 2 === 0
        ? integerNoDecimal
        : integerNoDecimal + 1
      : Math.round(numberNoDecimal);
  return decimals ? roundedNumber / power : roundedNumber;
}
