import { merge } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { LOCATION_PAGE_LAYOUTS } from '../../../common/globals';

export const useOpenAmountStyles = ({ layout }) =>
  makeStyles((theme) => {
    const baseStyles = {
      inputField: {
        margin: '10px 0px 12px 0px',
        width: '100%',
        background: '#FFFFFF',
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
      inputFieldNoBackground: {
        background: 'unset',
      },
      inputHelperText: {
        margin: '3px 12px 0',
        maxWidth: '100%',
      },
      dividerStyleFix: {
        margin: '20px 0 12px',
      },
      formClassName: {
        marginBottom: '8px',
      },
      dateTitle: {
        color: '#212224',
        fontSize: '16px',
        lineHeight: '24px',
      },
      charCount: {
        display: 'findLastIndex',
      },
      setEndDateVisible: {
        color: '#0096EF',
      },
    };

    const styles = {
      [LOCATION_PAGE_LAYOUTS.EXPRESS]: {
        currencyInput: {
          width: '100%',
          '& div': {
            padding: '12px 16px',
            '& input': {
              width: '100%',
              fontSize: '32px',
              textAlign: 'center',
              color: theme.palette.text.secondary,
            },
          },
        },
      },
      [LOCATION_PAGE_LAYOUTS.DETAIL]: {
        currencyInput: baseStyles.inputField,
      },
    };

    const overrideStyles =
      styles[layout] || styles[LOCATION_PAGE_LAYOUTS.DETAIL];
    return merge(baseStyles, overrideStyles);
  })();

export const useFixedAmountStyles = ({ layout }) =>
  makeStyles(() => {
    const baseStyles = {
      quantity: {
        color: '#222222',
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
      },
      inputField: {
        height: 60,
        width: 76,
        marginBottom: 12,
        background: '#FFFFFF',
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
      memoInputField: {
        margin: '10px 0px 12px 0px',
        width: '100%',
        background: '#FFFFFF',
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
      dividerStyleFix: {
        margin: 0,
        marginBottom: '12px',
      },
      dateTitle: {
        color: '#212224',
        fontSize: '16px',
        lineHeight: '24px',
      },
      inputHelperText: {
        margin: '3px 12px 0',
        maxWidth: '100%',
      },
    };

    const styles = {
      [LOCATION_PAGE_LAYOUTS.EXPRESS]: {
        inputField: {
          height: 60,
          width: '100%',
          marginBottom: 12,
          background: '#FFFFFF',
          '&:last-of-type': {
            marginBottom: 0,
          },
        },
        price: {
          color: '#222222',
          fontSize: 42,
          paddingBottom: 34,
          paddingTop: 16,
        },
      },
      [LOCATION_PAGE_LAYOUTS.DETAIL]: {
        inputField: {
          height: 60,
          width: 76,
          marginBottom: 12,
          background: '#FFFFFF',
          '&:last-of-type': {
            marginBottom: 0,
          },
        },
        price: {
          color: '#222222',
          fontSize: 18,
          marginBottom: 40,
        },
      },
    };

    const overrideStyles =
      styles[layout] || styles[LOCATION_PAGE_LAYOUTS.DETAIL];
    return merge(baseStyles, overrideStyles);
  })();

export default { useOpenAmountStyles };
