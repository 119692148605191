import { Typography, useTheme, withStyles } from '@material-ui/core';
import { object, objectOf, string } from 'prop-types';
import LogoContainer from '../common/LogoContainer';

const styles = (theme) => ({
  headerName: {
    fontWeight: 'bold',
    display: 'inline-block',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: ({ isMobileView }) =>
      isMobileView ? 'break-spaces' : 'nowrap',
    overflow: 'hidden',
    color: 'inherit',
    paddingTop: 4,
    paddingLeft: theme.spacing(5),
    fontSize: 16,
  },
});

function HeaderName({ classes, loc }) {
  const theme = useTheme();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!loc || (!loc?.logoImageUrl && !loc?.primaryColor)) return <></>;

  return (
    <>
      <LogoContainer
        imageUrl={loc.logoImageUrl}
        backgroundColor={loc.primaryColor || theme.palette.primary.main}
        name={loc.parentOrgName}
      />

      <Typography className={`${classes.headerName} `}>
        {loc.parentOrgName}
      </Typography>
    </>
  );
}

HeaderName.propTypes = {
  classes: objectOf(string).isRequired,
  loc: object.isRequired,
};

HeaderName.defaultProps = {};

export default withStyles(styles)(HeaderName);
