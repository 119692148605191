/**
 * Checks if all values in the given map are truthy
 * @param validationMap - the map to be checked
 * @returns {boolean}
 */
// eslint-disable-next-line import/prefer-default-export
export function validateAll(validationMap) {
  let valid = true;
  const keys = Object.keys(validationMap);
  keys.forEach(entry => {
    if (!validationMap[entry]) {
      valid = false;
    }
  });
  return valid;
}
