import { createStyles, makeStyles } from '@material-ui/core';

const commonUnderline = {
  textDecoration: 'underline',
  '&:hover': {
    background: 'transparent',
    textDecoration: 'underline',
  },
};

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      borderBottom: 'none',
    },
    signUpButton: {
      ...commonUnderline,
    },
    continueButton: {
      color: theme.palette.grey['600'],
      ...commonUnderline,
    },
    container: {
      textAlign: 'center',
      marginTop: theme.spacing(5),
    },
    verticalGutter: {
      marginTop: theme.spacing(2),
    },
    modal: {
      maxWidth: 410,
    },
  }),
);

export default useStyles;
