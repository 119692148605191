import { useEffect, useState } from 'react';
import { bool, func, objectOf, string } from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';
import { withStyles, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Modal from '../modal';

import styles from './styles';

export function PreventLeavePageModal({
  classes,
  shouldOpenOnNavigate,
  onConfirm,
  onClose,
  showPreventModal,
  title,
  description,
  confirmText,
}) {
  const [isOpen, setOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [navigateConfirmed, setNavigateConfirmed] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const closeModal = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    if (navigateConfirmed && nextLocation) {
      history.push(nextLocation);
      if (onConfirm) {
        onConfirm(nextLocation);
      }
      setNextLocation(null);
      setNavigateConfirmed(false);
    }
  }, [history, onConfirm, navigateConfirmed, nextLocation]);

  useEffect(() => {
    if (showPreventModal) {
      setNextLocation(null);
    }
  }, [showPreventModal]);

  const handleOnLeave = (location) => {
    if (!navigateConfirmed) {
      setOpen(true);
      setNextLocation(location);
      return false;
    }

    return true;
  };

  const onLeavePage = () => {
    if (nextLocation) {
      setNavigateConfirmed(true);
      setOpen(false);
    } else if (onConfirm) onConfirm();
  };

  return (
    <>
      <Prompt when={shouldOpenOnNavigate} message={handleOnLeave} />
      <Modal
        classes={{ ...classes, closeIcon: undefined }}
        open={isOpen || showPreventModal}
        onClose={closeModal}
      >
        <Modal.Title className={classes.title}>
          {title || t('layout.leave.title')}
        </Modal.Title>
        <Modal.Content>
          <Typography>
            {description || t('layout.leave.description')}
          </Typography>
        </Modal.Content>
        <Modal.Actions className={classes.actions}>
          <Button onClick={closeModal} variant="outlined" color="primary">
            {t('common.cancel')}
          </Button>
          <Button variant="outlined" onClick={onLeavePage}>
            {confirmText || t('layout.leave.confirm')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

PreventLeavePageModal.propTypes = {
  classes: objectOf(string).isRequired,
  shouldOpenOnNavigate: bool,
  onConfirm: func,
  onClose: func.isRequired,
  showPreventModal: bool.isRequired,
  title: string,
  description: string,
  confirmText: string,
};

PreventLeavePageModal.defaultProps = {
  shouldOpenOnNavigate: false,
  onConfirm: undefined,
  title: '',
  description: '',
  confirmText: '',
};

export default withStyles(styles)(PreventLeavePageModal);
