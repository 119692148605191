export const USER_DETAILS_FETCH = 'USER_DETAILS_FETCH';
export const USER_DETAILS_COMPLETE = 'USER_DETAILS_COMPLETE';
export const USER_DETAILS_CLEAR = 'USER_DETAILS_CLEAR';
export const USER_DETAILS_SAVE = 'USER_DETAILS_SAVE';
export const USER_DETAILS_SAVE_COMPLETE = 'USER_DETAILS_SAVE_COMPLETE';
export const USER_DETAILS_SAVE_ERROR = 'USER_DETAILS_SAVE_ERROR';
export const USER_DETAILS_ERROR = 'USER_DETAILS_ERROR';

export const BASKET_SET_ITEMS = 'BASKET_SET_ITEMS';
export const BASKET_SET_PAYMENT_OPTIONS = 'BASKET_SET_PAYMENT_OPTIONS';
export const CLEAR_PAYMENT_OPTIONS = 'CLEAR_PAYMENT_OPTIONS';

export const SET_HEXEA_OBJECT = 'SET_HEXEA_OBJECT';
export const PAYMENT_ID_CLEAR = 'PAYMENT_ID_CLEAR';
export const PAYMENT_ID_FETCH = 'PAYMENT_ID_FETCH';
export const PAYMENT_ID_COMPLETE = 'PAYMENT_ID_COMPLETE';
export const PAYMENT_ID_ERROR = 'PAYMENT_ID_ERROR';
export const SELECTED_PAYMENT_METHOD_FETCH = 'SELECTED_PAYMENT_METHOD_FETCH';
export const SELECTED_PAYMENT_METHOD_COMPLETE =
  'SELECTED_PAYMENT_METHOD_COMPLETE';
export const SELECTED_PAYMENT_METHOD_ERROR = 'SELECTED_PAYMENT_METHOD_ERROR';
export const PAYMENT_METHOD_SAVE = 'SELECTED_PAYMENT_METHOD_SAVE';
export const PAYMENT_METHOD_SAVE_COMPLETE = 'PAYMENT_METHOD_SAVE_COMPLETE';
export const PAYMENT_METHOD_SAVE_ERROR = 'PAYMENT_METHOD_SAVE_ERROR';

export const SAVED_PAYMENT_METHODS_FETCH = 'SAVED_PAYMENT_METHODS_FETCH';
export const SAVED_PAYMENT_METHODS_COMPLETE = 'SAVED_PAYMENT_METHODS_COMPLETE';
export const SAVED_PAYMENT_METHODS_ERROR = 'SAVED_PAYMENT_METHODS_ERROR';
export const SAVED_PAYMENT_METHODS_DELETE = 'SAVED_PAYMENT_METHODS_DELETE';
export const SAVED_PAYMENT_METHODS_DELETE_COMPLETE =
  'SAVED_PAYMENT_METHODS_DELETE_COMPLETE';
export const SAVED_PAYMENT_METHODS_DELETE_ERROR =
  'SAVED_PAYMENT_METHODS_DELETE_ERROR';

export const CSRF_FETCH = 'CSRF_FETCH';
export const CSRF_COMPLETE = 'CSRF_COMPLETE';
export const CSRF_ERROR = 'CSRF_ERROR';

export const SESSION_INIT_COMPLETE = 'SESSION_INIT_COMPLETE';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const IDS_SESSION_SAVE = 'IDS_SESSION_SAVE';
export const IDS_SESSION_CHECK_UPDATE = 'IDS_SESSION_CHECK_UPDATE';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';

// MP Session data
export const SESSION_DATA_FETCH = 'SESSION_DATA_FETCH';
export const SESSION_DATA_COMPLETE = 'SESSION_DATA_COMPLETE';
export const SESSION_DATA_ERROR = 'SESSION_DATA_ERROR';

// MP Updated JWT URL
export const UPDATED_JWT_FETCH = 'UPDATED_JWT_FETCH';
export const UPDATED_JWT_COMPLETE = 'UPDATED_JWT_COMPLETE';
export const UPDATED_JWT_ERROR = 'UPDATED_JWT_ERROR';
