/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import {
  Box,
  Tabs,
  Tab,
  withStyles,
  Button,
  Typography,
  ButtonGroup,
} from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PAYMENT_FREQUENCY_OPTIONS } from '../../../common/globals';
import useFeatures from '../../../hooks/common/useFeatures';

const styleOverrides = (theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

function RecurringOption({
  classes,
  field,
  form,
  allowInstallments,
  ...props
}) {
  const { t } = useTranslation();
  const { Installment: installmentFF } = useFeatures();
  const { name, value } = field;
  const { touched, errors, isSubmitting } = form;

  const handleChange = (option) => {
    form.setFieldValue(field.name, option);
  };

  return (
    <ButtonGroup disableElevation fullWidth {...props}>
      <Button
        className={`${
          value === PAYMENT_FREQUENCY_OPTIONS.ONCE ? classes.selected : ''
        }`}
        onClick={() => handleChange(PAYMENT_FREQUENCY_OPTIONS.ONCE)}
        disableRipple
      >
        <Typography className={classes.setEndDateVisible}>
          {t('addDonation.oneTime')}
        </Typography>
      </Button>
      <Button
        className={`${
          value === PAYMENT_FREQUENCY_OPTIONS.RECURRING ? classes.selected : ''
        }`}
        onClick={() => handleChange(PAYMENT_FREQUENCY_OPTIONS.RECURRING)}
        disableRipple
      >
        <Typography className={classes.setEndDateVisible}>
          {t('addDonation.recurring')}
        </Typography>
      </Button>

      {installmentFF && allowInstallments && (
        <Button
          className={`${
            value === PAYMENT_FREQUENCY_OPTIONS.INSTALLMENT
              ? classes.selected
              : ''
          }`}
          onClick={() => handleChange(PAYMENT_FREQUENCY_OPTIONS.INSTALLMENT)}
          disableRipple
        >
          <Typography className={classes.setEndDateVisible}>
            {t('addDonation.installments')}
          </Typography>
        </Button>
      )}
    </ButtonGroup>
  );
}

RecurringOption.defaultProps = {
  allowInstallments: false,
};

RecurringOption.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  allowInstallments: PropTypes.bool,
};

export default withStyles(styleOverrides)(RecurringOption);
