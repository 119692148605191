import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import location from './location';
import user from './user';
import session from './session';
import campaigns from './campaigns';
import basket from './basket';
import paymentMethod from './paymentMethod';
import savedPaymentMethods from './savedPaymentMethods';
import transactions from './transactions';
import notifications from './notifications';
import payment from './payment';
import features from './features';
import frame from './frame';
import origin from './origin';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history), // required by the connected-react-router package
    location,
    user,
    session,
    campaigns,
    basket,
    paymentMethod,
    savedPaymentMethods,
    transactions,
    notifications,
    payment,
    features,
    frame,
    origin,
  });

export default createRootReducer;
