import { bool, number, oneOf, object, string } from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getCurrencyFormat } from '../../../common/utils';
import { CAMPAIGN_TYPE } from '../../../common/globals';

function ContinueButtons({
  type,
  amount,
  inBasket,
  formik,
  className,
  secondaryVariant,
  isExpressLayout,
  submitText,
  ignorePaymentSuffix,
  ...rest
}) {
  const { t } = useTranslation();

  const goToBasket = () => {
    formik.setFieldValue('toPayment', false);
    formik.submitForm();
  };

  const goToPayment = () => {
    formik.setFieldValue('toPayment', true);
  };

  return (
    <Box className={className}>
      <Button
        type="button"
        color="primary"
        fullWidth
        onClick={goToBasket}
        {...(secondaryVariant && { variant: secondaryVariant })}
        {...rest}
      >
        <>
          {t('addDonation.addToBasket')}
          {!ignorePaymentSuffix &&
            type === CAMPAIGN_TYPE.PAYMENT &&
            ` — ${getCurrencyFormat(amount)}`}
        </>
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        fullWidth
        onClick={goToPayment}
        {...rest}
      >
        {submitText}
      </Button>
    </Box>
  );
}

ContinueButtons.propTypes = {
  type: oneOf([CAMPAIGN_TYPE.GIFT, CAMPAIGN_TYPE.PAYMENT]).isRequired,
  inBasket: bool.isRequired,
  amount: number,
  formik: object.isRequired,
  className: string,
  secondaryVariant: string,
  isExpressLayout: bool,
  submitText: string,
  ignorePaymentSuffix: bool,
};

ContinueButtons.defaultProps = {
  amount: 0,
  className: '',
  secondaryVariant: null,
  isExpressLayout: false,
  submitText: i18next.t('addDonation.continuePayment'),
  ignorePaymentSuffix: false,
};

export default ContinueButtons;
