export default () => ({
  addToBasketButton: {
    fontWeight: 'bold',
    marginTop: '12px',
  },
  addToBasketButtonNoCard: {
    marginTop: '22px',
  },
  sidebarTitle: {
    padding: '10px 0px',
  },
});
