import i18next from 'i18next';
import {
  USER_DETAILS_FETCH,
  USER_DETAILS_COMPLETE,
  USER_DETAILS_ERROR,
  USER_DETAILS_CLEAR,
  USER_DETAILS_SAVE,
  USER_DETAILS_SAVE_ERROR,
  USER_DETAILS_SAVE_COMPLETE,
  USER_REQUIRED_ACTIONS_FETCH,
  USER_REQUIRED_ACTIONS_ERROR,
  USER_REQUIRED_ACTIONS_COMPLETE,
  USER_REQUIRED_ACTIONS_SUBMITED,
  MIGRATED_TRANSACTIONS_PATCH,
  MIGRATED_TRANSACTIONS_PATCH_ERROR,
  MIGRATED_TRANSACTIONS_PATCH_COMPLETE,
  USER_REQUIRED_ACTIONS_ADD_PAYMENT_METHOD,
  USER_REQUIRED_ACTIONS_SUBMIT_ERROR,
  USER_REQUIRED_ACTIONS_SUBMITING,
  USER_REQUIRED_ACTIONS_URL_REMOVED,
} from './actionTypes';
import AuthService from '../api/AuthService';
import UserService from '../api/UserService';
import { transformPendingRecurrence } from '../api/transform/PendingRecurrenceTransform';
import PaymentService from '../api/PaymentService';
import * as storageUtils from '../common/storageUtils';
import { REQUIRED_ACTIONS_PATCH_COMPLETED } from '../common/globals';
import { filterFailedTransactions } from '../common/utils';
import { isTokenExpired } from '../utils/SessionUtils';

// eslint-disable-next-line import/prefer-default-export
export function fetchUserDetails() {
  return async (dispatch, getState) => {
    const { sessionDetails } = getState().session;

    const isTokenValidObject = sessionDetails && sessionDetails.accessToken;

    if (!isTokenValidObject || isTokenExpired(sessionDetails)) {
      return dispatch({ type: USER_DETAILS_CLEAR });
    }

    const { accessToken: currentToken } = sessionDetails;

    if (currentToken) {
      dispatch({ type: USER_DETAILS_FETCH });
      let userDetails;
      try {
        userDetails = await AuthService.getUserInfo(currentToken);
        return dispatch({ type: USER_DETAILS_COMPLETE, userDetails });
      } catch (err) {
        dispatch({ type: USER_DETAILS_ERROR });
        throw err;
      }
    }
    return dispatch({ type: USER_DETAILS_CLEAR });
  };
}

export function saveUserDetails(userDetails, orgId, hasScheduledItems = false) {
  return async (dispatch) => {
    dispatch({ type: USER_DETAILS_SAVE });

    let response;
    try {
      response = await UserService.saveUserDetails(
        userDetails,
        orgId,
        hasScheduledItems, // This flag will allow to create a user if provided email is not found on tenant as an existing user.
      );
    } catch (error) {
      dispatch({ type: USER_DETAILS_SAVE_ERROR });
      throw error;
    }

    if (response?.status === 200 && response?.data) {
      dispatch(fetchUserDetails());
      dispatch({
        type: USER_DETAILS_SAVE_COMPLETE,
      });
      return response.data;
    }

    dispatch({ type: USER_DETAILS_SAVE_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function fetchUserRequiredActions(locationId) {
  return async (dispatch) => {
    dispatch({ type: USER_REQUIRED_ACTIONS_FETCH });

    let response;
    try {
      response = await UserService.getUserActionRequired(locationId);
    } catch (error) {
      dispatch({ type: USER_REQUIRED_ACTIONS_ERROR });
      throw error;
    }

    if (response?.status === 200) {
      dispatch({
        type: USER_REQUIRED_ACTIONS_COMPLETE,
        requiredActions: response,
      });
      return response.data;
    }

    dispatch({ type: USER_REQUIRED_ACTIONS_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function userRequiredActionsSubmited(submittedTransactions) {
  return async (dispatch) => {
    dispatch({ type: USER_REQUIRED_ACTIONS_SUBMITED, submittedTransactions });
  };
}

export function userRequiredActionsSubmitError() {
  return async (dispatch) => {
    dispatch({ type: USER_REQUIRED_ACTIONS_SUBMIT_ERROR });
  };
}

export function submitUserRequiredActions(items, reCaptchaToken) {
  return async (dispatch, getState) => {
    dispatch({ type: USER_REQUIRED_ACTIONS_SUBMITING });
    const { location, user } = getState();
    const { requiredActions } = user;
    const transactions = transformPendingRecurrence(items, requiredActions);
    const completedTransactions = await Promise.all(
      transactions.map(async (transaction) => {
        // create payment token
        try {
          const newPaymentResponse = await PaymentService.getPaymentId(
            location.id,
          );
          const {
            data: {
              data: {
                payment: { id: paymentID },
              },
            },
          } = newPaymentResponse;

          // add payment header to storage
          storageUtils.setVancoCampaignsPaymentHeaderStorage(
            location.id,
            newPaymentResponse.headers['vp-campaigns-payment-header'],
          );

          // add payment method to payment
          const savePaymentMethodResponse =
            await PaymentService.saveSelectedPaymentMethod(
              transaction.paymentMethod,
              paymentID,
              null,
            );

          // add payment method header to storage
          storageUtils.setVancoCampaignsPaymentHeaderStorage(
            location.id,
            savePaymentMethodResponse.headers['vp-campaigns-payment-header'],
          );

          // submit payment
          const paymentSubmitResponse = await PaymentService.submitPayment(
            paymentID,
            transaction.items,
            false,
            user.email,
            reCaptchaToken,
            { id: user.uid },
          );

          // if payment was successfull mark the included recurrences as completed
          if (
            paymentSubmitResponse.status === 200 ||
            paymentSubmitResponse.status === 201
          ) {
            const patchedRecurrencesResponses = await Promise.all(
              transaction.items.map(async (item) => {
                const { recurrenceId } = item;

                const patchResponse = await UserService.patchActionRequired(
                  recurrenceId,
                  REQUIRED_ACTIONS_PATCH_COMPLETED,
                  location.id,
                ).catch(() => ({ data: false }));
                return patchResponse?.data || false;
              }),
            );
            return {
              success: true,
              transaction: paymentSubmitResponse?.data?.data,
              included: patchedRecurrencesResponses.map(
                (response) => response.data,
              ),
            };
          }
          return {
            success: false,
            error: { failedTransactions: transaction.items },
          };
        } catch (error) {
          return {
            success: false,
            error: { failedTransactions: transaction.items },
          };
        }
      }),
    );

    dispatch(fetchUserRequiredActions(location.id));
    // if all transactions failed dispatch error
    if (completedTransactions.every((t) => !t.success)) {
      dispatch(userRequiredActionsSubmitError());
      return { success: false, completedTransactions };
    }

    // if all transactions were successful delete transactions cookie
    if (completedTransactions.every((t) => t.success)) {
      storageUtils.deleteMigratedTransactionsCookie();
      dispatch(userRequiredActionsSubmited(completedTransactions));
      return { success: true, completedTransactions };
    }
    // some transactions passed
    storageUtils.setMigratedTransactionsCookie(
      filterFailedTransactions(items, completedTransactions),
    );
    dispatch(userRequiredActionsSubmited(completedTransactions));
    return { success: true, completedTransactions };
  };
}

export function patchRequiredAction(recurrenceId, status) {
  return async (dispatch, getState) => {
    dispatch({ type: MIGRATED_TRANSACTIONS_PATCH });

    let response;
    const { location } = getState();
    try {
      dispatch({
        type: MIGRATED_TRANSACTIONS_PATCH,
        recurrenceId,
        status,
        location,
      });
      response = await UserService.patchActionRequired(
        recurrenceId,
        status,
        location.id,
      );
      if (response.status === 200 && response.data.data === true) {
        dispatch({
          type: MIGRATED_TRANSACTIONS_PATCH_COMPLETE,
          recurrenceId,
          status,
          location,
        });
      } else {
        dispatch({
          type: MIGRATED_TRANSACTIONS_PATCH_ERROR,
        });
      }
    } catch (error) {
      dispatch({ type: MIGRATED_TRANSACTIONS_PATCH_ERROR });
      throw error;
    }
    return response.data;
  };
}

export function addUserRequiredActionsPaymentMethod(newPaymentMethod) {
  return async (dispatch) => {
    dispatch({
      type: USER_REQUIRED_ACTIONS_ADD_PAYMENT_METHOD,
      newPaymentMethod,
    });
  };
}

export function actionNeededUrlRemoved() {
  return async (dispatch) => {
    dispatch({
      type: USER_REQUIRED_ACTIONS_URL_REMOVED,
    });
  };
}
