import PropTypes from 'prop-types';
import { withStyles, Typography, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import {
  getCurrencyFormat,
  getTransactionDisplayName,
} from '../../../../common/utils';
import styles from './styles';

function MobileHistoryLineItem(props) {
  const { classes, transaction, handleTransactionClick } = props;

  const handleClick = () => {
    handleTransactionClick(transaction);
  };

  return (
    <>
      <TableRow onClick={handleClick}>
        <TableCell className={classes.nameCell} align="left">
          <Typography
            className={classes.nameText}
            variant="body2"
            id="mobile-history-line-name"
          >
            {getTransactionDisplayName(transaction)}
          </Typography>
        </TableCell>
        <TableCell className={classes.amountCell} align="right" rowSpan={2}>
          <Typography variant="body2" id="mobile-history-line-amount">
            {getCurrencyFormat(transaction.amount)}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow onClick={handleClick}>
        <TableCell className={classes.dateCell}>
          <Typography id="mobile-history-line-date">
            {moment(transaction.transactionDate).format('MMMM D, YYYY')}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

MobileHistoryLineItem.propTypes = {
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  handleTransactionClick: PropTypes.func.isRequired,
};

MobileHistoryLineItem.defaultProps = {};

export default withStyles(styles)(MobileHistoryLineItem);
