import { makeStyles } from '@material-ui/core';

export const useHeaderDesktopStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'nowrap',
    color: theme?.palette?.text?.secondary,
  },
  titleColumn: {
    maxWidth: '100%',
    flexShrink: 1,
    minWidth: 0,
    paddingTop: 1,
  },
  buttonColumn: {
    maxWidth: '100%',
    flexShrink: 0,
    flexGrow: 0,
  },
  headerText: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
  },
  headerIcon: {
    marginRight: '10px',
    color: 'inherit',
  },
  headerButton: {
    marginLeft: '10px',
    color: theme?.palette?.text?.secondary,
  },
  headerButtonText: {
    width: 'auto',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    color: theme?.palette?.text?.secondary,
  },
  disabled: {
    pointerEvents: 'none',
  },
  languages: {
    display: 'flex',
    paddingLeft: '10px',
    gap: '10px',
  },
}));

export const useHeaderMobileDrawerStyles = makeStyles((theme) => ({
  subMenu: {
    paddingLeft: theme.spacing(7),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontWeight: 'bold',
    color: 'inherit',
  },
  drawerListItem: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontWeight: 'bold',
    color: 'inherit',
  },
  closeListItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    color: 'inherit',
  },
  icon: {
    color: 'inherit',
  },
}));

export default { useHeaderDesktopStyles, useHeaderMobileDrawerStyles };
