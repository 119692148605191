import * as globals from '../../common/globals';

export const savedPaymentMethodsTransformToFrontend = savedPaymentMethods => {
  return savedPaymentMethods.map(method => {
    let { paymentMethodType, brand, accountType } = method;
    if (method.paymentMethodType === 'CreditCard') {
      paymentMethodType = globals.CREDIT_CARD;
      brand = method.brand.toLowerCase();
    } else if (method.paymentMethodType === 'ACH') {
      paymentMethodType = globals.ACH;
      accountType = method.accountType.toLowerCase();
    }

    return { ...method, paymentMethodType, brand, accountType };
  });
};

export default { savedPaymentMethodsTransformToFrontend };
