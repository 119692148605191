import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

export default function CurrencyNumberFormat(props) {
  const { onChange, inputRef, ...other } = props;
  return (
    <NumericFormat
      {...other}
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
      inputMode="decimal"
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
}

CurrencyNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
