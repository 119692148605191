import { useTranslation } from 'react-i18next';
import SimpleLayout from '../../../components/common/simpleLayout/SimpleLayout';

export function GeneralError() {
  const { t } = useTranslation();

  return (
    <SimpleLayout>
      <SimpleLayout.Banner src="https://images.unsplash.com/photo-1531206715517-5c0ba140b2b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjczMTgyfQ&auto=format&fit=crop&w=1650&q=80" />
      <SimpleLayout.Title>{t('error.general.base')}</SimpleLayout.Title>
      <SimpleLayout.Content>{t('error.general.body')}</SimpleLayout.Content>
    </SimpleLayout>
  );
}

export default GeneralError;
