import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import translationEN from './locales/en/translations.json';
import translationES from './locales/es/translations.json';
import { APP_LANGUAGES, APP_LANGUAGE_COOKIE_KEY } from './common/globals';
import months from './locales/months/index.json';

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

const detection = {
  // Where to get stored language
  order: ['cookie'],

  // cookie key name
  lookupCookie: APP_LANGUAGE_COOKIE_KEY,

  // cookies config
  caches: ['cookie'],
  cookieMinutes: 60 * 24 * 20, // Set cookie expiration time to 20 days
  cookieOptions: { path: '/', sameSite: 'strict' },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: APP_LANGUAGES.ENGLISH,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    detection, // Detections options check i18next-browser-languagedetector docs
  });

const updateLanguage = () => {
  moment.updateLocale(i18n.language, months[i18n.language]);
};

i18n.on('languageChanged', updateLanguage);

updateLanguage();

export default i18n;
