import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { useParams } from 'react-router';

export function PaymentSuccess({ classes }) {
  const { redirectUrl } = useParams();
  const { t } = useTranslation();

  const redirectToApp = () => {
    window.location = atob(decodeURIComponent(redirectUrl)); // Decoding a Url safe encoded value
  };
  return (
    <div className={classes.thankYouContent}>
      <div>
        <CheckCircleOutlinedIcon className={classes.checkIcon} />
      </div>

      <Typography variant="h3" className={classes.thankYouTitle}>
        {t('titles.thankYou')}
      </Typography>
      <div>
        <Typography className={classes.transactionSuccessful}>
          {t('transactions.thankYou.successful')}
        </Typography>
        <Button
          data-testid="redirect-button"
          className={classes.backToApp}
          color="primary"
          variant="contained"
          onClick={redirectToApp}
        >
          {t('transactions.thankYou.returnToApp')}
        </Button>
        <div>
          <div className={classes.capcha}>
            <Typography variant="caption">
              {t('basket.recaptchaCaption')}{' '}
              <a
                href="https://policies.google.com/privacy"
                className={classes.captionLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('basket.privacyPolicy')}
              </a>{' '}
              {t('basket.and')}{' '}
              <a
                href="https://policies.google.com/terms"
                className={classes.captionLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('basket.termsOfService')}
              </a>{' '}
              {t('basket.apply')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

PaymentSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PaymentSuccess;
