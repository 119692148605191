import moment from 'moment';
import * as globals from '../common/globals';

export const getInitialMonth = (selectedDate, allowedDates, features) => {
  if (selectedDate) {
    return selectedDate;
  }

  const date = moment();
  let showNextMonth = moment().endOf('month').diff(date, 'days') < (features.NextDayRecurring ? 1 : 2);
  if (!showNextMonth && allowedDates) {
    showNextMonth = Math.max(...allowedDates) < date.date();
  }

  return showNextMonth ? date.add(1, 'M') : date;
};

export const getLatestAllowedDate = (date) => {
  return moment(date).endOf('day').subtract(1, 'day').add(1, 'year');
};

export const getLatestAllowedEndDate = (date) => {
  return moment(date).startOf('day').add(100, 'year');
};

export const getEarliestAllowedDate = (date, frequency, features) => {
  const earliestDay = features.NextDayRecurring ? 1 : 2;
  return moment(date).startOf('day').add(frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_NOW ? earliestDay : 0, 'day');
};

export const formatDateToServerRequest = (date) =>
  moment(date).format('YYYY-MM-DD');

// Checks a response date from server is valid and is not the default value
export const isValidResponseDate = (date) => {
  const parsedDate = moment(date);
  return parsedDate.isValid() && !parsedDate.isSame('0001-01-01T00:00:00');
};

export default {
  getInitialMonth,
  getLatestAllowedDate,
  getEarliestAllowedDate,
  getLatestAllowedEndDate,
};
