import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from './history';
import './App.css';
import TelemetryService from './api/TelemetryService';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles/react-dates-overrides.css';
import { setHexeaObject } from './actions/paymentMethod';
import Router from './components/navigation/router/Router';

export class App extends Component {
  constructor(props) {
    super(props);

    if (
      window.location.hostname !== 'localhost' &&
      window.location.hostname !== '127.0.0.1'
    ) {
      TelemetryService.initialize({ history });
    }
  }

  componentDidMount() {
    const { setHexeaObjectConnect, loc } = this.props;
    if (loc.pcct) {
      setHexeaObjectConnect(loc.pcct);
    }
  }

  componentDidUpdate(prevProps) {
    const { setHexeaObjectConnect, loc } = this.props;
    if (loc.pcct && loc.pcct !== prevProps.loc.pcct) {
      setHexeaObjectConnect(loc.pcct);
    }
  }

  render() {
    return <Router />;
  }
}

App.propTypes = {
  loc: PropTypes.object.isRequired,
  setHexeaObjectConnect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loc: state.location,
  hexea: state.paymentMethod.hexeaObject,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setHexeaObjectConnect: (pcct) => {
      dispatch(setHexeaObject(pcct));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
