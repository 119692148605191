export default (theme) => ({
  addToBasketButton: {
    fontWeight: 'bold',
    marginTop: '12px',
  },
  sidebarTitle: {
    padding: '10px 0px',
  },
  transactionCard: {
    width: '100%',
    marginTop: '25px',
  },
  form: {
    width: '100%',
  },
  submitButton: {
    float: 'right',
    marginTop: theme.spacing(6),
  },
});
