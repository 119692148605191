export default (theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  hints: {
    textAlign: 'left',
    padding: theme.spacing(2),
    border: '1px solid #BCC0C4',
    backgroundColor: '#F6F6F7',
    borderRadius: theme.spacing(1),
    maxWidth: 300,
    margin: 'auto',
    marginTop: theme.spacing(4),
  },
  verticalGutter: {
    marginTop: theme.spacing(1),
  },
  verticalGutterLarge: {
    marginTop: theme.spacing(3),
  },
  clearButton: {
    padding: 0,
    marginLeft: 5,
  },
  buttonText: {
    fontWeight: 'bold',
  },
  list: {
    paddingLeft: 25,
    marginBottom: 0,
  },
});
