import PropTypes from 'prop-types';
import { withStyles, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as globals from '../../common/globals';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  formItem: {
    marginBottom: '20px',
  },
  formItemGroup: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
});

export function ACHPaymentMethod(props) {
  const { classes } = props;

  const { t } = useTranslation();

  return (
    <Typography component="div" className={classes.container}>
      <Field
        name={`${globals.ACH}.accountNumber`}
        component={TextField}
        id="account-number"
        inputProps={{ inputMode: 'numeric' }}
        className={classes.formItem}
        label={t('accountPayment.accountNumber')}
        variant="outlined"
      />
      <Field
        name={`${globals.ACH}.routingNumber`}
        component={TextField}
        id="routing-number"
        inputProps={{ inputMode: 'numeric' }}
        className={classes.formItem}
        label={t('accountPayment.routingNumber')}
        variant="outlined"
      />
      <Field
        name={`${globals.ACH}.accountType`}
        component={TextField}
        id="account-type"
        label={t('accountPayment.accountType')}
        select
        variant="outlined"
        className={classes.formItem}
      >
        <MenuItem
          key={globals.ACCOUNT_CHECKING}
          value={globals.ACCOUNT_CHECKING}
        >
          {t('accountPayment.checking')}
        </MenuItem>
        <MenuItem key={globals.ACCOUNT_SAVINGS} value={globals.ACCOUNT_SAVINGS}>
          {t('accountPayment.savings')}
        </MenuItem>
      </Field>
    </Typography>
  );
}

ACHPaymentMethod.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ACHPaymentMethod);
