import i18next from 'i18next';
import { CXP_ERROR_CODES } from '../common/globals';

const getCxpErrorMessage = (e) => {
  const errors = e?.response?.data?.errors;
  let message;
  if (!errors || !errors.length > 0) return message;

  const { code } = errors[0];

  switch (code) {
    case CXP_ERROR_CODES.ADDRESS_AND_POSTAL_CODE_MISSMATCH: {
      message = i18next.t('errors.cxp.missmatch', {
        value: i18next.t('errors.cxp.addres.and.zip'),
      });
      break;
    }
    case CXP_ERROR_CODES.POSTAL_CODE_MISSMATCH: {
      message = i18next.t('errors.cxp.missmatch', {
        value: i18next.t('errors.cxp.zip'),
      });
      break;
    }
    case CXP_ERROR_CODES.ADDRESS_MISSMATCH: {
      message = i18next.t('errors.cxp.missmatch', {
        value: i18next.t('errors.cxp.addres'),
      });
      break;
    }
    case CXP_ERROR_CODES.INVALID_CVV: {
      message = i18next.t('errors.cxp.cvv');
      break;
    }
    default:
  }

  return message;
};

export default getCxpErrorMessage;
