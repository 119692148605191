/* eslint-disable react/function-component-definition */
import { object, string } from 'prop-types';
import { Button, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Modal from '../modal';
import {
  POCKET_PLATFORM_ORIGIN,
  TRANSACTIONS_ORIGIN,
} from '../../../common/globals';
import AuthService from '../../../api/AuthService';
import { getCurrentPathNameWithParams } from '../../../common/utils';
import useStyles from './styles';

const LoginModal = ({
  session: { loggedIn, isSessionLoaded },
  idsPayerTenant,
}) => {
  const { t: translate } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  const origin = new URLSearchParams(window.location.search).get(
    TRANSACTIONS_ORIGIN,
  );

  const isFromPocketPlatform = origin === POCKET_PLATFORM_ORIGIN;
  const shouldOpenModal = isFromPocketPlatform && isSessionLoaded && !loggedIn;

  useEffect(() => {
    if (shouldOpenModal) {
      setOpen(true);
    }
  }, [shouldOpenModal]);

  const onClose = () => {
    setOpen(false);
  };

  const pocketPlatformLogin = (isSignUp) => {
    AuthService.login(idsPayerTenant, getCurrentPathNameWithParams(), isSignUp);
    onClose();
  };
  const onLogin = () => pocketPlatformLogin(false);
  const onSignUp = () => pocketPlatformLogin(true);

  return (
    <Modal
      classes={{ closeIcon: undefined }}
      open={isOpen}
      onClose={onClose}
      className={classes.modal}
    >
      <Modal.Title className={classes.title} />
      <Modal.Content>
        <Typography variant="h3">{translate('login.modal.title')}</Typography>
        <Typography variant="body1">
          {translate('login.modal.description')}
        </Typography>

        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={onLogin}
              color="primary"
              fullWidth
            >
              {translate('app.logIn')}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.verticalGutter}>
            <Typography>{translate('login.modal.no.account')}</Typography>
            <Button
              onClick={onSignUp}
              variant="text"
              color="primary"
              disableRipple
              className={classes.signUpButton}
            >
              {translate('app.signUp')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>{translate('login.modal.or')}</Typography>
            <Button
              onClick={onClose}
              variant="text"
              disableRipple
              className={classes.continueButton}
            >
              {translate('login.modal.continue.without.account')}
            </Button>
          </Grid>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <div />
      </Modal.Actions>
    </Modal>
  );
};

LoginModal.propTypes = {
  session: object.isRequired,
  idsPayerTenant: string.isRequired,
};

export default LoginModal;
