export default function styles() {
  return {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: '32px',
      paddingLeft: '58px',
      flexDirection: 'column',
      marginBottom: '5px',
    },
    title: {
      letterSpacing: '-0.38px',
    },
  };
}
