import { isIOS, isMacOs } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from '../../actions/notifications';
import * as globals from '../../common/globals';
import copyText from '../../utils/text/copyText';

export function useSocialShareData(location, campaign, isPostCheckout) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const siteUrl = `${window.env.REACT_APP_FRONTEND_URL}/${location.id}/${
    campaign ? `campaign/${campaign.id}` : 'home'
  }`;
  const callToActionText = t(
    `socialShare.callToAction${isPostCheckout ? '.postCheckout' : ''}`,
    {
      orgName: location.parentOrgName,
      siteName: campaign?.title || location.name,
      siteUrl,
    },
  );
  const smsLink = `sms://${
    isIOS || isMacOs ? ';' : '?'
  }&body=${encodeURIComponent(`${callToActionText} ${siteUrl}`)}`;
  const onSMSClick = () => {
    window.location.href = smsLink;
  };

  const cardTitle = `${t('socialShare.header')} ${location.name}`;

  const showSuccessToast = () => {
    dispatch(
      enqueueSnackbar({
        variant: globals.NOTIFICATION_SUCCESS,
        message: t('alert.linkCopied'),
      }),
    );
  };

  const onCopy = () => {
    copyText(siteUrl);
    showSuccessToast();
  };

  return {
    siteUrl,
    callToActionText,
    onSMSClick,
    cardTitle,
    onCopy,
  };
}

export default useSocialShareData;
