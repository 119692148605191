import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { TextField, MenuItem } from '@material-ui/core';

export default function Dropdown({
  label,
  field,
  form,
  helperTextClass,
  onChange,
  options,
  ...props
}) {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;
  return (
    <TextField
      name={name}
      id={name}
      label={label}
      FormHelperTextProps={{ classes: { root: helperTextClass } }}
      select
      variant="outlined"
      disabled={isSubmitting}
      error={showError}
      helperText={showError ? fieldError : ''}
      value={field.value}
      onChange={onChange}
      {...props}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </TextField>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  helperTextClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.any,
      text: PropTypes.string,
    }),
  ).isRequired,
};

Dropdown.defaultProps = {
  label: '',
  helperTextClass: '',
};
