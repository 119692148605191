import {
  TRANSACTIONS_SCHEDULED_FETCH,
  TRANSACTIONS_SCHEDULED_COMPLETE,
  TRANSACTION_HISTORY_FETCH,
  TRANSACTION_HISTORY_COMPLETE,
  TRANSACTION_HISTORY_ERROR,
  TRANSACTIONS_SCHEDULED_ERROR,
  TRANSACTIONS_SCHEDULED_DELETE,
  TRANSACTIONS_SCHEDULED_DELETE_COMPLETE,
  TRANSACTIONS_SCHEDULED_DELETE_ERROR,
} from '../actions/actionTypes';

const initialState = {
  isFetchingTransactions: false,
  areTransactionsLoaded: false,
  isFetchingHistory: false,
  isHistoryLoaded: false,
  isDeletingTransaction: false,
  scheduledTransactions: {},
  transactionHistory: [],
};

export default function transactions(state = initialState, action = {}) {
  switch (action.type) {
    case TRANSACTIONS_SCHEDULED_FETCH:
      return {
        ...state,
        isFetchingTransactions: true,
        areTransactionsLoaded: false,
      };
    case TRANSACTIONS_SCHEDULED_COMPLETE:
      return {
        ...state,
        isFetchingTransactions: false,
        areTransactionsLoaded: true,
        scheduledTransactions: action.transactions,
      };
    case TRANSACTIONS_SCHEDULED_ERROR:
      return {
        ...state,
        isFetchingTransactions: false,
        areTransactionsLoaded: true,
        scheduledTransactions: {},
      };
    case TRANSACTION_HISTORY_FETCH:
      return { ...state, isFetchingHistory: true, isHistoryLoaded: false };
    case TRANSACTION_HISTORY_COMPLETE:
      return {
        ...state,
        isFetchingHistory: false,
        isHistoryLoaded: true,
        transactionHistory: action.history,
      };
    case TRANSACTION_HISTORY_ERROR:
      return {
        ...state,
        isFetchingHistory: false,
        isHistoryLoaded: true,
        transactionHistory: [],
      };
    case TRANSACTIONS_SCHEDULED_DELETE:
      return { ...state, isDeletingTransaction: true };
    case TRANSACTIONS_SCHEDULED_DELETE_COMPLETE:
      return { ...state, isDeletingTransaction: false };
    case TRANSACTIONS_SCHEDULED_DELETE_ERROR:
      return { ...state, isDeletingTransaction: false };
    default:
      return state;
  }
}
