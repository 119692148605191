import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import {
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  withStyles,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { createSchema } from '../../../common/validation/PaymentMethodValidationUtils';
import * as globals from '../../../common/globals';
import styles from './styles';

import MyBasket from '../../components/basket/MyBasket';
import LoggedInWrapper from '../../components/paymentMethod/LoggedInWrapper';
import { userDetailsTransformToHexeaBillingInfo } from '../../api/transform/UserDetailsTransform';
import Footer from '../footer/Footer';
import getStateAbbreviation from '../../../utils/text/getStateAbbreviation';
import ContactInformation from '../../components/contactInformation';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import PaymentSummary from '../../components/paymentSummary';
import FormButtons from '../../components/formButtons';
import SwitchAccount from '../../components/switchAccount';
import LoadingDialog from '../../components/LoadingDialog';
import { getCurrencyFormat } from '../../../common/utils';
import usePageTitle from '../../../hooks/common/usePageTitle';

function PaymentMethod({
  classes,
  fetchSessionDataConnect,
  match,
  sessionData,
  selectedPaymentType,
  loggedIn,
  supportedPaymentTypes,
  setHexeaObjectConnect,
  fetchPaymentIdConnect,
  hexea,
  paymentId,
  fetchSavedPaymentMethodsConnect,
  fetchPaymentOptionsConnect,
  enqueueSnackbarAndLogErrorConnect,
  makePaymentMethodConnect,
  getOrRenewAccessTokenConnect,
  saveUserDetailsConnect,
  savePaymentOptionsConnect,
  getFeatureFlagsConnect,
  checkStoredSessionConnect,
  endSessionConnect,
  user,
  isFetchingLocationDetails,
  isFetchingSavedMethods,
  isSavingPaymentMethod,
  isSavingUserDetails,
  paymentMethods,
  features,
  isFetchingFeatures,
  includeProcessingFee,
}) {
  const { t } = useTranslation();

  usePageTitle(t('titles.paymentMethod'));
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { vpSessionId } = useParams();
  const showPocketPlatformUI =
    features.MPPocketPlatformIntegration && isSmallScreen;

  const isPocketPlatform = sessionData?.origin === 'MP-PPM';
  const invalidData =
    features.MPPocketPlatformIntegration &&
    isPocketPlatform &&
    (!sessionData?.fundId ||
      !sessionData?.frequency ||
      !sessionData?.startDate);

  const [state, setState] = useState({
    hasScheduledItems: false,
    selectedMethod: {
      [globals.CREDIT_CARD]: '',
      [globals.ACH]: '',
    },
    selectedMethodDetails: {
      [globals.CREDIT_CARD]: null,
      [globals.ACH]: null,
    },
    enableProcessingFees: {
      [globals.CREDIT_CARD]: false,
      [globals.ACH]: false,
    },
    processingFees: {
      [globals.CREDIT_CARD]: null,
      [globals.ACH]: null,
    },
    cardInfo: {},
    paymentMethod: {},
    inStreamingApp: false,
    iframe: {},
    showErrors: false,
    canr: false,
    isIDSDialogOpen: false,
    editContactInformation: false,
    isConfirmModalOpen: false,
  });
  const totalAmount = includeProcessingFee
    ? (sessionData?.amount ?? 0) +
      (sessionData?.amount ?? 0) *
        (state.processingFees[selectedPaymentType] / 100)
    : sessionData?.amount;
  const history = useHistory();

  useEffect(() => {
    fetchSessionDataConnect(match.params.vpSessionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setHexeaObj = async () => {
      await setHexeaObjectConnect(sessionData?.pcct);
    };

    const setPaymentId = async () => {
      await fetchPaymentIdConnect(match.params.vpSessionId);
    };

    if (sessionData?.pcct) {
      setHexeaObj();

      setPaymentId();
      fetchPaymentOptionsConnect(sessionData?.pcct, state.isLoggedIn);

      let newState = {};
      if (sessionData.donationOptionsModel.enableFeeContribution) {
        newState = {
          ...state,
          canr: true,
          enableProcessingFees: {
            [globals.CREDIT_CARD]: sessionData.donationOptionsModel
              .transactionMethods.creditCard
              ? sessionData.donationOptionsModel.transactionMethods.creditCard
                  .active
              : false,
            [globals.ACH]: sessionData.donationOptionsModel.transactionMethods
              .ach
              ? sessionData.donationOptionsModel.transactionMethods.ach.active
              : false,
          },
          processingFees: {
            [globals.CREDIT_CARD]: sessionData.donationOptionsModel
              .transactionMethods.creditCard
              ? sessionData.donationOptionsModel.transactionMethods.creditCard
                  .feeValue
              : null,
            [globals.ACH]: sessionData.donationOptionsModel.transactionMethods
              .ach
              ? sessionData.donationOptionsModel.transactionMethods.ach.feeValue
              : null,
          },
        };
      } else {
        newState = {
          ...state,
          canr: true,
        };
      }
      setState({
        ...newState,
      });
      getFeatureFlagsConnect(sessionData.tenant);
    }

    if (isPocketPlatform && !invalidData && !loggedIn) {
      // Try to login from local storage
      checkStoredSessionConnect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData?.pcct]);

  useEffect(() => {
    const setSavedPayment = async () => {
      await fetchSavedPaymentMethodsConnect(sessionData?.pcct);
    };

    if (loggedIn && sessionData?.pcct) {
      setSavedPayment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, sessionData?.pcct]);

  // update payment method
  useEffect(() => {
    if (hexea) {
      setState({
        ...state,
        paymentMethod: hexea.paymentMethod(globals.CREDIT_CARD_TOKENIZE),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hexea]);

  const isUserInfoEqualSessionData =
    user &&
    sessionData &&
    sessionData.addressCity === user.address?.locality &&
    sessionData.addressState === user.address?.region &&
    sessionData.addressStreet === user.address?.street_address &&
    sessionData.addressZip === user.address.postal_code &&
    sessionData.email === user.email &&
    sessionData.firstName === user.given_name &&
    sessionData.lastName === user.family_name &&
    sessionData.phone === user.phone_number;

  const isUserInfoComplete =
    sessionData &&
    sessionData.addressCity?.length > 0 &&
    sessionData.addressState?.length > 0 &&
    sessionData.addressStreet?.length > 0 &&
    sessionData.addressZip?.length > 0 &&
    sessionData.email?.length > 0 &&
    sessionData.firstName?.length > 0 &&
    sessionData.lastName?.length > 0 &&
    sessionData.phone?.length > 0;

  const showEditInfo =
    (showPocketPlatformUI &&
      ((user.email !== '' && !isUserInfoEqualSessionData) ||
        !isUserInfoComplete)) ||
    !showPocketPlatformUI;

  useEffect(() => {
    if (!isFetchingFeatures) {
      setState({
        ...state,
        editContactInformation: showEditInfo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingFeatures, showPocketPlatformUI]);

  useEffect(() => {
    if (invalidData && sessionData && sessionData?.errorMessage !== '') {
      enqueueSnackbarAndLogErrorConnect(sessionData, {
        variant: globals.NOTIFICATION_ERROR,
        message: sessionData?.errorMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidData, sessionData?.errorMessage]);

  /**
   * Adds CXP user identification to the data we use for tokenizing
   * @param data - object containing info and options that is passed to CXP
   * @param accessToken - the access token for a user that is logged in, should be null if they aren't logged in
   * @param guestId - the id for a guest user that isn't logged in, should be null if they are logged in
   */
  // eslint-disable-next-line class-methods-use-this
  const addUserIdentification = (data, accessToken, guestId) => {
    const result = { ...data };
    if (accessToken) {
      result.userToken = accessToken;
    } else {
      result.partnerPayerRef = guestId;
    }
    return result;
  };

  /**
   * if the user isn't logged in, this creates a representation for them in the backend and IDS, and saves their details to it.
   * if they are logged in, this makes sure their access token is still valid and doesn't save any new information.
   * @param values - formik values passed to the submit function
   * @returns {Promise<*[]>} - Promise resolves to an array containing accessToken followed by guestId. one of the two should be null.
   */
  const saveDetailsAndGetIdentifier = async (values) => {
    let accessToken = null;
    let guestId = null;
    let userDetailsResponse = null;

    if (!loggedIn || !user.detailsComplete) {
      try {
        userDetailsResponse = await saveUserDetailsConnect(
          values.billingInfo,
          sessionData.tenant,
          match.params.vpSessionId,
        );
      } catch (e) {
        // TODO: This will need to be updated in the future when/if we go with the errors array pattern for the backend
        if (e?.response?.data?.message) {
          e.message = e.response.data.message;
        } else {
          e.message = t('errors.guestUserBackend');
        }
        throw e;
      }
    }

    if (!loggedIn) {
      guestId = userDetailsResponse.id;
    } else {
      accessToken = await getOrRenewAccessTokenConnect(); // we should try to renew the access token here since if they've been sitting on the checkout page it could be expired.
      if (!accessToken) {
        // this means the user was previously logged in, but isn't now. The page should already be about to reload at this point, but we'll error out of the submit just in case.
        throw new Error('session is expired');
      }
    }
    return [accessToken, guestId];
  };

  const handleExistingPaymentSubmit = async () => {
    const { selectedMethodDetails } = state;

    let transactionType = 'ACH_EFT';
    let transactionMethod = 'ach';

    if (selectedPaymentType === globals.CREDIT_CARD) {
      transactionType = 'CREDIT_DEBIT';
      transactionMethod = selectedMethodDetails[selectedPaymentType].brand;
    }

    // all payment and user information is gathered within the selected method details
    const { uuid } = selectedMethodDetails[selectedPaymentType];

    const { success, transactionId } = await makePaymentMethodConnect(
      selectedMethodDetails[selectedPaymentType],
      paymentId,
      uuid,
      includeProcessingFee,
    );
    // update to ensure its based on actual platform
    const redirectUrl = `${sessionData?.shimReturnUrl}&success=${success}&transactionid=${transactionId}&transactionType=${transactionType}&transactionMethod=${transactionMethod}`;
    if (sessionData && isPocketPlatform) {
      history.push(`/payment-success/${encodeURIComponent(btoa(redirectUrl))}`); // Url safe encoding
    } else {
      window.location = redirectUrl;
    }
  };

  const handleNewPaymentSubmit = async (values) => {
    const { cardInfo, paymentMethod } = state;

    const userDetails = {
      email: values.billingInfo.email,
      firstName: values.billingInfo.firstName,
      lastName: values.billingInfo.lastName,
      addressLine1: values.billingInfo.addressLine1,
      addressLine2: values.billingInfo.addressLine2,
      city: values.billingInfo.city,
      state: values.billingInfo.state,
      zipCode: values.billingInfo.zipCode,
    };

    const [accessToken, guestId] = await saveDetailsAndGetIdentifier({
      ...values,
      billingInfo: {
        ...values.billingInfo,
        email: userDetails.email,
      },
    });

    const billingData = addUserIdentification(
      userDetailsTransformToHexeaBillingInfo(user, values, selectedPaymentType),
      accessToken,
      guestId,
    );

    const tokenizeDetails = await hexea.tokenize(
      selectedPaymentType === globals.ACH
        ? hexea.paymentMethod('echeck')
        : paymentMethod,
      billingData,
    );

    const { error } = tokenizeDetails;

    // guestId should only be non-null here if the user is logged in
    if (!error) {
      const paymentMethodInfo = { ...tokenizeDetails.paymentMethod };

      let transactionType = 'ACH_EFT';
      let transactionMethod = 'ach';

      if (selectedPaymentType === globals.CREDIT_CARD) {
        paymentMethodInfo.cardInfo = cardInfo;
        transactionType = 'CREDIT_DEBIT';
        transactionMethod = cardInfo.cardBrand;
      }

      const { success, transactionId } = await makePaymentMethodConnect(
        paymentMethodInfo,
        paymentId,
        guestId,
        includeProcessingFee,
      );
      const redirectUrl = `${sessionData?.shimReturnUrl}&success=${success}&transactionid=${transactionId}&transactionType=${transactionType}&transactionMethod=${transactionMethod}`;
      if (sessionData && isPocketPlatform) {
        history.push(
          `/payment-success/${encodeURIComponent(btoa(redirectUrl))}`,
        ); // Url safe encoding
      } else {
        window.location = redirectUrl;
      }
    } else {
      // the error returned from tokenize is just an object, we need to turn it into an actual error
      // if we don't label it as being from CXP tokenize, there's no way to tell the error came from there in telemetry.
      throw new Error(error.detailed_error_message || error.message);
    }
  };

  const handleSubmit = async (values, actions) => {
    const { infoValidity, selectedMethod } = state;

    if (
      // a previously saved payment method is selected
      selectedMethod[selectedPaymentType] &&
      selectedMethod[selectedPaymentType] !== globals.NEW_PAYMENT_METHOD
    ) {
      await handleExistingPaymentSubmit();
    } else if (
      // card or ach are selected as the payment type, and card info in CXP form fields is valid if selected
      (selectedPaymentType === globals.CREDIT_CARD && infoValidity?.cardInfo) ||
      selectedPaymentType === globals.ACH
    ) {
      await handleNewPaymentSubmit(values);
    }

    actions.setSubmitting(false);
  };

  const handlePaymentTypeChange = (event, value) => {
    // updates selectedPaymentType
    savePaymentOptionsConnect({
      paymentType: value,
      applyProcessingFee: false,
      processingFees: value === globals.CREDIT_CARD ? 1 : 2,
    });

    if (value === globals.CREDIT_CARD) {
      setState({
        ...state,
        paymentMethod: hexea.paymentMethod(globals.CREDIT_CARD_TOKENIZE),
      });
    }
  };

  const handleSelectedMethodChange = (type) => (id, details) => {
    setState({
      ...state,
      selectedMethod: { ...state.selectedMethod, [type]: id },
      selectedMethodDetails: {
        ...state.selectedMethodDetails,
        [type]: details,
      },
      paymentMethod: hexea.paymentMethod(globals.CREDIT_CARD_TOKENIZE),
    });
  };

  const handleInputChange = (node) => (name, value) => {
    if (name === undefined || value === undefined) {
      return;
    }
    setState({
      ...state,
      [node]: {
        ...state[node],
        [name]: value,
      },
    });
  };

  const handleValidityChange = (child) => (valid) => {
    setState({
      ...state,
      infoValidity: { [child]: valid },
    });
  };

  const onSubmitButtonClick = () => {
    setState({
      ...state,
      showErrors: true,
    });
  };

  const onGoBack = () => {
    setState({
      ...state,
      isConfirmModalOpen: true,
    });
  };

  const onCancelGoBack = () => {
    setState({
      ...state,
      isConfirmModalOpen: false,
    });
  };

  const onConfirmGoBack = () => {
    onCancelGoBack();
    window.location.href = sessionData?.cancelUrl;
  };

  const toggleIDSDialog = () => {
    setState({ ...state, isIDSDialogOpen: !state.isIDSDialogOpen });
  };

  const logOut = () => {
    endSessionConnect(vpSessionId);
  };

  const isDoingWork = () => {
    const res =
      isFetchingLocationDetails ||
      isFetchingSavedMethods ||
      isSavingPaymentMethod ||
      isSavingUserDetails;
    return res;
  };

  // contact information is required when initially adding a payment methods
  // so validation will not execute when
  // there are existing payment methods
  // user is a adding an additional payment method
  // validation should always execute when the user is not logged in
  const getValidationSchema = () => {
    let validationSchema;

    if (loggedIn && paymentMethods.length > 0) {
      return validationSchema;
    }

    if (
      !loggedIn ||
      (selectedPaymentType === globals.CREDIT_CARD &&
        (state.selectedMethod[selectedPaymentType] === '' ||
          state.selectedMethod[selectedPaymentType] !==
            globals.NEW_PAYMENT_METHOD))
    ) {
      validationSchema = createSchema(
        selectedPaymentType,
        state.selectedMethod[selectedPaymentType] ===
          globals.NEW_PAYMENT_METHOD,
        {
          loggedIn,
          cardBrand: state.cardInfo.cardBrand,
          profileComplete: user && user.detailsComplete,
          hasScheduledItems: state.hasScheduledItems,
        },
      );
    }

    return validationSchema;
  };

  const getUserDisplayName = () => {
    const userName = user.detailsComplete
      ? user.given_name
      : sessionData?.firstName;
    return userName;
  };

  const onEditContactInformation = () =>
    setState({
      ...state,
      editContactInformation: true,
    });

  const onApplyProcessingFees = (event) => {
    savePaymentOptionsConnect({
      paymentType: selectedPaymentType,
      applyProcessingFee: event.target.checked,
      processingFee: state.processingFees[selectedPaymentType],
    });
  };

  // While rendering this page depends on a FF this should be done.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isFetchingFeatures) return <></>;

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            await handleSubmit(values, actions);
          } catch (error) {
            enqueueSnackbarAndLogErrorConnect(error, {
              variant: globals.NOTIFICATION_ERROR,
              message: error.message,
            });
            actions.setSubmitting(false);
          }
        }}
        initialValues={{
          [globals.CREDIT_CARD]: {
            zipCode: '',
          },
          // values from the fields in the ACHPaymentMethod component
          [globals.ACH]: {
            accountNumber: '',
            routingNumber: '',
            accountType: globals.ACCOUNT_CHECKING,
            nacha: false,
          },
          // values from the fields in the BillingInfo component
          billingInfo: {
            firstName: sessionData?.firstName || '',
            lastName: sessionData?.lastName || '',
            addressLine1: sessionData?.addressStreet || '',
            addressLine2: sessionData?.addressStreet2 || '',
            city: sessionData?.addressCity || '',
            zipCode: sessionData?.addressZip || '',
            state: getStateAbbreviation(sessionData?.addressState) || '',
            saveMethod: loggedIn && state.hasScheduledItems,
            email: sessionData?.email || '',
            phoneNumber: sessionData?.phone || '',
          },
        }}
        validationSchema={getValidationSchema()}
      >
        {(formikProps) => (
          <Form>
            <div className={classes.paymentMethodRoot}>
              {isDoingWork() && (
                <div className={classes.progressHolder}>
                  <LinearProgress
                    id="payment-progress"
                    data-testid="payment-progress"
                    className={classes.paymentProgress}
                  />
                </div>
              )}
              {sessionData && (
                <div className={classes.paymentMethod}>
                  {showPocketPlatformUI ? (
                    <div>
                      <Typography variant="h4">
                        {sessionData.dbaName}
                      </Typography>
                    </div>
                  ) : (
                    <div
                      id="payment-method-title"
                      data-testid="payment-method-title"
                      className={classes.paymentMethodTitle}
                    >
                      <Typography variant="h2">
                        {t('mpPayment.title')}
                      </Typography>
                    </div>
                  )}

                  <div
                    className={`${classes.paymentMethodTitle} ${
                      showPocketPlatformUI ? classes.horizontalGutter : ''
                    }`}
                  >
                    {sessionData && (
                      <Typography variant="h6">
                        {t('mpPayment.titleUserGreeting', {
                          userName: getUserDisplayName(),
                        })}
                      </Typography>
                    )}

                    {!showPocketPlatformUI && (
                      <Typography>
                        {t('mpPayment.titleDescription', {
                          dbaName: sessionData?.dbaName ?? '',
                        })}
                      </Typography>
                    )}
                  </div>

                  {showPocketPlatformUI && (
                    <PaymentSummary
                      paymentData={sessionData}
                      totalAmount={totalAmount}
                      fees={{
                        enableProcessingFees:
                          state.enableProcessingFees[selectedPaymentType],
                        includeProcessingFee,
                        processingFee:
                          state.processingFees[selectedPaymentType],
                        onApplyProcessingFees,
                      }}
                    />
                  )}

                  <div
                    className={`${classes.paymentMethodSubTitle} ${
                      showPocketPlatformUI ? classes.horizontalGutter : ''
                    }`}
                  >
                    <Typography variant="h6">
                      {t('mpPayment.loginSection.title')}
                    </Typography>
                  </div>

                  {features.MPPocketPlatformIntegration && loggedIn && (
                    <SwitchAccount user={user} onAction={logOut} isLogOut />
                  )}

                  <div
                    className={[
                      classes.paymentMethodTitle,
                      classes.paymentMethodJoin,
                    ].join(' ')}
                  >
                    {!loggedIn && (
                      <>
                        <Typography
                          style={{ marginRight: '5px' }}
                          display="inline"
                          color="primary"
                          className={classes.button}
                          onClick={toggleIDSDialog}
                        >
                          {t('mpPayment.loginSection.login')}
                        </Typography>
                        <Typography display="inline">
                          {t('mpPayment.loginSection.description')}
                        </Typography>
                      </>
                    )}

                    {loggedIn && paymentMethods.length < 1 && (
                      <Typography>
                        {t('mpPayment.loginSection.noPaymentMethods')}
                      </Typography>
                    )}
                  </div>
                  {selectedPaymentType && (
                    <>
                      <Tabs
                        value={selectedPaymentType}
                        onChange={handlePaymentTypeChange}
                        indicatorColor="primary"
                      >
                        {supportedPaymentTypes.creditCard && (
                          <Tab
                            value={globals.CREDIT_CARD}
                            label={t(
                              supportedPaymentTypes.creditCard.debitOnly
                                ? 'payment.debitCard'
                                : 'payment.creditOrDebitCard',
                            )}
                          />
                        )}
                        {supportedPaymentTypes.ach && (
                          <Tab
                            value={globals.ACH}
                            label={t('transactions.history.bankAccount')}
                          />
                        )}
                      </Tabs>
                      {hexea && (
                        <LoggedInWrapper
                          id="logged-in-wrapper"
                          selectedPaymentType={selectedPaymentType}
                          selectedMethod={state.selectedMethod}
                          setSelected={handleSelectedMethodChange}
                          onUpdate={handleInputChange}
                          setValid={handleValidityChange}
                          showErrors={state.showErrors}
                          cardInfo={state.cardInfo}
                          paymentMethod={state.paymentMethod}
                          supportedPaymentTypes={supportedPaymentTypes}
                          formikProps={formikProps}
                          isIDSDialogOpen={state.isIDSDialogOpen}
                          toggleIDSDialog={toggleIDSDialog}
                          editContactInformation={state.editContactInformation}
                          features={features}
                        />
                      )}
                    </>
                  )}
                  {!state.editContactInformation && (
                    <ContactInformation
                      contactData={formikProps.values.billingInfo}
                      onEdit={onEditContactInformation}
                    />
                  )}
                </div>
              )}
              {sessionData && !showPocketPlatformUI && (
                <MyBasket
                  pcct={sessionData?.pcct}
                  onSubmit={onSubmitButtonClick}
                  isSubmit
                  isSubmitting={formikProps.isSubmitting}
                  showSecurePaymentsMessage
                  submitButtonText={`${t(
                    'mpbasket.submit',
                  )} ${getCurrencyFormat(totalAmount)}`}
                  enableProcessingFees={
                    state.enableProcessingFees[selectedPaymentType]
                  }
                  processingFee={state.processingFees[selectedPaymentType]}
                  selectedPaymentType={selectedPaymentType}
                  iframe={state.iframe}
                  sessionData={sessionData}
                />
              )}
              {sessionData && showPocketPlatformUI && (
                <>
                  <FormButtons
                    onBack={isPocketPlatform ? onGoBack : undefined}
                    onSubmit={onSubmitButtonClick}
                    hasErrors={invalidData}
                  />

                  <div className={classes.secureMessage}>
                    <LockOutlinedIcon />
                    <Typography variant="caption">
                      {t('mpbasket.paymentsProcessedSecurely')}
                    </Typography>
                  </div>

                  <ConfirmationDialog
                    title={t(
                      'transactions.scheduled.cancel.confirmation.title',
                    )}
                    message={t('mpPayment.confirm.message')}
                    submitButtonText={t('common.yes')}
                    cancelButtonText={t('common.cancel')}
                    onSubmit={onConfirmGoBack}
                    onClose={onCancelGoBack}
                    isOpen={state.isConfirmModalOpen}
                  />
                </>
              )}
              <LoadingDialog
                isLoading={
                  (showPocketPlatformUI && formikProps.isSubmitting) || false
                }
                message={t('common.processing')}
              />
            </div>
          </Form>
        )}
      </Formik>
      {sessionData && !showPocketPlatformUI && <Footer />}
    </>
  );
}

PaymentMethod.propTypes = {
  classes: PropTypes.object.isRequired,
  sessionData: PropTypes.object,
  match: PropTypes.object.isRequired,
  selectedPaymentType: PropTypes.string,
  loggedIn: PropTypes.bool.isRequired,
  supportedPaymentTypes: PropTypes.object,
  hexea: PropTypes.object,
  paymentId: PropTypes.string,
  fetchSessionDataConnect: PropTypes.func.isRequired,
  setHexeaObjectConnect: PropTypes.func.isRequired,
  fetchPaymentIdConnect: PropTypes.func.isRequired,
  fetchPaymentOptionsConnect: PropTypes.func.isRequired,
  fetchSavedPaymentMethodsConnect: PropTypes.func.isRequired,
  enqueueSnackbarAndLogErrorConnect: PropTypes.func.isRequired,
  saveUserDetailsConnect: PropTypes.func.isRequired,
  savePaymentOptionsConnect: PropTypes.func.isRequired,
  getOrRenewAccessTokenConnect: PropTypes.func.isRequired,
  makePaymentMethodConnect: PropTypes.func.isRequired,
  getFeatureFlagsConnect: PropTypes.func.isRequired,
  checkStoredSessionConnect: PropTypes.func.isRequired,
  endSessionConnect: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isFetchingLocationDetails: PropTypes.bool.isRequired,
  isFetchingSavedMethods: PropTypes.bool.isRequired,
  isSavingPaymentMethod: PropTypes.bool.isRequired,
  isSavingUserDetails: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  features: PropTypes.object.isRequired,
  isFetchingFeatures: PropTypes.bool.isRequired,
  includeProcessingFee: PropTypes.bool.isRequired,
};

PaymentMethod.defaultProps = {
  sessionData: null,
  selectedPaymentType: globals.CREDIT_CARD,
  supportedPaymentTypes: {
    creditCard: {
      allowedCardBrands: ['visa', 'mastercard', 'amex', 'discover'],
      enableFeeContribution: true,
      feeValue: 1,
      feeValueType: 'Percentage',
    },
    ach: {
      enableFeeContribution: true,
      feeValue: 2,
      feeValueType: 'Percentage',
    },
  },
  hexea: null,
  paymentId: null,
};

export default withStyles(styles)(PaymentMethod);
