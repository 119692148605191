import { withStyles } from '@material-ui/core';
import PaymentSuccess from './PaymentSuccess';

const styles = (theme) => ({
  thankYouContent: {
    margin: '116px 200px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
  },
  checkIcon: {
    color: theme.palette.primary.main,
    height: '84px',
    width: '84px',
    marginTop: '40px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  captionLink: {
    color: theme.palette.primary.main,
  },
  transactionSuccessful: {
    marginTop: '20px',
    fontSize: '20px',
  },
  thankYouTitle: {
    marginTop: '50px',
    fontWeight: theme.typography.fontWeightExtraBold,
  },
  capcha: {
    marginTop: '40px',
    marginLeft: '10%',
    marginRight: '10%',
  },
  backToApp: {
    marginTop: '140px',
  },
  [theme.breakpoints.down('sm')]: {
    thankYouContent: {
      height: '100%',
      margin: '0 6vw',
      justifyContent: 'center',
      paddingBottom: '64px',
    },
  },
});

export default withStyles(styles)(PaymentSuccess);
