import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  withStyles,
  Button,
} from '@material-ui/core';

import styles from './styles';

export function DialogContentWrapper(props) {
  const { title, message, onClose, classes } = props;

  return (
    <>
      <DialogTitle id="client-dialog-title" className={classes.header}>
        {title}
      </DialogTitle>
      <DialogContent
        id="client-dialog-content"
        aria-describedby="client-dialog-message"
        className={classes.content}
      >
        <Typography
          variant="body1"
          id="client-dialog-message"
          className={classes.message}
        >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={onClose}
          className={classes.button}
          color="primary"
          data-testid="client-dialog-wrapper-button"
        >
          OK
        </Button>
      </DialogActions>
    </>
  );
}

DialogContentWrapper.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

DialogContentWrapper.defaultProps = {
  title: null,
  message: null,
};

export default withStyles(styles)(DialogContentWrapper);
