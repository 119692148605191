import USStates from '../../components/paymentMethod/usStates';

/**
 * Obtain the abbreviated state value expected by cxp
 * @param addressState - the state name or abbreviation
 */
export default (addressState) => {
  let expectedStateValue;

  USStates.forEach((usState) => {
    if (
      usState.name.toUpperCase() === addressState?.toUpperCase() ||
      usState.abbreviation.toUpperCase() === addressState?.toUpperCase()
    ) {
      expectedStateValue = usState.abbreviation;
    }
  });

  return expectedStateValue;
};
