import { node } from 'prop-types';

import { useSimpleLayoutContext } from './context/SimpleLayoutContext';

export function SimpleLayoutContent ({ children }) {
  const { classes } = useSimpleLayoutContext();
  return <div className={classes.body}>{children}</div>;
}

SimpleLayoutContent.propTypes = {
  children: node,
};

SimpleLayoutContent.defaultProps = {
  children: '',
};

export default SimpleLayoutContent;
