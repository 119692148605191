import { useState } from 'react';

const useBasketLoginDialog = () => {
  const [isIDSDialogOpen, setIsIDSDialogOpen] = useState(false);

  const toggleIDSDialog = () =>
    setIsIDSDialogOpen((prevIsIDSDialogOpen) => !prevIsIDSDialogOpen);

  const closeIDSDialog = () => setIsIDSDialogOpen(false);

  return {
    isIDSDialogOpen,
    toggleIDSDialog,
    closeIDSDialog,
  };
};

export default useBasketLoginDialog;
