// cookieStorage is a useful fallback to ensure that the store will function
// However, stored values will not persist when the browser navigates to
// a new page or reloads the current page.

export function getItem(key: string) {
  let result = document.cookie.match(new RegExp(key + '=([^;]+)'));
  const value = result && (result = JSON.parse(result[1]));
  return value;
}

export function setItem(
  key: string,
  data: {
    nonce?: string;
    appState?: unknown;
    state?: string;
    codeVerifier?: string;
    exchangeAuthCode?: boolean;
    deleted?: boolean;
  }
) {
  const date = new Date();
  date.setTime(date.getTime() + 86400);
  document.cookie =
    key + '=' + JSON.stringify(data) + '; expires=' + date.toUTCString();
}

export function removeItem(key: string) {
  document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
}

const cookies = {
  getItem: getItem,
  setItem: setItem,
  removeItem: removeItem,
};

export default cookies;
