import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  outerBox: {
    height: '0',
    overflow: 'hidden',
    paddingTop: props => props.height,
    position: 'relative',
  },
  innerContent: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
});

export function AspectRatioBox({ classes, children }) {
  return (
    <div className={classes.outerBox}>
      <div className={classes.innerContent}>{children}</div>
    </div>
  );
}

AspectRatioBox.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  height: PropTypes.string.isRequired,
};

export default withStyles(styles)(AspectRatioBox);
