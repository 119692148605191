import i18next from 'i18next';
import moment from 'moment';
import * as Yup from 'yup';
import * as globals from '../globals';

const createMigratedPaymentsSchema = (transactionItems) => {
  return transactionItems.reduce((map, item) => {
    const newMap = map;
    newMap[item.fundId] = Yup.object().shape({
      selectedCampaign: Yup.string().required().nullable(),
      paymentToken: Yup.string().required().nullable(),
      amount: Yup.string()
        .required()
        .test(
          'isAmountTooLow',
          i18next.t('formValidation.amountTooLow'),
          (value) => {
            return parseFloat(value) >= globals.MIN_TRANSACTION_AMOUNT;
          },
        ),
      frequency: Yup.string().required(),
      startDate: Yup.object()
        .nullable()
        .test('isRequired', i18next.t('formValidation.required'), (value) => {
          return !!value;
        })
        .test(
          'isScheduled',
          i18next.t('formValidation.selectNewDate'),
          function test(value) {
            const today = moment()
              .hour(12)
              .minutes(0)
              .seconds(0)
              .milliseconds(0);

            let valid = true;
            let message = '';

            // Note: we need to return true if value is falsey so that it fails the required validation
            if (
              value &&
              this.parent.frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_NOW
            ) {
              if (value.isBefore(today) || !value.isAfter(today)) {
                valid = false;
                message = i18next.t('formValidation.selectNewDate');
              }
            }

            return valid || this.createError({ message });
          },
        )
        .test(
          'isDateOverOneYear',
          i18next.t('formValidation.selectLessThanOneYear'),
          (value) => {
            return value
              ? value.isBefore(
                  moment()
                    .add(1, 'year')
                    .hour(12)
                    .minutes(0)
                    .seconds(0)
                    .milliseconds(0),
                )
              : true;
          },
        ),
    });
    return newMap;
  }, {});
};

export const createSchema = (transactionItems) => {
  Yup.setLocale({
    mixed: {
      required: `${i18next.t('formValidation.required')}`,
    },
  });

  return transactionItems
    ? Yup.object().shape(createMigratedPaymentsSchema(transactionItems))
    : Yup.object();
};

export default createSchema;
