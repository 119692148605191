import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';

export default function PhoneNumberFormat(props) {
  const { value, ...other } = props;
  return (
    <PatternFormat
      {...other}
      value={value}
      displayType="text"
      valueIsNumericString
      format="(###) ###-####"
    />
  );
}

PhoneNumberFormat.propTypes = {
  value: PropTypes.string.isRequired,
};
