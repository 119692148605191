export class ErrorResponse<TAppState = unknown> {
  constructor(error: string, description: string, appState: TAppState = null) {
    this.error = error;
    this.errorDescription = description;
    this.appState = appState;
  }

  error: string;
  errorDescription: string;
  state?: string;
  sessionState?: string;
  appState?: TAppState;
}

export function buildResponse<TAppState = unknown>(
  error: string,
  description: string,
  appState: TAppState = null
): ErrorResponse {
  return new ErrorResponse<TAppState>(error, description, appState);
}

export function invalidToken<TAppState = unknown>(
  description: string,
  appState: TAppState = null
) {
  return buildResponse<TAppState>('invalid_token', description, appState);
}

export function ConfigurationError(message: string) {
  return buildResponse('ConfigurationError', message || '');
}

export function TokenValidationError(message: string) {
  return buildResponse('TokenValidationError', message || '');
}
