import { Grid, IconButton, ListItem, ListItemIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import spanishFlag from '../../../images/spanish.png';
import englishFlag from '../../../images/english.png';
import { APP_LANGUAGES } from '../../../common/globals';

function LanguageToggle({ classes, isMobileView }) {
  const { t, i18n } = useTranslation();
  const toggleLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  if (isMobileView) {
    return (
      <>
        <ListItem
          button
          onClick={() => toggleLanguage(APP_LANGUAGES.ENGLISH)}
          className={classes.drawerListItem}
        >
          <ListItemIcon>
            <img src={englishFlag} alt="english-flag" />
          </ListItemIcon>
          {t('app.language.en')}
        </ListItem>
        <ListItem
          button
          onClick={() => toggleLanguage(APP_LANGUAGES.SPANISH)}
          className={classes.drawerListItem}
        >
          <ListItemIcon>
            <img src={spanishFlag} alt="spanish-flag" />
          </ListItemIcon>
          {t('app.language.es')}
        </ListItem>
      </>
    );
  }
  return (
    <Grid item className={classes.languages}>
      <IconButton
        size="small"
        onClick={() => toggleLanguage(APP_LANGUAGES.ENGLISH)}
        aria-label="english"
      >
        <img src={englishFlag} alt="english-flag" />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => toggleLanguage(APP_LANGUAGES.SPANISH)}
        aria-label="spanish"
      >
        <img src={spanishFlag} alt="spanish-flag" />
      </IconButton>
    </Grid>
  );
}

LanguageToggle.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobileView: PropTypes.bool,
};

LanguageToggle.defaultProps = {
  isMobileView: false,
};

export default LanguageToggle;
