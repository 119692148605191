export default function styles(theme) {
  return {
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    switchButton: {
      marginRight: theme.spacing(2),
      fontWeight: 'bold',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    name: {
      fontWeight: 'bold',
    },
  };
}
