import i18next from 'i18next';
import * as Yup from 'yup';

import { hasValidCharsOnly } from '../../utils/text/getSupportedSpecialChars';
import { isValid } from '../../utils/text/stringValidation';

const createOpenAmountSchema = transactionItems => {
  return transactionItems.reduce((map, item) => {
    const newMap = map;
    newMap[item.fundId] = Yup.object().shape({
      memoLine: Yup.string().test(
        'memoLineIsRequired',
        value => {
          return !isValid(value?.value)
            ? i18next.t('formValidation.required')
            : i18next.t('errors.invalidChar');
        },
        value => {
          // no validation is required
          if (!item.memoLine?.showTextField) return true;

          // always check if text is valid
          const isValidValue = isValid(value);

          // if its not required then text is not required either
          if (!isValidValue) {
            return !item.memoLine?.responseRequired;
          }

          // always run char restriction verification
          // optional and required text must pass the char restriction
          return hasValidCharsOnly(value);
        },
      ),
    });
    return newMap;
  }, {});
};

export const createSchema = transactionItems => {
  Yup.setLocale({
    mixed: {
      required: `${i18next.t('formValidation.required')}`,
    },
  });

  return transactionItems
    ? Yup.object().shape(createOpenAmountSchema(transactionItems))
    : Yup.object();
};

export default createSchema;
