import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: 14,
    padding: '4px 8px',
    backgroundColor: '#424248',
    position: 'relative',
    bottom: 15,
    left: 15,
  },
})(Tooltip);

export default CustomTooltip;
