import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import i18next from 'i18next';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CxpPaymentMethodFormField from '../cxpPaymentMethodFormField/CxpPaymentMethodFormField';
import * as FormUtils from './FormUtils';
import * as globals from '../../common/globals';
import useFeatures from '../../hooks/common/useFeatures';
import SecurityCodeGuidance from './components/SecurityCodeGuidance';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  formItemGroup: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  inlineFormItem: {
    marginTop: '20px',
    flex: '1', // was 1 1 0, changed to fix IE display bug
    '&:not(:first-child)': {
      marginLeft: '16px',
    },
    minHeight: '56px',
  },
  [theme.breakpoints.down('xs')]: {
    inlineFormItem: {
      marginTop: '25px',
      '&:not(:first-child)': {
        marginLeft: '0',
      },
      minHeight: '56px', // fix for flex iOS fields shrinking
    },
    formItemGroup: {
      flexDirection: 'column',
    },
  },
});

const initialState = {
  validationMap: {
    cardNumber: false,
    cardExpiration: false,
    cardVerificationValue: false,
  },
  helperTextMap: {
    cardNumber: i18next.t('formValidation.required'),
    cardExpiration: i18next.t('formValidation.required'),
    cardVerificationValue: i18next.t('formValidation.required'),
  },
  isDirtyMap: {
    cardNumber: false,
    cardExpiration: false,
    cardVerificationValue: false,
  },
  isBlurredMap: {
    cardNumber: true,
    cardExpiration: true,
    cardVerificationValue: true,
  },
};
export function CreditCardPaymentMethod({
  classes,
  paymentMethod,
  showErrors,
  onUpdate,
  setValid,
}) {
  const [state, setState] = useState(initialState);
  const [cardType, setCardType] = useState(globals.CARD_TYPE_AMEX);
  const { t } = useTranslation();
  const { CVV: showCVV, hasLoadedFeatures } = useFeatures();

  // Pass CVV validation when FF is OFF. Remove this useEffect on CVV FF clean up.
  useEffect(() => {
    if (hasLoadedFeatures && !showCVV) {
      setState((prevState) => {
        return {
          ...prevState,
          validationMap: {
            ...prevState.validationMap,
            cardVerificationValue: true,
          },
        };
      });
    }
  }, [hasLoadedFeatures, showCVV]);

  const handleCxpFieldChange = (field) => (event) => {
    let isValid = true;
    let errorMessage = '';

    // set is dirty map for this field
    if (!state.isDirtyMap[field]) {
      setState((prevState) => {
        return {
          ...prevState,
          isDirtyMap: { ...prevState.isDirtyMap, [field]: true },
        };
      });
    }

    // check event.error and set validationMap and helperTextMap if error exists
    if (event) {
      // if field === cardNumber, set cardBrand in state for cvv validation
      if (field === 'cardNumber' && event.brand !== cardType) {
        onUpdate('cardBrand', event.brand);
        setCardType(
          event.brand === 'unknown' ? globals.CARD_TYPE_AMEX : event.brand,
        );
      }

      if (event.empty) {
        isValid = false;
        errorMessage = t('formValidation.required');
      }

      if (event.error) {
        isValid = false;
        errorMessage = t(event.error.message);
      }
    }

    setState((prevState) => {
      return {
        ...prevState,
        validationMap: { ...prevState.validationMap, [field]: isValid },
        helperTextMap: {
          ...prevState.helperTextMap,
          [field]: errorMessage,
        },
      };
    });
  };

  const handleCxpFieldBlur = (field) => () => {
    setState((prevState) => {
      return {
        ...prevState,
        isBlurredMap: {
          ...prevState.isBlurredMap,
          [field]: true,
        },
      };
    });
  };

  useEffect(
    () => setValid(FormUtils.validateAll(state.validationMap)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.validationMap],
  );

  const handleCxpFieldFocus = (field) => () => {
    setState((prevState) => {
      return {
        ...prevState,
        isBlurredMap: {
          ...prevState.isBlurredMap,
          [field]: false,
        },
      };
    });
  };

  const { validationMap, isDirtyMap, helperTextMap, isBlurredMap } = state;

  return (
    <Typography component="div" className={classes.container}>
      {paymentMethod && (
        <CxpPaymentMethodFormField
          field="cardNumber"
          id="card-number"
          className={classes.formItem}
          label={t('cardPayment.cardNumber')}
          paymentMethod={paymentMethod}
          onChange={(e) => handleCxpFieldChange('cardNumber')(e)}
          onBlur={(e) => handleCxpFieldBlur('cardNumber')(e)}
          onFocus={(e) => handleCxpFieldFocus('cardNumber')(e)}
          error={
            !validationMap.cardNumber &&
            ((isDirtyMap.cardNumber && isBlurredMap.cardNumber) || showErrors)
          }
          helperText={
            !validationMap.cardNumber &&
            ((isDirtyMap.cardNumber && isBlurredMap.cardNumber) || showErrors)
              ? helperTextMap.cardNumber
              : ''
          }
          data-testid="cardNumber-field"
        />
      )}
      <span className={classes.formItemGroup}>
        {paymentMethod && (
          <CxpPaymentMethodFormField
            field="cardExpiration"
            id="card-exp"
            className={classes.inlineFormItem}
            label={t('cardPayment.expDate')}
            paymentMethod={paymentMethod}
            onChange={(e) => handleCxpFieldChange('cardExpiration')(e)}
            onBlur={(e) => handleCxpFieldBlur('cardExpiration')(e)}
            onFocus={(e) => handleCxpFieldFocus('cardExpiration')(e)}
            error={
              !validationMap.cardExpiration &&
              ((isDirtyMap.cardExpiration && isBlurredMap.cardExpiration) ||
                showErrors)
            }
            helperText={
              !validationMap.cardExpiration &&
              ((isDirtyMap.cardExpiration && isBlurredMap.cardExpiration) ||
                showErrors)
                ? helperTextMap.cardExpiration
                : ''
            }
            data-testid="cardExpiration-field"
          />
        )}
        <Field
          name={`${globals.CREDIT_CARD}.zipCode`}
          component={TextField}
          autoComplete="section-payment"
          id="card-zip-code"
          data-testid="zipCode-field"
          inputProps={{ inputMode: 'numeric' }}
          className={classes.inlineFormItem}
          label={t('billingInfo.zipCode')}
          variant="outlined"
        />
        {paymentMethod && showCVV && (
          <CxpPaymentMethodFormField
            field="cardVerificationValue"
            id="cardVerificationValue"
            className={classes.inlineFormItem}
            label={t('cardPayment.cvv')}
            paymentMethod={paymentMethod}
            onChange={(e) => handleCxpFieldChange('cardVerificationValue')(e)}
            onBlur={(e) => handleCxpFieldBlur('cardVerificationValue')(e)}
            onFocus={(e) => handleCxpFieldFocus('cardVerificationValue')(e)}
            error={
              !validationMap.cardVerificationValue &&
              ((isDirtyMap.cardVerificationValue &&
                isBlurredMap.cardVerificationValue) ||
                showErrors)
            }
            helperText={
              !validationMap.cardVerificationValue &&
              ((isDirtyMap.cardVerificationValue &&
                isBlurredMap.cardVerificationValue) ||
                showErrors)
                ? helperTextMap.cardVerificationValue
                : ''
            }
            data-testid="cvv-field"
            inputprops={{
              endAdornment: <SecurityCodeGuidance cardType={cardType} />,
            }}
          />
        )}
      </span>
    </Typography>
  );
}

CreditCardPaymentMethod.propTypes = {
  classes: PropTypes.object.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
  showErrors: PropTypes.bool,
};

CreditCardPaymentMethod.defaultProps = {
  showErrors: false,
};

export default withStyles(styles)(CreditCardPaymentMethod);
