import { useRef } from 'react';
import { oneOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import usePageTitle from '../../../hooks/common/usePageTitle';
import { VANCO_VIDEO_TYPE } from '../../../common/globals';
import useStyles from './styles';
import useIFrameListener from '../../../hooks/useIFrameListener';

function BaseVideoComponent({ variant, locationId, organizationId }) {
  const { t } = useTranslation();
  const iFrameRef = useRef(null);
  useIFrameListener({ iFrameRef, locationId });
  const classes = useStyles();

  // Function to retrieve the organization's primary color
  const orgPrimaryColor = useSelector(({ location }) => location.primaryColor);

  // Construct the URL, including the org color as a query parameter
  const baseUrl = `${window.env.REACT_APP_FAITHFORWARD_ORIGIN}/${variant}/vanco/${organizationId}`;
  const url = `${baseUrl}?orgColor=${orgPrimaryColor}`;

  const titleKey = {
    [VANCO_VIDEO_TYPE.VIDEO]: 'app.view.videos',
  };

  const title = t(titleKey[variant]);
  usePageTitle(title);

  if (!organizationId || !locationId) {
    return <LinearProgress />;
  }

  return (
    <iframe
      src={url}
      title={title}
      className={classes.iframeStyle}
      ref={iFrameRef}
    />
  );
}

BaseVideoComponent.defaultProps = {
  variant: VANCO_VIDEO_TYPE.VIDEO,
  locationId: '',
  organizationId: '',
};

BaseVideoComponent.propTypes = {
  variant: oneOf(Object.values(VANCO_VIDEO_TYPE)),
  locationId: string,
  organizationId: string,
};

export default BaseVideoComponent;
