import qs from 'qs';

import IdTokenVerifier from './idtoken-verifier';
import { assert, inIframe, object } from '@vancoplatform/utils';
import * as error from '../helper/error';
import TransactionManager from './transaction-manager';
import Authentication, { SignOnOptions } from '../Authentication';
import Popup from './popup';
import Embedded from './embedded';
import CheckSession from './check-session';
import {
  AuthorizeOptions,
  ParseHashResponse,
  WebAuthOptions,
  UserClaims,
  EmbeddedAuthorizeOptions,
  Transaction,
} from '../types';

interface BaseOptions extends WebAuthOptions {
  token_issuer: string;
}

interface BaseParseHashOptions {
  hash?: string;
  state?: string;
}

interface ParseHashOptions extends BaseParseHashOptions {
  nonce?: string;
  codeVerifier?: string;
  usePKCE?: boolean;
  exchangeAuthCode?: boolean;
  responseType?: string;
  redirectUri?: string;
}

// const ExpLeeway = 150;
// const NbfLeeway = 600;

function buildParseHashResponse<TAppState = unknown>(
  qsParams: {
    access_token?: string;
    id_token?: string;
    code?: string;
    refresh_token?: string;
    state?: string;
    expires_in?: string;
    token_type?: string;
    scope?: string;
    session_state?: string;
    original_redirect_uri?: string;
  },
  appState?: TAppState,
  token: UserClaims | null = null
): ParseHashResponse<TAppState> {
  return {
    accessToken: qsParams.access_token || undefined,
    idToken: qsParams.id_token || undefined,
    idTokenPayload: token || undefined,
    code: qsParams.code || undefined,
    appState: appState || undefined,
    refreshToken: qsParams.refresh_token || undefined,
    state: qsParams.state,
    expiresIn: qsParams.expires_in
      ? parseInt(qsParams.expires_in, 10)
      : undefined,
    tokenType: qsParams.token_type || undefined,
    scope: qsParams.scope || undefined,
    sessionState: qsParams.session_state || undefined,
    originalRedirectUri: qsParams.original_redirect_uri || undefined,
  };
}

class WebAuth {
  baseOptions: BaseOptions;
  transactionManager: TransactionManager;
  client: Authentication;
  popup: Popup;
  embedded: Embedded;
  sessionChecker: CheckSession;

  constructor(options: WebAuthOptions) {
    assert.check(
      options,
      { type: 'object', message: 'options parameter is not valid' },
      {
        domain: { type: 'string', message: 'domain option is required' },
        clientId: { type: 'string', message: 'clientId option is required' },
        tenant: {
          optional: true,
          type: 'string',
          message: 'tenant is not valid',
        },
        responseType: {
          optional: true,
          type: 'string',
          message: 'responseType is not valid',
        },
        responseMode: {
          optional: true,
          type: 'string',
          message: 'responseMode is not valid',
        },
        redirectUri: {
          optional: true,
          type: 'string',
          message: 'redirectUri is not valid',
        },
        scope: {
          optional: true,
          type: 'string',
          message: 'scope is not valid',
        },
        audience: {
          optional: true,
          type: 'string',
          message: 'audience is not valid',
        },
      }
    );

    this.baseOptions = {
      ...options,
      token_issuer: `https://${options.domain}/`,
    };
    this.baseOptions.usePKCE = options.usePKCE != null ? options.usePKCE : true;

    this.transactionManager = new TransactionManager(
      this.baseOptions.transaction
    );

    this.client = new Authentication(this.baseOptions);
    this.popup = new Popup(this, this.baseOptions);
    this.embedded = new Embedded(this, this.baseOptions);
    this.sessionChecker = new CheckSession(this.baseOptions);
  }

  extractHashObject(
    options: BaseParseHashOptions = {}
  ): Record<string, string> {
    let hashStr =
      options.hash === undefined ? window.location.hash : options.hash;
    hashStr = hashStr.replace(/^#?\??\/?/, '');

    const parsedQs = qs.parse(hashStr);

    if (Object.prototype.hasOwnProperty.call(parsedQs, 'error')) {
      const err = error.buildResponse(
        parsedQs['error'] as string,
        parsedQs['error_description'] as string
      );

      if (parsedQs['state']) {
        const state = parsedQs['state'] as string;
        err.state = state;
        const transaction = this.transactionManager.getStoredTransaction(state);
        err.appState = (transaction && transaction.appState) || null;
      }

      if (parsedQs['error'] === 'login_required') {
        err.sessionState = parsedQs['session_state'] as string;
      }

      throw err;
    }

    return parsedQs as Record<string, string>;
  }

  parseLogoutHash<TAppState = unknown>(options: BaseParseHashOptions = {}) {
    const parsedHash: Record<string, string> = this.extractHashObject(options);

    if (!Object.prototype.hasOwnProperty.call(parsedHash, 'state')) {
      return null;
    }

    const { state } = parsedHash;
    const transaction =
      this.transactionManager.getStoredTransaction<TAppState>(state);
    const transactionState =
      options.state || (transaction && transaction.state) || null;

    if (transactionState !== state) {
      throw error.invalidToken('`state` does not match.');
    }

    const appState = (transaction && transaction.appState) || null;

    return {
      state,
      appState,
    };
  }

  async parseHash<TAppState = unknown>(
    options: ParseHashOptions = {}
  ): Promise<ParseHashResponse<TAppState> | null> {
    const parsedQs: Record<string, string> = this.extractHashObject(options);
    const { state } = parsedQs;
    const transaction =
      this.transactionManager.getStoredTransaction<TAppState>(state);
    const appState = (transaction && transaction.appState) || null;

    if (
      !Object.prototype.hasOwnProperty.call(parsedQs, 'access_token') &&
      !Object.prototype.hasOwnProperty.call(parsedQs, 'id_token') &&
      !Object.prototype.hasOwnProperty.call(parsedQs, 'refresh_token') &&
      !Object.prototype.hasOwnProperty.call(parsedQs, 'code')
    ) {
      return null;
    }
    const responseTypes = (
      this.baseOptions.responseType ||
      options.responseType ||
      ''
    ).split(' ');
    if (
      responseTypes.length > 0 &&
      responseTypes.indexOf('token') !== -1 &&
      !Object.prototype.hasOwnProperty.call(parsedQs, 'access_token')
    ) {
      throw error.buildResponse(
        'invalid_hash',
        'response_type contains `token`, but the parsed hash does not contain an `access_token` property',
        appState
      );
    }
    if (
      responseTypes.length > 0 &&
      responseTypes.indexOf('id_token') !== -1 &&
      !Object.prototype.hasOwnProperty.call(parsedQs, 'id_token')
    ) {
      throw error.buildResponse(
        'invalid_hash',
        'response_type contains `id_token`, but the parsed hash does not contain an `id_token` property',
        appState
      );
    }
    if (
      responseTypes.length > 0 &&
      responseTypes.indexOf('code') !== -1 &&
      !Object.prototype.hasOwnProperty.call(parsedQs, 'code')
    ) {
      throw error.buildResponse(
        'invalid_hash',
        'response_type contains `code`, but the parsed hash does not contain an `code` property',
        appState
      );
    }
    return await this.validateAuthenticationResponse<TAppState>(
      options,
      parsedQs,
      transaction
    );
  }

  async validateAuthenticationResponse<TAppState = unknown>(
    options: ParseHashOptions,
    parsedHash: Record<string, string>,
    transaction: Transaction<TAppState>
  ): Promise<ParseHashResponse<TAppState>> {
    const { state } = parsedHash;
    const appState = (transaction && transaction.appState) || null;
    const transactionState =
      options.state || (transaction && transaction.state) || null;

    if (transactionState !== state) {
      throw error.invalidToken<TAppState>('`state` does not match.', appState);
    }

    const transactionNonce =
      options.nonce || (transaction && transaction.nonce) || null;
    const codeVerifier =
      options.codeVerifier || (transaction && transaction.codeVerifier) || null;

    // Some clients (such as web-store) use our client library to get an auth code
    // which they then exchange from their backend with a client secret. So we
    // only auto-exchange auth codes if we created a code_verifier for the transaction
    const exchangeAuthCode =
      options.usePKCE ||
      options.exchangeAuthCode ||
      (transaction && (transaction.exchangeAuthCode || transaction.usePKCE)) ||
      false;

    // If we have a code verifier and an authorization_code,
    // attempt to exchange the authorization_code for an id and access token.
    if (exchangeAuthCode && parsedHash['code']) {
      const params = {
        ...options,
        code: parsedHash['code'],
      };

      if (codeVerifier) {
        params.codeVerifier = codeVerifier;
      }

      const tokens = await this.client.exchange(params);

      parsedHash = {
        ...parsedHash,
        ...tokens,
      };
    }

    if (!parsedHash['id_token']) {
      return buildParseHashResponse<TAppState>(parsedHash, appState);
    }

    let payload;
    const verifier = new IdTokenVerifier({
      issuer: this.baseOptions.token_issuer,
      audience: this.baseOptions.clientId,
    });

    try {
      payload = await this.validateToken(
        verifier,
        parsedHash['id_token'],
        transactionNonce,
        !!parsedHash['code']
      );
    } catch (validationError) {
      if (validationError !== 'invalid_token') {
        throw validationError;
      }
      // if it's an invalid_token error, decode the token
      const decodedToken = verifier.decode(parsedHash['id_token']);
      if (decodedToken instanceof error.ErrorResponse) {
        throw validationError;
      }
      // if the alg is not HS256, return the raw error
      if (decodedToken.header.alg !== 'HS256') {
        throw validationError;
      }
      throw error.invalidToken(
        "The id_token cannot be validated because it was signed with the HS256 algorithm and public clients (like a browser) can't store secrets."
      );
    }

    if (!parsedHash['access_token'] || !payload.at_hash) {
      return buildParseHashResponse<TAppState>(parsedHash, appState, payload);
    }
    // here we're absolutely sure that the id_token's alg is RS256
    // and that the id_token is valid, so we can check the access_token
    return new Promise<ParseHashResponse<TAppState>>((resolve, reject) =>
      verifier.validateAccessToken(
        parsedHash['access_token'],
        'RS256',
        payload.at_hash,
        (err) => {
          if (err) {
            reject(error.invalidToken(err.message));
          }
          resolve(
            buildParseHashResponse<TAppState>(parsedHash, appState, payload)
          );
        }
      )
    );
  }

  validateToken(
    verifier: IdTokenVerifier,
    token: string,
    nonce: string,
    usingAuthorizationCode: boolean
  ) {
    return new Promise((resolve, reject) => {
      verifier.validateExpAndNbf = !usingAuthorizationCode;
      verifier.verify(token, nonce, (err, payload) => {
        if (err) {
          reject(error.invalidToken(err.message));
          return;
        }

        resolve(payload);
      });
    });
  }

  /**
   * @param {Object} options
   */
  renewAuth(
    options: {
      clientId?: string;
      tenant?: string;
      redirectUri?: string;
      responseType?: ResponseType;
      scope?: string;
      audience?: string;
      state?: string;
      nonce?: string;
      idTokenHint?: string;
      maxAge?: number;
    } = {}
  ) {
    const params: EmbeddedAuthorizeOptions = object
      .merge(this.baseOptions, [
        'clientId',
        'tenant',
        'redirectUri',
        'responseType',
        'scope',
        'audience',
        'state',
        'nonce',
        'maxAge',
      ])
      .with(options);

    params.responseType = params.responseType || 'token';
    params.responseMode = params.responseMode || 'fragment';

    assert.check(params, {
      type: 'object',
      message: 'options parameter is not valid',
    });

    params.prompt = 'none';
    params.iframeOptions = {
      hidden: true,
    };

    return this.embedded.authorize(params, document.body);
  }

  async checkSession(options: { sessionState: string }) {
    assert.check(options, {
      type: 'object',
      message: 'options parameter is not valid',
    });

    const params = object.merge(this.baseOptions, ['clientId']).with(options);

    assert.check(
      params,
      { type: 'object', message: 'options parameter is not valid' },
      {
        clientId: {
          type: 'string',
          message: 'clientId option is required',
        },
        sessionState: {
          type: 'string',
          message: 'sessionState option is required',
        },
      }
    );

    await this.sessionChecker.render();
    return await this.sessionChecker.checkSession(params);
  }

  /**
   * Redirects you the login page (`/oidc/authorize`) in order to start a new oauth2 transaction.
   * After that, you'll have to use the {@link parseHash} function at the specified `redirectUri`.
   *
   * @param {Object} options
   */
  async authorize(options: AuthorizeOptions = {}) {
    let params: AuthorizeOptions = object
      .merge(this.baseOptions, [
        'clientId',
        'tenant',
        'responseType',
        'responseMode',
        'redirectUri',
        'scope',
        'audience',
        'state',
        'nonce',
        'maxAge',
        'provider',
        'usePKCE',
      ])
      .with(options);

    assert.check(
      params,
      { type: 'object', message: 'options parameter is not valid' },
      {
        responseType: {
          type: 'string',
          message: 'responseType option is required',
        },
      }
    );

    params.responseType = params.responseType || 'code';
    params.responseMode = params.responseMode || 'fragment';
    delete params.redirectMode;

    if (!inIframe() && !params.provider && params.tenant) {
      try {
        const tenantInfo = await this.client.tenantInfo(params.tenant);
        if (
          !tenantInfo.signInWithEmail &&
          !tenantInfo.signInWithPhone &&
          !tenantInfo.signInWithUsername &&
          tenantInfo.ssoProviders.length === 1
        ) {
          // If we only sign in via an sso provider, and there is also only a single sso provider,
          // add the provider parameter to send users directly there.
          params.provider = tenantInfo.ssoProviders[0].provider;
        }
      } catch (e) {
        console.error(
          `Unable to fetch tenant details for tenant ${params.tenant}`,
          e
        );
      }
    }

    // Prevent the use of prompt=provider from inside an iframe.
    if (inIframe() && params.prompt === 'provider') {
      delete params.prompt;
    }

    params = await this.transactionManager.process(params);
    params.scope = params.scope || 'openid profile email';

    // Redirect the browser to the authorize url
    window.location.href = this.client.buildAuthorizeUrl(params);
  }

  /**
   * Redirects you to an external sign on
   */
  signOn(options: SignOnOptions) {
    assert.check(
      options,
      { type: 'object', message: 'options parameter is not valid' },
      {
        provider: {
          optional: true,
          type: 'string',
          message: 'provider parameter is not valid',
        },
      }
    );

    window.location.href = this.client.buildSignOnUrl(options);
  }

  /**
   * Redirects you to the logout endpoint
   */
  logout(options: {
    clientId?: string;
    postLogoutRedirectUri?: string;
    idTokenHint?: string;
    appState?: unknown;
    state?: string;
  }): void {
    const params = object
      .merge(this.baseOptions, ['clientId', 'postLogoutRedirectUri', 'state'])
      .with(options);

    assert.check(
      params,
      { type: 'object', message: 'options parameter is not valid' },
      {
        clientId: {
          type: 'string',
          message: 'clientId option is required',
        },
      }
    );

    // Redirect the browser to the logout url
    window.location.href = this.client.buildLogoutUrl(
      this.transactionManager.processLogout(options)
    );
  }
}

export default WebAuth;
