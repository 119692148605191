import { WebAuth } from '@vancoplatform/web-auth';
import store from '../../store';
import { parseObjectToQueryParams } from '../../common/utils';

/**
 * Wrapper for the IDS auth implementation. I'm excluding this from tests because I can't think of anything meaningful to test here
 */
export class AuthService {
  static idsClient = undefined;

  get IDSClient() {
    if (!this.idsClient) {
      this.idsClient = new WebAuth({
        domain: window.env.REACT_APP_IDS_DOMAIN,
        clientId: window.env.REACT_APP_IDS_CLIENT_ID,
        audience: window.env.REACT_APP_IDS_AUDIENCE,
        responseType: 'code',
        scope: 'openid profile email address phone',
        redirectUri: window.env.REACT_APP_FRONTEND_REDIRECT_URL,
      });
    }

    return this.idsClient;
  }

  parseHash = (options) => {
    return this.IDSClient.parseHash(options);
  };

  embedLogin = (iframeNode, isSignup, returnTo = null) => {
    const { tenant } = store.getState().paymentMethod.sessionData;
    const options = {
      redirectMode: 'redirect',
      tenant,
      appState: {
        returnTo: returnTo || window.location.pathname,
      },
    };
    if (isSignup) {
      options.prompt = 'create';
    }
    return this.IDSClient.embedded.authorize(options, iframeNode);
  };

  mpLogin = (tenant, returnTo = null, stateToken = null) => {
    // this call is only executed 1x and only when login occurs via a Pocket Platform tenant
    const options = {
      tenant,
      appState: {
        returnTo: returnTo || window.location.pathname,
        mpPocketPlatformInitialLogin: true,
      },
      token: stateToken,
      prompt: !stateToken ? 'none' : null,
    };

    return this.IDSClient.authorize(options);
  };

  renewAuth = (idTokenHint) => {
    const { tenant } = store.getState().paymentMethod.sessionData;
    if (!tenant) return null;
    return this.IDSClient.renewAuth({ idTokenHint, tenant });
  };

  checkSession = (sessionState) => {
    const { tenant } = store.getState().paymentMethod.sessionData;
    if (!tenant) return null;
    return this.IDSClient.checkSession({ sessionState });
  };

  logout = (queryObject) => {
    const { mpPocketPlatformInitialLogin } = store.getState().session;
    // tenant is required for MP oAuth Logout endpoint
    const { tenant } = store.getState().paymentMethod.sessionData;
    const { idToken: idTokenHint, sessionId } = queryObject;
    // sessionId is the only variable used via logout for MP
    // the tenant is only omitted when the user is logged in via Pocket Platform
    // and the above should only occur once
    this.IDSClient.logout({
      postLogoutRedirectUri: window.env.REACT_APP_FRONTEND_REDIRECT_URL,
      state: btoa(JSON.stringify({ sessionId })),
      idTokenHint,
      tenant: mpPocketPlatformInitialLogin ? null : tenant,
    });
  };

  getUserInfo = (accessToken) => {
    return this.IDSClient.client.userInfo(accessToken);
  };

  getAccessTokenfromRefreshToken = (refreshToken) => {
    const data = parseObjectToQueryParams({
      grant_type: 'refresh_token',
      client_id: window.env.REACT_APP_IDS_CLIENT_ID,
      refresh_token: refreshToken,
      audience: window.env.REACT_APP_IDS_AUDIENCE,
      scope: 'profile email phone address',
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return this.noAuthHttp.post(
      `https://${window.env.REACT_APP_IDS_DOMAIN}/oauth/token`,
      data,
      config,
    );
  };
}

export default new AuthService();
