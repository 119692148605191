import i18next from 'i18next';
import moment from 'moment';
import * as Yup from 'yup';
import * as globals from '../globals';

import { hasValidCharsOnly } from '../../utils/text/getSupportedSpecialChars';
import { isValid } from '../../utils/text/stringValidation';

const createOpenAmountSchema = (transactionItems) => {
  return transactionItems.reduce((map, item) => {
    const newMap = map;
    newMap[item.fundId] = Yup.object().shape({
      amount: Yup.string()
        .required()
        .test(
          'isAmountTooLow',
          i18next.t('formValidation.amountTooLow'),
          (value) => {
            return parseFloat(value) >= globals.MIN_TRANSACTION_AMOUNT;
          },
        ),
      recurringOption: Yup.string().required(),
      frequency: Yup.string().required(),
      startDate: Yup.object()
        .nullable()
        .test(
          'isRequired',
          i18next.t('formValidation.required'),
          function test(value) {
            return (
              this.parent.frequency ===
                globals.PAYMENT_FREQUENCY_ONE_TIME_NOW || !!value
            );
          },
        )
        .test(
          'isScheduled',
          i18next.t('formValidation.selectNewDate'),
          function test(value) {
            const today = moment()
              .hour(12)
              .minutes(0)
              .seconds(0)
              .milliseconds(0);

            let valid = true;
            let message = '';

            // Note: we need to return true if value is falsey so that it fails the required validation
            if (
              value &&
              this.parent.frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_NOW
            ) {
              if (value.isBefore(today) || !value.isAfter(today)) {
                valid = false;
                message = i18next.t('formValidation.selectNewDate');
              }
            }

            return valid || this.createError({ message });
          },
        )
        .test(
          'isDateOverOneYear',
          i18next.t('formValidation.selectLessThanOneYear'),
          (value) => {
            return value
              ? value.isBefore(
                  moment()
                    .add(1, 'year')
                    .hour(12)
                    .minutes(0)
                    .seconds(0)
                    .milliseconds(0),
                )
              : true;
          },
        ),
      endDate: Yup.object()
        .nullable()
        .test('enddate', function validate(value) {
          const { startDate, recurringOption } = this.parent;
          // endDate is required for Installments
          const endDateNotSet = value === null || value === undefined;
          if (
            recurringOption === globals.PAYMENT_FREQUENCY_OPTIONS.INSTALLMENT &&
            endDateNotSet
          )
            return this.createError({
              message: i18next.t('formValidation.required'),
            });
          // start date is a required field - so dont trigger errors for this field when start date is missing
          // since this field is optional allow for value to be null
          if (
            startDate === null ||
            startDate === undefined ||
            value === null ||
            value === undefined
          ) {
            return true;
          }
          // end date must be after - cannot be on the same day or before
          return startDate.isValid() && moment(value).isAfter(startDate)
            ? true
            : this.createError({
                message: i18next.t('formValidation.endDateMustBeGreater', {
                  startDate: startDate.format('L'),
                }),
              });
        }),
      memoLine: Yup.string().test(
        'memoLineIsRequired',
        (value) => {
          return !isValid(value?.value)
            ? i18next.t('formValidation.required')
            : i18next.t('errors.invalidChar');
        },
        (value) => {
          // no validation is required
          if (!item.memoLine?.showTextField) return true;

          // always check if text is valid
          const isValidValue = isValid(value);

          // if its not required then text is not required either
          if (!isValidValue) {
            return !item.memoLine?.responseRequired;
          }

          // always run char restriction verification
          // optional and required text must pass the char restriction
          return hasValidCharsOnly(value);
        },
      ),
      installmentsValid: Yup.bool().test(
        'installmentsValid',
        function validate(value) {
          const { recurringOption } = this.parent;

          if (
            !value &&
            recurringOption === globals.PAYMENT_FREQUENCY_OPTIONS.INSTALLMENT
          )
            return this.createError({
              message: i18next.t('formValidation.installments'),
            });

          return true;
        },
      ),
    });
    return newMap;
  }, {});
};

export const createSchema = (transactionItems) => {
  Yup.setLocale({
    mixed: {
      required: `${i18next.t('formValidation.required')}`,
    },
  });

  return transactionItems
    ? Yup.object().shape(createOpenAmountSchema(transactionItems))
    : Yup.object();
};

export default createSchema;
