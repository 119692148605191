import i18next from 'i18next';
import {
  USER_DETAILS_FETCH,
  USER_DETAILS_COMPLETE,
  USER_DETAILS_ERROR,
  USER_DETAILS_CLEAR,
  USER_DETAILS_SAVE,
  USER_DETAILS_SAVE_ERROR,
  USER_DETAILS_SAVE_COMPLETE,
} from './actionTypes';
import AuthService from '../api/AuthService';
import UserService from '../api/UserService';

// eslint-disable-next-line import/prefer-default-export
export function fetchUserDetails() {
  return async (dispatch, getState) => {
    const { sessionDetails } = getState().session;
    const { accessToken: currentToken } = sessionDetails;

    if (currentToken) {
      dispatch({ type: USER_DETAILS_FETCH });
      let userDetails;
      try {
        userDetails = await AuthService.getUserInfo(currentToken);
        return dispatch({ type: USER_DETAILS_COMPLETE, userDetails });
      } catch (err) {
        dispatch({ type: USER_DETAILS_ERROR });
        throw err;
      }
    }
    return dispatch({ type: USER_DETAILS_CLEAR });
  };
}

export function saveUserDetails(userDetails, tenant, sessionId) {
  return async (dispatch) => {
    dispatch({ type: USER_DETAILS_SAVE });

    let response;
    try {
      response = await UserService.saveUserDetails(
        userDetails,
        tenant,
        sessionId,
      );
    } catch (error) {
      dispatch({ type: USER_DETAILS_SAVE_ERROR });
      throw error;
    }

    if (response?.status === 200 && response?.data) {
      dispatch(fetchUserDetails());
      dispatch({
        type: USER_DETAILS_SAVE_COMPLETE,
      });
      return response.data;
    }

    dispatch({ type: USER_DETAILS_SAVE_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}
