import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { VANCO_ONLINE_APP_TITLE } from '../../common/globals';

const usePageTitle = (title) => {
  const locationName = useSelector(({ location }) => location.name);
  const formatTitle = () => {
    if (title) {
      return `${title} | ${locationName || VANCO_ONLINE_APP_TITLE}`;
    }
    return VANCO_ONLINE_APP_TITLE;
  };

  useEffect(() => {
    document.title = formatTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationName, title]);
};

export default usePageTitle;
