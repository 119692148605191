import 'iframe-resizer/js/iframeResizer';
import { assert } from '@vancoplatform/utils';
import EmbeddedIframeHandler from './helper/embeddedIframe';
import { ThemeOptions } from './types';

type ProfileOptions = {
  element: object;
  url: string;
  tenant: string;
  clientid: string;
  id: string;
  classname: string;
  theme?: ThemeOptions;
};

class Profile {
  baseOptions: ProfileOptions;
  authority: string;

  /**
   * Creates a iframe of the CX1 Identity Server profile page
   * @param {Object} options.element the dom element in which to render the iframe
   * @param {String} options.url the URL to the Identity Server (domain)
   * @param {String} options.tenant the tenant slug
   * @param {String} options.id optional. An ID for the iframe element
   * @param {String} options.classname optional. An class for the iframe element
   */
  constructor(options: ProfileOptions) {
    assert.check(
      options,
      { type: 'object', message: 'options parameter is not valid' },
      {
        element: { type: 'object', message: 'element option is required' },
        url: { type: 'string', message: 'url option is required' },
        tenant: { type: 'string', message: 'tenant option is required' },
        clientid: {
          optional: true,
          type: 'string',
          message: 'clientid is not valid',
        },
        id: {
          optional: true,
          type: 'string',
          message: 'id is not valid',
        },
        classname: {
          optional: true,
          type: 'string',
          message: 'classname is not valid',
        },
      }
    );

    if (options.theme) {
      assert.check(options.theme, {
        type: 'object',
        message: 'theme option is not valid',
      });
    }

    this.baseOptions = options;
    this.authority = this.baseOptions.url;
    if (this.authority.endsWith('/')) {
      this.authority = this.authority.substring(0, this.authority.length - 1);
    }

    if (!this.authority.startsWith('https')) {
      this.authority = 'https://' + this.authority;
    }
    this.baseOptions.url =
      this.authority + '/' + this.baseOptions.tenant + '/profile';

    if (this.baseOptions.clientid) {
      this.baseOptions.url += '?app=' + this.baseOptions.clientid;
    }
  }

  render() {
    const handler = new EmbeddedIframeHandler({
      url: this.baseOptions.url,
    });
    handler.mount(this.baseOptions.element);
    handler.on('getTheme', () => this.baseOptions.theme || {});
  }
}

export default Profile;
