export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px 20px 16px 20px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  amount: {
    fontWeight: '700',
  },
  lineAmount: {
    color: '#999999',
  },
  total: {
    marginTop: '8px',
  },
  date: {
    fontSize: '1.4rem',
    color: '#666666',
    marginTop: '8px',
  },
  method: {
    fontSize: '1.4rem',
    color: '#666666',
    marginTop: '4px',
  },
  frequencyType: {
    fontSize: '1.2rem',
    color: '#999999',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  itemBreak: {
    borderTop: '1px solid',
    borderColor: theme.palette.itemBreak,
    height: '1px',
    width: '100%',
    margin: '0',
  },
  button: {
    display: 'flex',
    minHeight: '50px',
    fontWeight: '700',
  },
  cancelButtonDeprecated: {
    color: theme.palette.primary.main,
    display: 'flex',
    minHeight: '50px',
    fontWeight: '700',
  },
});
