export default theme => ({
  wrap: {
    borderBottom: '1px solid #EBEBEB',
    '&:first-child': {
      paddingTop: 36,
    },
  },
  menuLink: {
    padding: '14px 47px',
    minHeight: 52,
    display: 'flex',
    '&$noIcon': {
      padding: '14px 32px 14px 17px',
    },
  },
  externalLink: {
    textDecoration: 'none',
  },
  label: {
    marginLeft: 15,
  },
  active: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  noIcon: {},
  [theme.breakpoints.only('sm')]: {
    menuLink: {
      padding: '14px 0px',
      flexDirection: 'column',
      alignItems: 'center',
    },
    label: {
      marginLeft: 0,
      textAlign: 'center',
      fontSize: '1.0rem',
    },
    wrap: {
      borderBottom: 'none',
    },
  },
});
