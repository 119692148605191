export default () => ({
  title: {
    paddingBottom: '20px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
