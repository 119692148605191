import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { TextField, MenuItem, withStyles } from '@material-ui/core';

const styles = () => ({
  menu: {
    height: '280px',
    position: 'fixed',
  },
});

export function EditableCampaignDropdown({
  textFieldLabel,
  field,
  form,
  campaigns,
  helperTextClass,
  classes,
  onChange,
  ...props
}) {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const handleOnChange = (event) => {
    form.setFieldValue(field.name, event.target.value, true);
    form.setFieldTouched(field.name, true, false);
    onChange(event.target.value);
  };

  return (
    <TextField
      name={name}
      id={name}
      label={textFieldLabel}
      FormHelperTextProps={{ classes: { root: helperTextClass } }}
      select
      variant="outlined"
      disabled={isSubmitting}
      error={showError}
      helperText={showError ? fieldError : ''}
      value={campaigns?.length ? field.value : ''}
      onChange={handleOnChange}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      data-testid="editable-campaign-select"
      {...props}
    >
      {campaigns.map((campaign) => (
        <MenuItem key={campaign.id} value={campaign.id}>
          {campaign.title}
        </MenuItem>
      ))}
    </TextField>
  );
}

EditableCampaignDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  textFieldLabel: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  helperTextClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

EditableCampaignDropdown.defaultProps = {
  helperTextClass: '',
};

export default withStyles(styles)(EditableCampaignDropdown);
