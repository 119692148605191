/* eslint-disable no-extra-boolean-cast */
import BaseService from './BaseService';

class CampaignService extends BaseService {
  getCampaign(campaignId) {
    return this.http.get(`campaigns/${campaignId}`);
  }

  getEditableCampaignsByOrganizationURI(organizationURI) {
    if (!Boolean(organizationURI)) {
      return {};
    }

    return this.http.get(`organizations/${organizationURI}/campaigns`);
  }
}

export default new CampaignService();
