import variable from './variable';
import attribute from './attribute';
import value from './value';

interface CheckConfig {
  optional?: boolean;
  type: string;
  message: string;
}

interface CheckAttribute<
  T extends Record<string, unknown>,
  Key extends keyof T
> {
  type: string;
  message: string;
  optional?: boolean;
  condition?: (obj: T) => boolean;
  values?: T[Key][];
  value_message?: string;
}

export default function check<T extends { [key: string]: unknown }>(
  o: T,
  config: CheckConfig,
  attributes?: { [Key in keyof T]?: CheckAttribute<T, Key> }
) {
  if (!config.optional || o) {
    variable(o, config.type, config.message);
  }
  if (config.type === 'object' && attributes) {
    const keys = Object.keys(attributes);

    for (let index = 0; index < keys.length; index += 1) {
      const a = keys[index];
      const attr = attributes[a];
      if (attr != undefined && (!attr.optional || o[a])) {
        const { condition } = attr;
        if (!condition || condition(o)) {
          attribute(o, a, attr.type, attr.message);
          if (attr.values) {
            value(o[a], attr.values, attr.value_message);
          }
        }
      }
    }
  }
}
