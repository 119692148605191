export default theme => ({
  header: {
    textAlign: 'center',
    paddingTop: '32px',
    paddingBottom: '8px',
  },
  message: {
    lineHeight: '26px',
    color: '#666666',
  },
  content: {
    width: '375px',
    padding: '8px 32px 24px 32px',
    textAlign: 'center',
  },
  action: {
    height: '62px',
    borderTop: `1px solid ${theme.palette.itemBreak}`,
    padding: 0,
  },
  button: {
    height: '100%',
    width: '100%',
    fontWeight: 'bold',
  },
  [theme.breakpoints.down('xs')]: {
    content: {
      width: '92vw',
    },
  },
});
