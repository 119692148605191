function hidden() {
  return null;
}
/**
 * Takes a component as a parameter and returns a empty component if the window is within a webview
 * otherwise it returns the original component
 * @param {React.Component} component
 * @returns {React.Component}
 */
function withRNWebviewHide(component) {
  if (window.ReactNativeWebView) {
    return hidden;
  }
  return component;
}

export default withRNWebviewHide;
