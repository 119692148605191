import Snackbar from '@material-ui/core/Snackbar';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { dequeueSnackbar } from '../../../actions/notifications';
import SnackbarContentWrapper from './contentWrapper/SnackbarContentWrapper';

export function AppSnackbar(props) {
  const { notification, dequeueSnackbarConnect } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!notification.key);
  }, [notification.key]);

  const handleExited = () => {
    dequeueSnackbarConnect();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        id="client-snackbar"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        key={notification.key}
        // need to do this check in case autoHideDuration is null
        autoHideDuration={
          Object.prototype.hasOwnProperty.call(notification, 'autoHideDuration')
            ? notification.autoHideDuration
            : 6000
        }
        onClose={handleClose}
        TransitionProps={{
          onExited: handleExited,
        }}
      >
        <SnackbarContentWrapper
          id="client-snackbar-wrapper"
          onClose={handleClose}
          variant={notification.variant}
          style={{ flexWrap: 'nowrap' }}
          message={
            notification.message && typeof notification.message !== 'string'
              ? i18next.t('errors.somethingWrong')
              : notification.message
          }
        />
      </Snackbar>
    </div>
  );
}

AppSnackbar.propTypes = {
  notification: PropTypes.object,
  dequeueSnackbarConnect: PropTypes.func.isRequired,
};

AppSnackbar.defaultProps = {
  notification: {},
};

const mapStateToProps = (state) => {
  return {
    notification: state.notifications.snackbarQueue[0],
  };
};

const mapDispatchToProps = (dispatch) => ({
  dequeueSnackbarConnect: () => {
    dispatch(dequeueSnackbar());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSnackbar);
