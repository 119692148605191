import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  withStyles,
  CardActionArea,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './styles';
import * as globals from '../../../../common/globals';
import { getCurrencyFormat } from '../../../../common/utils';

function MobileScheduledTransactionCard(props) {
  const {
    className,
    classes,
    transactions,
    transactionId,
    onCancel,
    onEdit,
    features,
  } = props;

  const isOneTime =
    transactions[0].frequency === globals.PAYMENT_FREQUENCY_ONE_TIME_NOW ||
    transactions[0].frequency === globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE;
  const isEndDateIncorrect =
    moment(transactions[0].endDate) < moment(transactions[0].startDate);

  const { t } = useTranslation();

  const getPaymentFormat = (transaction) => {
    let format = '';
    if (transaction) {
      switch (transaction.paymentMethodType) {
        case globals.CREDIT_CARD:
          format = t('transactions.scheduled.cardNumber', {
            cardNumber: transaction.last4,
          });
          break;
        case globals.ACH:
          format = t('transactions.scheduled.accountNumber', {
            accountNumber: transaction.last4,
          });
          break;
        default:
          format = '';
      }
    }
    return format;
  };

  const getFrequencyFormat = (frequency) => {
    let format = '';
    const displayNames = globals.PAYMENT_FREQUENCY_DISPLAY_NAMES[frequency];

    if (displayNames && frequency && !isOneTime) {
      format = t(displayNames.fullDescription);
    }
    return format;
  };

  const totalAmount = transactions.reduce((sum, transaction) => {
    let result = sum;
    result += transaction.amount;
    if (transaction.processingFees) {
      result += transaction.processingFees;
    }
    return result;
  }, 0);

  const handleCancelTransactionButtonClick = () => {
    onCancel(transactionId);
  };

  const handleEditTransactionButtonClick = () => {
    onEdit(transactionId);
  };

  return (
    <Card
      className={`${classes.root} ${className || ''}`}
      data-testid="mobile-scheduled-transaction-card"
    >
      <CardContent className={classes.content}>
        <div
          className={classes.frequencyType}
          id="scheduled-frequency-type"
          data-testid="scheduled-frequency-type"
        >
          {isOneTime
            ? t('transactions.scheduled.oneTime')
            : t('transactions.scheduled.recurring')}
        </div>

        {transactions.map((transaction) => (
          <div
            id="scheduled-item"
            data-testid="scheduled-item"
            key={`${transaction.paymentId}-${
              transaction.campaignId
                ? transaction.campaignId
                : transaction.accountCode
            }`}
          >
            <Typography
              component="span"
              id="scheduled-transaction-title"
              variant="body2"
            >
              {`${transaction.title} `}
            </Typography>
            {transactions.length > 1 && (
              <span
                className={classes.lineAmount}
                id="line-amount"
                data-testid="line-amount"
              >
                {`(${getCurrencyFormat(
                  transaction.amount +
                    (transaction.processingFees
                      ? transaction.processingFees
                      : 0),
                )})`}
              </span>
            )}
          </div>
        ))}

        <span
          className={classes.date}
          id="scheduled-date"
          data-testid="scheduled-date"
        >
          {`${
            isOneTime
              ? t('transactions.scheduled.date')
              : t('transactions.scheduled.nextDate')
          }: ${moment(transactions[0].startDate).format('LL')}`}
        </span>
        {isEndDateIncorrect ? null : (
          <span className={classes.date} data-testid="scheduled-endDate">
            {`${t('transactions.scheduled.endDate')}: ${moment(
              transactions[0].endDate,
            ).format('LL')} `}
          </span>
        )}
        <span
          className={classes.method}
          id="scheduled-method"
          data-testid="scheduled-method"
        >{`${t('transactions.scheduled.paymentMethod')}: ${getPaymentFormat(
          transactions[0],
        )}`}</span>

        <div id="total" data-testid="total" className={classes.total}>
          <span className={classes.amount}>
            {getCurrencyFormat(totalAmount)}
          </span>
          {!isOneTime && (
            <span>
              {` ${getFrequencyFormat(
                transactions[0].frequency,
              ).toLowerCase()}`}
            </span>
          )}
        </div>
      </CardContent>
      <div className={classes.itemBreak} />

      {features.EditScheduledTransactions ? (
        <Grid container>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              onClick={handleEditTransactionButtonClick}
              fullWidth
            >
              {t('transactions.scheduled.edit')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              color="primary"
              onClick={handleCancelTransactionButtonClick}
              fullWidth
            >
              {t('transactions.scheduled.cancelTransaction')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <CardActionArea
          className={classes.cancelButtonDeprecated}
          color="primary"
          onClick={handleCancelTransactionButtonClick}
        >
          <Typography variant="body2">
            {t('transactions.scheduled.cancelTransaction')}
          </Typography>
        </CardActionArea>
      )}
    </Card>
  );
}

MobileScheduledTransactionCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  transactionId: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
};

MobileScheduledTransactionCard.defaultProps = {
  className: '',
};

export default withStyles(styles)(MobileScheduledTransactionCard);
