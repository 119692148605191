export default (theme) => ({
  subTitle: {
    color: '#222222',
    fontWeight: '600',
    paddingBottom: theme.spacing(2),
    '&:last-of-type': {
      marginTop: theme.spacing(3),
      paddingBottom: 0,
    },
  },
  inputField: {
    margin: '10px 0px 12px 0px',
    width: '100%',
    marginTop: theme.spacing(3),
  },
  inputHelperText: {
    margin: '3px 12px 0',
    maxWidth: '100%',
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
});
