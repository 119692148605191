export default function styles() {
  return {
    pageRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '32px',
      paddingBottom: '10vh',
    },
    image: {
      height: '25vh',
      width: '100%',
    },
    body: {
      lineHeight: '1.63',
      textAlign: 'center',
    },
    title: {
      paddingTop: '10vh',
      paddingBottom: '20px',
      textAlign: 'center',
    },
  };
}
