import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as globals from '../../common/globals';
import { renderCardIcon } from '../../common/utils';

const styles = () => ({
  cardIcon: {
    marginRight: '10px',
    width: '24px',
    height: '18px',
    display: 'inline-block',
  },
  cardNumber: {
    fontWeight: 'bold',
  },
  dropdownItem: {
    display: 'flex',
  },
});

export function MethodDropdown(props) {
  const {
    classes,
    textFieldClassName,
    paymentMethods,
    selectedMethod,
    methodType,
    setSelected,
    dropdownLabel,
    newMethodLabel,
    ...restProps
  } = props;

  useEffect(() => {
    if (
      !selectedMethod &&
      paymentMethods[0].paymentMethodToken &&
      paymentMethods[0].paymentMethodType === methodType
    ) {
      setSelected(paymentMethods[0].paymentMethodToken, paymentMethods[0]);
    }
  });

  const { t } = useTranslation();

  const handleChange = (event) => {
    const id = event.target.value;
    const details = paymentMethods.reduce((selected, method) => {
      if (!selected && method.paymentMethodToken === id) {
        return method;
      }
      return selected;
    }, null);
    setSelected(id, details);
  };

  return (
    <TextField
      className={textFieldClassName}
      id="payment-method-dropdown"
      data-testid="payment-method-dropdown"
      label={dropdownLabel}
      select
      variant="outlined"
      value={selectedMethod}
      onChange={handleChange}
      SelectProps={{ classes: { select: classes.dropdownItem } }}
      {...restProps}
    >
      {paymentMethods.map((method) => (
        <MenuItem
          className={classes.dropdownItem}
          key={method.paymentMethodToken}
          value={method.paymentMethodToken}
          data-testid={method.paymentMethodToken}
        >
          {methodType === globals.CREDIT_CARD && (
            <Fragment>
              {method.brand === globals.CARD_TYPE_AMEX &&
                renderCardIcon(globals.CARD_TYPE_AMEX, classes.cardIcon)}
              {method.brand === globals.CARD_TYPE_MASTERCARD &&
                renderCardIcon(globals.CARD_TYPE_MASTERCARD, classes.cardIcon)}
              {method.brand === globals.CARD_TYPE_VISA &&
                renderCardIcon(globals.CARD_TYPE_VISA, classes.cardIcon)}
              {method.brand === globals.CARD_TYPE_DISCOVER &&
                renderCardIcon(globals.CARD_TYPE_DISCOVER, classes.cardIcon)}
              {globals.CARD_DISPLAY_NAMES[method.brand]}
            </Fragment>
          )}
          {methodType === globals.ACH && (
            <Fragment>
              {method.accountType === globals.ACCOUNT_SAVINGS &&
                t('accountPayment.savings')}
              {method.accountType === globals.ACCOUNT_CHECKING &&
                t('accountPayment.checking')}
            </Fragment>
          )}
          {t('accountPayment.endingIn')}
          {`${method.last4}`}
        </MenuItem>
      ))}
      <MenuItem key="new" value={globals.NEW_PAYMENT_METHOD}>
        {newMethodLabel}
      </MenuItem>
    </TextField>
  );
}

MethodDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  textFieldClassName: PropTypes.string,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMethod: PropTypes.string.isRequired,
  methodType: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  dropdownLabel: PropTypes.string.isRequired,
  newMethodLabel: PropTypes.string.isRequired,
};

MethodDropdown.defaultProps = {
  textFieldClassName: '',
};

export default withStyles(styles)(MethodDropdown);
