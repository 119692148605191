import Cookies from 'js-cookie';
import * as globals from './globals';

/**
 * adapted from a examples at https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
 *
 * @returns {boolean|boolean|*} whether or not sessionStorage is available
 */
function sessionStorageAvailable() {
  let storage;
  try {
    storage = window.sessionStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

function localStorageAvailable() {
  let storage;
  try {
    storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

function setToSessionStorage(key, value) {
  if (sessionStorageAvailable()) {
    sessionStorage.setItem(key, value);
  } else {
    Cookies.set(key, value); // Default expiration is 'session'
  }
}

function deleteFromSessionStorage(key) {
  if (sessionStorageAvailable()) {
    sessionStorage.removeItem(key);
  } else {
    Cookies.remove(key);
  }
}

function getFromSessionStorage(key) {
  if (sessionStorageAvailable()) {
    return sessionStorage.getItem(key);
  }

  return Cookies.get(key);
}

function setToLocalStorage(key, value) {
  if (localStorageAvailable()) {
    localStorage.setItem(key, value);
  } else {
    Cookies.set(key, value);
  }
}

function deleteFromLocalStorage(key) {
  if (localStorageAvailable()) {
    localStorage.removeItem(key);
  } else {
    Cookies.remove(key);
  }
}

function getFromLocalStorage(key) {
  if (localStorageAvailable()) {
    return localStorage.getItem(key);
  }
  return Cookies.get(key);
}

export function getColorFromCache(locationId) {
  try {
    const cachedColors = JSON.parse(
      getFromLocalStorage(globals.COLOR_STORAGE_NAME),
    );
    if (cachedColors && cachedColors[locationId.toUpperCase()]) {
      return cachedColors[locationId.toUpperCase()];
    }
  } catch (e) {
    // Something must be messed up with the cache, so lets blow it away
    deleteFromLocalStorage(globals.COLOR_STORAGE_NAME);
  }
  return null;
}

export function setColorToCache(locationId, color) {
  try {
    let cachedColors = JSON.parse(
      getFromLocalStorage(globals.COLOR_STORAGE_NAME),
    );
    if (!cachedColors) {
      cachedColors = {};
    }
    cachedColors[locationId.toUpperCase()] = color;
    setToLocalStorage(globals.COLOR_STORAGE_NAME, JSON.stringify(cachedColors));
  } catch (e) {
    // Something must be messed up with the cache, so lets blow it away
    deleteFromLocalStorage(globals.COLOR_STORAGE_NAME);
  }
}

export function setBasketItemsToStorage(locationId, items) {
  setToSessionStorage(
    globals.BASKET_STORAGE_NAME + locationId.toUpperCase(),
    JSON.stringify(items),
  );
}

export function getBasketItemsFromStorage(locationId) {
  const items = getFromSessionStorage(
    globals.BASKET_STORAGE_NAME + locationId.toUpperCase(),
  );

  if (items) {
    return JSON.parse(items);
  }
  return [];
}

export function clearBasketItemsFromStorage(locationId) {
  deleteFromSessionStorage(
    globals.BASKET_STORAGE_NAME + locationId.toUpperCase(),
  );
}

export function getPaymentOptionsFromStorage(locationId) {
  const paymentOptions = getFromSessionStorage(
    globals.PAYMENT_OPTIONS_STORAGE_NAME + locationId.toUpperCase(),
  );
  if (paymentOptions) {
    return JSON.parse(paymentOptions);
  }
  return { paymentType: null, applyProcessingFee: false, processingFee: null };
}

export function setPaymentOptionsToStorage(locationId, paymentOptions) {
  const currentOptions = getPaymentOptionsFromStorage(locationId.toUpperCase());
  const newOptions = { ...currentOptions, ...paymentOptions };
  setToSessionStorage(
    globals.PAYMENT_OPTIONS_STORAGE_NAME + locationId.toUpperCase(),
    JSON.stringify(newOptions),
  );

  return newOptions;
}

export function clearPaymentOptionsFromStorage(locationId) {
  deleteFromSessionStorage(
    globals.PAYMENT_OPTIONS_STORAGE_NAME + locationId.toUpperCase(),
  );
}

export function getPaymentIdFromStorage(locationId) {
  return getFromSessionStorage(
    globals.PAYMENT_ID_STORAGE_NAME + locationId.toUpperCase(),
  );
}

export function setPaymentIdToStorage(locationId, paymentId) {
  setToSessionStorage(
    globals.PAYMENT_ID_STORAGE_NAME + locationId.toUpperCase(),
    paymentId,
  );
}

export function clearPaymentIdFromStorage(locationId) {
  deleteFromSessionStorage(
    globals.PAYMENT_ID_STORAGE_NAME + locationId.toUpperCase(),
  );
}

export function getVancoCampaignsPaymentHeaderStorage(locationId) {
  return getFromSessionStorage(
    globals.VP_CAMPAIGNS_PAYMENT_HEADER_NAME + locationId.toUpperCase(),
  );
}

export function setVancoCampaignsPaymentHeaderStorage(
  locationId,
  paymentHeader,
) {
  setToSessionStorage(
    globals.VP_CAMPAIGNS_PAYMENT_HEADER_NAME + locationId.toUpperCase(),
    paymentHeader,
  );
}

export function clearVancoCampaignsPaymentHeaderStorage(locationId) {
  deleteFromSessionStorage(
    globals.VP_CAMPAIGNS_PAYMENT_HEADER_NAME + locationId.toUpperCase(),
  );
}

export function setSubmittedPaymentIdToStorage(locationId, submittedPaymentId) {
  setToSessionStorage(
    globals.SUBMITTED_PAYMENT_ID_STORAGE_NAME + locationId.toUpperCase(),
    submittedPaymentId,
  );
}

export function getSubmittedPaymentIdFromStorage(locationId) {
  return getFromSessionStorage(
    globals.SUBMITTED_PAYMENT_ID_STORAGE_NAME + locationId.toUpperCase(),
  );
}

export function setSubmittedUserDetailsToStorage(locationId, details) {
  setToSessionStorage(
    globals.SUBMITTED_USER_DETAILS_STORAGE_NAME + locationId.toUpperCase(),
    JSON.stringify(details),
  );
}

export function getSubmittedUserDetailsFromStorage(locationId) {
  const details = getFromSessionStorage(
    globals.SUBMITTED_USER_DETAILS_STORAGE_NAME + locationId.toUpperCase(),
  );
  if (details) {
    return JSON.parse(details);
  }
  return {};
}

export function getSessionCookie() {
  return (
    JSON.parse(getFromLocalStorage(globals.SESSION_COOKIE_NAME) || null) || null
  );
}

export function setSessionCookie(session) {
  setToLocalStorage(globals.SESSION_COOKIE_NAME, JSON.stringify(session));
}

export function deleteSessionCookie() {
  deleteFromLocalStorage(globals.SESSION_COOKIE_NAME);
}

export function getTenantCookie() {
  return getFromLocalStorage(globals.TENANT_COOKIE_NAME) || null;
}

export function setTenantCookie(tenant) {
  setToLocalStorage(globals.TENANT_COOKIE_NAME, tenant);
}

export function deleteTenantCookie() {
  deleteFromLocalStorage(globals.TENANT_COOKIE_NAME);
}

export function setWasIdleToStorage(idle) {
  setToSessionStorage(globals.IDLE_STORAGE_NAME, JSON.stringify(idle));
}

export function getWasIdleFromStorage() {
  return JSON.parse(getFromSessionStorage(globals.IDLE_STORAGE_NAME));
}

export function clearWasIdleFromStorage() {
  deleteFromSessionStorage(globals.IDLE_STORAGE_NAME);
}

export function setFeaturesInStorage(features, orgId) {
  const filteredFeatures = { filterOrg: orgId, features };
  setToSessionStorage(
    globals.FEATURES_STORAGE_NAME,
    JSON.stringify(filteredFeatures),
  );
}

export function getFeaturesFromStorage(orgId) {
  const filteredFeatures = JSON.parse(
    getFromSessionStorage(globals.FEATURES_STORAGE_NAME),
  );
  return orgId === filteredFeatures?.filterOrg
    ? filteredFeatures?.features
    : undefined;
}

// The below LastActive functions could be called through actions in the future if more components end up needing them
/**
 * gets the time the user was last active on the site from session storage
 * @returns {string}
 */
export function getLastActiveTime() {
  return JSON.parse(
    getFromSessionStorage(globals.LAST_ACTIVE_TIME_STORAGE_NAME),
  );
}

/**
 * Sets the time the user was last active to session storage.
 * I'm choosing not to key this by the location id since I don't think it's necessary, but it's an option to include for the future if we want to ensure the time is per-location.
 * @param time
 */
export function setLastActiveTime(time) {
  setToSessionStorage(
    globals.LAST_ACTIVE_TIME_STORAGE_NAME,
    JSON.stringify(time),
  );
}

export function setMigratedTransactionsCookie(session) {
  setToLocalStorage(
    globals.MIGRATED_TRANSACTIONS_STORAGE_NAME,
    JSON.stringify(session),
  );
}

export function deleteMigratedTransactionsCookie() {
  deleteFromLocalStorage(globals.MIGRATED_TRANSACTIONS_STORAGE_NAME);
}

export function setOriginToStorage(locationId, origin) {
  setToSessionStorage(
    globals.ORIGIN_STORAGE_NAME + locationId.toUpperCase(),
    origin,
  );
}

export function getOriginFromStorage(locationId) {
  return getFromSessionStorage(
    globals.ORIGIN_STORAGE_NAME + locationId.toUpperCase(),
  );
}

export function setLayoutViewToStorage(locationId, layout) {
  setToSessionStorage(
    globals.LAYOUT_VIEW_STORAGE_NAME + locationId.toUpperCase(),
    layout,
  );
}

export function getLayoutViewFromStorage(locationId) {
  return getFromSessionStorage(
    globals.LAYOUT_VIEW_STORAGE_NAME + locationId.toUpperCase(),
  );
}
