import { func, objectOf, shape, string } from 'prop-types';
import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/EditOutlined';
import styles from './styles';
import formatPhoneNumber from '../../../common/utils';

function ContactInformation({ classes, contactData, onEdit }) {
  const {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    email,
    phoneNumber,
  } = contactData;
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.contactHeader}>
        <Typography variant="h6">
          {t('mpPayment.contact.information')}
        </Typography>
        <Tooltip title={t('campaign.edit')}>
          <IconButton
            onClick={onEdit}
            className={classes.editButton}
            data-testid="edit-button"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.contactBody}>
        <Typography variant="body2">{`${firstName} ${lastName}`}</Typography>
        <Typography>{email}</Typography>

        <Typography>{addressLine1}</Typography>
        <Typography>{addressLine2}</Typography>
        <Typography>{`${city}, ${state} ${zipCode}`}</Typography>
        <Typography>{formatPhoneNumber(phoneNumber ?? '')}</Typography>
      </div>
    </div>
  );
}

ContactInformation.propTypes = {
  classes: objectOf(string).isRequired,
  contactData: shape({}).isRequired,
  onEdit: func.isRequired,
};

export default withStyles(styles)(ContactInformation);
