import { Fragment } from 'react';
import {
  QuillDeltaToHtmlConverter,
  InlineGroup,
  BlockGroup,
  ListGroup,
} from 'quill-delta-to-html';

const HEADERS = {
  H1: 1,
  H2: 2,
  H3: 3,
};

const LIST_TYPE = {
  BULLET: 'bullet',
  ORDERED: 'ordered',
};

export const textDescriptionToDelta = description => {
  const paragraphs = description.split('\n');
  return paragraphs.map((t, i) => {
    const breakLine =
      paragraphs.length - 1 === i ? '' : String.fromCharCode(10);
    return { insert: `${t}${breakLine}` };
  });
};

export const parseDescription = description => {
  if (typeof description === 'string') {
    try {
      const content = JSON.parse(description);
      return typeof content === 'object'
        ? content
        : textDescriptionToDelta(content);
    } catch {
      return textDescriptionToDelta(description);
    }
  } else {
    return description;
  }
};

export const getQuillDeltaGroupedOps = description => {
  const ops = parseDescription(description);
  const converter = new QuillDeltaToHtmlConverter(ops, {});

  return converter.getGroupedOps();
};

export const renderElement = (element, key) => {
  const {
    attributes = {},
    insert: { value },
  } = element;

  if (value.charCodeAt(0) === 10) {
    return <br key={key} />;
  }

  let component = value;

  if (attributes.bold) {
    component = <b>{component}</b>;
  }

  if (attributes.italic) {
    component = <em>{component}</em>;
  }

  if (attributes.underline) {
    component = <u>{component}</u>;
  }

  if (attributes.link) {
    component = (
      <a target="_blank" rel="noopener noreferrer" href={attributes.link}>
        {component}
      </a>
    );
  }

  return <Fragment key={key}>{component}</Fragment>;
};

export const renderHeader = (headerType, childrenComponents, key) => {
  switch (headerType) {
    case HEADERS.H1:
      return <h1 key={key}>{childrenComponents}</h1>;
    case HEADERS.H2:
      return <h2 key={key}>{childrenComponents}</h2>;
    case HEADERS.H3:
      return <h3 key={key}>{childrenComponents}</h3>;
    default:
      throw new Error('Invalid header type');
  }
};

export const renderInlineGroup = ({ ops }) => {
  return ops.map((c, i) => {
    return renderElement(c, `Inline-Element-${i}`);
  });
};

export const renderBlockGroup = (blockGroup, key) => {
  const {
    op: { attributes = {} },
    ops,
  } = blockGroup;
  const childrenComponents = ops.map((c, i) => {
    return renderElement(c, `BlockGroup-Element-${i}`);
  });

  if (attributes.header) {
    return renderHeader(attributes.header, childrenComponents, `Header-${key}`);
  }
  if (attributes.list) {
    return <li key={`ListItem-${key}`}>{childrenComponents}</li>;
  }

  return <Fragment key={`BlockGroup-${key}`}>{childrenComponents}</Fragment>;
};

const getListType = depth => {
  const listTypes = ['1', 'a', 'i'];
  return listTypes[depth % 3];
};

let renderListGroupRef; // Forward reference required due to mutually recursive functions

const renderList = (listType, items, key, depth) => {
  const childrenComponents = items.map((item, i) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`RenderList-${key}-${i}`}>
        {renderBlockGroup(item.item, `${key}-${i}`)}
        {item.innerList &&
          renderListGroupRef(item.innerList, `${key}-inner-${i}`, depth + 1)}
      </Fragment>
    );
  });

  const indent = items[0]?.item?.op?.attributes?.indent ?? 0;
  let wrapInList;
  switch (listType) {
    case LIST_TYPE.BULLET:
      wrapInList = function bulleted(content) {
        return <ul key={`ul-${key}`} style={{ margin: 0 }}>
          {content}
        </ul>
      };
      break;
    case LIST_TYPE.ORDERED:
      wrapInList = function ordered(content, totalDepth) {
        return <ol
          key={`ol-${key}`}
          style={{ margin: 0 }}
          type={getListType(totalDepth)}
        >
          {content}
        </ol>
      };
      break;
    default:
      throw new Error('Invalid list type');
  }

  const makeListRecursive = currentIndent => {
    const content =
      currentIndent < indent
        ? makeListRecursive(currentIndent + 1)
        : childrenComponents;
    return wrapInList(content, currentIndent);
  };
  return makeListRecursive(depth);
};

export const renderListGroup = (listGroup, key, depth) => {
  // The list type is stored on the individual list items, so we
  // need to retrieve that here
  const listType = listGroup.items[0]?.item?.op?.attributes?.list;
  const childrenComponents = renderList(listType, listGroup.items, key, depth);
  return <Fragment key={`ListGroup-${key}`}>{childrenComponents}</Fragment>;
};

renderListGroupRef = renderListGroup;

export const renderBlock = (component, key) => {
  switch (component.constructor) {
    case InlineGroup:
      return renderInlineGroup(component);
    case BlockGroup:
      return renderBlockGroup(component, key);
    case ListGroup:
      return renderListGroup(component, key, 0);
    default:
      throw new Error('Component not supported');
  }
};

export const renderDescriptionTileHTML = description => {
  const groups = getQuillDeltaGroupedOps(description);
  return groups.map((g, i) => renderBlock(g, i));
};
