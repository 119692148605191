import { connect } from 'react-redux';

import i18next from 'i18next';
import PaymentMethod from './PaymentMethod';
import {
  fetchSessionData,
  fetchUpdatedJWT,
  setHexeaObject,
  fetchPaymentID,
  makePayment,
} from '../../actions/paymentMethod';
import { fetchSavedPaymentMethods } from '../../actions/savedPaymentMethods';
import { fetchPaymentOptions, savePaymentOptions } from '../../actions/basket';
import { logError } from '../../../common/errorUtils';
import { ENQUEUE_SNACKBAR } from '../../actions/actionTypes';
import * as globals from '../../../common/globals';

import { getOrRenewAccessToken } from '../../../actions/session';
import { saveUserDetails } from '../../actions/user';
import { getFeatures } from '../../../actions/features';
import { checkStoredSession, endSession } from '../../actions/session';

export const enqueueSnackbar = (notification) => {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      variant: globals.NOTIFICATION_ERROR,
      message: i18next.t('errors.somethingWrong'),
      ...notification,
    },
  };
};

export const enqueueSnackbarAndLogError = (error, notification) => {
  logError(error);
  return enqueueSnackbar(notification);
};

const getSelectedPaymentTypeFromState = (state) => {
  // if there are no payment methods saved - default to credit card
  return state.basket.selectedPaymentType ?? globals.CREDIT_CARD;
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSessionDataConnect: (sessionId) => {
      return dispatch(fetchSessionData(sessionId));
    },
    fetchUpdatedJWTConnect: (url, success, transactionId) => {
      return dispatch(fetchUpdatedJWT(url, success, transactionId));
    },
    setHexeaObjectConnect: (pcct) => {
      dispatch(setHexeaObject(pcct));
    },
    fetchPaymentIdConnect: (sessionId) => {
      return dispatch(fetchPaymentID(sessionId));
    },
    fetchSavedPaymentMethodsConnect: (pcct) => {
      return dispatch(fetchSavedPaymentMethods(pcct));
    },
    fetchPaymentOptionsConnect: (pcct, isLoggedIn) => {
      return dispatch(fetchPaymentOptions(pcct, isLoggedIn));
    },
    enqueueSnackbarAndLogErrorConnect: (error, notification) => {
      return dispatch(enqueueSnackbarAndLogError(error, notification));
    },
    makePaymentMethodConnect: (
      paymentMethodDetails,
      paymentId,
      guestId,
      includeProcessingFee,
    ) => {
      return dispatch(
        makePayment(
          paymentMethodDetails,
          paymentId,
          guestId,
          includeProcessingFee,
        ),
      );
    },
    savePaymentOptionsConnect: (paymentOptions) => {
      return dispatch(savePaymentOptions(paymentOptions));
    },
    saveUserDetailsConnect: (userDetails, orgId, sessionId) => {
      return dispatch(saveUserDetails(userDetails, orgId, sessionId));
    },
    getOrRenewAccessTokenConnect: () => {
      return dispatch(getOrRenewAccessToken());
    },
    getFeatureFlagsConnect: (orgId) => {
      return dispatch(getFeatures(orgId));
    },
    checkStoredSessionConnect: () => {
      return dispatch(checkStoredSession());
    },
    endSessionConnect: (vpSessionId) => {
      return dispatch(endSession(vpSessionId));
    },
  };
};

export function mapStateToProps(state) {
  return {
    isFetchingLocationDetails:
      state.location?.isFetchingLocationDetails ?? false,
    isFetchingSavedMethods:
      state?.savedPaymentMethods?.isFetchingSavedMethods ?? false,
    isSavingPaymentMethod: state.paymentMethod?.isSavingPaymentMethod ?? false,
    isSavingUserDetails: state.user?.isSavingUserDetails ?? false,
    loggedIn: state.session.loggedIn,
    sessionData: state.paymentMethod.sessionData,
    hexea: state.paymentMethod.hexeaObject,
    paymentId: state.paymentMethod.paymentId,
    selectedPaymentType: getSelectedPaymentTypeFromState(state),
    user: state.user,
    paymentMethods: state?.savedPaymentMethods.savedMethods,
    isFetchingFeatures: state.features.isFetching,
    includeProcessingFee: state.basket.applyProcessingFee,
    features: state.features.features,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
