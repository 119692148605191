import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles, Button } from '@material-ui/core';
import { Field } from 'formik';

import { useTranslation } from 'react-i18next';
import * as globals from '../../common/globals';
import CreditCardPaymentMethod from './CreditCardPaymentMethod';
import ACHPaymentMethod from './ACHPaymentMethod';
import BillingInfo from './BillingInfo';
import MethodDropdown from './MethodDropdown';
import { renderCardIcon, hasScheduledTransactions } from '../../common/utils';
import FormikCheckbox from '../common/FormikCheckbox';
import IDSLoginDialog from '../common/IDSLoginDialog';
import NachaConfirmation from './NachaConfirmation';
import SwiperCard from './SwiperCard';

const styles = () => ({
  dropdown: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  formItemGroup: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  cardIcon: {
    marginRight: '10px',
  },
  button: {
    padding: 0,
    width: 'fit-content',
    display: 'table', // width: fit-content IE11 workaround
    fontWeight: 'bold',
    margin: '10px 0',
    '&:hover': {
      textDecoration: 'underline',
      background: 'transparent',
    },
  },
});

export function LoggedInWrapper(props) {
  const {
    selectedPaymentType,
    selectedMethod,
    classes,
    savedPaymentMethods,
    session,
    supportedPaymentTypes,
    paymentMethod,
    showErrors,
    setSelected,
    onUpdate,
    setValid,
    userDetailsComplete,
    formikProps,
    requestIDSDialogOpen,
    onRequestIDSDialogOpen,
    hasScheduledItems,
    features,
  } = props;

  const { t } = useTranslation();
  const [isIDSDialogOpen, setIsIDSDialogOpen] = useState(false);

  const allowedCardBrands =
    (supportedPaymentTypes.creditCard &&
      supportedPaymentTypes.creditCard.allowedCardBrands) ||
    [];

  const selectedMethods = savedPaymentMethods.savedMethods.filter(
    (method) => method.paymentMethodType === selectedPaymentType,
  );

  const allowedMethods =
    selectedPaymentType === globals.CREDIT_CARD
      ? selectedMethods.filter((method) =>
          allowedCardBrands.includes(method.brand),
        )
      : selectedMethods;

  const newMethodByType = {
    [globals.CREDIT_CARD]: t('accountPayment.newCard'),
    [globals.ACH]: t('accountPayment.newAccount'),
  };

  const newMethodLabel = newMethodByType[selectedPaymentType];

  const toggleIDSDialog = () => {
    setIsIDSDialogOpen(!isIDSDialogOpen);
  };

  const onParentLogin = () => {
    setIsIDSDialogOpen(false);
  };

  useEffect(() => {
    if (requestIDSDialogOpen) {
      setIsIDSDialogOpen(true);
      if (onRequestIDSDialogOpen) onRequestIDSDialogOpen();
    }
  }, [requestIDSDialogOpen, onRequestIDSDialogOpen]);

  return (
    <Fragment>
      {selectedPaymentType === globals.CREDIT_CARD && (
        <div className={classes.formItemGroup}>
          {allowedCardBrands?.map((card) =>
            renderCardIcon(card, classes.cardIcon),
          ) || []}
        </div>
      )}
      {session.loggedIn && allowedMethods.length > 0 && (
        <>
          <MethodDropdown
            id="dropdown"
            textFieldClassName={classes.dropdown}
            paymentMethods={allowedMethods}
            setSelected={(id, details) =>
              setSelected(selectedPaymentType)?.(id, details)
            }
            selectedMethod={selectedMethod[selectedPaymentType]}
            methodType={selectedPaymentType}
            dropdownLabel={t('accountPayment.selectMethod')}
            newMethodLabel={newMethodLabel}
          />
          {selectedPaymentType === globals.ACH &&
            selectedMethod[selectedPaymentType] !==
              globals.NEW_PAYMENT_METHOD && (
              <NachaConfirmation
                name={`${globals.ACH}.nacha`}
                id="nacha"
                {...formikProps}
              />
            )}
        </>
      )}
      {(selectedMethod[selectedPaymentType] === globals.NEW_PAYMENT_METHOD ||
        !(session.loggedIn && allowedMethods.length > 0)) && (
        <Fragment>
          {selectedPaymentType === globals.SWIPE && <SwiperCard />}
          {selectedPaymentType === globals.CREDIT_CARD && (
            <CreditCardPaymentMethod
              className="creditCardWidget"
              paymentMethod={paymentMethod}
              onUpdate={(name, value) => onUpdate('cardInfo')?.(name, value)}
              setValid={(valid) => setValid('cardInfo')?.(valid)}
              showErrors={showErrors}
            />
          )}
          {selectedPaymentType === globals.ACH && (
            <>
              <ACHPaymentMethod className="ACHWidget" />
              <NachaConfirmation
                name={`${globals.ACH}.nacha`}
                id="nacha"
                {...formikProps}
              />
            </>
          )}
          {session.loggedIn ? (
            <Field
              name="billingInfo.saveMethod"
              component={FormikCheckbox}
              id="save-method"
              label={t('billingInfo.savePaymentMethod')}
              {...formikProps}
            />
          ) : (
            !window.ReactNativeWebView && (
              <>
                <Button
                  color="primary"
                  disableRipple
                  className={classes.button}
                  onClick={toggleIDSDialog}
                >
                  {t('billingInfo.logInSavedPaymentMethods')}
                </Button>
                {features.GuestScheduledTransactions && hasScheduledItems && (
                  <Field
                    name="billingInfo.saveMethod"
                    component={FormikCheckbox}
                    id="save-method"
                    label={t('billingInfo.savePaymentMethod')}
                    description={t('billingInfo.savePaymentMethod.description')}
                    {...formikProps}
                  />
                )}
              </>
            )
          )}
          {!userDetailsComplete && (
            <BillingInfo id="billing-info" hideEmail={session.loggedIn} />
          )}
        </Fragment>
      )}
      <IDSLoginDialog
        id="payment-method-login-dialog"
        open={isIDSDialogOpen}
        onClose={toggleIDSDialog}
        onParentLogin={onParentLogin}
      />
    </Fragment>
  );
}

LoggedInWrapper.propTypes = {
  session: PropTypes.object.isRequired,
  savedPaymentMethods: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  selectedPaymentType: PropTypes.string.isRequired,
  selectedMethod: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  supportedPaymentTypes: PropTypes.object.isRequired,
  userDetailsComplete: PropTypes.bool,
  formikProps: PropTypes.object.isRequired,
  requestIDSDialogOpen: PropTypes.bool,
  onRequestIDSDialogOpen: PropTypes.func,
  hasScheduledItems: PropTypes.bool.isRequired,
  features: PropTypes.object.isRequired,
};

LoggedInWrapper.defaultProps = {
  userDetailsComplete: false,
  requestIDSDialogOpen: false,
  onRequestIDSDialogOpen: null,
};

const mapStateToProps = (state) => {
  const { session, savedPaymentMethods } = state;
  return {
    session,
    savedPaymentMethods,
    userDetailsComplete: state.user.detailsComplete,
    hasScheduledItems: hasScheduledTransactions(state.basket.items),
    features: state.features?.features,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(LoggedInWrapper);
