import {
  PAYMENT_SUBMIT_BEGIN,
  PAYMENT_SUBMIT_COMPLETE,
  PAYMENT_SUBMIT_ERROR,
  PAYMENT_SUBMITTED_ID_FETCH,
  PAYMENT_SUBMITTED_USER_DETAILS_FETCH,
} from '../actions/actionTypes';

const initialState = {
  isSubmittingPayment: false,
  submittedPaymentId: null,
  isSubmittedPaymentLoaded: false,
  userDetails: {},
};

export default function payment(state = initialState, action = {}) {
  switch (action.type) {
    case PAYMENT_SUBMIT_BEGIN: {
      return {
        ...state,
        isSubmittingPayment: true,
        isSubmittedPaymentLoaded: false,
      };
    }
    case PAYMENT_SUBMIT_COMPLETE: {
      return { ...state, isSubmittingPayment: false };
    }
    case PAYMENT_SUBMIT_ERROR: {
      return { ...state, isSubmittingPayment: false };
    }
    case PAYMENT_SUBMITTED_ID_FETCH: {
      return {
        ...state,
        submittedPaymentId: action.submittedPaymentId,
        isSubmittedPaymentLoaded: true,
      };
    }
    case PAYMENT_SUBMITTED_USER_DETAILS_FETCH: {
      return {
        ...state,
        userDetails: action.details,
      };
    }
    default: {
      return state;
    }
  }
}
