import {
  IDS_POST_REDIRECT,
  INVALID_LOCATIONS_ID_BLACKLIST,
} from '../common/globals';

export const isTokenExpired = (session) => {
  const currentDate = Date.now();
  const { tExpires, accessToken } = session;
  return !!accessToken && !!accessToken.length && currentDate > tExpires;
};

export const createSession = (session) => {
  const currentDate = Date.now();
  return {
    ...session,
    tExpires: currentDate + session.expiresIn * 1000, // 1h expiration
  };
};

/**
 * Parses our urls to find the location id, which is currently the first path parameter.
 * IMPORTANT: this only works properly on pages that start with the location id,
 * so this won't do what you want on the error page or post-redirect page for example. Use with caution.
 * @returns {string}
 */
export const getLocationIdFromUrl = () => {
  const locationId = window.location.pathname.split('/')[1];
  const isNotValidId = INVALID_LOCATIONS_ID_BLACKLIST.some(
    (item) => item === locationId,
  );
  if (isNotValidId || locationId === IDS_POST_REDIRECT) {
    return '';
  }
  return locationId;
};
