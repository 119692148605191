import { Redirect } from 'react-router-dom';
import Profile from '../../../views/profile/Profile';
import {
  getCurrentPathNameWithParams,
  hasScheduledTransactions,
} from '../../../common/utils';
import * as globals from '../../../common/globals';
import PaymentMethod from '../../../views/paymentMethod/PaymentMethod';
import Review from '../../../views/review/Review';
import ThankYou from '../../../views/thankYou/ThankYou';
import IDSLoginWrapper from '../../common/IDSLoginWrapper';

// eslint-disable-next-line import/prefer-default-export
export function getPaymentMethodComponent(
  paymentMethodProps,
  session,
  basket,
  features,
) {
  const {
    match: {
      params: { locId },
    },
  } = paymentMethodProps;

  if (basket.isBasketLoaded) {
    if (basket.items.length === 0) {
      return <Redirect to={`/${locId}/${globals.HOME_PATH}`} />;
    }

    const shouldRedirectToBasket =
      session.isSessionLoaded &&
      session.loggedIn === false &&
      hasScheduledTransactions(basket.items) &&
      /*
       * Edge case: when user types YGJ2/payment on browser,
       * features param value is gonna be {}, since the features have not been loaded
       * hasLoaded = false. Therefore, it will redirect
       * to /basket instead.
       * While this is acceptable, the Express layout won't
       * match the criteria since features param, for this case, is defined {..., GuestScheduledTransactions: bool}.
       */
      !features.GuestScheduledTransactions;

    if (shouldRedirectToBasket) {
      return <Redirect to={`/${locId}/${globals.BASKET_PATH}`} />;
    }
  }

  return <PaymentMethod {...paymentMethodProps} />;
}

export function getReviewComponent(
  reviewProps,
  session,
  basket,
  paymentMethod,
  features,
) {
  const {
    match: {
      params: { locId },
    },
  } = reviewProps;

  // last4 is the one common property among all payment types, so this will
  // let us know if a payment method has been selected.
  if (paymentMethod.isSelectedPaymentMethodLoaded && !paymentMethod.last4) {
    return <Redirect to={`/${locId}/${globals.PAYMENT_PATH}`} />;
  }

  if (basket.isBasketLoaded) {
    if (basket.items.length === 0) {
      return <Redirect to={`/${locId}/${globals.HOME_PATH}`} />;
    }

    if (
      !features.GuestScheduledTransactions &&
      session.isSessionLoaded &&
      session.loggedIn === false &&
      hasScheduledTransactions(basket.items)
    ) {
      return <Redirect to={`/${locId}/${globals.BASKET_PATH}`} />;
    }
  }

  return <Review {...reviewProps} />;
}

export function getThankYouComponent(thankYouProps, payment) {
  const {
    match: {
      params: { locId },
    },
  } = thankYouProps;

  if (payment.isSubmittedPaymentLoaded && !payment.submittedPaymentId) {
    return <Redirect to={`/${locId}/${globals.HOME_PATH}`} />;
  }

  return <ThankYou {...thankYouProps} />;
}

export function getProfileComponent(profileProps, session, user, features) {
  const {
    match: {
      params: { locId },
    },
  } = profileProps;

  if (!features.hasLoadedFeatures)
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;

  if (features?.features.ImportExternalPayerRecurrence) {
    if (session.loggedIn) {
      return <Profile {...profileProps} user={user} />;
    }
    if (session.isSessionLoaded && !session.loggedIn) {
      return <IDSLoginWrapper returnTo={getCurrentPathNameWithParams()} />;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  // Remove on ImportExternalPayerRecurrence FF cleanup
  return session.isSessionLoaded && session.loggedIn === false ? (
    <Redirect to={`/${locId}/${globals.HOME_PATH}`} />
  ) : (
    <Profile {...profileProps} user={user} />
  );
}
