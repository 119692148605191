import * as storageUtils from '../../common/storageUtils';
import * as globals from '../../common/globals';
import { CAMPAIGN_TYPE } from '../../common/globals';
import { basketItemsMatch } from '../../common/utils';
import {
  BASKET_SET_ITEMS,
  BASKET_SET_PAYMENT_OPTIONS,
  CLEAR_PAYMENT_OPTIONS,
} from './actionTypes';

function updateOrAddPaymentItem(locationId, campaignId, createItem) {
  const items = storageUtils.getBasketItemsFromStorage(locationId);
  const existingItem = (items || []).find(
    (i) => i.campaignDetails.id === campaignId,
  );

  const newItems = items.filter((i) => i.campaignDetails.id !== campaignId);
  const item = createItem(existingItem);
  return [...newItems, item];
}
function combinePaymentItems(existingItem, newItem) {
  if (!existingItem) {
    return newItem;
  }

  const transactionData =
    existingItem.transactionData[newItem.campaignDetails.id];
  const newTransactionData =
    newItem.transactionData[newItem.campaignDetails.id];

  return {
    campaignDetails: {
      ...existingItem.campaignDetails,
      ...newItem.campaignDetails,
    },
    transactionData: {
      ...existingItem.transactionData,
      [newItem.campaignDetails.id]: {
        ...transactionData,
        ...newTransactionData,
        quantity: newTransactionData.quantity + transactionData.quantity,
        amount:
          parseFloat(newTransactionData.amount) +
          parseFloat(transactionData.amount),
      },
    },
  };
}

export function updatePaymentItemQuantity(locationId, campaignId, quantity) {
  const combineItems = (existingItem) => {
    if (!existingItem) {
      throw new Error('Failed to find an existing payment item to update');
    }

    const transactionData = existingItem.transactionData[campaignId];

    return {
      ...existingItem,
      transactionData: {
        ...existingItem.transactionData,
        [campaignId]: {
          ...transactionData,
          quantity,
          amount: parseFloat(transactionData.itemAmount) * quantity,
        },
      },
    };
  };
  const items = updateOrAddPaymentItem(locationId, campaignId, combineItems);
  storageUtils.setBasketItemsToStorage(locationId, items);
  return { type: BASKET_SET_ITEMS, items };
}

export function addBasketItem(locationId, item) {
  let items;
  if (item.campaignDetails.type === CAMPAIGN_TYPE.GIFT) {
    items = storageUtils.getBasketItemsFromStorage(locationId);
    items.push(item);
  } else {
    const combineItems = (existingItem) =>
      combinePaymentItems(existingItem, item);
    items = updateOrAddPaymentItem(
      locationId,
      item.campaignDetails.id,
      combineItems,
    );
  }

  storageUtils.setBasketItemsToStorage(locationId, items);
  return { type: BASKET_SET_ITEMS, items };
}

export function removeBasketItem(locationId, item) {
  const items = storageUtils
    .getBasketItemsFromStorage(locationId)
    .filter((storedItem) => !basketItemsMatch(storedItem, item));
  storageUtils.setBasketItemsToStorage(locationId, items);
  return { type: BASKET_SET_ITEMS, items };
}

export function clearBasketItems(locationId) {
  storageUtils.clearBasketItemsFromStorage(locationId);
  return { type: BASKET_SET_ITEMS, items: [] };
}

export function loadBasketContents(locationId) {
  const items = storageUtils.getBasketItemsFromStorage(locationId);
  return { type: BASKET_SET_ITEMS, items };
}

export function savePaymentOptions(newOptions) {
  return {
    type: BASKET_SET_PAYMENT_OPTIONS,
    paymentOptions: newOptions,
  };
}

export function clearPaymentOptions(locationId) {
  storageUtils.clearPaymentOptionsFromStorage(locationId);
  return {
    type: CLEAR_PAYMENT_OPTIONS,
  };
}

export function fetchPaymentOptions(pcct, isLoggedIn) {
  if (!isLoggedIn) {
    return {
      type: BASKET_SET_PAYMENT_OPTIONS,
      paymentOptions: {
        paymentType: globals.CREDIT_CARD,
        applyProcessingFee: false,
        processingFee: 1,
      },
    };
  }

  // figure out what call is storing the payement method options
  return {
    type: BASKET_SET_PAYMENT_OPTIONS,
    paymentOptions: storageUtils.getPaymentOptionsFromStorage(pcct),
  };
}
