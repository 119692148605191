import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import USStates from './usStates';

export default function StateDropdown({ field, form, ...props }) {
  const { t } = useTranslation();
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;
  return (
    <TextField
      name="billingInfo.state"
      autoComplete="section-billing address-level1"
      id="state"
      label={t('billingInfo.state')}
      select
      variant="outlined"
      value={field.value}
      error={showError}
      helperText={showError ? fieldError : ''}
      disabled={isSubmitting}
      onChange={(event) => {
        form.setFieldValue(field.name, event.target.value);
      }}
      SelectProps={{
        native: true,
      }}
      {...props}
    >
      <option key="" value="">
        {' '}
      </option>
      {USStates.map((state) => (
        <option key={state.abbreviation} value={state.abbreviation}>
          {state.name}
        </option>
      ))}
    </TextField>
  );
}

StateDropdown.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};
