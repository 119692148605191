import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/common/modal';
import EditForm from './form';
import useEditTransactionModal from './useEditTransactionModal';

const styles = () => ({
  title: {
    '& h6': {
      color: '#222222',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
  submitButton: {
    fontWeight: 'bold',
  },
});

export function EditTransactionModal(props) {
  const {
    transaction,
    onSubmit,
    onClose,
    isOpen,
    paymentMethods,
    areSavedMethodsLoaded,
    classes,
  } = props;

  const {
    handleSubmit,
    getValidationSchema,
    renderModalActions,
    isDataLoading,
    getInitialFormValues,
    handleCloseModal,
    isSubmittingValues,
    transactionDetails,
    editableCampaigns,
  } = useEditTransactionModal({
    transaction,
    paymentMethods,
    onSubmit,
    onClose,
    classes,
    areSavedMethodsLoaded,
  });

  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
      initialValues={getInitialFormValues()}
      validationSchema={getValidationSchema()}
    >
      {(formikProps) => (
        <Modal
          open={isOpen}
          onClose={() =>
            handleCloseModal(formikProps.touched, formikProps.values)
          }
        >
          <Modal.Title className={classes.title} isLoading={isSubmittingValues}>
            {t('transactions.scheduled.editTransaction')}
          </Modal.Title>
          <Modal.Content>
            <Form className={classes.form}>
              {isDataLoading ? (
                <Skeleton variant="rect" width="100%" height="5.5rem" />
              ) : (
                <EditForm
                  transaction={transactionDetails}
                  paymentMethods={paymentMethods}
                  formikProps={formikProps}
                  campaigns={editableCampaigns}
                />
              )}
              {renderModalActions(formikProps)}
            </Form>
          </Modal.Content>
        </Modal>
      )}
    </Formik>
  );
}

EditTransactionModal.propTypes = {
  transaction: PropTypes.object.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  areSavedMethodsLoaded: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
};

EditTransactionModal.defaultProps = {
  onClose: null,
  isOpen: false,
  paymentMethods: [],
};

export default withStyles(styles)(EditTransactionModal);
