import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import { useHistory, withRouter } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as globals from '../../common/globals';
import * as storageUtils from '../../common/storageUtils';
import { addBasketItem } from '../../actions/basket';
import {
  enqueueSnackbar,
  enqueueSnackbarAndLogError,
} from '../../actions/notifications';
import ExpressLayoutForm from './form';
import {
  getErrorMessage,
  getPaymentId,
  getTransactionDetailsFromCampaign,
  hasError,
  isPayment,
} from '../../utils/CampaignUtils';
import styles from './styles';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { basketItemsMatch } from '../../common/utils';
import transformCampaignDetails from '../../api/transform/CampaignTransform';
import useFIPLogin from '../../hooks/transactionsHooks/useFIPLogin';
import useBasketLoginDialog from '../../hooks/transactionsHooks/useBasketLoginDialog';
import BasketLoginDialog from '../../views/basket/BasketLoginDialog';

export function ExpressTiles({
  classes,
  campaigns,
  enableDefaultFrequency,
  locationId,
  basketIds,
  addBasketItemConnect,
  enqueueSnackbarConnect,
  enqueueSnackbarAndLogErrorConnect,
}) {
  const history = useHistory();
  const [state, setState] = useState({
    errorModalOpen: false,
    errorModalMessage: null,
    // item data passed in from cart. We use cloneDeep to ensure we're not modifying the redux store contents with our operations,
    // but we might be able to do this using just layered shallow copies. But since we're only modifying the store once at the end
    // and the only item being changed is the current campaign, this approach might be fine. We should look into this more later.
    cartData: null,
  });
  const defaultTile = {
    memoLine: {},
    fundId: '',
    frequency: '',
    frequencies: [],
    amount: '',
    itemAmount: 0,
    quantity: 1,
  };

  const [transactionDetails, setTransactionDetails] = useState(defaultTile);

  const { t } = useTranslation();

  const { shouldForceLoginByItem } = useFIPLogin();
  const { isIDSDialogOpen, toggleIDSDialog, closeIDSDialog } =
    useBasketLoginDialog();

  const onSelectTile = (tileId) => {
    if (tileId !== transactionDetails?.fundId) {
      const currentTransactionDetails = getTransactionDetailsFromCampaign(
        campaigns.find((e) => e.id === tileId),
      );
      setTransactionDetails(currentTransactionDetails);
    }
  };

  const setErrorModalOpen = ({ isOpen, errorMessage }) => {
    setState((prevState) => {
      return {
        ...prevState,
        errorModalOpen: isOpen,
        errorModalMessage: isOpen ? errorMessage : null,
      };
    });
  };

  /**
   * Catches the submit event from the form, compiles the data that's been passed up by each child transaction item, and sends it through redux to be added to the basket.
   * Then redirects to the basket page.
   * If the user pressed "Continue to payment" they are taken to that page
   */
  const addToBasket = (values, actions) => {
    const { toPayment = false } = values;

    const campaignDetail = transformCampaignDetails(
      campaigns.find((e) => e.id === values.fundId),
    );

    const itemToAdd = values?.[values.fundId];

    const shouldForceLogin = shouldForceLoginByItem(itemToAdd);
    if (toPayment && shouldForceLogin) {
      toggleIDSDialog();
      return;
    }
    const memoLine = itemToAdd?.memoLine?.trim() ?? '';

    const newBasketItem = {
      campaignDetails: {
        ...campaignDetail,
        transactionItems: [transactionDetails],
      },
      transactionData: {
        [values.fundId]: {
          ...itemToAdd,
          memoLine,
          frequency:
            itemToAdd.frequency ?? globals.PAYMENT_FREQUENCY_ONE_TIME_NOW, // Default value for a Payment
        },
      },
    };

    const storedItems = storageUtils.getBasketItemsFromStorage(locationId);
    if (isPayment(campaignDetail)) {
      const paymentId = getPaymentId(newBasketItem);
      const existingPaymentItems = storedItems.filter((item) =>
        Boolean(item.transactionData[paymentId]),
      );
      if (hasError(newBasketItem, existingPaymentItems[0] || {})) {
        setErrorModalOpen({
          isOpen: true,
          errorMessage: getErrorMessage(
            newBasketItem,
            existingPaymentItems[0] || {},
          ),
        });
        actions.setSubmitting(false);
        return;
      }
    } else {
      const matchingItems = storedItems.filter((it) =>
        basketItemsMatch(it, newBasketItem),
      );
      if (matchingItems.length > 0) {
        setErrorModalOpen({
          isOpen: true,
          errorMessage: t('basket.itemAlreadyInBasket'),
        });
        actions.setSubmitting(false);
        return;
      }
    }

    try {
      addBasketItemConnect(locationId, newBasketItem);
      if (toPayment) {
        history.push(`/${locationId}/${globals.PAYMENT_PATH}`);
      } else {
        // Clear form and show notification
        actions.resetForm();
        setTransactionDetails(defaultTile);
        enqueueSnackbarConnect({
          variant: globals.NOTIFICATION_SUCCESS,
          message: t('addDonation.added.to.basket'),
        });
        history.push(`/${locationId}/${globals.HOME_PATH}`);
      }
    } catch (e) {
      enqueueSnackbarAndLogErrorConnect(e, {
        variant: globals.NOTIFICATION_ERROR,
        message: e.message,
      });
    } finally {
      actions.setSubmitting(false);
    }

    actions.setSubmitting(false);
  };

  const formProps = {
    transactionDetails,
    enableDefaultFrequency,
    classes,
    basketIds,
    campaigns,
    locationId,
    onSelectTile,
    addToBasket,
  };

  return (
    <>
      <ExpressLayoutForm {...formProps} />
      <ConfirmationDialog
        title={t('basket.errorModal.title')}
        message={state.errorModalMessage}
        submitButtonText={t('app.gotIt')}
        onSubmit={() => setErrorModalOpen({ isOpen: false })}
        isOpen={state.errorModalOpen}
      />

      <BasketLoginDialog
        id="basket-login-dialog"
        open={isIDSDialogOpen}
        onClose={toggleIDSDialog}
        returnTo={`/${locationId}/${globals.HOME_PATH}`}
        closeIDSDialog={closeIDSDialog}
      />
    </>
  );
}

ExpressTiles.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  enableDefaultFrequency: PropTypes.bool,
  locationId: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
  basketIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  addBasketItemConnect: PropTypes.func.isRequired,
  enqueueSnackbarConnect: PropTypes.func.isRequired,
  enqueueSnackbarAndLogErrorConnect: PropTypes.func.isRequired,
};

ExpressTiles.defaultProps = {
  enableDefaultFrequency: false,
};

const mapStateToProps = (state) => ({
  items: state.basket.items || [],
});

const mapDispatchToProps = (dispatch) => ({
  addBasketItemConnect: (locationId, item, keepExistingCampaignItems) => {
    dispatch(addBasketItem(locationId, item, keepExistingCampaignItems));
  },
  enqueueSnackbarAndLogErrorConnect: (error, notification) => {
    return dispatch(enqueueSnackbarAndLogError(error, notification));
  },
  enqueueSnackbarConnect: (error, notification) => {
    return dispatch(enqueueSnackbar(error, notification));
  },
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ExpressTiles);
