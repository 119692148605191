import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  silentIcon: {
    color: theme.palette.secondary.light,
    scale: 0.8,
    cursor: 'default',
  },
  tooltipContent: {
    padding: '12px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    '&>img': {
      width: '200px',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  caption: {
    position: 'relative',
  },
  description: {
    fontSize: '1.4rem',
  },
  line: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'end',
    borderRight: `2px solid ${theme.palette.tooltip.accent}`,
    borderBottom: `2px solid ${theme.palette.tooltip.accent}`,
    right: '26px',
    bottom: '8px',
    width: '35px',
    height: '75px',
  },
  noAmex: {
    right: '28px',
    height: '55px',
  },
  ellipsis: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: theme.palette.tooltip.accent,
    transform: 'translateY(4px)',
  },
}));
