import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGetCampaignById } from '../../../../hooks';
import { useUpdateScheduledTransaction } from '../../../../hooks/transactionsHooks/transactionsHooks';
import { useGetEditableCampaignsByOrgURI } from '../../../../hooks/campaignsHooks/campaignsHooks';
import filterNonOneNowFrequencies from '../../../../common/frequenciesUtils';
import { isValidResponseDate } from '../../../../utils/CalendarUtils';
import {
  convertDecimalToString,
  isObjectEmptyOrNull,
} from '../../../../common/utils';
import { transformUpdateScheduleTransactionRequest } from '../../../../api/transform/TransactionTransform';
import { saveSelectedPaymentMethod } from '../../../../actions/paymentMethod';
import createSchema from '../../../../common/validation/EditScheduledValidationUtils';
import { Modal } from '../../../../components/common/modal';
import * as globals from '../../../../common/globals';

const useEditTransactionModal = ({
  transaction,
  paymentMethods,
  onSubmit,
  onClose,
  classes,
  areSavedMethodsLoaded,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedPaymentMethod] = useState(transaction.last4);
  const [selectedPaymentMethodToken, setSelectedPaymentMethodToken] =
    useState(null);
  const [isSubmittingValues, setIsSubmittingValues] = useState(null);

  useEffect(() => {
    if (
      selectedPaymentMethod &&
      paymentMethods.filter((p) => p.last4 === selectedPaymentMethod)
    ) {
      setSelectedPaymentMethodToken(
        paymentMethods.filter((p) => p.last4 === selectedPaymentMethod)[0]
          ?.paymentMethodToken,
      );
    }
  }, [paymentMethods, selectedPaymentMethod]);

  const [userDetails, paymentId, organizationURI] = useSelector(
    ({ user, paymentMethod, location }) => [
      user,
      paymentMethod.paymentId,
      location.organizationId,
    ],
  );

  const isLegacyCampaign = !transaction.campaignId;

  const { data: campaignDetails, isFetching: isCampaignByIdLoading } =
    useGetCampaignById(transaction.campaignId);

  const { updateScheduledTransaction } = useUpdateScheduledTransaction();
  const { data: campaigns = [], isFetching: areEditableCampaignLoading } =
    useGetEditableCampaignsByOrgURI(organizationURI);

  const legacyFrequencies = isLegacyCampaign ? [transaction.frequency] : [];

  const defaultFrequencies =
    campaignDetails && Object.keys(campaignDetails).length > 0
      ? filterNonOneNowFrequencies(
          campaignDetails.transactionItems?.[0]?.frequencies,
        )
      : legacyFrequencies;

  const transactionDetails = {
    ...transaction,
    frequencies: defaultFrequencies,
  };

  const editableCampaigns = campaigns.map(
    ({
      id,
      content: { title },
      donationOptions,
      showEndDate,
      memoFields,
      responseRequired,
      isTextFieldResponseType,
      fundRefId,
    }) => {
      const paymentFrequenciesWithoutOneTimeNow = filterNonOneNowFrequencies(
        donationOptions?.paymentFrequencies || [],
      );

      return {
        id,
        title,
        showEndDate,
        memoFields,
        responseRequired,
        isTextFieldResponseType,
        paymentFrequencies: paymentFrequenciesWithoutOneTimeNow,
        fundRefId,
      };
    },
  );

  const getDefaultEditableCampaignId = () => {
    if (!isLegacyCampaign) {
      return (
        editableCampaigns?.find((e) => e.id === transaction.campaignId)?.id ??
        ''
      );
    }

    const matchedFundRefIdCampaigns = editableCampaigns?.filter(
      (ec) => ec.fundRefId === transaction.fundRefId,
    );

    const hasOnlyOneFundRefIdCampaign = matchedFundRefIdCampaigns?.length === 1;

    return hasOnlyOneFundRefIdCampaign
      ? matchedFundRefIdCampaigns?.[0]?.id ?? ''
      : '';
  };

  const shouldShowEndDate =
    transaction.frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE
      ? isValidResponseDate(transaction.endDate)
      : false;

  const getInitialFormValues = () => {
    const data = {
      [`${transaction.fundId}`]: {
        fundId: transaction.fundId,
        selectedCampaign: transaction.campaignId,
        paymentToken: selectedPaymentMethodToken ?? null,
        paymentMethodUpdated: false,
        amount: convertDecimalToString(transaction.totalAmount),
        frequency: transaction.frequency,
        startDate: transaction.startDate ? moment(transaction.startDate) : null,
        endDate: shouldShowEndDate ? moment(transaction.endDate) : null,
        showEndDate: shouldShowEndDate,
        memoLine: {},
        paymentId: transaction.paymentId,
      },
      editableCampaignId: getDefaultEditableCampaignId(),
    };

    return data;
  };

  const getMemoLine = (currentCampaignId) => {
    const {
      responseRequired = false,
      isTextFieldResponseType = false,
      memoFields = [],
    } = editableCampaigns?.find((x) => currentCampaignId === x.id) ?? {};

    if (!responseRequired) return '';

    if (isTextFieldResponseType) return 'donation';

    return memoFields?.[0]?.customText ?? '';
  };

  const handleSubmit = async (formValues, actions) => {
    if (isSubmittingValues) {
      return;
    }
    /*
     * If anything have changed do not submit data.
     * Discard editableCampaignId from initial form values
     */
    const preSelectedCampaignId = transaction.fundId;
    const currentCampaignId = formValues.editableCampaignId;

    const isSameData =
      isEqual(
        formValues[preSelectedCampaignId],
        getInitialFormValues()[preSelectedCampaignId],
      ) && currentCampaignId === Object.keys(formValues)[0];

    if (isSameData) {
      actions.setSubmitting(false);
      return;
    }

    setIsSubmittingValues(true);
    const newPaymentMethod = paymentMethods.find(
      (paymentMethod) =>
        paymentMethod.paymentMethodToken ===
        formValues[transaction.fundId].paymentToken,
    );

    const { paymentToken, data } = transformUpdateScheduleTransactionRequest(
      {
        ...formValues[transaction.fundId],
        editableCampaignId: formValues.editableCampaignId,
        memoLine: getMemoLine(currentCampaignId),
      },
      userDetails,
      transactionDetails,
    );

    let succeeded = true;
    try {
      await dispatch(saveSelectedPaymentMethod(newPaymentMethod, paymentId));
      await updateScheduledTransaction({
        paymentToken,
        data,
      });
    } catch (e) {
      succeeded = false;
    } finally {
      actions.setSubmitting(false);
      setIsSubmittingValues(false);
      onSubmit(succeeded);
    }
  };

  function getValidationSchema() {
    return createSchema(transaction);
  }

  const handleCloseModal = (touchedFields, currentFormValues) => {
    if (isSubmittingValues) {
      return;
    }
    const canCloseModal =
      isObjectEmptyOrNull(touchedFields) ||
      isEqual(getInitialFormValues(), currentFormValues);

    onClose(canCloseModal);
  };

  const renderModalActions = (formikProps) => (
    <Modal.Actions>
      {onClose && (
        <Button
          color="primary"
          onClick={() =>
            handleCloseModal(formikProps.touched, formikProps.values)
          }
          disabled={isSubmittingValues}
        >
          {t('transactions.scheduled.cancel.closeButtonText')}
        </Button>
      )}
      <Button
        className={classes.submitButton}
        color="primary"
        variant="contained"
        type="submit"
        disabled={isSubmittingValues}
      >
        {t('transactions.scheduled.save')}
      </Button>
    </Modal.Actions>
  );

  const fetchingQueries = isCampaignByIdLoading || areEditableCampaignLoading;

  const isDataLoading =
    !areSavedMethodsLoaded || fetchingQueries || !transactionDetails;

  return {
    handleSubmit,
    getValidationSchema,
    renderModalActions,
    isDataLoading,
    getInitialFormValues,
    handleCloseModal,
    isSubmittingValues,
    transactionDetails,
    editableCampaigns,
  };
};

export default useEditTransactionModal;
