import PropTypes from 'prop-types';
import { withStyles, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import FormHelperTextBottom from 'components/common/FormHelperTextBottom';
import { MIN_TRANSACTION_AMOUNT } from 'common/globals';
import useInstallments from '../../../hooks/useInstallments';

const commonStyles = (theme) => {
  return {
    cardTitle: {
      display: 'flex',
      alignItems: 'center',
      color: '#696969',
      justifyContent: 'center',
    },
    errorText: {
      color: theme.palette.error.main,
    },
  };
};
const styleOverrides = (theme) => ({
  container: {
    padding: 16,
    backgroundColor: '#E5E5E5',
    borderRadius: 8,
    marginTop: 16,
    marginBottom: 16,
  },
  elementContainer: {
    marginTop: 8,
    height: 56,
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    alignContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  cardTitle: {
    ...commonStyles(theme).cardTitle,
  },
  cardTitleError: {
    ...commonStyles(theme).cardTitle,
    ...commonStyles(theme).errorText,
  },
  subtitle: {
    color: '#676B70',
    fontSize: 12,
    marginTop: 8,
    textAlign: 'center',
  },
});

function InstallmentOverview({
  classes,
  values,
  setInstallmentsValid,
  errorMessage,
}) {
  const { t } = useTranslation();

  const { amount, frequency, startDate, endDate } = values;

  const {
    totalAmount,
    quantity,
    isSameRate,
    firstInstallment,
    rate,
    isValidData,
    hasAllRequiredValues,
  } = useInstallments(amount, frequency, startDate, endDate);

  useEffect(() => {
    setInstallmentsValid(isValidData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidData]);

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {t('addDonation.installments.overview')}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs>
          {/* Installment Amount */}
          <div className={classes.elementContainer}>
            <Typography className={classes.cardTitle}>
              {hasAllRequiredValues ? quantity : '-'}
            </Typography>
          </div>
          <Typography className={classes.subtitle}>
            {t('addDonation.installments')}
          </Typography>
        </Grid>

        <Grid item xs>
          <div className={classes.elementContainer}>
            <Typography
              className={
                isValidData || !hasAllRequiredValues
                  ? classes.cardTitle
                  : classes.cardTitleError
              }
            >
              {hasAllRequiredValues
                ? `$ ${isSameRate ? rate : firstInstallment}`
                : '-'}
            </Typography>
          </div>
          <Typography className={classes.subtitle}>
            {t(
              isSameRate && quantity > 1
                ? 'addDonation.installments.amount.all'
                : 'addDonation.installments.amount',
            )}
            {!isSameRate && quantity >= 2 && ''}
          </Typography>
        </Grid>

        {!isSameRate && (
          <Grid item xs>
            <div className={classes.elementContainer}>
              <Typography
                className={
                  isValidData ||
                  !hasAllRequiredValues ||
                  rate >= MIN_TRANSACTION_AMOUNT
                    ? classes.cardTitle
                    : classes.cardTitleError
                }
              >
                {`$ ${rate}`}
              </Typography>
            </div>
            <Typography className={classes.subtitle}>
              {`${
                quantity === 2
                  ? t('addDonation.installments.amount')
                  : t('addDonation.installments.amount.rest')
              } ${quantity === 2 ? quantity : ''}`}
            </Typography>
          </Grid>
        )}

        {/* Installment Amount */}
        <Grid item xs={4}>
          <div className={classes.elementContainer}>
            <Typography className={classes.cardTitle}>
              {hasAllRequiredValues ? `$ ${totalAmount}` : '-'}
            </Typography>
          </div>
          <Typography className={classes.subtitle}>
            {t('addDonation.installments.amount.total')}
          </Typography>
        </Grid>

        {hasAllRequiredValues && !isValidData && errorMessage && (
          <Grid item xs={12}>
            <FormHelperTextBottom left={errorMessage} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

InstallmentOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setInstallmentsValid: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

InstallmentOverview.defaultProps = {
  errorMessage: '',
};

export default withStyles(styleOverrides)(InstallmentOverview);
