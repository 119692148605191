import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

// This class was based off the example found at https://github.com/Azure-Samples/application-insights-react-demo
class TelemetryService {
  constructor() {
    this.reactPlugin = new ReactPlugin();
  }

  initialize(reactPluginConfig) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: window.env.REACT_APP_TELEMETRY_INSTRUMENTATION_KEY,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        extensions: [this.reactPlugin],
        enableCorsCorrelation: true,
        extensionConfig: {
          [this.reactPlugin.identifier]: reactPluginConfig,
        },
      },
    });
    this.appInsights.loadAppInsights();
  }

  handleException(e) {
    if (this.appInsights) {
      this.appInsights.trackException({ exception: e });
    }
  }
}

export default new TelemetryService();
