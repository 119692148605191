import {
  FRAME_SEND,
  FRAME_COMPLETE,
  FRAME_ERROR,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  // this line is to have awareness that we are in an iframe or webview
  inIframe:
    window.location !== window.parent.location ||
    Boolean(window.ReactNativeWebView),
  inStreamingApp: false,
  error: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case FRAME_SEND:
      return {
        ...state,
      };

    case FRAME_COMPLETE: {
      return {
        ...state,
        ...action.data,
      };
    }

    case FRAME_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};
