import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { TextField, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as globals from '../../../common/globals';

export default function FrequencyDropdown({
  field,
  form,
  frequencies,
  helperTextClass,
  features,
  ...props
}) {
  const { t } = useTranslation();
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;
  const frequencyList = globals.PAYMENT_FREQUENCY_DISPLAY_NAMES;

  return (
    <TextField
      name={name}
      id={name}
      label={t('addDonation.frequency')}
      FormHelperTextProps={{ classes: { root: helperTextClass } }}
      select
      variant="outlined"
      disabled={isSubmitting}
      error={showError}
      helperText={showError ? fieldError : ''}
      value={frequencies?.length > 0 ? field.value || '' : ''}
      onChange={(event) => {
        form.setFieldValue(field.name, event.target.value, true);
        form.setFieldTouched(field.name, true, false);
      }}
      {...props}
    >
      <MenuItem key="blank" value="" />
      {frequencies
        .filter((freq) => !!frequencyList[freq])
        .sort((freq1, freq2) => {
          return (
            frequencyList[freq1].displayOrder -
            frequencyList[freq2].displayOrder
          );
        })
        .map((freq) => (
          <MenuItem key={freq} value={freq}>
            {t(frequencyList[freq].fullDescription)}
          </MenuItem>
        ))}
    </TextField>
  );
}

FrequencyDropdown.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  frequencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  helperTextClass: PropTypes.string,
  features: PropTypes.object.isRequired,
};

FrequencyDropdown.defaultProps = {
  helperTextClass: '',
};
