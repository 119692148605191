import { useSelector } from 'react-redux';
import { hasScheduledTransactions } from '../../common/utils';
import { PAYMENT_FREQUENCY_ONE_TIME_NOW } from '../../common/globals';

const useFIPLogin = () => {
  const {
    loggedIn,
    orgFIPEnabled,
    hasRecurringInBasket,
    ffGuestScheduledTransactions,
  } = useSelector(
    ({
      basket,
      session,
      location,
      features: { features: { GuestScheduledTransactions = false } = {} } = {},
    }) => ({
      loggedIn: session?.loggedIn,
      orgFIPEnabled: location?.orgFIPEnabled,
      hasRecurringInBasket: hasScheduledTransactions(basket?.items),
      ffGuestScheduledTransactions: GuestScheduledTransactions,
    }),
  );

  const isTryingGuestTransaction = (hasRecurringItem) =>
    !loggedIn && hasRecurringItem;

  const forceByFF = (hasRecurringItem) =>
    isTryingGuestTransaction(hasRecurringItem) && !ffGuestScheduledTransactions;

  const forceByFIP = (hasRecurringItem) =>
    isTryingGuestTransaction(hasRecurringItem) && orgFIPEnabled;

  const forceLogin = (hasRecurring) =>
    forceByFF(hasRecurring) || forceByFIP(hasRecurring);

  const shouldForceLoginByItem = (item) => {
    const isItemRecurring =
      Boolean(item?.frequency) &&
      item.frequency !== PAYMENT_FREQUENCY_ONE_TIME_NOW;

    const hasAnyRecurring = isItemRecurring || hasRecurringInBasket;

    return forceLogin(hasAnyRecurring);
  };

  return {
    shouldForceLogin: forceLogin(hasRecurringInBasket),
    shouldForceLoginByItem,
  };
};

export default useFIPLogin;
