import { createTheme } from '@material-ui/core';
import palette from './palette';
import zIndex from './zIndex';
import buildTypography from './typography';

const shadows = new Array(24).fill('0 5px 10px 0 rgba(0, 0, 0, 0.2)');

export default options => {
  const props = {
    // assign default values
    overrides: {}, // if overrides need to access theme variables, you can access them through a function i.e. color: theme => theme.palette.primary.main
    primaryColor: '',
    ...options,
  };

  const customPalette = { ...palette };
  if (props.primaryColor) {
    customPalette.primary.main = props.primaryColor;
  }

  const typography = buildTypography(customPalette);

  return createTheme({
    palette: customPalette,
    typography,
    zIndex,
    shadows: ['none', ...shadows],
    overrides: {
      MuiButton: {
        root: {
          ...typography.button,
          borderRadius: 6,
        },
        sizeLarge: {
          ...typography.button,
          padding: '13px 30px 13px 30px',
        },
        contained: {
          boxShadow: 'none',
        },
        label: {
          textTransform: 'none',
        },
      },
      MuiTabs: {
        root: {
          minHeight: '36px',
        },
      },
      MuiTab: {
        root: {
          marginRight: '10px',
          padding: 4,
          minHeight: 0,
          fontSize: '15px',
          '&$selected': {
            color: customPalette.primary.main,
            fontWeight: 'bold',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          maxWidth: 350,
          marginBottom: 14,
          marginTop: 14,
          marginLeft: 20,
          marginRight: 20,
          fontSize: 13,
          lineHeight: 'normal',
        },
      },
      MuiPaper: {},
      MuiTableCell: {
        body: {
          padding: 16,
        },
      },
      ...props.overrides,
    },
  });
};
