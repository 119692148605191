import base64UrlToBase64 from './base64UrlToBase64';

export default function base64UrlToString(b64u: string) {
  let b64 = base64UrlToBase64(b64u);
  switch (b64.length % 4) {
    case 0:
      break;
    case 2:
      b64 += '==';
      break;
    case 3:
      b64 += '=';
      break;
    default:
      throw new Error('Not a valid Base64Url');
  }
  const utf8 = atob(b64);
  try {
    return decodeURIComponent(escape(utf8));
  } catch (e) {
    return utf8;
  }
}
