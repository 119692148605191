export default function styles(theme) {
  const sharedStyles = {
    width: 32,
    height: 32,
    padding: 10,
  };
  return {
    container: {
      display: 'flex',
      float: 'right',
      marginRight: '5%',
      marginTop: theme.spacing(5),
    },
    active: {
      ...sharedStyles,
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '& svg': {
        fill: theme.palette.primary.main,
        color: theme.palette.primary.main,
        stroke: theme.palette.primary.main,
        width: 32,
        height: 32,
      },
    },
    inactive: {
      ...sharedStyles,
      '& svg': {
        width: 32,
        height: 32,
      },
    },
  };
}
