import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  continueButtons: {
    display: 'flex',
    gap: '16px',
    alignSelf: 'center',
    width: '100%',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    justifyContent: 'space-between',
    height: '100%',
  },
  shortDescription: {
    padding: '18px 0',
  },
  inputField: {
    width: '100%',
  },
}));
