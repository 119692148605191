import i18next from 'i18next';
import FeaturesService from '../api/FeaturesService';
import {
  FEATURES_FETCH,
  FEATURES_COMPLETE,
  FEATURES_ERROR,
} from './actionTypes';
import {
  setFeaturesInStorage,
  getFeaturesFromStorage,
} from '../common/storageUtils';

// eslint-disable-next-line import/prefer-default-export
export function getFeatures(orgId) {
  return async dispatch => {
    dispatch({ type: FEATURES_FETCH });

    {
      const features = getFeaturesFromStorage();
      if (features) {
        return dispatch({ type: FEATURES_COMPLETE, data: features });
      }
    }

    let res;
    try {
      res = await FeaturesService.getFeatures(orgId);
    } catch (e) {
      dispatch({
        type: FEATURES_ERROR,
        error: e.message,
      });
      throw e;
    }

    if ([201, 200].includes(res.status)) {
      const features = res.data.data;
      setFeaturesInStorage(features, orgId);
      return dispatch({
        type: FEATURES_COMPLETE,
        data: features,
      });
    }

    dispatch({ type: FEATURES_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}
