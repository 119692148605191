import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Button, withStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import {
  fetchSubmittedPaymentId,
  fetchSubmittedUserDetails,
} from '../../actions/payment';
import SocialShareCard from '../../components/common/SocialShareCard';
import usePageTitle from '../../hooks/common/usePageTitle';

const styles = (theme) => ({
  thankYouContent: {
    margin: '116px 200px',
    display: 'flex',
    flexDirection: 'column',
  },
  checkIcon: {
    color: theme.palette.primary.main,
    height: '44px',
    width: '44px',
    marginBottom: '20px',
  },
  thankYouText: {
    marginBottom: '40px',
  },
  thankYouTitle: {
    marginBottom: '10px',
    fontWeight: theme.typography.fontWeightExtraBold,
  },
  homeLink: {
    textDecoration: 'none',
  },
  backToHomeButton: {
    textTransform: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    thankYouContent: {
      height: '100%',
      margin: '0 6vw',
      justifyContent: 'center',
      paddingBottom: '64px',
    },
  },
  share: {
    margin: '8px 0 48px',
  },
});

export function ThankYou(props) {
  const {
    match: {
      params: { locId },
    },
    classes,
    userDetails,
    fetchSubmittedPaymentIdConnect,
    fetchSubmittedUserDetailsConnect,
    loc,
  } = props;

  const { t } = useTranslation();

  usePageTitle(t('titles.thankYou'));

  useEffect(() => {
    fetchSubmittedPaymentIdConnect(locId);
    fetchSubmittedUserDetailsConnect(locId);
  }, [locId, fetchSubmittedUserDetailsConnect, fetchSubmittedPaymentIdConnect]);

  return (
    <div className={classes.thankYouContent}>
      <CheckCircleOutlinedIcon className={classes.checkIcon} />
      <Typography variant="h4" className={classes.thankYouTitle}>
        {t('thankYou.title', { firstName: userDetails.firstName })}
      </Typography>
      <div className={classes.thankYouText}>
        {t('thankYou.text', { email: userDetails.email })}
      </div>
      {loc.allowSocialSharing && (
        <SocialShareCard className={classes.share} isPostCheckout />
      )}
      <div>
        <Link to={`/${locId}/home`} id="home-link" className={classes.homeLink}>
          <Button
            className={classes.backToHomeButton}
            color="primary"
            variant="contained"
          >
            {t('thankYou.goHome')}
          </Button>
        </Link>
      </div>
    </div>
  );
}

ThankYou.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  fetchSubmittedPaymentIdConnect: PropTypes.func.isRequired,
  fetchSubmittedUserDetailsConnect: PropTypes.func.isRequired,
  loc: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    payment: { userDetails },
    location,
  } = state;
  return {
    userDetails,
    loc: location,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSubmittedPaymentIdConnect: (locationId) => {
    dispatch(fetchSubmittedPaymentId(locationId));
  },
  fetchSubmittedUserDetailsConnect: (locationId) => {
    dispatch(fetchSubmittedUserDetails(locationId));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ThankYou);
