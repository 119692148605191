import {
  FEATURES_FETCH,
  FEATURES_COMPLETE,
  FEATURES_ERROR,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  features: {},
  isFetching: false,
  hasLoadedFeatures: false,
  error: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case FEATURES_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case FEATURES_COMPLETE: {
      const oldKeys = Object.keys(state.features ?? {});
      const newKeys = Object.keys(action.data ?? {});
      // Don't bother checking the values for each key
      // because, for the features object, every value is always true
      const areEqual =
        oldKeys.length === newKeys.length &&
        oldKeys.every((oldKey, index) => oldKey === newKeys[index]);

      return {
        ...state,
        // Since we get a brand new object every time we load from storage
        // keep the old copy if it hasn't changed.
        features: areEqual ? state.features : action.data,
        isFetching: false,
        hasLoadedFeatures: true,
      };
    }

    case FEATURES_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};
