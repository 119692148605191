import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Button, withStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { useTranslation } from 'react-i18next';
import { PROFILE_PATH, TRANSACTIONS_PATH } from '../../../../common/globals';
import usePageTitle from '../../../../hooks/common/usePageTitle';

const styles = (theme) => ({
  thanksContent: {
    margin: '116px 100px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  checkIcon: {
    color: theme.palette.primary.main,
    height: '44px',
    width: '44px',
    marginBottom: '20px',
  },
  thanksText: {
    marginBottom: '40px',
  },
  thanksTitle: {
    marginBottom: '10px',
    fontWeight: theme.typography.fontWeightExtraBold,
  },
  backDiv: {
    textAlign: 'right',
    marginTop: theme.spacing(24),
  },
  backButton: {
    textTransform: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    thanksContent: {
      height: '100%',
      margin: '0 6vw',
      justifyContent: 'center',
      paddingBottom: '64px',
    },
    backDiv: {
      textAlign: 'right',
      marginTop: theme.spacing(12),
    },
  },
  share: {
    margin: '8px 0 48px',
  },
});

export function Thanks(props) {
  const { classes, locationId } = props;

  const { t } = useTranslation();

  usePageTitle(t('titles.thankYou'));

  const history = useHistory();

  const handleClick = () => {
    history.push(`/${locationId}/${PROFILE_PATH}/${TRANSACTIONS_PATH}`);
  };

  return (
    <div className={classes.thanksContent}>
      <CheckCircleOutlinedIcon className={classes.checkIcon} />
      <Typography variant="h4" className={classes.thanksTitle}>
        {t('transactions.thankYou.title')}
      </Typography>
      <div className={classes.thanksText}>
        {t('transactions.thankYou.message')}
      </div>
      <div className={classes.thanksText}>
        {t('transactions.thankYou.custom.message')}
      </div>
      <div className={classes.backDiv}>
        <Button
          className={classes.backButton}
          color="primary"
          variant="contained"
          onClick={handleClick}
        >
          {t('transactions.thankYou.goto')}
        </Button>
      </div>
    </div>
  );
}

Thanks.propTypes = {
  classes: PropTypes.object.isRequired,
  locationId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    locationId: state.location.id,
  };
};

export default compose(
  connect(mapStateToProps, null),
  withStyles(styles),
)(Thanks);
