import BaseService from './BaseService';

class CsrfService extends BaseService {
  csrfUrl = 'csrf/init';

  getCsrfUrl = () => {
    return this.csrfUrl;
  };

  initCsrf() {
    return this.http.get(this.getCsrfUrl());
  }
}

export default new CsrfService();
