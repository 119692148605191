import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as globals from '../../common/globals';
import { renderCardIcon } from '../../common/utils';

const styles = () => ({
  cardDetails: {
    display: 'flex',
  },
  cardIcon: {
    marginRight: '10px',
  },
});

export function CreditCardPaymentSummary(props) {
  const { classes, cardType, last4 } = props;
  const { t } = useTranslation();

  return (
    <Typography
      component="div"
      className={classes.cardDetails}
      data-testid="credit-card-payment-summary"
    >
      {cardType === globals.CARD_TYPE_VISA &&
        renderCardIcon(globals.CARD_TYPE_VISA, classes.cardIcon)}
      {cardType === globals.CARD_TYPE_MASTERCARD &&
        renderCardIcon(globals.CARD_TYPE_MASTERCARD, classes.cardIcon)}
      {cardType === globals.CARD_TYPE_AMEX &&
        renderCardIcon(globals.CARD_TYPE_AMEX, classes.cardIcon)}
      {cardType === globals.CARD_TYPE_DISCOVER &&
        renderCardIcon(globals.CARD_TYPE_DISCOVER, classes.cardIcon)}
      <span id="card-number" data-testid="card-number">
        {t('paymentMethodSummary.cardNumber', {
          cardNumber: last4,
        })}
      </span>
    </Typography>
  );
}

CreditCardPaymentSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  cardType: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
};

export default withStyles(styles)(CreditCardPaymentSummary);
