import PropTypes from 'prop-types';
import { Badge, Button, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ShoppingBasketOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import * as globals from '../../common/globals';

const styles = () => ({
  basketButtonText: {
    marginLeft: '8px',
    fontWeight: 'bold',
  },
});

export function EmbeddedHeader({ transactionCount, loc, classes }) {
  const { t } = useTranslation();
  return (
    <Button
      id="basket-button"
      color="primary"
      component={Link}
      aria-label="Basket"
      to={`/${loc.id}/${globals.BASKET_PATH}`}
    >
      <Badge
        id="header-basket-badge"
        badgeContent={transactionCount}
        color="primary"
        overlap="rectangular"
      >
        <ShoppingBasketOutlined />
      </Badge>
      <Typography className={classes.basketButtonText}>
        {t('basket.goto')}
      </Typography>
    </Button>
  );
}

EmbeddedHeader.propTypes = {
  transactionCount: PropTypes.number.isRequired,
  loc: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmbeddedHeader);
