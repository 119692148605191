import i18next from 'i18next';
import {
  SESSION_LOGIN,
  SESSION_LOGOUT,
  SESSION_LOGOUT_INIT,
  SESSION_INIT_COMPLETE,
  USER_DETAILS_CLEAR,
  CSRF_FETCH,
  CSRF_COMPLETE,
  CSRF_ERROR,
  IDS_SESSION_SAVE,
  IDS_SESSION_CHECK_UPDATE,
  IDS_NON_INTERACTIVE_LOGIN_ERROR,
  IDS_NON_INTERACTIVE_LOGIN_ERROR_HANDLED,
} from './actionTypes';
import * as storageUtils from '../common/storageUtils';
import {
  createSession,
  getLocationIdFromUrl,
  isTokenExpired,
} from '../utils/SessionUtils';
import AuthService from '../api/AuthService';
import { fetchUserDetails } from './user';
import { clearBasketItems } from './basket';
import * as globals from '../common/globals';
import { logError } from '../common/errorUtils';
import CsrfService from '../api/CsrfService';
import store from '../store';
import { objectKeysToCamelCase } from '../common/utils';

export function fetchCsrfToken() {
  return async (dispatch, getState) => {
    const isFetching = getState().session.isFetchingCsrf;
    let response;
    // Attempting to prevent a race condition where multiple calls are made. If we still see issues with this, the protection can be made more robust.
    // If a fetch is already in progress, then any subsequent calls will instead await the original fetch's promise
    if (!isFetching) {
      dispatch({ type: CSRF_FETCH });
      try {
        response = await CsrfService.initCsrf();
      } catch (e) {
        dispatch({ type: CSRF_ERROR }); // TODO: add error handling to redirect to server error page?
        if (e.code !== 'ECONNABORTED') throw e; // The IDS non-interactive login can cause this error in some browsers since it runs asynchronously.
      }
      if (response?.headers?.[globals.CSRF_HEADER_NAME]) {
        const csrfToken = response.headers[globals.CSRF_HEADER_NAME];
        dispatch({
          type: CSRF_COMPLETE,
          csrfToken,
        });
        return csrfToken;
      }
      dispatch({ type: CSRF_ERROR });
      throw new Error(i18next.t('errors.csrfFailure'));
    }
    // Return value to satisfy async arrow function rule
    return null;
  };
}

/**
 * Ends the current user session by deleting the session cookie and ending the IDS session.
 * In the future if we want to prevent the screen flashing to the logged out state before refreshing, we can remove the
 * logout dispatch
 * @returns {Function}
 */
export function endSession() {
  return (dispatch, getState) => {
    const { id } = getState().location;
    const { sessionDetails } = getState().session;
    const { idToken } = sessionDetails;
    const locationId = id || getLocationIdFromUrl();

    const returnState = {
      [globals.REDIRECT_ID_STORAGE_NAME]: locationId,
      idToken,
    };
    storageUtils.clearBasketItemsFromStorage(locationId);
    storageUtils.deleteSessionCookie();
    storageUtils.deleteTenantCookie();
    AuthService.logout(returnState);
    dispatch({ type: SESSION_LOGOUT });
    dispatch({ type: USER_DETAILS_CLEAR });
  };
}

/**
 * Checks the current org tenant and the stored tenant to make sure they either match, or the stored tenant doesn't exist.
 * @returns {function(...[*]=)}
 */
export function checkSessionTenant(tenant = '') {
  return (dispatch, getState) => {
    const currentTenant = tenant || getState().location.idsPayerTenant;
    const storedTenant = storageUtils.getTenantCookie();
    // user logged in elsewhere and is still logged in
    if (currentTenant && storedTenant && currentTenant !== storedTenant) {
      dispatch(endSession());
      return false;
    }
    return true;
  };
}

/**
 * Creates a new session based off details from IDS. If an existing session already exists from a different tenant, we log the user out
 * instead to prevent strange cross-tenant behavior. This should change once IDS moves to having separate sessions per tenant since it's pretty bad user
 * experience if they visit different Vanco Online pages in separate tabs.
 * @param sessionDetails
 * @returns {Function}
 */
export function initSession(sessionDetails) {
  return async (dispatch, getState) => {
    const newSession = createSession(sessionDetails);
    const tenant =
      getState().location.idsPayerTenant ||
      sessionDetails.appState.idsPayerTenant;
    const isTenantValid = dispatch(checkSessionTenant(tenant));
    if (isTenantValid) {
      storageUtils.setSessionCookie(newSession);
      storageUtils.setTenantCookie(tenant);
      dispatch({ type: SESSION_LOGIN, payload: newSession });
      await store.dispatch(fetchCsrfToken());
      if (sessionDetails.idToken) {
        dispatch(fetchUserDetails());
      }
    }
  };
}

export function checkStoredSession() {
  return (dispatch) => {
    const sessionDetails = storageUtils.getSessionCookie();
    const isTenantValid = dispatch(checkSessionTenant());
    if (sessionDetails) {
      if (isTenantValid) {
        dispatch({ type: SESSION_LOGIN, payload: sessionDetails });
        dispatch(fetchUserDetails());
      }
    } else {
      dispatch({ type: SESSION_LOGOUT_INIT });
      dispatch({ type: USER_DETAILS_CLEAR });
    }
  };
}

/**
 * Clears the basket items for guest users. This is essentially the guest version of the above endSession function.
 * It uses the clearBasketItems action rather than clearing storage directly since the page won't be reloaded when this is called, unlike endSession.
 */
export function clearGuestItems() {
  return (dispatch, getState) => {
    const { id: locationId } = getState().location;
    dispatch(clearBasketItems(locationId));
  };
}

/**
 * Obtains a new access token using the refresh token, the response object keys need to be transformed to camel case.
 */
export const renewToken = async (refreshToken) => {
  const result = await AuthService.getAccessTokenfromRefreshToken(refreshToken);
  if (result.data && result.status === 200) {
    result.data = objectKeysToCamelCase(result.data);
    return result.data;
  }
  return null;
};

/**
 * If the token is still fresh, returns the stored access token.
 * If it's expired, uses the stored refresh token to return a new access token.
 * If the refresh token is expired, end user session.
 * @returns {Function}
 */
export function getOrRenewAccessToken() {
  return async (dispatch, getState) => {
    const { sessionDetails } = getState().session;
    const { accessToken: currentToken, refreshToken } = sessionDetails;
    const isTenantValid = dispatch(checkSessionTenant());
    if (isTokenExpired(sessionDetails)) {
      try {
        // Attempt to obtain a new access token using the refresh token.
        const token = await renewToken(refreshToken);
        if (token.accessToken && isTenantValid) {
          await dispatch(initSession(token));
          return token.accessToken;
        }
      } catch (e) {
        logError(e);
        // Could not obtain a new token
        dispatch(endSession());
        return null;
      }
      return null;
    }
    return currentToken;
  };
}

function updateMonitorTimer(dispatch) {
  dispatch({
    type: IDS_SESSION_CHECK_UPDATE,
    payload: new Date(),
  });
}

async function handleIdsError(authState, idsSessionState, loggedIn, dispatch) {
  // save session state
  if (authState.logginRequired) {
    if (authState.sessionState !== idsSessionState) {
      dispatch({
        type: IDS_SESSION_SAVE,
        payload: authState.sessionState,
      });
    }

    // log user out
    if (loggedIn) {
      await dispatch(endSession());
    }
  }

  // continue monitoring
  await updateMonitorTimer(dispatch);
}

function tryRenewAuth(idToken) {
  return async () => {
    const authState = {};
    try {
      const renewedSession = await AuthService.renewAuth(idToken);
      authState.renewedSession = renewedSession;
      authState.sessionState = renewedSession?.sessionState;
    } catch (error) {
      logError(error);
      authState.logginRequired = error?.error === globals.IDS_LOGIN_REQUIRED;
      authState.error = error;
      authState.sessionState = error?.sessionState;
    }

    return authState;
  };
}

export function processRenewAuth() {
  return async (dispatch, getState) => {
    const { sessionDetails, idsSessionState, loggedIn } = getState().session;
    const isTenantValid = dispatch(checkSessionTenant());

    // check IDS Authorization
    const authState = await dispatch(tryRenewAuth(sessionDetails.idToken));
    if (authState.error) {
      handleIdsError(authState, idsSessionState, loggedIn, dispatch);
      return '';
    }

    // VO Login
    if (isTenantValid) {
      await dispatch(initSession(authState.renewedSession));
    } else {
      logError({ authState, isTenantValid });
    }

    // update Session State
    if (idsSessionState !== authState.sessionState) {
      dispatch({
        type: IDS_SESSION_SAVE,
        payload: authState.sessionState,
      });
    }

    await updateMonitorTimer(dispatch);

    return authState.renewedSession.accessToken;
  };
}

export function checkIdsSessionState() {
  return async (dispatch, getState) => {
    const { idsSessionState } = getState().session;

    if (typeof idsSessionState !== 'undefined' && idsSessionState !== null) {
      const sessionCheckResult =
        await AuthService.checkSession(idsSessionState);
      if (sessionCheckResult === 'changed') {
        await dispatch(processRenewAuth());
      } else {
        updateMonitorTimer(dispatch);
      }
    }
  };
}

export function initComplete() {
  return { type: SESSION_INIT_COMPLETE };
}

export function idsNonInteractiveError(error) {
  return async (dispatch) => {
    dispatch({ type: IDS_NON_INTERACTIVE_LOGIN_ERROR, payload: error });
  };
}

export function idsNonInteractiveErrorHandled() {
  return async (dispatch) => {
    dispatch({ type: IDS_NON_INTERACTIVE_LOGIN_ERROR_HANDLED });
  };
}
