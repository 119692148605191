export default function styles() {
  return {
    title: {
      padding: '0px 24px',
      '& h6': {
        textAlign: 'left',
        paddingLeft: 0,
      },
    },
    actions: {
      padding: '32px 24px 24px 24px',
      border: 0,
    },
  };
}
