declare global {
  interface Window {
    msCrypto: any;
  }
}

export default function randomString(length: number): string | null {
  // eslint-disable-next-line
  var bytes = new Uint8Array(length);
  const result: string[] = [];
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

  const cryptoObj = window.crypto || window.msCrypto;
  if (!cryptoObj) {
    return null;
  }

  const random = cryptoObj.getRandomValues(bytes);

  for (let a = 0; a < random.length; a++) {
    result.push(charset[random[a] % charset.length]);
  }

  return result.join('');
}
