import { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as globals from '../common/globals';
import AspectRatioBox from './common/AspectRatioBox';
import typography from '../styles/typography';
import palette from '../styles/palette';
import SocialShareButton from './common/SocialShareButton';
import LinkButton from './common/LinkButton';

const parseCSSToFloat = (val) => {
  return parseFloat(val.replace(/[^\d.-]/g, ''));
};

// CSS constants for calculating snippet text box size.
const dummyTypography = typography(palette);
const buttonPadding = 16;
const buttonHeight = 40;
const innerContentHeight = 53.125; // percent value expected
const innerTextPadding = 16; // padding applied by the default Material CardContent class
const snippetLineHeight = 1.5;
const titleStyleHeight = parseCSSToFloat(dummyTypography.h5.fontSize) * 1.332;
const snippetFontHeight = dummyTypography.body1.fontSize;
const tinyScreenMaxWidth = 400;

const styles = (theme) => ({
  card: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
  },
  /* allow campaign cards full width on xs, sm & md screens */
  [theme.breakpoints.down('sm')]: {
    card: {
      width: '100%',
    },
  },
  media: {
    height: '0',
    paddingTop: '56.25%',
  },
  snippet: {
    overflow: 'hidden',
    lineHeight: `${snippetLineHeight}`, // Important that this is defined here to base resizing logic off of
  },
  fade: {
    position: 'relative',
    height: '100%' /* size of text box */,
    '&:after': {
      content: "''",
      textAlign: 'right',
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '15%',
      height: '1.5em',
      background:
        'linear-gradient(\n    to right,\n    rgba(255, 255, 255, 0),\n    rgba(255, 255, 255, 1) 50%\n  )',
    },
  },
  textContent: {
    flex: '1 1 auto' /* added to support IE 11 */,
    wordBreak: 'break-word',
    paddingBottom: '0px',
  },
  cardInnerContent: {
    height: '100%',
    flex: '1 1 auto' /* changed from flex: 1 to support IE 11 */,
  },
  title: {
    overflow: 'hidden',
    maxHeight: `${titleStyleHeight * 2}rem`,
  },
  lmButton: {
    minWidth: '171px',
    minHeight: `${buttonHeight}px`,
  },
  buttonContainer: {
    padding: `${buttonPadding}px`,
    // Prevents the button from being hidden on narrow screens
    [theme.breakpoints.down(`${tinyScreenMaxWidth}`)]: {
      padding: `0px ${buttonPadding}px ${buttonPadding}px`,
    },
  },
  inIframe: {
    boxShadow: 'none',
    border: '1px solid #DEE1E3',
  },
  shareButtonContainer: {
    display: 'flex',
    height: 0,
  },
  shareButton: {
    position: 'relative',
    marginLeft: 'auto',
    zIndex: 1,
    top: 20,
    right: 20,
  },
});

export function Campaign(props) {
  const {
    imageUrl,
    title,
    snippet,
    locationId,
    campaignId,
    buttonText,
    classes,
    iframe,
    onShareClick,
  } = props;

  const tileRef = useRef(null);
  const titleRef = useRef(null);
  const [snippetHeight, setSnippetHeight] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);
  const [customInnerContentHeight, setCustomInnerContentHeight] = useState(
    `${innerContentHeight}%`,
  );
  const [showShareButton, setShowShareButton] = useState(false);
  const isTinyScreen = useMediaQuery(`(max-width:${tinyScreenMaxWidth}px)`);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const { t } = useTranslation();

  function convertRemToPixels(rem) {
    return (
      rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
  }

  /**
   * Calculates the size of the text box for the snippet.
   * This will likely need to be updated for any style or content changes that happen with tiles.
   * @returns {string}
   */
  const calculateSnippetHeight = useCallback(() => {
    const tileWidth = tileRef?.current?.offsetWidth;
    if (!tileWidth) {
      return 0;
    }

    const snippetTotalLineHeight =
      convertRemToPixels(
        parseFloat(snippetFontHeight.replace(/[^\d.-]/g, '')),
      ) * snippetLineHeight; // 1.5 is line height defined in styles object.

    // Total inner area height based off ratio defined above, subtracting space for the title and button and all padding.
    const totalHeight = Math.floor(
      (tileWidth * innerContentHeight) / 100 -
        (titleHeight + buttonHeight + buttonPadding * 2 + innerTextPadding) -
        1,
    ); // the - 1 at the end is to catch sub-pixel rounding errors

    // Set height to be a multiple of line height
    const calculatedHeight =
      totalHeight - (totalHeight % snippetTotalLineHeight);
    return calculatedHeight >= 0 ? calculatedHeight : 0;
  }, [titleHeight]);

  const calculateCustomInnerContentHeight = useCallback(() => {
    return isTinyScreen
      ? `${
          titleHeight +
          innerTextPadding * 2 +
          (snippetHeight > 0 ? snippetHeight : innerTextPadding) +
          buttonHeight +
          buttonPadding * 2
        }px`
      : `${innerContentHeight}%`;
  }, [isTinyScreen, snippetHeight, titleHeight]);

  useEffect(() => {
    const handleResize = () => {
      setTitleHeight(
        titleRef?.current?.offsetHeight || convertRemToPixels(titleStyleHeight),
      );
      setSnippetHeight(calculateSnippetHeight());
      setCustomInnerContentHeight(calculateCustomInnerContentHeight());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  // run once on initial mount
  useEffect(() => {
    setSnippetHeight(calculateSnippetHeight());
    setCustomInnerContentHeight(calculateCustomInnerContentHeight());
    setTitleHeight(
      titleRef?.current?.offsetHeight || convertRemToPixels(titleStyleHeight),
    );
  }, [calculateCustomInnerContentHeight, calculateSnippetHeight]);

  useEffect(() => {
    setShowShareButton(!isLargeScreen);
  }, [isLargeScreen]);

  return (
    <Card
      className={`${classes.card} ${iframe ? classes.inIframe : ''}`}
      ref={tileRef}
      onMouseEnter={() => setShowShareButton(true)}
      onMouseLeave={() => setShowShareButton(!isLargeScreen)}
      data-testid="campaign-card"
    >
      {showShareButton && Boolean(onShareClick) && (
        <div
          id="campaign-card-share-button"
          className={classes.shareButtonContainer}
        >
          <div className={classes.shareButton}>
            <SocialShareButton
              onClick={() => onShareClick({ id: campaignId, title })}
              iconOnly
              aria-label="share"
            />
          </div>
        </div>
      )}
      {imageUrl && (
        <CardMedia
          id="campaign-card-media"
          data-testid="campaign-card-media"
          className={classes.media}
          image={imageUrl}
        />
      )}
      <AspectRatioBox height={customInnerContentHeight}>
        <Grid
          container
          className={classes.cardInnerContent}
          direction="column"
          justifyContent="space-between"
        >
          <CardContent className={classes.textContent}>
            <Typography
              variant="h5"
              id="campaign-card-title"
              data-testid="campaign-card-title"
              className={classes.title}
              ref={titleRef}
            >
              {title}
            </Typography>
            {snippet && (
              <AspectRatioBox height={`${snippetHeight}px`}>
                <Typography
                  variant="body1"
                  id="campaign-card-snippet"
                  data-testid="campaign-card-snippet"
                  className={`${classes.snippet} ${classes.fade}`}
                  component="p"
                >
                  {snippet}
                </Typography>
              </AspectRatioBox>
            )}
          </CardContent>
          <Grid item className={classes.buttonContainer}>
            <LinkButton
              id="campaign-card-lm-button"
              to={`/${locationId}/${globals.CAMPAIGN_PATH}/${campaignId}`}
              className={classes.lmButton}
              color="primary"
              variant="contained"
            >
              {buttonText || t('campaign.learnMore')}
            </LinkButton>
          </Grid>
        </Grid>
      </AspectRatioBox>
    </Card>
  );
}

Campaign.propTypes = {
  classes: PropTypes.object.isRequired,
  imageUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  snippet: PropTypes.string,
  locationId: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  iframe: PropTypes.bool.isRequired,
  onShareClick: PropTypes.func,
};

Campaign.defaultProps = {
  imageUrl: null,
  snippet: null,
  buttonText: null,
  onShareClick: null,
};

export default withStyles(styles)(Campaign);
