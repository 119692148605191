import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
  withStyles,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as globals from '../../../common/globals';
import {
  fetchScheduledTransactions,
  fetchTransactionHistory,
} from '../../../actions/transactions';
import { enqueueSnackbar } from '../../../actions/notifications';
import styles from './styles';
import ScheduledTransactions from './scheduledTransactions/ScheduledTransactions';
import TransactionHistory from './transactionHistory/TransactionHistory';
import MigratedTransactions from './migratedTransactions/MigratedTransactions';
import {
  fetchUserRequiredActions,
  userRequiredActionsSubmited,
} from '../../../actions/user';
import usePageTitle from '../../../hooks/common/usePageTitle';

export function Transactions({
  classes,
  isFetchingTransactions,
  areTransactionsLoaded,
  isFetchingHistory,
  isHistoryLoaded,
  isDeletingTransaction,
  fetchScheduledTransactionsConnect,
  fetchTransactionHistoryConnect,
  fetchUserRequiredActionsConnect,
  userRequiredActionsSubmitedConnect,
  scheduledTransactions,
  transactionHistory,
  enqueueSnackbarConnect,
  locationId,
  requiredActionsLoaded,
  requiredActions,
  showActionNeededUI,
  isFetchingRequiredActions,
  hasActionNeededURL,
  actionNeededSubmited,
  isSubmittingActions,
  match,
  features,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  usePageTitle(t('titles.transactions'));
  const loadingActionNeeded = hasActionNeededURL && !requiredActionsLoaded;
  // This allows to show Action Needed tab, even if has not query parameter in the URL.
  const actionNeeded =
    requiredActionsLoaded && requiredActions?.pendingActions?.length > 0;
  const [selectedTransactionType, setSelectedTransactionType] = useState(
    actionNeeded || showActionNeededUI
      ? globals.TRANSACTIONS_ACTION_NEEDED
      : globals.TRANSACTIONS_SCHEDULED,
  );
  const handleActionRequiredFetch = useCallback(
    async (id) => {
      try {
        if (!requiredActionsLoaded) await fetchUserRequiredActionsConnect(id);
      } catch (e) {
        enqueueSnackbarConnect({
          variant: globals.NOTIFICATION_ERROR,
          message: e.message,
        });
      }
    },
    [
      enqueueSnackbarConnect,
      fetchUserRequiredActionsConnect,
      requiredActionsLoaded,
    ],
  );

  useEffect(() => {
    const handleScheduledFetch = async (id) => {
      try {
        await fetchScheduledTransactionsConnect(id);
      } catch (e) {
        enqueueSnackbarConnect({
          variant: globals.NOTIFICATION_ERROR,
          message: e.message,
        });
      }
    };
    const handleHistoryFetch = async (id) => {
      try {
        await fetchTransactionHistoryConnect(id);
      } catch (e) {
        enqueueSnackbarConnect({
          variant: globals.NOTIFICATION_ERROR,
          message: e.message,
        });
      }
    };

    switch (selectedTransactionType) {
      case globals.TRANSACTIONS_SCHEDULED:
        handleScheduledFetch(locationId);
        break;
      case globals.TRANSACTIONS_HISTORY:
        handleHistoryFetch(locationId);
        break;
      case globals.TRANSACTIONS_ACTION_NEEDED:
        if (features.ImportExternalPayerRecurrence && !actionNeededSubmited) {
          handleActionRequiredFetch(locationId);
        }
        break;
      default:
        break;
    }
  }, [
    selectedTransactionType,
    fetchScheduledTransactionsConnect,
    fetchTransactionHistoryConnect,
    fetchUserRequiredActionsConnect,
    enqueueSnackbarConnect,
    locationId,
    handleActionRequiredFetch,
    features.ImportExternalPayerRecurrence,
    actionNeededSubmited,
  ]);

  useEffect(() => {
    if (features.ImportExternalPayerRecurrence && !actionNeededSubmited) {
      handleActionRequiredFetch(locationId);
    }
  }, [
    actionNeededSubmited,
    features.ImportExternalPayerRecurrence,
    handleActionRequiredFetch,
    locationId,
  ]);

  useEffect(() => {
    setSelectedTransactionType(
      actionNeeded || showActionNeededUI
        ? globals.TRANSACTIONS_ACTION_NEEDED
        : globals.TRANSACTIONS_SCHEDULED,
    );
  }, [actionNeeded, showActionNeededUI]);

  useEffect(() => {
    if (
      features.ImportExternalPayerRecurrence &&
      hasActionNeededURL &&
      requiredActionsLoaded &&
      !showActionNeededUI &&
      !actionNeededSubmited
    ) {
      userRequiredActionsSubmitedConnect();
      history.push(`${match.url}/${globals.TRANSACTIONS_THANKS_PATH}`);
    }
  }, [
    features.ImportExternalPayerRecurrence,
    hasActionNeededURL,
    history,
    match.url,
    requiredActionsLoaded,
    showActionNeededUI,
    actionNeededSubmited,
    userRequiredActionsSubmitedConnect,
  ]);

  const handleSelectedTransactionTypeChange = (event, value) => {
    setSelectedTransactionType(value);
  };
  const handleMigrationSuccess = () => {
    setSelectedTransactionType(globals.TRANSACTIONS_SCHEDULED);
  };
  return (
    <>
      {(isFetchingTransactions ||
        isFetchingHistory ||
        isDeletingTransaction ||
        isFetchingRequiredActions ||
        isSubmittingActions) && (
        <div className={classes.progressHolder}>
          <LinearProgress
            data-testid="linear-progress"
            className={classes.transactionsProgress}
          />
        </div>
      )}

      {!loadingActionNeeded && (
        <div className={classes.root}>
          <Typography
            variant="h2"
            component="div"
            className={`${classes.title} ${
              showActionNeededUI ? classes.titleWithoutTab : ''
            }`}
          >
            {t(
              showActionNeededUI
                ? 'transactions.action.needed'
                : 'app.transactions',
            )}
          </Typography>
          {!showActionNeededUI && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {actionNeeded ? (
                <Tabs
                  className={classes.tabs}
                  value={selectedTransactionType}
                  onChange={handleSelectedTransactionTypeChange}
                  indicatorColor="primary"
                >
                  <Tab
                    value={globals.TRANSACTIONS_SCHEDULED}
                    label={t('transactions.scheduled')}
                  />
                  <Tab
                    value={globals.TRANSACTIONS_HISTORY}
                    label={t('transactions.history')}
                  />
                  <Tab
                    value={globals.TRANSACTIONS_ACTION_NEEDED}
                    label={t('transactions.action.needed')}
                  />
                </Tabs>
              ) : (
                <Tabs
                  className={classes.tabs}
                  value={selectedTransactionType}
                  onChange={handleSelectedTransactionTypeChange}
                  indicatorColor="primary"
                >
                  <Tab
                    value={globals.TRANSACTIONS_SCHEDULED}
                    label={t('transactions.scheduled')}
                  />
                  <Tab
                    value={globals.TRANSACTIONS_HISTORY}
                    label={t('transactions.history')}
                  />
                </Tabs>
              )}
            </>
          )}

          {selectedTransactionType === globals.TRANSACTIONS_SCHEDULED &&
            areTransactionsLoaded && (
              <ScheduledTransactions
                transactions={scheduledTransactions}
                locationId={locationId}
              />
            )}
          {selectedTransactionType === globals.TRANSACTIONS_HISTORY &&
            isHistoryLoaded && (
              <TransactionHistory transactionHistory={transactionHistory} />
            )}
          {selectedTransactionType === globals.TRANSACTIONS_ACTION_NEEDED &&
            actionNeeded && (
              <MigratedTransactions
                requiredActions={requiredActions}
                onTransactionSuccess={handleMigrationSuccess}
              />
            )}
        </div>
      )}
    </>
  );
}

Transactions.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetchingTransactions: PropTypes.bool,
  areTransactionsLoaded: PropTypes.bool,
  isFetchingHistory: PropTypes.bool,
  isHistoryLoaded: PropTypes.bool,
  isDeletingTransaction: PropTypes.bool,
  fetchScheduledTransactionsConnect: PropTypes.func.isRequired,
  fetchTransactionHistoryConnect: PropTypes.func.isRequired,
  fetchUserRequiredActionsConnect: PropTypes.func.isRequired,
  userRequiredActionsSubmitedConnect: PropTypes.func.isRequired,
  enqueueSnackbarConnect: PropTypes.func.isRequired,
  scheduledTransactions: PropTypes.object,
  transactionHistory: PropTypes.arrayOf(PropTypes.object),
  locationId: PropTypes.string.isRequired,
  requiredActionsLoaded: PropTypes.bool.isRequired,
  showActionNeededUI: PropTypes.bool.isRequired,
  requiredActions: PropTypes.object,
  isFetchingRequiredActions: PropTypes.bool.isRequired,
  hasActionNeededURL: PropTypes.bool.isRequired,
  actionNeededSubmited: PropTypes.bool.isRequired,
  isSubmittingActions: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  features: PropTypes.object,
};

Transactions.defaultProps = {
  isFetchingHistory: false,
  isHistoryLoaded: false,
  isFetchingTransactions: false,
  areTransactionsLoaded: false,
  isDeletingTransaction: false,
  scheduledTransactions: {},
  transactionHistory: [],
  requiredActions: {},
  features: {},
};

const mapStateToProps = (state) => {
  return {
    isFetchingTransactions: state.transactions.isFetchingTransactions,
    areTransactionsLoaded: state.transactions.areTransactionsLoaded,
    isFetchingHistory: state.transactions.isFetchingHistory,
    isHistoryLoaded: state.transactions.isHistoryLoaded,
    isDeletingTransaction: state.transactions.isDeletingTransaction,
    scheduledTransactions: state.transactions.scheduledTransactions,
    transactionHistory: state.transactions.transactionHistory,
    requiredActionsLoaded: state.user.requiredActionsLoaded,
    requiredActions: state.user.requiredActions,
    showActionNeededUI: state.user.showActionNeededUI,
    isFetchingRequiredActions: state.user.isFetchingRequiredActions,
    hasActionNeededURL: state.user.hasActionNeededURL,
    actionNeededSubmited: state.user.actionNeededSubmited,
    isSubmittingActions: state.user.isSubmittingActions,
    features: state.features.features,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchScheduledTransactionsConnect: async (locationId) => {
      await dispatch(fetchScheduledTransactions(locationId));
    },
    fetchTransactionHistoryConnect: async (locationId) => {
      await dispatch(fetchTransactionHistory(locationId));
    },
    fetchUserRequiredActionsConnect: async (locationId) => {
      await dispatch(fetchUserRequiredActions(locationId));
    },
    enqueueSnackbarConnect: (notification) => {
      dispatch(enqueueSnackbar(notification));
    },
    userRequiredActionsSubmitedConnect: () => {
      return dispatch(userRequiredActionsSubmited());
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Transactions);
