import { object } from '@vancoplatform/utils';

export function getOrigin() {
  const location = window.location;
  let origin = location.origin;

  if (!origin) {
    origin = object.getOriginFromUrl(location.href);
  }

  return origin;
}

export function getHostAndPath() {
  const location = window.location;
  return `${location.protocol}//${location.host}${location.pathname}`;
}

export function isSameOrigin(origin1: string, origin2: string | null = null) {
  if (origin1 == null) {
    return false;
  }

  if (origin2 == null || origin2 === '') {
    origin2 = getOrigin();
  }

  return origin1.toUpperCase() === origin2.toUpperCase();
}

export function redirect(url: string) {
  window.location.href = url;
}

export function getDocument() {
  return window.document;
}

export function getWindow() {
  return window;
}
