/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import LocationService from '../../api/LocationService';
import CampaignService from '../../api/CampaignService';
import transformCampaignDetails from '../../api/transform/CampaignTransform';
import { enqueueSnackbar } from '../../actions/notifications';
import { logError } from '../../common/errorUtils';
import * as globals from '../../common/globals';

const getCampaigns = (locationId) => async () => {
  const {
    data: { data },
  } = await LocationService.getLocationCampaigns(locationId);
  return data;
};

const getCampaignById = (campaignId) => async () => {
  if (!campaignId) return {};

  const {
    data: { data },
  } = await CampaignService.getCampaign(campaignId);
  return transformCampaignDetails(data);
};

const computeTopCampaigns = (campaigns, basketIds) => {
  if (campaigns.length === 0) {
    return [];
  }
  const topCampaigns = campaigns
    .slice()
    .filter((item) => !basketIds.includes(item.id));
  return topCampaigns.slice(0, 2);
};

export function useGetCampaigns(locationId) {
  const dispatch = useDispatch();

  const queryResult = useQuery({
    queryKey: ['campaigns', locationId],
    queryFn: getCampaigns(locationId),
    onError: (error) => {
      dispatch(
        enqueueSnackbar({
          variant: globals.NOTIFICATION_ERROR,
          message: error.message,
        }),
      );
    },
  });
  return queryResult;
}

export function useGetTopCampaigns(locationId) {
  const { data, status } = useGetCampaigns(locationId);
  const basketIds = useSelector((state) =>
    state.basket.items.map((item) => item.campaignDetails.id),
  );
  const topCampaigns = useMemo(
    () => computeTopCampaigns(data || [], basketIds),
    [data, basketIds],
  );

  return {
    data: topCampaigns,
    status,
  };
}

export function useGetCampaignById(campaignId) {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const history = useHistory();

  const queryResult = useQuery({
    queryKey: ['campaign', campaignId],
    queryFn: getCampaignById(campaignId),
    onError: (error) => {
      const is404 = error?.response?.status === 404;
      const is5XX = error?.response?.status.toString()[0] === '5';

      if (is404) {
        history.push(
          `/${params.locId}/${globals.ERROR_PATH}?reason=${globals.ERROR_MISSING_CAMPAIGN}&missingId=${campaignId}`,
        );
      }

      if (is5XX) {
        logError(error);
        history.push(
          `/${params.locId}/${globals.ERROR_PATH}?reason=${globals.ERROR_SERVER}`,
        );
      }

      if (!is404 && !is5XX) {
        dispatch(
          enqueueSnackbar({
            variant: globals.NOTIFICATION_ERROR,
            message: error.message,
          }),
        );
      }
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return queryResult;
}

const getEditableCampaignsByOrganizationURI = (organizationURI) => async () => {
  const response =
    await CampaignService.getEditableCampaignsByOrganizationURI(
      organizationURI,
    );

  if (!response || !Object.keys(response).length || response.status !== 200) {
    return [];
  }

  return response.data.data;
};

export function useGetEditableCampaignsByOrgURI(organizationURI) {
  const dispatch = useDispatch();

  const queryResult = useQuery({
    queryKey: ['campaigns/editable', organizationURI],
    queryFn: getEditableCampaignsByOrganizationURI(organizationURI),
    onError: (error) => {
      dispatch(
        enqueueSnackbar({
          variant: globals.NOTIFICATION_ERROR,
          message: error.message,
        }),
      );
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return queryResult;
}
