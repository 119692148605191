import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CssBaseline, Typography } from '@material-ui/core';
import './utils/setEnv';
import './index.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import Api from './api/Api';
import {
  createRequestInterceptor,
  createResponseInterceptor,
} from './api/ApiInterceptors';
import store from './store';
import CustomThemeProvider from './styles/CustomThemeProvider';
import AzureAuthService from './api/AzureAuthService';

Api.addRequestInterceptor(createRequestInterceptor(store));
Api.addResponseInterceptor(createResponseInterceptor(store));

const msalClientInstance = new PublicClientApplication(
  AzureAuthService.b2cConfig,
);

const queryClient = new QueryClient();
ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <CustomThemeProvider>
        <Typography id="typography-root" component="div">
          <MsalProvider instance={msalClientInstance}>
            <App />
          </MsalProvider>
        </Typography>
      </CustomThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
