export default function styles() {
  return {
    dropdown: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    formItemGroup: {
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    cardIcon: {
      marginRight: '10px',
    },
    button: {
      padding: 0,
      width: 'fit-content',
      display: 'table', // width: fit-content IE11 workaround
      fontWeight: 'bold',
      margin: '10px 0',
      '&:hover': {
        textDecoration: 'underline',
        background: 'transparent',
      },
    },
  };
}
