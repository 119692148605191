import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  Assignment,
  CreditCard,
  Settings,
  PermIdentity,
} from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MenuItem from './menuItem/MenuItem';
import CustomDrawer from './customDrawer/CustomDrawer';
import * as globals from '../../../common/globals';
import { endSession } from '../../../actions/session';

export function LeftNav({
  location,
  match,
  loc,
  endSessionConnect,
  iframe,
  showProfileSettings,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const DRAWER_WIDTH = isSmall
    ? globals.LEFT_NAV_SIZE_SMALL
    : globals.LEFT_NAV_SIZE_LARGE;
  const currentPath = location.pathname;
  const { idsPayerTenant, id: locationId } = loc;
  const { url } = match;
  /**
   * Function to logout the user from IDS.
   */
  const handleLogout = (event) => {
    event.preventDefault();
    endSessionConnect();
  };

  return (
    <CustomDrawer
      anchor="left"
      size={DRAWER_WIDTH}
      elevated
      open
      fixed
      iframe={iframe}
    >
      <MenuItem
        icon={<Assignment />}
        label={t('app.transactions')}
        linkPath={`${url}/${globals.TRANSACTIONS_PATH}`}
        active={
          currentPath ===
            `/${locationId}/${globals.PROFILE_PATH}/${globals.TRANSACTIONS_PATH}` ||
          currentPath === `/${locationId}/${globals.PROFILE_PATH}`
        }
      />
      <MenuItem
        icon={<CreditCard />}
        label={t('app.paymentMethods')}
        linkPath={`${url}/${globals.PAYMENT_PATH}`}
        active={
          currentPath ===
          `/${locationId}/${globals.PROFILE_PATH}/${globals.PAYMENT_PATH}`
        }
      />
      {showProfileSettings && (
        <MenuItem
          icon={<Settings />}
          label={t('app.profileSettings')}
          linkPath={
            // due to browser conflicts - we are always resorting to external tab
            `https://${window.env.REACT_APP_IDS_DOMAIN}/${idsPayerTenant}/profile`
          }
          active={
            currentPath ===
            `/${locationId}/${globals.PROFILE_PATH}/${globals.SETTINGS_PATH}`
          }
          externalLink
        />
      )}
      <MenuItem
        icon={<PermIdentity />}
        label={t('app.logOut')}
        onClick={handleLogout}
        active={false}
      />
    </CustomDrawer>
  );
}

LeftNav.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loc: PropTypes.object.isRequired,
  endSessionConnect: PropTypes.func.isRequired,
  iframe: PropTypes.bool.isRequired,
  showProfileSettings: PropTypes.bool,
};

LeftNav.defaultProps = {
  showProfileSettings: false,
};

const mapStateToProps = (state) => {
  const { location, frame } = state;
  return {
    loc: location,
    iframe: frame.inIframe,
  };
};

const mapDispatchToProps = (dispatch) => ({
  endSessionConnect: () => {
    return dispatch(endSession());
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(LeftNav);
