import {
  LOCATION_DETAILS_COMPLETE,
  LOCATION_DETAILS_FETCH,
  LOCATION_DETAILS_ERROR,
  LOCATION_TENANT_FETCH,
  LOCATION_TENANT_COMPLETE,
  LOCATION_TENANT_ERROR,
} from '../actions/actionTypes';

const initialState = {
  id: '',
  name: '',
  parentOrgName: '',
  homePageUrl: '',
  pcct: '',
  address: {},
  contact: {},
  paymentMethods: {},
  primaryColor: '',
  isAutomation: false,
  idsPayerTenant: '',
  isFetchingLocationDetails: false,
  hasLoadedLocationDetails: false,
  isFetchingLocationTenant: false,
  hasLoadedLocationTenant: false,
  allowSocialSharing: false,
  logoImageUrl: '',
};

export default function location(state = initialState, action = {}) {
  switch (action.type) {
    case LOCATION_DETAILS_FETCH:
      return { ...state, id: action.id, isFetchingLocationDetails: true };
    case LOCATION_DETAILS_COMPLETE:
      return {
        ...action.data,
        isFetchingLocationDetails: false,
        hasLoadedLocationDetails: true,
      };
    case LOCATION_DETAILS_ERROR:
      return {
        ...state,
        isFetchingLocationDetails: false,
      };
    case LOCATION_TENANT_FETCH:
      return {
        ...state,
        id: action.id,
        isFetchingLocationTenant: true,
      };
    case LOCATION_TENANT_COMPLETE:
      return {
        ...state,
        idsPayerTenant: action.data.data,
        isFetchingLocationTenant: false,
        hasLoadedLocationTenant: true,
      };
    case LOCATION_TENANT_ERROR:
      return {
        ...state,
        isFetchingLocationTenant: false,
      };
    default:
      return state;
  }
}
