import BaseService from './BaseService';
import { Api } from './Api';
import { transformLocationDetails } from './transform/LocationTransform';

class LocationService extends BaseService {
  constructor() {
    super();
    /* Creates a second API instance without interceptors to handle fetching org details as part of app initialization.
  This prevents race conditions and complexity with handling csrf tokens and IDS access tokens. */
    this.noAuthHttp = new Api();
  }

  async getLocationDetails(locationId) {
    const response = await this.noAuthHttp.get(
      `locations/${locationId}?include=organization`,
    );
    return transformLocationDetails(response);
  }

  async getLocationTenant(locationId) {
    const response = await this.noAuthHttp.get(
      `locations/tenant/${locationId}`,
    );
    return response;
  }

  getLocationCampaigns(locationId) {
    return this.http.get(`campaigns?location=${locationId}`);
  }
}

export default new LocationService();
