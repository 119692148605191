import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  withStyles,
  CardActionArea,
  Typography,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './styles';
import * as globals from '../../../common/globals';
import { getCurrencyFormat } from '../../../common/utils';

function ScheduledTransactionCard(props) {
  const {
    className,
    classes,
    transactions,
    transactionId,
    onCancel,
    onEdit,
    features,
  } = props;
  const isEndDateIncorrect =
    moment(transactions[0].endDate) < moment(transactions[0].startDate);
  const isOneTime = [
    globals.PAYMENT_FREQUENCY_ONE_TIME_NOW,
    globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE,
  ].includes(transactions[0].frequency);

  const { t } = useTranslation();

  const getPaymentFormat = (transaction) => {
    let format = '';
    if (transaction) {
      switch (transaction.paymentMethodType) {
        case globals.CREDIT_CARD:
          format = t('transactions.scheduled.cardNumber', {
            cardNumber: transaction.last4,
          });
          break;
        case globals.ACH:
          format = t('transactions.scheduled.accountNumber', {
            accountNumber: transaction.last4,
          });
          break;
        default:
          format = '';
      }
    }
    return format;
  };

  const getFrequencyFormat = (frequency) => {
    let format = '';
    const displayNames = globals.PAYMENT_FREQUENCY_DISPLAY_NAMES[frequency];

    if (displayNames && frequency && !isOneTime) {
      format = t(displayNames.fullDescription);
    }
    return format;
  };

  const handleCancelTransactionButtonClick = () => {
    onCancel(transactionId);
  };

  const handleEditTransactionButtonClick = () => {
    onEdit(transactionId);
  };

  return (
    <Card
      className={`${classes.root} ${className || ''}`}
      data-testid="scheduled-transaction-card"
    >
      <CardContent className={classes.content}>
        <div
          className={classes.frequencyType}
          id="scheduled-frequency-type"
          data-testid="scheduled-frequency-type"
        >
          {isOneTime
            ? t('transactions.scheduled.oneTime')
            : t('transactions.scheduled.recurring')}
        </div>
        {transactions.map((transaction) => (
          <div
            id="scheduled-item"
            data-testid="scheduled-item"
            className={classes.item}
            key={`${transaction.paymentId}-${
              transaction.campaignId
                ? transaction.campaignId
                : transaction.accountCode
            }`}
          >
            <Typography
              id="scheduled-transaction-title"
              data-testid="scheduled-transaction-title"
              variant="body2"
            >
              {transaction.title}
            </Typography>
            <div id="total" data-testid="total">
              <span className={classes.amount}>
                {getCurrencyFormat(
                  transaction.amount +
                    (transaction.processingFees
                      ? transaction.processingFees
                      : 0),
                )}
              </span>
              {!isOneTime && (
                <span>
                  {` ${getFrequencyFormat(
                    transaction.frequency,
                  ).toLowerCase()}`}
                </span>
              )}
            </div>
          </div>
        ))}

        <span
          className={classes.subtitle}
          id="scheduled-date"
          data-testid="scheduled-date"
        >
          {`${
            isOneTime
              ? t('transactions.scheduled.date')
              : t('transactions.scheduled.nextDate')
          }: ${moment(transactions[0].startDate).format('LL')}`}
        </span>
        {isEndDateIncorrect ? null : (
          <span className={classes.subtitle} data-testid="scheduled-endDate">
            {`${t('transactions.scheduled.endDate')}: ${moment(
              transactions[0].endDate,
            ).format('LL')} `}
          </span>
        )}
        <span
          className={classes.subtitle}
          id="scheduled-method"
          data-testid="scheduled-method"
        >{`${t('transactions.scheduled.paymentMethod')}: ${getPaymentFormat(
          transactions[0],
        )}`}</span>
      </CardContent>
      <div className={classes.itemBreak} />
      {features.EditScheduledTransactions ? (
        <div className={classes.buttonsContent}>
          <Button
            className={classes.cancelButton}
            onClick={handleEditTransactionButtonClick}
          >
            {t('transactions.scheduled.edit')}
          </Button>
          <Button
            className={classes.cancelButton}
            color="primary"
            onClick={handleCancelTransactionButtonClick}
          >
            {t('transactions.scheduled.cancelTransaction')}
          </Button>
        </div>
      ) : (
        <CardActionArea
          className={classes.cancelButtonDeprecated}
          color="primary"
          onClick={handleCancelTransactionButtonClick}
        >
          <Typography variant="body2">
            {t('transactions.scheduled.cancelTransaction')}
          </Typography>
        </CardActionArea>
      )}
    </Card>
  );
}

ScheduledTransactionCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  transactionId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
};

ScheduledTransactionCard.defaultProps = {
  className: '',
};

export default withStyles(styles)(ScheduledTransactionCard);
