export default {
  primary: {
    main: '#30119b', // indigo-blue
  },
  secondary: {
    xtralight: '#767676', // light grey
    light: '#979797', // grey
    main: '#000000', // black
  },
  text: {
    secondary: '#464c5a',
  },
  tooltip: {
    background: 'rgba(232, 232, 232)',
    accent: '#4F449B',
  },
  // non-material ui base colors
  h1: '#1f2126',
  h2: '#121212',
  h3: '#141414',
  h6: '#1B2125',
  white: 'white',
  overlay: '#f6f6f6',
  itemBreak: '#EAEAEA',
};
