import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'react-fast-compare';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { setMigratedTransactionsCookie } from '../../common/storageUtils';
import { MIGRATED_TRANSACTIONS_STORAGE_NAME } from '../../common/globals';

function FormikPersist({ name }) {
  const { values } = useFormikContext();
  const prefValuesRef = useRef();

  const onSave = (currentValues) => {
    if (name === MIGRATED_TRANSACTIONS_STORAGE_NAME) {
      setMigratedTransactionsCookie(currentValues);
    }
  };

  const debouncedOnSave = debounce(onSave, 300);

  useEffect(() => {
    if (!isEqual(prefValuesRef.current, values)) {
      debouncedOnSave(values);
    }
  });

  useEffect(() => {
    prefValuesRef.current = values;
  });

  return null;
}

FormikPersist.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikPersist;
