import PropTypes from 'prop-types';
import { getIn } from 'formik';
import {
  TextField,
  MenuItem,
  withStyles,
  Typography,
  Button,
} from '@material-ui/core';
import AddButton from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { renderCardIcon } from '../../../common/utils';
import * as globals from '../../../common/globals';

const styles = (theme) => ({
  menu: {
    height: '280px',
  },
  cardDetails: {
    '& span': {
      marginLeft: theme.spacing(2),
    },
  },
  addButton: {
    paddingLeft: 0,
  },
});

export function CardDropdown({
  textFieldLabel,
  field,
  form,
  data,
  helperTextClass,
  classes,
  onNewPaymentMethod,
  ...props
}) {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const { t } = useTranslation();

  return (
    <TextField
      name={name}
      id={name}
      label={textFieldLabel}
      FormHelperTextProps={{ classes: { root: helperTextClass } }}
      select
      variant="outlined"
      disabled={isSubmitting}
      error={showError}
      helperText={showError ? fieldError : ''}
      value={field.value || ''}
      onChange={(event) => {
        form.setFieldValue(field.name, event.target.value, true);
        form.setFieldTouched(field.name, true, false);
      }}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      data-testid="card-dropdown"
      {...props}
    >
      {data.map((d) => (
        <MenuItem
          key={`${name}-${d.paymentMethodToken}`}
          value={d.paymentMethodToken}
        >
          <Typography component="div" className={classes.cardDetails}>
            {d.brand === globals.CARD_TYPE_VISA &&
              renderCardIcon(globals.CARD_TYPE_VISA, classes.cardIcon)}
            {d.brand === globals.CARD_TYPE_MASTERCARD &&
              renderCardIcon(globals.CARD_TYPE_MASTERCARD, classes.cardIcon)}
            {d.brand === globals.CARD_TYPE_AMEX &&
              renderCardIcon(globals.CARD_TYPE_AMEX, classes.cardIcon)}
            {d.brand === globals.CARD_TYPE_DISCOVER &&
              renderCardIcon(globals.CARD_TYPE_DISCOVER, classes.cardIcon)}
            {d.accountType === globals.ACCOUNT_SAVINGS ||
            d.accountType === globals.ACCOUNT_CHECKING ? (
              <span id="ach-number" data-testid="ach-number">
                {`*******${d.last4}`}
              </span>
            ) : (
              <span id="card-number" data-testid="card-number">
                {`**** ****** ${d.last4}`}
              </span>
            )}
          </Typography>
        </MenuItem>
      ))}
      <MenuItem key="new-payment-method" value="" onClick={onNewPaymentMethod}>
        <Button color="primary" className={classes.addButton}>
          <AddButton />
          {t('transactions.action.add.new.paymentMethod')}
        </Button>
      </MenuItem>
    </TextField>
  );
}

CardDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  textFieldLabel: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  helperTextClass: PropTypes.string,
  onNewPaymentMethod: PropTypes.func.isRequired,
};

CardDropdown.defaultProps = {
  helperTextClass: '',
};

export default withStyles(styles)(CardDropdown);
