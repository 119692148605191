import { string } from 'prop-types';
import { CardMedia } from '@material-ui/core';

import { useSimpleLayoutContext } from './context/SimpleLayoutContext';

export function SimpleLayoutBanner ({ src }) {
  const { classes } = useSimpleLayoutContext();
  return <CardMedia className={classes.image} image={src} />;
}

SimpleLayoutBanner.propTypes = {
  src: string.isRequired,
};

export default SimpleLayoutBanner;
