import { useSelector } from 'react-redux';
import BaseVideoComponent from '../../components/common/BaseVideoComponent/BaseVideoComponent';
import { VANCO_VIDEO_TYPE } from '../../common/globals';

function Videos() {
  const loc = useSelector(({ location }) => ({
    locationId: location.id,
    organizationId: location.organizationId,
  }));
  return <BaseVideoComponent {...loc} variant={VANCO_VIDEO_TYPE.VIDEO} />;
}

export default Videos;
