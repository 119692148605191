import PropTypes from 'prop-types';
import { withStyles, Typography, Card, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isIOS, isMacOs, isMobileOnly } from 'react-device-detect';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FacebookIcon } from '../../images/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../images/twitter.svg';
import { ReactComponent as WhatsappIcon } from '../../images/whatsapp-default.svg';
import { ReactComponent as EmailIcon } from '../../images/email.svg';
import { ReactComponent as SmsIcon } from '../../images/sms.svg';
import useSocialShareData from '../../hooks/socialShareHooks/socialShareHooks';

const styles = (theme) => ({
  container: {
    maxWidth: 500,
    padding: '16px 16px 24px',
    width: '100%',
    borderRadius: 8,
    '& svg': {
      width: 33.33,
      height: 33.33,
      '& path': {
        fill: '#5E6266',
      },
    },
  },
  sectionGutter: {
    marginBottom: 10,
  },
  socialButton: {
    width: 56,
    height: 56,
    borderRadius: 8,
    backgroundColor: theme.palette.white,
    '&:hover': {
      background: '#F0F1F2',
      cursor: 'pointer',
    },
    border: 'none',
    marginRight: 16,
  },
  codeContainer: {
    display: 'flex',
    padding: '16px 24px 16px 16px',
    border: '1px solid #CCCCCC',
    borderRadius: 6,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  copyControl: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    display: 'flex',
    fontWeight: 700,
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    alignItems: 'center',
    marginLeft: 30,
    '& svg': {
      marginRight: 10,
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  url: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  inIframe: {
    boxShadow: 'none',
    border: '1px solid #DEE1E3',
  },
});

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: 14,
    padding: '4px 8px',
    backgroundColor: '#424248',
    position: 'relative',
    bottom: 8,
    right: 8,
  },
})(Tooltip);

function SocialShareCard({ classes, className, loc, iframe, isPostCheckout }) {
  const { t } = useTranslation();
  const { siteUrl, callToActionText, onSMSClick, cardTitle, onCopy } =
    useSocialShareData(loc, null, isPostCheckout);

  const createSocialButton = (title, ShareButton, Icon, customProps = {}) => {
    return (
      <CustomTooltip title={title} placement="top">
        {/* Added this span to avoid a Material-UI error
            "you have provided a title property to the child of <Tooltip />"") */}
        <span>
          <ShareButton
            url={siteUrl}
            resetButtonStyle={false}
            className={classes.socialButton}
            {...customProps}
          >
            <Icon />
          </ShareButton>
        </span>
      </CustomTooltip>
    );
  };

  return (
    <Card
      className={`${classes.container} ${iframe ? classes.inIframe : ''} ${
        className || ''
      }`}
    >
      <Typography className={classes.sectionGutter} variant="body2">
        {cardTitle}
      </Typography>
      <Typography className={classes.sectionGutter}>
        {`${t('socialShare.description')}`}
      </Typography>
      <div className={classes.sectionGutter}>
        {createSocialButton(
          t('socialShare.facebook'),
          FacebookShareButton,
          FacebookIcon,
          { quote: callToActionText },
        )}
        {createSocialButton(
          t('socialShare.twitter'),
          TwitterShareButton,
          TwitterIcon,
          { title: callToActionText },
        )}
        {createSocialButton(
          t('socialShare.whatsApp'),
          WhatsappShareButton,
          WhatsappIcon,
          { title: callToActionText },
        )}
        {(isMobileOnly || isIOS || isMacOs) && (
          <CustomTooltip title={t('socialShare.sms.truncated')} placement="top">
            <span>
              <button
                type="button"
                className={classes.socialButton}
                onClick={onSMSClick}
                aria-label="sms"
              >
                <SmsIcon />
              </button>
            </span>
          </CustomTooltip>
        )}
        {createSocialButton(
          t('socialShare.email'),
          EmailShareButton,
          EmailIcon,
          {
            subject: t('socialShare.emailSubject', {
              orgName: loc.parentOrgName,
            }),
            body: callToActionText,
          },
        )}
      </div>
      <div className={classes.sectionGutter}>
        <div className={classes.codeContainer}>
          <Typography
            component="span"
            className={classes.url}
          >{`${siteUrl}`}</Typography>
          <Typography
            onClick={onCopy}
            component="span"
            className={classes.copyControl}
          >
            <InsertLinkIcon />
            {t('common.copy')}
          </Typography>
        </div>
      </div>
    </Card>
  );
}

SocialShareCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  loc: PropTypes.object.isRequired,
  iframe: PropTypes.bool.isRequired,
  isPostCheckout: PropTypes.bool,
};

SocialShareCard.defaultProps = {
  className: null,
  isPostCheckout: false,
};

const mapStateToProps = (state) => ({
  loc: state.location,
  iframe: state.frame.inIframe,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(SocialShareCard);
