import i18next from 'i18next';
import PaymentService from '../api/PaymentService';
import * as storageUtils from '../common/storageUtils';
import {
  PAYMENT_SUBMIT_BEGIN,
  PAYMENT_SUBMIT_COMPLETE,
  PAYMENT_SUBMIT_ERROR,
  PAYMENT_SUBMITTED_ID_FETCH,
  PAYMENT_SUBMITTED_USER_DETAILS_FETCH,
} from './actionTypes';

export function submitPayment(locationId, reCaptchaToken) {
  return async (dispatch, getState) => {
    dispatch({ type: PAYMENT_SUBMIT_BEGIN });
    const { items, applyProcessingFee } = getState().basket;
    const user = storageUtils.getSubmittedUserDetailsFromStorage(locationId);
    // get email from the user details that were stored during payment method stashing
    const { email } = user;
    const paymentId = storageUtils.getPaymentIdFromStorage(locationId);
    const { origin } = getState().origin;
    const {
      features: { Installment: installmentFF },
    } = getState().features;

    // TODO fail if paymentId in storage is different from paymentId in state

    if (paymentId && items) {
      let response;
      try {
        response = await PaymentService.submitPayment(
          paymentId,
          items,
          applyProcessingFee,
          email,
          reCaptchaToken,
          user,
          installmentFF,
          origin,
        );
      } catch (error) {
        dispatch({ type: PAYMENT_SUBMIT_ERROR });
        throw error;
      }

      if (
        response &&
        (response.status === 200 || response.status === 201) &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        storageUtils.setSubmittedPaymentIdToStorage(
          locationId,
          response.data.data[0].id,
        );
        return dispatch({ type: PAYMENT_SUBMIT_COMPLETE });
      }
    }

    dispatch({ type: PAYMENT_SUBMIT_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function fetchSubmittedUserDetails(locationId) {
  const details = storageUtils.getSubmittedUserDetailsFromStorage(locationId);
  return { type: PAYMENT_SUBMITTED_USER_DETAILS_FETCH, details };
}

export function fetchSubmittedPaymentId(locationId) {
  const submittedPaymentId =
    storageUtils.getSubmittedPaymentIdFromStorage(locationId);
  return { type: PAYMENT_SUBMITTED_ID_FETCH, submittedPaymentId };
}

export default { submitPayment };
