import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles, LinearProgress, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import {
  fetchSavedPaymentMethods,
  deleteSavedPaymentMethod,
} from '../../../actions/savedPaymentMethods';
import AppTitle from '../../../components/common/AppTitle';
import styles from './styles';
import SavedPaymentMethodCard from '../../../components/paymentMethod/SavedPaymentMethodCard';
import * as globals from '../../../common/globals';
import { enqueueSnackbar } from '../../../actions/notifications';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';

export function Payment({
  locationId,
  loggedIn,
  classes,
  paymentMethods,
  isFetchingSavedMethods,
  isDeletingSavedMethod,
  fetchSavedPaymentMethodsConnect,
  deleteSavedPaymentMethodConnect,
  enqueueSnackbarConnect,
  iframe,
}) {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [paymentMethodToDelete, setPaymentMethodToDelete] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchMethods() {
      try {
        await fetchSavedPaymentMethodsConnect(locationId);
      } catch (e) {
        enqueueSnackbarConnect({
          variant: globals.NOTIFICATION_ERROR,
          message: e.message,
        });
      }
    }
    if (locationId && loggedIn) {
      fetchMethods();
    }
  }, [
    fetchSavedPaymentMethodsConnect,
    locationId,
    loggedIn,
    enqueueSnackbarConnect,
  ]);

  const confirmDelete = (paymentMethod) => {
    setPaymentMethodToDelete(paymentMethod);
    setIsConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setPaymentMethodToDelete(null);
    setIsConfirmationOpen(false);
  };

  const handleDelete = async () => {
    const { paymentMethodToken } = paymentMethodToDelete;
    handleConfirmationClose();

    try {
      await deleteSavedPaymentMethodConnect(locationId, paymentMethodToken);

      enqueueSnackbarConnect({
        variant: globals.NOTIFICATION_SUCCESS,
        message: t('paymentMethods.remove.success'),
      });
    } catch (e) {
      enqueueSnackbarConnect({
        variant: globals.NOTIFICATION_ERROR,
        message: e.message,
      });
    }
  };

  return (
    <>
      <AppTitle title={t('titles.savedPaymentMethods')} />
      {(isFetchingSavedMethods || isDeletingSavedMethod) && (
        <div className={classes.progressHolder}>
          <LinearProgress
            data-testid="linear-progress"
            className={classes.progress}
          />
        </div>
      )}
      {!isFetchingSavedMethods && (
        <div className={classes.root}>
          <Typography variant="h2" className={classes.title}>
            {t('app.paymentMethods')}
          </Typography>
          {paymentMethods.map((pm) => (
            <div
              className={classes.card}
              key={`${pm.paymentMethodType}${pm.last4}`}
            >
              <SavedPaymentMethodCard
                paymentMethod={pm}
                onDelete={confirmDelete}
                iframe={iframe}
              />
            </div>
          ))}
          <Typography component="div" className={classes.info}>
            <InfoOutlined className={classes.infoIcon} />
            {t('paymentMethods.add')}
          </Typography>
        </div>
      )}
      <ConfirmationDialog
        id="confirm-delete-dialog"
        isOpen={isConfirmationOpen}
        title={t('paymentMethods.remove.confirmation.title')}
        message={t('paymentMethods.remove.confirmation.message')}
        submitButtonText={t('paymentMethods.remove')}
        onClose={handleConfirmationClose}
        onSubmit={handleDelete}
      />
    </>
  );
}

Payment.propTypes = {
  locationId: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetchingSavedMethods: PropTypes.bool.isRequired,
  isDeletingSavedMethod: PropTypes.bool.isRequired,
  fetchSavedPaymentMethodsConnect: PropTypes.func.isRequired,
  deleteSavedPaymentMethodConnect: PropTypes.func.isRequired,
  enqueueSnackbarConnect: PropTypes.func.isRequired,
  iframe: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    locationId: state.location.id,
    loggedIn: state.session.loggedIn,
    isFetchingSavedMethods: state.savedPaymentMethods.isFetchingSavedMethods,
    isDeletingSavedMethod: state.savedPaymentMethods.isDeletingSavedMethod,
    paymentMethods: state.savedPaymentMethods.savedMethods,
    iframe: state.frame.inIframe,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSavedPaymentMethodsConnect: (locationId) => {
      return dispatch(fetchSavedPaymentMethods(locationId));
    },
    deleteSavedPaymentMethodConnect: (locationId, paymentMethodToken) => {
      return dispatch(deleteSavedPaymentMethod(locationId, paymentMethodToken));
    },
    enqueueSnackbarConnect: (notification) => {
      return dispatch(enqueueSnackbar(notification));
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Payment);
