export const POST_PAYMENT_INFO = 'POST_PAYMENT_INFO';

class RNWebViewHandler {
  constructor() {
    this.isInWebview = Boolean(window.ReactNativeWebView);
  }

  sendMessage = (type, payload) => {
    if (!this.isInWebview) return;
    const formattedPayload = JSON.stringify({ type, payload });
    window.ReactNativeWebView.postMessage(formattedPayload);
  };

  setMessageListener = (type, callback) => {
    if (!this.isInWebview) return null;
    const eventKey = `webviewMessage-${type}`;
    const messageCallback = (e) => callback(e);
    window.addEventListener(eventKey, messageCallback);
    return () => window.removeEventListener(eventKey, messageCallback);
  };
}
export default RNWebViewHandler;
