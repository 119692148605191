import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Tabs,
  Tab,
  LinearProgress,
  withStyles,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import MyBasket from '../../components/basket/MyBasket';
import {
  saveSelectedPaymentMethod,
  fetchPaymentID,
} from '../../actions/paymentMethod';
import * as globals from '../../common/globals';
import LoggedInWrapper from '../../components/paymentMethod/LoggedInWrapper';
import { fetchSavedPaymentMethods } from '../../actions/savedPaymentMethods';
import { savePaymentOptions, fetchPaymentOptions } from '../../actions/basket';
import {
  enqueueSnackbarAndLogError,
  enqueueSnackbar,
} from '../../actions/notifications';
import { createSchema } from '../../common/validation/PaymentMethodValidationUtils';
import {
  getSelectedPaymentType,
  getStrippedPhoneNumber,
  hasScheduledTransactions,
} from '../../common/utils';
import { getOrRenewAccessToken } from '../../actions/session';
import {
  saveGuestDetailsComplete,
  saveGuestDetailsError,
  saveGuestDetailsInit,
} from '../../actions/guest';
import { saveUserDetails } from '../../actions/user';
import {
  setSubmittedUserDetailsToStorage,
  getPaymentIdFromStorage,
  getVancoCampaignsPaymentHeaderStorage,
  getSubmittedUserDetailsFromStorage,
} from '../../common/storageUtils';
import { userDetailsTransformToHexeaBillingInfo } from '../../api/transform/UserDetailsTransform';
import ReturnLink from '../../components/common/ReturnLink';
import getStateAbbreviation from '../../utils/text/getStateAbbreviation';
import NoPaymentsSupported from '../../components/paymentMethod/NoPaymentsSupported';
import RNWebViewHandler from '../../utils/RNWebViewHandler';
import AppTitle from '../../components/common/AppTitle';
import { getApiErrorMessage, isInviteUserError } from '../../common/errorUtils';
import ReturnToOpportunities from '../../components/navigation/returnToOpportunities/ReturnToOpportunities';

const styles = (theme) => ({
  paymentMethod: {
    paddingTop: '5vh',
    marginLeft: '10vw',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '500px',
    width: '88vw',
  },
  paymentMethodTitle: {
    lineHeight: '1.23',
    letterSpacing: '0.6px',
    marginBottom: '20px',
    marginTop: '20px',
  },
  progressHolder: {
    width: '100%',
    position: 'absolute',
    alignSelf: 'baseline',
  },
  paymentProgress: {
    width: '100%',
  },
  paymentMethodRoot: {
    display: 'flex',
  },
  [theme.breakpoints.down('sm')]: {
    paymentMethodRoot: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    paymentMethod: {
      marginLeft: '6vw',
      marginRight: '6vw',
    },
  },
  unacceptedMethods: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px',
  },
});

export class PaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.ccTabRef = createRef();
    const { match, hexea } = this.props;
    const { isInWebview, sendMessage, setMessageListener } =
      new RNWebViewHandler();
    this.isInWebview = isInWebview;
    this.sendMessage = sendMessage;
    this.setMessageListener = setMessageListener;
    this.state = {
      isSwiperConnected: !!window.isSwiperConnected,
      locationId: match.params.locId,
      selectedMethod: {
        [globals.CREDIT_CARD]: '',
        [globals.ACH]: '',
      },
      selectedMethodDetails: {
        [globals.CREDIT_CARD]: null,
        [globals.ACH]: null,
      },
      showErrors: false,
      // whether each form component is valid
      infoValidity: {
        cardInfo: false,
      },
      // values from the fields in the CreditCardPaymentMethod component
      cardInfo: {
        cardBrand: '',
      },
      paymentMethod: hexea
        ? hexea.paymentMethod(globals.CREDIT_CARD_TOKENIZE)
        : null,
      requestIDSDialogOpen: false,
    };
    this.initialBillingInfo = {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      zipCode: '',
      state: '',
      email: '',
      phoneNumber: '',
    };

    this.t = props.t;
  }

  async componentDidMount() {
    const {
      fetchSavedPaymentMethodsConnect,
      fetchPaymentOptionsConnect,
      fetchPaymentIdConnect,
      enqueueSnackbarConnect,
      location,
    } = this.props;
    const { locationId } = this.state;
    try {
      await fetchPaymentIdConnect(locationId);
      await fetchSavedPaymentMethodsConnect(locationId);
      if (location?.state?.fromKiosk) {
        this.initialBillingInfo = this.getInitialBillingInfo();
      }
      if (this.isInWebview) {
        this.clearSwipperListener = this.setMessageListener(
          'swipperConnectionChange',
          this.handleSwipperConnectionChange,
        );
      }
      this.handleDefaultOffset();
    } catch (e) {
      enqueueSnackbarConnect({
        variant: globals.NOTIFICATION_ERROR,
        message: e ? e.message : this.t('errors.somethingWrong'),
      });
    }
    fetchPaymentOptionsConnect(locationId);
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    const { hexea } = this.props;

    if (hexea !== prevProps.hexea) {
      this.setState({
        paymentMethod: hexea.paymentMethod(globals.CREDIT_CARD_TOKENIZE),
      });
    }

    // NOTE: In the past we have encountered issues with double assignment to the a single CXP fields in hexea
    // If two separate instances of our CXP fields call the hexea field() method due to them being rerendered, the hexea library hangs on tokenization
    // I believe that delaying rendering until all data has been loaded, and recreating the paymentMethod object when a payment type or method is changed,
    // will address this issue. If we do encounter it again, note that the previous implementation had a check here as seen in the following commit:
    // https://github.com/vancopayments/donations-payer/commit/4f17adf376084635e69855d53501a1d33b0b8d7d#diff-da6901198d690eed76bd5fd56d41e921R107
  }

  componentWillUnmount() {
    if (this.clearSwipperListener) {
      this.clearSwipperListener();
    }
  }

  handleDefaultOffset = () => {
    const {
      savePaymentOptionsConnect,
      enableProcessingFees,
      applyFeeByDefault,
      selectedPaymentType,
      processingFees,
    } = this.props;
    const { locationId } = this.state;

    if (applyFeeByDefault && enableProcessingFees[selectedPaymentType]) {
      savePaymentOptionsConnect(locationId, {
        paymentType: selectedPaymentType,
        applyProcessingFee: true,
        processingFee: processingFees[selectedPaymentType],
      });
    }
  };

  /**
   * Updates the state values for the child components' form fields
   * @param node - the child component sending the update
   * @param name - the field to be updated
   * @param value - the new value of the field
   */
  handleInputChange = (node) => (name, value) => {
    this.setState((prevState) => {
      return { [node]: { ...prevState[node], [name]: value } };
    });
  };

  /**
   * Sets whether the child component is fully valid or not
   * @param child - the child component sending the update
   * @param valid - whether or not the child's fields are valid
   */
  handleValidityChange = (child) => (valid) => {
    this.setState((prevState) => {
      return { infoValidity: { ...prevState.infoValidity, [child]: valid } };
    });
  };

  /**
   * Changes the selected payment type
   * @param event
   * @param value - the new type
   */
  handlePaymentTypeChange = (event, value) => {
    const {
      savePaymentOptionsConnect,
      hexea,
      enableProcessingFees,
      processingFees,
      applyFeeByDefault,
    } = this.props;
    const { locationId } = this.state;

    const applyProcessingFee = applyFeeByDefault && enableProcessingFees[value];
    savePaymentOptionsConnect(locationId, {
      paymentType: value,
      applyProcessingFee,
      processingFee: processingFees[value],
    });
    if (value === globals.CREDIT_CARD) {
      this.setState((prevState) => {
        return {
          ...prevState,
          paymentMethod: hexea.paymentMethod(globals.CREDIT_CARD_TOKENIZE),
        };
      });
    }
    if (value === globals.SWIPE) {
      this.setState((prevState) => {
        return {
          ...prevState,
          selectedPaymentType: value,
        };
      });
    }
  };

  /**
   * Changes the selected payment method
   * @param id - the new method's id
   * @param details - the payment method details for the selected method
   * @param type - the type of the method
   */
  handleSelectedMethodChange = (type) => (id, details) => {
    const { hexea } = this.props;
    this.setState((prevState) => {
      return {
        selectedMethod: { ...prevState.selectedMethod, [type]: id },
        selectedMethodDetails: {
          ...prevState.selectedMethodDetails,
          [type]: details,
        },
        paymentMethod: hexea.paymentMethod(globals.CREDIT_CARD_TOKENIZE),
      };
    });
  };

  onSubmitButtonClick = () => {
    this.setState({ showErros: true });
  };

  handleSwipperConnectionChange = (event) => {
    const { detail: isConnected } = event;
    const { selectedPaymentType } = this.state;
    this.setState({
      isSwiperConnected: isConnected,
    });
    if (isConnected) {
      this.handlePaymentTypeChange(event, globals.SWIPE);
    } else if (selectedPaymentType !== globals.CREDIT_CARD) {
      this.ccTabRef?.current?.click(); // if handlePaymentTypeChange is called directly for CC it triggers a captcha failure
    }
  };

  /**
   * Adds CXP user identification to the data we use for tokenizing
   * @param data - object containing info and options that is passed to CXP
   * @param accessToken - the access token for a user that is logged in, should be null if they aren't logged in
   * @param guestId - the id for a guest user that isn't logged in, should be null if they are logged in
   */
  // eslint-disable-next-line class-methods-use-this
  addUserIdentification = (data, accessToken, guestId) => {
    const result = { ...data };
    if (accessToken) {
      result.userToken = accessToken;
    } else {
      result.partnerPayerRef = guestId;
    }
    return result;
  };

  /**
   * if the user isn't logged in, this creates a representation for them in the backend and IDS, and saves their details to it.
   * if they are logged in, this makes sure their access token is still valid and doesn't save any new information.
   * @param values - formik values passed to the submit function
   * @returns {Promise<*[]>} - Promise resolves to an array containing accessToken followed by guestId. one of the two should be null.
   */
  saveDetailsAndGetIdentifier = async (values) => {
    const {
      loggedIn,
      user,
      getOrRenewAccessTokenConnect,
      saveUserDetailsConnect,
      loc,
      enqueueSnackbarConnect,
      hasScheduledItems,
      features,
    } = this.props;

    let accessToken = null;
    let guestId = null;
    let userDetailsResponse = null;

    if (!loggedIn || !user.detailsComplete) {
      try {
        userDetailsResponse = await saveUserDetailsConnect(
          values.billingInfo,
          loc.organizationId,
          hasScheduledItems && features.GuestScheduledTransactions,
        );
      } catch (e) {
        if (hasScheduledItems && isInviteUserError(e)) {
          const message = getApiErrorMessage(e);
          enqueueSnackbarConnect({
            variant: globals.NOTIFICATION_ERROR,
            message: message || e.message,
          });
          // Invoke auth modal that requires guest to set up a user to process a scheduled transaction
          this.setState((prevState) => {
            return {
              ...prevState,
              requestIDSDialogOpen: true,
            };
          });
        } else {
          // TODO: This will need to be updated in the future when/if we go with the errors array pattern for the backend
          if (e?.response?.data?.message) {
            e.message = e.response.data.message;
          } else {
            e.message = this.t('errors.guestUserBackend');
          }
          throw e;
        }
      }
    }

    if (!loggedIn) {
      guestId = userDetailsResponse.id;
    } else {
      accessToken = await getOrRenewAccessTokenConnect(); // we should try to renew the access token here since if they've been sitting on the checkout page it could be expired.
      if (!accessToken) {
        // this means the user was previously logged in, but isn't now. The page should already be about to reload at this point, but we'll error out of the submit just in case.
        throw new Error('session is expired');
      }
    }
    return [accessToken, guestId];
  };

  getInitialBillingInfo = () => {
    const { locationId } = this.state;
    const userDetailsFromStorage =
      getSubmittedUserDetailsFromStorage(locationId);
    if (userDetailsFromStorage.firstName) {
      return userDetailsFromStorage;
    }
    return {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      zipCode: '',
      state: '',
      email: '',
      phoneNumber: '',
    };
  };

  /**
   * Catches the submit event and checks to ensure all required fields are valid before allowing the user to continue
   * @param values - the values passed in from Formik
   */
  handleSubmit = async (values, actions) => {
    const { locationId, infoValidity, selectedMethod, selectedMethodDetails } =
      this.state;
    const {
      history,
      saveSelectedPaymentMethodConnect,
      selectedPaymentType,
      user,
      paymentId,
      hexea,
      applyProcessingFee,
      processingFees,
      items: basketItems,
    } = this.props;

    const streets = user?.address?.street_address?.split('\n');
    const street1 = streets?.length >= 1 ? streets[0] : '';
    const street2 = streets?.length > 1 ? streets[1] : '';
    const strippedPhone = user?.phone_number
      ? getStrippedPhoneNumber(user?.phone_number)
      : getStrippedPhoneNumber(values?.billingInfo?.phoneNumber);

    const userDetails = {
      email: user?.email || values.billingInfo.email,
      firstName: user?.given_name || values.billingInfo.firstName,
      lastName: user?.family_name || values.billingInfo.lastName,
      addressLine1: street1 || values.billingInfo.addressLine1,
      addressLine2: street2 || values.billingInfo.addressLine2,
      city: user?.address?.locality || values.billingInfo.city,
      state:
        getStateAbbreviation(user?.address?.region) || values.billingInfo.state,
      zipCode: user?.address?.postal_code || values.billingInfo.zipCode,
      phoneNumber: strippedPhone || '',
    };

    if (
      // a previously saved payment method is selected
      selectedMethod[selectedPaymentType] &&
      selectedMethod[selectedPaymentType] !== globals.NEW_PAYMENT_METHOD
    ) {
      await saveSelectedPaymentMethodConnect(
        selectedMethodDetails[selectedPaymentType],
        paymentId,
        null,
      );
      setSubmittedUserDetailsToStorage(locationId, userDetails);
      actions.setSubmitting(false);
      history.push(`/${locationId}/${globals.REVIEW_PATH}`);
    } else if (
      // card or ach are selected as the payment type, and card info in CXP form fields is valid if selected
      (selectedPaymentType === globals.CREDIT_CARD && infoValidity.cardInfo) ||
      selectedPaymentType === globals.ACH
    ) {
      const { cardInfo, paymentMethod } = this.state;

      const { email } = userDetails;
      const [accessToken, guestId] = await this.saveDetailsAndGetIdentifier({
        ...values,
        billingInfo: {
          ...values.billingInfo,
          email,
        },
      });

      const billingData = this.addUserIdentification(
        userDetailsTransformToHexeaBillingInfo(
          user,
          values,
          selectedPaymentType,
        ),
        accessToken,
        guestId,
      );

      const tokenizeDetails = await hexea.tokenize(
        selectedPaymentType === globals.ACH
          ? hexea.paymentMethod('echeck')
          : paymentMethod,
        billingData,
      );

      // save billing info for guest users
      setSubmittedUserDetailsToStorage(locationId, userDetails);

      const { error } = tokenizeDetails;
      // guestId should only be non-null here if the user is logged in
      if (!error) {
        const paymentMethodInfo = { ...tokenizeDetails.paymentMethod };

        if (selectedPaymentType === globals.CREDIT_CARD) {
          paymentMethodInfo.cardInfo = cardInfo;
        }

        await saveSelectedPaymentMethodConnect(
          paymentMethodInfo,
          paymentId,
          guestId,
        );
      } else {
        // the error returned from tokenize is just an object, we need to turn it into an actual error
        // if we don't label it as being from CXP tokenize, there's no way to tell the error came from there in telemetry.
        throw new Error(error.detailed_error_message || error.message);
      }
      actions.setSubmitting(false);

      // if the page is in a webview send the details to the native app and skip redirection
      if (this.isInWebview) {
        this.sendMessage('paymentMethod', tokenizeDetails); // this is sent only if the payment was CC
      } else {
        history.push(`/${locationId}/${globals.REVIEW_PATH}`);
      }
    }
    if (this.isInWebview) {
      const [accessToken, guestId] = await this.saveDetailsAndGetIdentifier({
        ...values,
        billingInfo: {
          ...values.billingInfo,
          email: userDetails.email,
        },
      });
      this.sendMessage('basket', basketItems);
      this.sendMessage('contact', userDetails);
      this.sendMessage('processingFee', {
        applyProcessingFee,
        processingFee: processingFees[selectedPaymentType],
      });
      // send the payment token
      this.sendMessage('paymentToken', getPaymentIdFromStorage(locationId));

      // send 'vp-campaigns-payment-header'
      this.sendMessage(
        'paymentHeader',
        getVancoCampaignsPaymentHeaderStorage(locationId),
      );
      this.sendMessage('accessToken', accessToken ?? guestId);
    }
  };

  render() {
    const {
      supportedPaymentTypes,
      enableProcessingFees,
      processingFees,
      selectedPaymentType,
      loggedIn,
      user,
      enqueueSnackbarAndLogErrorConnect,
      hasScheduledItems,
      classes,
      isFetchingLocationDetails,
      isFetchingSavedMethods,
      isSavingPaymentMethod,
      areSavedMethodsLoaded,
      isSavingUserDetails,
      iframe,
      inStreamingApp,
      features,
    } = this.props;
    const {
      locationId,
      selectedMethod,
      showErrors,
      cardInfo,
      paymentMethod,
      isSwiperConnected,
      requestIDSDialogOpen,
    } = this.state;

    const { creditCard, ach } = supportedPaymentTypes;

    if (
      typeof creditCard?.isAllowed !== 'boolean' &&
      typeof ach?.isAllowed !== 'boolean'
    )
      return null;

    const onRequestIDSDialogOpen = () => {
      this.setState((prevState) => {
        return {
          ...prevState,
          requestIDSDialogOpen: false,
        };
      });
    };

    return !creditCard.isAllowed && !ach.isAllowed ? (
      <NoPaymentsSupported locId={locationId} />
    ) : (
      <>
        <AppTitle title={this.t('titles.paymentMethod')} />
        <Formik
          enableReinitialize
          onSubmit={async (values, actions) => {
            try {
              await this.handleSubmit(values, actions);
            } catch (e) {
              enqueueSnackbarAndLogErrorConnect(e, {
                variant: globals.NOTIFICATION_ERROR,
                message: e.message,
              });
              actions.setSubmitting(false);
            }
          }}
          initialValues={{
            [globals.CREDIT_CARD]: {
              zipCode: '',
            },
            // values from the fields in the ACHPaymentMethod component
            [globals.ACH]: {
              accountNumber: '',
              routingNumber: '',
              accountType: globals.ACCOUNT_CHECKING,
              nacha: false,
            },
            // values from the fields in the BillingInfo component
            billingInfo: {
              ...this.initialBillingInfo,
              saveMethod: loggedIn && hasScheduledItems,
            },
          }}
          validationSchema={
            selectedPaymentType === globals.CREDIT_CARD &&
            selectedMethod[selectedPaymentType] &&
            (selectedMethod[selectedPaymentType] === '' ||
              selectedMethod[selectedPaymentType] !==
                globals.NEW_PAYMENT_METHOD)
              ? undefined // saved credit card payment method
              : createSchema(
                  selectedPaymentType,
                  selectedMethod[selectedPaymentType] ===
                    globals.NEW_PAYMENT_METHOD,
                  {
                    loggedIn,
                    cardBrand: cardInfo.cardBrand,
                    profileComplete: user && user.detailsComplete,
                    hasScheduledItems,
                    allowGuestScheduledTransactions:
                      features.GuestScheduledTransactions,
                  },
                )
          }
        >
          {(formikProps) => (
            <Form>
              <div className={classes.paymentMethodRoot}>
                {(isFetchingLocationDetails ||
                  isFetchingSavedMethods ||
                  isSavingPaymentMethod ||
                  isSavingUserDetails) && (
                  <div className={classes.progressHolder}>
                    <LinearProgress
                      id="payment-progress"
                      data-testid="payment-progress"
                      className={classes.paymentProgress}
                    />
                  </div>
                )}
                {areSavedMethodsLoaded && (
                  <div className={classes.paymentMethod}>
                    <ReturnToOpportunities useWordMore />
                    <div
                      id="payment-method-title"
                      data-testid="payment-method-title"
                      className={classes.paymentMethodTitle}
                    >
                      <Typography variant="h2">
                        {this.t('payment.paymentMethod')}
                      </Typography>
                    </div>
                    {selectedPaymentType && (
                      <>
                        <Tabs
                          value={selectedPaymentType}
                          onChange={this.handlePaymentTypeChange}
                          indicatorColor="primary"
                        >
                          {isSwiperConnected &&
                            this.isInWebview &&
                            supportedPaymentTypes.creditCard?.isAllowed && (
                              <Tab value={globals.SWIPE} label="Card Reader" />
                            )}
                          {supportedPaymentTypes.creditCard?.isAllowed && (
                            <Tab
                              value={globals.CREDIT_CARD}
                              ref={this.ccTabRef}
                              label={this.t(
                                supportedPaymentTypes.creditCard.debitOnly
                                  ? 'payment.debitCard'
                                  : 'payment.creditOrDebitCard',
                              )}
                            />
                          )}
                          {!this.isInWebview &&
                            supportedPaymentTypes.ach?.isAllowed && (
                              <Tab
                                value={globals.ACH}
                                label={this.t(
                                  'transactions.history.bankAccount',
                                )}
                              />
                            )}
                        </Tabs>

                        {paymentMethod && (
                          <LoggedInWrapper
                            id="logged-in-wrapper"
                            selectedPaymentType={selectedPaymentType}
                            selectedMethod={selectedMethod}
                            setSelected={this.handleSelectedMethodChange}
                            onUpdate={this.handleInputChange}
                            setValid={this.handleValidityChange}
                            showErrors={showErrors}
                            cardInfo={cardInfo}
                            paymentMethod={paymentMethod}
                            supportedPaymentTypes={supportedPaymentTypes}
                            formikProps={formikProps}
                            requestIDSDialogOpen={requestIDSDialogOpen}
                            onRequestIDSDialogOpen={onRequestIDSDialogOpen}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
                {selectedPaymentType && (
                  <>
                    <MyBasket
                      locationId={locationId}
                      onSubmit={this.onSubmitButtonClick}
                      isSubmit
                      showSecurePaymentsMessage={false}
                      submitButtonText={this.t('basket.continueToReview')}
                      enableProcessingFees={
                        enableProcessingFees[selectedPaymentType]
                      }
                      processingFee={processingFees[selectedPaymentType]}
                      iframe={iframe}
                    />
                    <ReturnLink
                      isDisplayed={inStreamingApp}
                      link={`/${locationId}/home`}
                      linkText={this.t('expanded.backToHome')}
                    />
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

PaymentMethod.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  supportedPaymentTypes: PropTypes.object,
  isFetchingLocationDetails: PropTypes.bool.isRequired,
  isFetchingSavedMethods: PropTypes.bool.isRequired,
  areSavedMethodsLoaded: PropTypes.bool.isRequired,
  saveSelectedPaymentMethodConnect: PropTypes.func.isRequired,
  fetchSavedPaymentMethodsConnect: PropTypes.func.isRequired,
  enableProcessingFees: PropTypes.object,
  processingFees: PropTypes.object,
  fetchPaymentIdConnect: PropTypes.func.isRequired,
  savePaymentOptionsConnect: PropTypes.func.isRequired,
  fetchPaymentOptionsConnect: PropTypes.func.isRequired,
  getOrRenewAccessTokenConnect: PropTypes.func.isRequired,
  saveUserDetailsConnect: PropTypes.func.isRequired,
  enqueueSnackbarAndLogErrorConnect: PropTypes.func.isRequired,
  enqueueSnackbarConnect: PropTypes.func.isRequired,
  selectedPaymentType: PropTypes.string,
  loggedIn: PropTypes.bool,
  user: PropTypes.object.isRequired,
  paymentId: PropTypes.string,
  hexea: PropTypes.object,
  hasScheduledItems: PropTypes.bool.isRequired,
  isSavingPaymentMethod: PropTypes.bool.isRequired,
  isSavingUserDetails: PropTypes.bool.isRequired,
  iframe: PropTypes.bool.isRequired,
  inStreamingApp: PropTypes.bool.isRequired,
  loc: PropTypes.object.isRequired,
  applyProcessingFee: PropTypes.bool.isRequired,
  applyFeeByDefault: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  features: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

PaymentMethod.defaultProps = {
  supportedPaymentTypes: {},
  enableProcessingFees: {},
  processingFees: {},
  selectedPaymentType: null,
  loggedIn: false,
  paymentId: null,
  hexea: null,
  applyFeeByDefault: false,
};

const getSelectedPaymentTypeFromState = ({
  location: { paymentMethods },
  basket: { selectedPaymentType },
}) => {
  const paymentType = getSelectedPaymentType(
    paymentMethods,
    selectedPaymentType,
  );
  return paymentType;
};

export const mapStateToProps = (state) => {
  const { location } = state;
  return {
    hexea: state.paymentMethod.hexeaObject,
    supportedPaymentTypes: location.paymentMethods,
    isFetchingLocationDetails: location.isFetchingLocationDetails,
    isFetchingSavedMethods: state.savedPaymentMethods.isFetchingSavedMethods,
    areSavedMethodsLoaded: state.savedPaymentMethods.areSavedMethodsLoaded,
    enableProcessingFees: {
      [globals.CREDIT_CARD]: location.paymentMethods.creditCard
        ? location.paymentMethods.creditCard.enableFeeContribution &&
          location.paymentMethods.creditCard.feeValue > 0
        : false,
      [globals.ACH]: location.paymentMethods.ach
        ? location.paymentMethods.ach.enableFeeContribution &&
          location.paymentMethods.ach.feeValue > 0
        : false,
      [globals.SWIPE]: location.paymentMethods.creditCard // same values as credit card
        ? location.paymentMethods.creditCard.enableFeeContribution &&
          location.paymentMethods.creditCard.feeValue > 0
        : false,
    },
    processingFees: {
      [globals.CREDIT_CARD]: location.paymentMethods.creditCard
        ? location.paymentMethods.creditCard.feeValue
        : null,
      [globals.ACH]: location.paymentMethods.ach
        ? location.paymentMethods.ach.feeValue
        : null,
      [globals.SWIPE]: location.paymentMethods.creditCard // same values as credit card
        ? location.paymentMethods.creditCard.feeValue
        : null,
    },
    selectedPaymentType: getSelectedPaymentTypeFromState(state),
    loggedIn: state.session.loggedIn,
    user: state.user,
    hasScheduledItems: hasScheduledTransactions(state.basket.items),
    paymentId: state.paymentMethod.paymentId,
    isSavingPaymentMethod: state.paymentMethod.isSavingPaymentMethod,
    isSavingUserDetails: state.user.isSavingUserDetails,
    iframe: state.frame.inIframe,
    inStreamingApp: state.frame.inStreamingApp,
    loc: location,
    applyProcessingFee: state.basket.applyProcessingFee,
    applyFeeByDefault: state.location.applyFeeByDefault,
    items: state.basket.items,
    features: state.features?.features,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveSelectedPaymentMethodConnect: (
    paymentMethodDetails,
    paymentId,
    guestId,
  ) => {
    return dispatch(
      saveSelectedPaymentMethod(paymentMethodDetails, paymentId, guestId),
    );
  },
  fetchSavedPaymentMethodsConnect: (locationId) => {
    return dispatch(fetchSavedPaymentMethods(locationId));
  },
  fetchPaymentIdConnect: (locationId) => {
    return dispatch(fetchPaymentID(locationId));
  },
  savePaymentOptionsConnect: (locationId, paymentOptions) => {
    return dispatch(savePaymentOptions(locationId, paymentOptions));
  },
  fetchPaymentOptionsConnect: (locationId) => {
    return dispatch(fetchPaymentOptions(locationId));
  },
  enqueueSnackbarAndLogErrorConnect: (error, notification) => {
    return dispatch(enqueueSnackbarAndLogError(error, notification));
  },
  enqueueSnackbarConnect: (notification) => {
    return dispatch(enqueueSnackbar(notification));
  },
  getOrRenewAccessTokenConnect: () => {
    return dispatch(getOrRenewAccessToken());
  },
  saveGuestDetailsInitConnect: () => {
    dispatch(saveGuestDetailsInit());
  },
  saveGuestDetailsCompleteConnect: () => {
    dispatch(saveGuestDetailsComplete());
  },
  saveGuestDetailsErrorConnect: () => {
    dispatch(saveGuestDetailsError());
  },
  saveUserDetailsConnect: (userDetails, orgId, hasScheduledItems) => {
    return dispatch(saveUserDetails(userDetails, orgId, hasScheduledItems));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
)(PaymentMethod);
