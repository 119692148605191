
import { useMemo } from 'react';
import { string, objectOf, node } from 'prop-types';
import { withStyles } from '@material-ui/core';

import SimpleLayoutContext from './context/SimpleLayoutContext';
import { SimpleLayoutBanner } from './SimpleLayoutBanner';
import { SimpleLayoutTitle } from './SimpleLayoutTitle';
import { SimpleLayoutContent } from './SimpleLayoutContent';

import styles from './styles';

export function SimpleLayout({ classes, children }) {
  const contextProviderProps = useMemo(() => ({classes}), [classes]);
  return (
    <div className={classes.pageRoot}>
      <SimpleLayoutContext.Provider value={contextProviderProps}>
        {children}
      </SimpleLayoutContext.Provider>
    </div>
  );
}

SimpleLayout.Banner = SimpleLayoutBanner;
SimpleLayout.Title = SimpleLayoutTitle;
SimpleLayout.Content = SimpleLayoutContent;

SimpleLayout.propTypes = {
  classes: objectOf(string).isRequired,
  children: node.isRequired,
};

export default withStyles(styles)(SimpleLayout);
