import * as globals from '../../common/globals';

export const paymentMethodTransformToBackend = paymentMethod => {
  const creditCardFields = {
    paymentMethodType: 'CreditCard',
    paymentMethodToken: paymentMethod.paymentMethodToken,
    last4: paymentMethod.last4,
    brand: paymentMethod.brand,
  };

  const achFields = {
    paymentMethodType: 'ACH',
    paymentMethodToken: paymentMethod.paymentMethodToken,
    last4: paymentMethod.accountNumberLast4,
    accountType: paymentMethod.accountType,
  };

  const savedAchFields = {
    paymentMethodType: 'ACH',
    paymentMethodToken: paymentMethod.paymentMethodToken,
    last4: paymentMethod.last4,
    accountType: paymentMethod.accountType,
  };

  const paymentMethodDetails = {
    [globals.CREDIT_CARD_TOKENIZE]: creditCardFields,
    [globals.ACH_TOKENIZE]: achFields,
    [globals.ACH]: savedAchFields,
  };

  return {
    ...paymentMethod,
    ...paymentMethodDetails[paymentMethod.paymentMethodType],
  };
};

export const paymentMethodTransformToFrontend = paymentMethod => {
  const paymentMethodTypes = {
    CreditCard: globals.CREDIT_CARD,
    ACH: globals.ACH,
  };

  return {
    ...paymentMethod,
    paymentMethodType: paymentMethodTypes[paymentMethod.paymentMethodType],
  };
};

export default {
  paymentMethodTransformToBackend,
  paymentMethodTransformToFrontend,
};
