import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { ReactComponent as SwipeIcon } from '../../images/swipe.svg';

function styles() {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  };
}

function SwiperCard({ classes }) {
  return (
    <Grid className={classes.container}>
      <SwipeIcon width={36} style={{ marginRight: 12 }} />
      <Typography variant="h6">Swipe card</Typography>
    </Grid>
  );
}
SwiperCard.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(SwiperCard);
