import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { TextField, MenuItem, withStyles } from '@material-ui/core';

const styles = () => ({
  menu: {
    height: '280px',
  },
});

export function MemoFieldsDropdown({
  textFieldLabel,
  field,
  form,
  memoFields,
  helperTextClass,
  classes,
  ...props
}) {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return (
    <TextField
      name={name}
      id={name}
      label={textFieldLabel}
      FormHelperTextProps={{ classes: { root: helperTextClass } }}
      select
      variant="outlined"
      disabled={isSubmitting}
      error={showError}
      helperText={showError ? fieldError : ''}
      value={field.value}
      onChange={(event) => {
        form.setFieldValue(field.name, event.target.value, true);
        form.setFieldTouched(field.name, true, false);
      }}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      {...props}
    >
      {memoFields.map((memo) => (
        <MenuItem key={memo.id} value={memo.customText}>
          {memo.customText}
        </MenuItem>
      ))}
    </TextField>
  );
}

MemoFieldsDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  textFieldLabel: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  memoFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  helperTextClass: PropTypes.string,
};

MemoFieldsDropdown.defaultProps = {
  helperTextClass: '',
};

export default withStyles(styles)(MemoFieldsDropdown);
