import { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, Typography } from '@material-ui/core';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import FormikCheckboxWithLink from '../common/FormikCheckboxWithLink';
import Modal from '../common/modal';

const styles = () => ({
  title: {
    '& h6': {
      color: '#222222',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
  content: {
    '& p:not(:first-child)': {
      marginTop: '1em',
    },
  },
  errorText: {
    color: '#222222',
    paddingBottom: 12,
  },
  submitButton: {
    fontWeight: 'bold',
  },
});

export function NachaConfirmation(props) {
  const { name, classes, errors, touched, setFieldValue, setFieldTouched } =
    props;
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Field
        name={name}
        component={FormikCheckboxWithLink}
        linkText={t('accountPayment.nacha.linkText')}
        onLinkClick={openModal}
        label={t('accountPayment.nacha.label')}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        data-testid="nacha-confirmation"
      />
      <Modal open={isOpen} onClose={closeModal}>
        <Modal.Title className={classes.title}>
          {t('accountPayment.nacha.modalTitle')}
        </Modal.Title>
        <Modal.Content className={classes.content}>
          <Typography>{t('accountPayment.nacha.modalText1')}</Typography>
          <Typography>{t('accountPayment.nacha.modalText2')}</Typography>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={classes.submitButton}
            color="primary"
            variant="contained"
            onClick={closeModal}
          >
            {t('accountPayment.nacha.modalButtonText')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

NachaConfirmation.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

NachaConfirmation.defaultProps = {};

export default withStyles(styles)(NachaConfirmation);
