import * as globals from '../../common/globals';
import {
  compareDecimals,
  isFuturePaymentFrequency,
  isRecurringPaymentFrequency,
} from '../../common/utils';
import { formatDateToServerRequest } from '../../utils/CalendarUtils';

/* Backend transaction history to a frontend repersentation of transaction history.
TODO: May want to throw an error if the data doesn't match the expected format such that a proper representation cannot be created */
// eslint-disable-next-line import/prefer-default-export
export const transformTransactionHistory = (response) => {
  if (response?.status === 200 && response?.data?.data) {
    const history = response.data.data;

    response.data = history.map((transaction) => ({
      last4: transaction.last4,
      paymentId: transaction.paymentId,
      paymentMethodType: transaction.paymentMethodType,
      status: transaction.status,
      statusReason: transaction.statusReason,
      amount: transaction.amount,
      transactionDate: transaction.transactionDate,
      transactionFee: transaction.transactionFee,
      transactionType: transaction.transactionType,
      origin: transaction.origin,
      accountCode: transaction.accountCode,
      displayName: transaction.displayName,
      quantity: transaction.quantity,
    }));
  }
  return response;
};

export const transformScheduledTransactions = (response) => {
  if (response?.status === 200 && response?.data?.data) {
    const transactions = response.data.data;
    const paymentMethodTypes = {
      CreditCard: globals.CREDIT_CARD,
      ACH: globals.ACH,
    };

    response.data = transactions
      .map((transaction) => ({
        paymentId: transaction.paymentId,
        accountCode: transaction.accountCode,
        campaignId: transaction.campaignId,
        title: transaction.title,
        amount: transaction.amount,
        processingFees: transaction.processingFees,
        paymentMethodType: paymentMethodTypes[transaction.paymentMethodType],
        last4: transaction.last4,
        startDate: transaction.startDate,
        endDate: transaction.endDate,
        frequency: transaction.frequency,
        totalAmount: transaction.totalAmount,
        fundRefId: transaction.fundRefId,
        showEndDate: transaction.showEndDate,
      }))
      .reduce((map, transaction) => {
        const newMap = map;
        if (newMap[transaction.paymentId]) {
          newMap[transaction.paymentId].push(transaction);
        } else {
          newMap[transaction.paymentId] = [transaction];
        }

        return newMap;
      }, {});
  }
  return response;
};

export const mapUserDetailsToRequest = ({
  email,
  address: { street_address: street, locality, region, postal_code: zipCode },
  family_name: familyName,
  given_name: givenName,
  phone_number: phoneNumber,
}) => ({
  email,
  firstName: givenName,
  lastName: familyName,
  addressLine1: street,
  city: locality,
  state: region,
  zipCode,
  phoneNumber,
  stateOrProvince: region,
  postalCode: zipCode,
  givenName,
  familyName,
});

export const transformUpdateScheduleTransactionRequest = (
  formValues,
  userDetails,
  transactionDetails,
) => {
  const {
    paymentId,
    frequency,
    amount,
    startDate,
    endDate,
    editableCampaignId,
    memoLine,
  } = formValues;

  const recurringModel = {
    ...(isFuturePaymentFrequency(frequency) && {
      paymentDate: formatDateToServerRequest(startDate),
    }),
    ...(isRecurringPaymentFrequency(frequency) && {
      startDate: formatDateToServerRequest(startDate),
      endDate: endDate ? formatDateToServerRequest(endDate) : null,
    }),
  };

  const {
    processingFees = 0,
    totalAmount,
    amount: transactionAmount,
  } = transactionDetails;

  const amountIsEqual = compareDecimals(totalAmount, amount);

  // Apply processing fees only if amount haven't changed and processing fees originally enabled
  const applyProcessingFee = Boolean(amountIsEqual && processingFees > 0);

  // If form amount didn't change should send original amount value to server
  const requestAmount = amountIsEqual ? transactionAmount : amount;

  //* editableCampaignId is going to be the new value to be sent
  const transactionItems = [
    {
      frequency,
      amount: requestAmount,
      campaign: {
        id: editableCampaignId,
      },
      includeProcessingFee: applyProcessingFee,
      memoLine,
      ...recurringModel,
    },
  ];

  return {
    data: {
      transactionItems,
      user: mapUserDetailsToRequest(userDetails),
      notificationEmail: userDetails.email,
      execute: true,
      reCaptchaToken: null,
      origin: null,
    },
    paymentToken: paymentId,
  };
};
