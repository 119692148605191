import isArray from '../assert/isArray';
import snakeToCamel from './snakeToCamel';

export default function toCamelCase(
  object: Record<string, unknown> | Array<unknown> | null,
  exceptions: string[] = []
): Record<string, unknown> | Array<unknown> | null {
  if (typeof object !== 'object' || isArray(object) || object === null) {
    return object;
  }

  const obj = object as Record<string, unknown>;

  return Object.assign(
    {},
    ...Object.keys(obj).map((key) => {
      const newKey = exceptions.indexOf(key) === -1 ? snakeToCamel(key) : key;

      return { [newKey]: toCamelCase(obj[key] as Record<string, unknown>) };
    })
  );
}
