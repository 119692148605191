import { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ReactResizeDetector from 'react-resize-detector';
import history from '../../../history';
import AppSnackbar from '../../notifications/snackbar/AppSnackbar';
import ScrollToTop from '../../ScrollToTop';
import Header from '../../header/Header';
import Home from '../../../views/home';
import Expanded from '../../../views/expanded';
import Basket from '../../../views/basket';
import Footer from '../../common/footer/Footer';
import * as globals from '../../../common/globals';
import * as Validation from './Validation';
import StorageHandler from '../../common/StorageHandler';
import LocationError from '../../../views/error/location/LocationError';
import GeneralError from '../../../views/error/general/GeneralError';
import VerificationSuccess from '../../../views/verificationSuccess/VerificationSuccess';
import AppDialog from '../../notifications/dialog/AppDialog';
import RedirectHandler from './navigation/RedirectHandler';
import useGetIdsSession from '../../../hooks/sessionHooks/sessionHooks';
import PaymentMethod from '../../../mp/views/paymentMethod';
import PaymentLogin from '../../../mp/views/paymentLogin';
import PaymentSuccess from '../../../mp/views/paymentSuccess';
import ExtConfirmation from '../../external/ExtConfirmation';
import KioskBasket from '../../../views/basket/kiosk/KioskBasket';
import LoginModal from '../../common/LoginModal';
import Videos from '../../../views/videos/Videos';

export function Router({
  session,
  user,
  basket,
  paymentMethod,
  payment,
  frame,
  location,
  features,
}) {
  const onResize = (_, height) => {
    if (frame.inIframe) {
      window.top.postMessage({ height }, '*');
    }
  };

  const mainRef = useRef(null);

  const nonInteractiveLoginComplete = useGetIdsSession(
    frame.inStreamingApp,
    session,
    location,
    features,
  );

  const setMainLayoutTopOffset = (size) => {
    if (!mainRef?.current) {
      return;
    }
    mainRef.current.style.paddingTop = `${size}px`;
  };

  return (
    <ConnectedRouter history={history}>
      <AppSnackbar />
      <AppDialog />
      <ScrollToTop />
      {features?.features?.LoginModal && (
        <LoginModal
          session={session}
          idsPayerTenant={location.idsPayerTenant}
        />
      )}
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            // TODO: Would we want a way to keep track of a user's last location to try sending them there if they hit the base path?
            return <Redirect to={`/${globals.GENERAL_ERROR_PATH}`} />;
          }}
        />
        <Route
          exact
          path={['/post-redirect', globals.AZURE_POST_REDIRECT]}
          render={(routerProps) => (
            <RedirectHandler routerProps={routerProps} />
          )}
        />
        <Route
          exact
          path="/error"
          render={(errorProps) => <GeneralError {...errorProps} />}
        />
        <Route
          exact
          path="/verification-success"
          render={() => <VerificationSuccess />}
        />
        <Route
          exact
          path={`/${globals.MPPAYMENT_PATH_LOGIN}/:tenant/:vpSessionId`}
          render={(paymentProps) => <PaymentLogin {...paymentProps} />}
        />
        <Route
          exact
          path={`/${globals.MPPAYMENT_PATH}/:vpSessionId`}
          render={(paymentProps) => <PaymentMethod {...paymentProps} />}
        />
        <Route
          exact
          path="/payment-success/:redirectUrl"
          render={() => <PaymentSuccess />}
        />
        <Route
          exact
          path={`/:locId/${globals.EXTERNAL_REVIEW_PATH}`}
          render={() => <ExtConfirmation />}
        />
        <Route
          path="/:locId"
          render={(rootProps) => (
            <div className={`${frame.inIframe ? 'whiteBackground' : 'App'}`}>
              {nonInteractiveLoginComplete && (
                <StorageHandler
                  locationId={rootProps.match.params.locId}
                  history={rootProps.history}
                />
              )}
              {!(
                rootProps.location.pathname.match(globals.THANK_YOU_PATH) ||
                (rootProps.location.pathname.match(globals.BASKET_PATH) &&
                  frame.inStreamingApp)
              ) && (
                <Header
                  allowLogin={
                    rootProps.location.pathname.toLowerCase() !==
                      `/${rootProps.match.params.locId}/${globals.REVIEW_PATH}`.toLowerCase() &&
                    !window.ReactNativeWebView
                  }
                  showEmbeddedView={
                    frame.inStreamingApp &&
                    (!!rootProps.location.pathname.match(globals.HOME_PATH) ||
                      !!rootProps.location.pathname.match(
                        globals.CAMPAIGN_PATH,
                      ))
                  }
                  setMainLayoutTopOffset={setMainLayoutTopOffset}
                />
              )}
              {nonInteractiveLoginComplete && (
                <main ref={mainRef}>
                  <div className="content">
                    <Switch>
                      <Route
                        exact
                        path="/:locId"
                        render={(homeProps) => (
                          <Redirect
                            to={`/${homeProps.match.params.locId}/${globals.HOME_PATH}`}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/:locId/${globals.HOME_PATH}`}
                        render={(homeProps) => <Home {...homeProps} />}
                      />
                      <Route
                        exact
                        path="/:locId/videos"
                        render={(videoProps) => <Videos {...videoProps} />}
                      />
                      <Route
                        exact
                        path={`/:locId/${globals.CAMPAIGN_PATH}/:campaignId`}
                        render={(campaignProps) => (
                          <Expanded {...campaignProps} />
                        )}
                      />
                      <Route
                        exact
                        path={`/:locId/${globals.BASKET_PATH}`}
                        render={(basketProps) =>
                          !window.ReactNativeWebView ? (
                            <Basket {...basketProps} />
                          ) : (
                            <KioskBasket {...basketProps} />
                          )
                        }
                      />
                      <Route
                        exact
                        path={`/:locId/${globals.PAYMENT_PATH}`}
                        render={(paymentProps) =>
                          Validation.getPaymentMethodComponent(
                            paymentProps,
                            session,
                            basket,
                            features?.features,
                          )
                        }
                      />
                      <Route
                        exact
                        path={`/:locId/${globals.REVIEW_PATH}`}
                        render={(reviewProps) =>
                          Validation.getReviewComponent(
                            reviewProps,
                            session,
                            basket,
                            paymentMethod,
                            features?.features,
                          )
                        }
                      />
                      <Route
                        exact
                        path={`/:locId/${globals.THANK_YOU_PATH}`}
                        render={(thankYouProps) =>
                          Validation.getThankYouComponent(
                            thankYouProps,
                            payment,
                          )
                        }
                      />
                      <Route
                        path={`/:locId/${globals.PROFILE_PATH}`}
                        render={(profileProps) =>
                          Validation.getProfileComponent(
                            profileProps,
                            session,
                            user,
                            features,
                          )
                        }
                      />
                      <Route
                        path={`/:locId/${globals.ERROR_PATH}`}
                        render={(errorProps) => (
                          <LocationError {...errorProps} />
                        )}
                      />
                      <Route
                        path="/:locId/*"
                        render={(errorProps) => (
                          <LocationError {...errorProps} notFound />
                        )}
                      />
                    </Switch>
                  </div>
                  {!rootProps.location.pathname.match(globals.THANK_YOU_PATH) &&
                    !frame.inIframe && <Footer />}
                </main>
              )}
              {frame.inIframe && (
                <ReactResizeDetector
                  handleWidth
                  handleHeight
                  onResize={onResize}
                />
              )}
            </div>
          )}
        />
      </Switch>
    </ConnectedRouter>
  );
}

Router.propTypes = {
  session: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  basket: PropTypes.object.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  frame: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
  user: state.user,
  basket: state.basket,
  paymentMethod: state.paymentMethod,
  payment: state.payment,
  frame: state.frame,
  location: state.location,
  features: state.features,
});

export default connect(mapStateToProps)(Router);
