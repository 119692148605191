import PropTypes from 'prop-types';
import { Paper, withStyles, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { renderCardIcon, toCapitalized } from '../../common/utils';
import * as globals from '../../common/globals';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    width: '100%',
    borderRadius: '6px',
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  icon: {
    marginRight: '10px',
  },
  itemBreak: {
    display: 'none',
    borderColor: 'rgba(228, 229, 230, 0.5)',
    marginTop: '15px',
    marginBottom: '0px',
    width: '100%',
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      flexDirection: 'column',
      padding: '0',
    },
    description: {
      padding: '20px 20px 10px 20px',
    },
    itemBreak: {
      display: 'block',
    },
    deleteButton: {
      borderTopRightRadius: '0px',
      borderTopLeftRadius: '0px',
      height: '50px',
    },
  },
  inIframe: {
    boxShadow: 'none',
    border: '1px solid #DEE1E3',
  },
});

export function SavedPaymentMethodCard({
  classes,
  paymentMethod,
  onDelete,
  iframe,
}) {
  const { t } = useTranslation();

  const getPaymentMethodDescription = () => {
    let description = '';
    if (paymentMethod.brand) {
      description = `${globals.CARD_DISPLAY_NAMES[paymentMethod.brand]} ${t(
        'paymentMethods.endingIn',
      )} ${paymentMethod.last4}`;
    } else if (paymentMethod.accountType) {
      description = `${toCapitalized(paymentMethod.accountType)} ${t(
        'paymentMethods.endingIn',
      )} ${paymentMethod.last4}`;
    }

    return description;
  };

  const handleDeleteButtonClick = () => {
    onDelete(paymentMethod);
  };

  return (
    <Paper className={`${classes.root} ${iframe ? classes.inIframe : ''}`}>
      <Typography
        component="div"
        className={classes.description}
        data-testid="description"
      >
        {paymentMethod.brand
          ? renderCardIcon(paymentMethod.brand, classes.icon)
          : renderCardIcon(globals.ACH, classes.icon)}
        <div>{getPaymentMethodDescription()}</div>
      </Typography>
      <hr className={classes.itemBreak} />
      <Button
        color="primary"
        onClick={handleDeleteButtonClick}
        className={classes.deleteButton}
      >
        {t('paymentMethods.remove')}{' '}
      </Button>
    </Paper>
  );
}

SavedPaymentMethodCard.propTypes = {
  classes: PropTypes.object.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  iframe: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SavedPaymentMethodCard);
