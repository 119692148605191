export default (theme) => ({
  dialog: {},
  dialogRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  backHeader: {
    alignSelf: 'start',
  },
  summary: {
    width: '100%',
    marginTop: '20px',
  },
  amount: {
    marginLeft: '15px',
  },
  summaryCard: {
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: 'none',
    borderTop: '1px solid #DEE1E3',
  },
  itemBreak: {
    borderColor: 'rgba(228, 229, 230, 0.5)',
  },
  optionLineItem: {
    display: 'block',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DEE1E3',
    '&:hover': {
      background: '#F0F1F2',
    },
  },
  optionButton: {
    padding: '20px 15px !important',
    height: '100%',
    width: '100%',
    textAlign: 'left',
    justifyContent: 'start',
  },
  copyLink: {
    width: '100%',
    paddingTop: 15,
    paddingBottom: 25,
    paddingLeft: 20,
    paddingRight: 20,
  },
  copyLinkText: {
    marginTop: 15,
  },
  copyLinkInput: {
    border: '1px solid #CBCDCF',
    boxSizing: 'border-box',
    borderRadius: 6,
    marginTop: 5,
    width: '100%',
    height: 48,
  },
  optionName: {
    marginLeft: 10,
    verticalAlign: 'top',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
  },
  header: {
    margin: '10px 15px 0px 15px',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  closeIcon: {
    top: -6,
  },
  subtitle: {
    paddingTop: 15,
  },
  sectionGutter: {
    marginBottom: 10,
  },
  socialButton: {
    width: 56,
    height: 56,
    borderRadius: 8,
    backgroundColor: theme.palette.white,
    '&:hover': {
      background: '#F0F1F2',
    },
    border: 'none',
    marginRight: 16,
  },
  codeContainer: {
    display: 'flex',
    padding: '16px 24px 16px 16px',
    border: '1px solid #CCCCCC',
    borderRadius: 6,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:focus span': {
      textOverflow: 'unset',
    },
  },
  copyControl: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    display: 'flex',
    fontWeight: 700,
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    alignItems: 'center',
    marginLeft: 30,
    '& svg': {
      marginRight: 10,
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  url: {
    overflow: 'auto',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: '0 !important',
      height: '0 !important',
    }
  },
  [theme.breakpoints.down('xs')]: {
    optionLineItem: {
      borderBottom: 'none',
      display: 'inline-block',
      width: '33.33%',
    },
    optionName: {
      marginLeft: 0,
      display: 'block',
    },
    optionIcon: {
      width: 30,
      height: 30,
    },
    optionButton: {
      display: 'block',
      textAlign: 'center',
    },
    copyLink: {
      borderTop: '1px solid #DEE1E3',
    },
  },
});
