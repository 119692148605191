import { connect } from 'react-redux';
import PaymentLogin from './PaymentLogin';

export function mapStateToProps(state) {
  return {
    loggedIn: state.session.loggedIn,
  };
}

export default connect(mapStateToProps, null)(PaymentLogin);
