import 'iframe-resizer/js/iframeResizer';
import { assert } from '@vancoplatform/utils';
import EmbeddedIframeHandler from '../helper/embeddedIframe';

class AppDrawer {
  /**
   * Creates a iframe of the Vanco app switcher drawer
   * @param {Object} options.element the dom element in which to render the iframe
   * @param {String} options.url the URL to the App Switcher application
   * @param {String} options.idsUrl the URL to the Identity Server (domain)
   * @param {String} options.id optional. An ID for the iframe element
   * @param {String} options.tenant the tenant slug of the containing session for styling purposes
   * @param {String} options.classname optional. An class for the iframe element
   */
  constructor(options) {
    assert.check(
      options,
      { type: 'object', message: 'options parameter is not valid' },
      {
        element: { type: 'object', message: 'element option is required' },
        url: { type: 'string', message: 'url option is required' },
        idsurl: { type: 'string', message: 'url option is required' },
        tenant: { type: 'string', message: 'tenant option is required' },
        classname: {
          optional: true,
          type: 'string',
          message: 'classname is not valid',
        },
        id: {
          optional: true,
          type: 'string',
          message: 'id is not valid',
        },
      }
    );
    this.baseOptions = options;
    this.authority = this.baseOptions.url;
    if (this.authority.endsWith('/')) {
      this.authority = this.authority.substring(0, this.authority.length - 1);
    }

    this.baseOptions.url = `${this.authority}?idsurl=${this.baseOptions.idsurl}&tenant=${this.baseOptions.tenant}`;
  }

  render() {
    const helper = new EmbeddedIframeHandler({
      url: this.baseOptions.url,
      id: this.baseOptions.id,
    });
    return helper.mount(this.baseOptions.element);
  }
}

export default AppDrawer;
