import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import get from 'lodash/get';

export default function FormikCheckbox(props) {
  const {
    label,
    description,
    field,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = props;

  // This control doesn't trigger Formik's error display logic in the same
  // way that the text fields in this form are triggered, so this effect ensures
  // that the error text is properly set.
  //
  // Furthermore, when a field is found to be invalid by Formik, it populates an errors
  // object with error messages for all invalid fields in that form, so in order to properly
  // set the errorText, we need to look at the touched object as well to make sure the field
  // we're trying to set is dirty.
  const [errorText, setErrorText] = useState('');
  useEffect(() => {
    setErrorText(
      get(errors, field.name) && get(touched, field.name)
        ? get(errors, field.name)
        : '',
    );
  }, [errors, touched, field.name]);

  const handleCheckboxChange = (name) => (e) => {
    setFieldValue(name, e.target.checked, true);
    setFieldTouched(name, true, true);
  };

  return (
    <FormControl error={!!errorText}>
      <FormControlLabel
        control={
          <Checkbox
            name={field.name}
            checked={field.value}
            onChange={handleCheckboxChange(field.name)}
            onBlur={field.onBlur}
            value={field.name}
            color="primary"
            style={{
              boxColor: '#30119b',
            }}
          />
        }
        label={label}
      />
      {description && (
        <FormHelperText
          style={{
            marginTop: '1px',
            marginLeft: '33px',
            color: 'GrayText',
            maxWidth: '85%',
            fontStyle: 'italic',
          }}
        >
          {description}
        </FormHelperText>
      )}
      {errorText && (
        <FormHelperText
          style={{ marginTop: '1px', marginLeft: '33px', width: '290px' }}
        >
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormikCheckbox.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  field: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

FormikCheckbox.defaultProps = {
  label: null,
  description: null,
};
