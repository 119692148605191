import { useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import styles from './styles';
import FormHelperTextBottom from '../FormHelperTextBottom';
import getCountText from '../../../utils/text/getCountText';

function OutlinedTextField (props) {
  const {
    children,
    classes,
    errorMessage,
    showCount,
    inputProps,
    onBlur,
    onFocus,
    value,
    ...textFieldProps
  } = props;
  const [focused, setFocused] = useState(false);
  const input = useRef(null);
  const focusWrap = useCallback(
    e => {
      setFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [setFocused, onFocus],
  );

  const blurWrap = useCallback(
    e => {
      setFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [setFocused, onBlur],
  );

  const mergedInputProps = useMemo(
    () => ({
      ref: input,
      ...inputProps,
    }),
    [inputProps, input],
  );

  const leftHelper = errorMessage || null;
  const rightHelper =
    input.current && inputProps && inputProps.maxLength
      ? getCountText(input.current.value.length, inputProps.maxLength)
      : null;

  return (
    <>
      <TextField
        InputLabelProps={{
          className: classes.label,
        }}
        inputProps={mergedInputProps}
        variant="outlined"
        margin="normal"
        fullWidth
        onFocus={focusWrap}
        onBlur={blurWrap}
        value={value === null || value === undefined ? '' : value}
        {...textFieldProps}
      >
        {children}
      </TextField>
      <FormHelperTextBottom
        left={leftHelper}
        right={showCount && focused ? rightHelper : null}
      />
    </>
  );
}

OutlinedTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  inputProps: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  showCount: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

OutlinedTextField.defaultProps = {
  children: null,
  errorMessage: null,
  inputProps: {},
  onFocus: null,
  onBlur: null,
  showCount: false,
  value: '',
};

export default withStyles(styles)(OutlinedTextField);
