import moment from 'moment';
import * as globals from '../../common/globals';
import useInstallments from '../../hooks/useInstallments';

export const paymentSubmitTransformToBackend = (
  basketItems,
  applyProcessingFee,
  email,
  reCaptchaToken,
  user,
  origin,
  installmentFF = false,
) => {
  const items = basketItems.map((item) => {
    const {
      campaignDetails: { id },
      transactionData,
    } = item;
    const data = transactionData[Object.keys(transactionData)[0]];

    const transformedItem = {
      frequency: data.frequency,
      campaign: { id },
      amount: data.amount,
      includeProcessingFee: applyProcessingFee,
      memoLine: data.memoLine,
    };
    if (data.itemAmount && data.quantity) {
      transformedItem.itemAmount = data.itemAmount;
      transformedItem.quantity = data.quantity;
    }

    if (data.frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_NOW) {
      const datePropName =
        data.frequency === globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE
          ? 'paymentDate'
          : 'startDate';
      transformedItem[datePropName] = moment(data.startDate).format(
        'YYYY-MM-DD',
      );

      if (data.frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE) {
        const endDatePropName = 'endDate';
        transformedItem[endDatePropName] = data.endDate
          ? moment(data.endDate).format('YYYY-MM-DD')
          : null;
      }
    }

    if (
      installmentFF &&
      data.recurringOption === globals.PAYMENT_FREQUENCY_OPTIONS.INSTALLMENT
    ) {
      const { isSameRate, firstInstallment, rate } = useInstallments(
        data.amount,
        data.frequency,
        data.startDate,
        data.endDate,
      );

      const formattedInstallment = {
        startDate: moment(data.startDate).format('YYYY-MM-DD'),
        endDate: moment(data.endDate).format('YYYY-MM-DD'),
        firstPaymentAmount: isSameRate ? null : firstInstallment,
        installmentPaymentAmount: rate,
        transactionItems: transformedItem,
      };
      return formattedInstallment;
    }

    return transformedItem;
  });
  const payment = {
    notificationEmail: email,
    reCaptchaToken,
    execute: true,
    origin,
  };

  payment.transactionItems = items.filter((item) => !item.transactionItems);
  payment.installments = installmentFF
    ? items.filter((item) => item.transactionItems)
    : [];
  if (user) {
    payment.user = user;
    payment.user.stateOrProvince = user.state;
    payment.user.postalCode = user.zipCode;
    payment.user.givenName = user.firstName;
    payment.user.familyName = user.lastName;
  }
  return payment;
};

export default { paymentSubmitTransformToBackend };
