import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { getExpressInitialValues } from '../../../utils/CampaignUtils';
import createSchema from '../../../common/validation/ExpressGivingValidationUtils';
import { useHomeContext } from '../../../views/home/context';
import ExpressForm from './ExpressForm';
import useQueryParams from '../../../hooks/common/useQueryParams';
import { getFloatFromText } from '../../../common/utils';

function ExpressLayoutForm({
  transactionDetails,
  enableDefaultFrequency,
  basketIds,
  campaigns,
  onSelectTile,
  addToBasket,
}) {
  const { setFormDirtyState } = useHomeContext();
  const { campaignId, amt } = useQueryParams();
  const initialAmount = amt ? getFloatFromText(amt, 2) : undefined;

  function getValidationSchema() {
    return createSchema(campaigns);
  }

  const handleSubmit = (values, actions) => {
    setFormDirtyState(false);
    addToBasket(values, actions);
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
      initialValues={getExpressInitialValues(
        campaigns,
        enableDefaultFrequency,
        campaignId,
        initialAmount ?? '',
      )}
      validationSchema={getValidationSchema()}
    >
      {(formikProps) => {
        return (
          <ExpressForm
            formikProps={formikProps}
            transactionDetails={transactionDetails}
            basketIds={basketIds}
            campaigns={campaigns}
            onSelectTile={onSelectTile}
          />
        );
      }}
    </Formik>
  );
}

ExpressLayoutForm.propTypes = {
  transactionDetails: PropTypes.object.isRequired,
  enableDefaultFrequency: PropTypes.bool.isRequired,
  basketIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object),
  onSelectTile: PropTypes.func.isRequired,
  addToBasket: PropTypes.func.isRequired,
};

ExpressLayoutForm.defaultProps = {
  campaigns: undefined,
};

export default ExpressLayoutForm;
