import { logError } from '../common/errorUtils';

export function wrapErrorHandling(action) {
  return (...args) => {
    let result;
    try {
      result = action(...args);
    } catch (err) {
      // sync error within a thunk
      logError(err);
      throw err;
    }
    if (result instanceof Promise) {
      // async error in thunk
      return result.then(
        value => value,
        e => {
          logError(e);
          throw e;
        },
      );
    }
    return result;
  };
}

export const errorReporter = () => next => action => {
  if (typeof action !== 'function') {
    return next(action);
  }
  return next(wrapErrorHandling(action));
};

export default errorReporter;
