import pick from './pick';

export default function merge<TBase, KBase extends keyof TBase>(
  object: TBase,
  keys?: KBase[]
) {
  const base = keys ? pick(object, keys) : object;
  return {
    with: <TMerge, KMerge extends keyof TMerge>(
      object2: TMerge,
      keys2?: KMerge[]
    ) => {
      const merge = keys2 ? pick(object2, keys2) : object2;
      return Object.assign({}, base, merge);
    },
  };
}
