import i18next from 'i18next';
import {
  ENQUEUE_SNACKBAR,
  DEQUEUE_SNACKBAR,
  ENQUEUE_DIALOG,
  DEQUEUE_DIALOG,
} from './actionTypes';
import * as globals from '../common/globals';
import { logError } from '../common/errorUtils';

export const enqueueSnackbar = notification => {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      variant: globals.NOTIFICATION_ERROR,
      message: i18next.t('errors.somethingWrong'),
      ...notification,
    },
  };
};

export const enqueueSnackbarAndLogError = (error, notification) => {
  logError(error);
  return enqueueSnackbar(notification);
};

export const dequeueSnackbar = () => {
  return {
    type: DEQUEUE_SNACKBAR,
  };
};

export const enqueueDialog = notification => {
  return {
    type: ENQUEUE_DIALOG,
    notification: {
      title: '',
      message: '',
      ...notification,
    },
  };
};

export const dequeueDialog = () => {
  return {
    type: DEQUEUE_DIALOG,
  };
};
