import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from './styles';

function FormHelperTextBottom ({ left, right, warning, classes, ...rest }) {
  return (
    <FormHelperText className={classes.formHelperText} {...rest}>
      <span className={warning ? classes.warning : classes.left}>{left}</span>
      <span className={classes.right}>{right}</span>
    </FormHelperText>
  );
}

FormHelperTextBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  left: PropTypes.node,
  right: PropTypes.node,
  warning: PropTypes.bool,
};

FormHelperTextBottom.defaultProps = {
  left: null,
  right: null,
  warning: false,
};

export default withStyles(styles)(FormHelperTextBottom);
