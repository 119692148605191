import * as base64 from './base64';
import { url } from '@vancoplatform/utils';

class StatusError extends Error {
  response: object;

  constructor(message: string, response: object) {
    super(message);
    this.response = response;
  }
}

export interface JwksResult {
  modulus: string;
  exp: string;
}

export function process(jwks): JwksResult {
  const modulus = base64.decodeToHEX(jwks.n);
  const exp = base64.decodeToHEX(jwks.e);

  return {
    modulus: modulus,
    exp: exp,
  };
}

function checkStatus(response) {
  if (response.ok) {
    return response.json();
  }
  const error = new StatusError(response.statusText, response);
  return Promise.reject(error);
}

export function getJWKS(options): Promise<JwksResult> {
  const jwksUrl =
    options.jwksURI || url.join(options.iss, '.well-known', 'jwks.json');
  return fetch(jwksUrl)
    .then(checkStatus)
    .then(function (data) {
      let matchingKey = null;
      let a;
      let key;
      for (a = 0; a < data.keys.length && matchingKey === null; a++) {
        key = data.keys[a];

        if (key.kid === options.kid) {
          matchingKey = key;
        }
      }
      if (!matchingKey) {
        throw new Error(
          'Could not find a public key for Key ID (kid) "' + options.kid + '"'
        );
      }
      return process(matchingKey);
    });
}
