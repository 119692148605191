export const LOCATION_DETAILS_FETCH = 'LOCATION_DETAILS_FETCH';
export const LOCATION_DETAILS_COMPLETE = 'LOCATION_DETAILS_COMPLETE';
export const LOCATION_DETAILS_ERROR = 'LOCATION_DETAILS_ERROR';
export const LOCATION_TENANT_FETCH = 'LOCATION_TENANT_FETCH';
export const LOCATION_TENANT_COMPLETE = 'LOCATION_TENANT_COMPLETE';
export const LOCATION_TENANT_ERROR = 'LOCATION_TENANT_ERROR';
export const USER_DETAILS_FETCH = 'USER_DETAILS_FETCH';
export const USER_DETAILS_COMPLETE = 'USER_DETAILS_COMPLETE';
export const USER_DETAILS_CLEAR = 'USER_DETAILS_CLEAR';
export const USER_DETAILS_SAVE = 'USER_DETAILS_SAVE';
export const USER_DETAILS_SAVE_COMPLETE = 'USER_DETAILS_SAVE_COMPLETE';
export const USER_DETAILS_SAVE_ERROR = 'USER_DETAILS_SAVE_ERROR';
export const USER_DETAILS_ERROR = 'USER_DETAILS_ERROR';
export const USER_REQUIRED_ACTIONS_FETCH = 'USER_REQUIRED_ACTIONS_FETCH';
export const USER_REQUIRED_ACTIONS_COMPLETE = 'USER_REQUIRED_ACTIONS_COMPLETE';
export const USER_REQUIRED_ACTIONS_ERROR = 'USER_REQUIRED_ACTIONS_ERROR';
export const USER_REQUIRED_ACTIONS_SUBMITING =
  'USER_REQUIRED_ACTIONS_SUBMITING';
export const USER_REQUIRED_ACTIONS_SUBMIT_ERROR =
  'USER_REQUIRED_ACTIONS_SUBMIT_ERROR';
export const USER_REQUIRED_ACTIONS_SUBMITED = 'USER_REQUIRED_ACTIONS_SUBMITED';
export const USER_REQUIRED_ACTIONS_ADD_PAYMENT_METHOD =
  'USER_REQUIRED_ACTIONS_ADD_PAYMENT_METHOD';
export const USER_REQUIRED_ACTIONS_PAYMENT_METHOD_REMOVED =
  'USER_REQUIRED_ACTIONS_PAYMENT_METHOD_REMOVED';
export const USER_REQUIRED_ACTIONS_URL_REMOVED =
  'USER_REQUIRED_ACTIONS_URL_REMOVED';
export const GUEST_DATA_SAVE = 'GUEST_DATA_SAVE';
export const GUEST_DATA_SAVE_COMPLETE = 'GUEST_DATA_SAVE_COMPLETE';
export const GUEST_DATA_SAVE_ERROR = 'GUEST_DATA_SAVE_ERROR';
export const GUEST_DATA_CLEAR = 'GUEST_DATA_CLEAR';
export const CAMPAIGN_DETAILS_FETCH = 'CAMPAIGN_DETAILS_FETCH';
export const CAMPAIGN_DETAILS_COMPLETE = 'CAMPAIGN_DETAILS_COMPLETE';
export const CAMPAIGN_DETAILS_ERROR = 'CAMPAIGN_DETAILS_ERROR';
export const BASKET_SET_ITEMS = 'BASKET_SET_ITEMS';
export const BASKET_SET_PAYMENT_OPTIONS = 'BASKET_SET_PAYMENT_OPTIONS';
export const UPDATE_BASKET_PAYMENT_TYPE = 'UPDATE_BASKET_PAYMENT_TYPE';
export const CLEAR_PAYMENT_OPTIONS = 'CLEAR_PAYMENT_OPTIONS';
export const SELECTED_PAYMENT_METHOD_FETCH = 'SELECTED_PAYMENT_METHOD_FETCH';
export const SELECTED_PAYMENT_METHOD_COMPLETE =
  'SELECTED_PAYMENT_METHOD_COMPLETE';
export const SELECTED_PAYMENT_METHOD_ERROR = 'SELECTED_PAYMENT_METHOD_ERROR';
export const PAYMENT_METHOD_SAVE = 'SELECTED_PAYMENT_METHOD_SAVE';
export const PAYMENT_METHOD_SAVE_COMPLETE = 'PAYMENT_METHOD_SAVE_COMPLETE';
export const PAYMENT_METHOD_SAVE_ERROR = 'PAYMENT_METHOD_SAVE_ERROR';
export const SESSION_INIT_COMPLETE = 'SESSION_INIT_COMPLETE';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_LOGOUT_INIT = 'SESSION_LOGOUT_INIT';
export const IDS_SESSION_SAVE = 'IDS_SESSION_SAVE';
export const IDS_SESSION_CHECK_UPDATE = 'IDS_SESSION_CHECK_UPDATE';
export const IDS_NON_INTERACTIVE_LOGIN_ERROR =
  'IDS_NON_INTERACTIVE_LOGIN_ERROR';
export const IDS_NON_INTERACTIVE_LOGIN_ERROR_HANDLED =
  'IDS_NON_INTERACTIVE_LOGIN_ERROR_HANDLED';
export const SAVED_PAYMENT_METHODS_FETCH = 'SAVED_PAYMENT_METHODS_FETCH';
export const SAVED_PAYMENT_METHODS_COMPLETE = 'SAVED_PAYMENT_METHODS_COMPLETE';
export const SAVED_PAYMENT_METHODS_ERROR = 'SAVED_PAYMENT_METHODS_ERROR';
export const SAVED_PAYMENT_METHODS_DELETE = 'SAVED_PAYMENT_METHODS_DELETE';
export const SAVED_PAYMENT_METHODS_DELETE_COMPLETE =
  'SAVED_PAYMENT_METHODS_DELETE_COMPLETE';
export const SAVED_PAYMENT_METHODS_DELETE_ERROR =
  'SAVED_PAYMENT_METHODS_DELETE_ERROR';
export const SAVED_PAYMENT_METHODS_ADD = 'SAVED_PAYMENT_METHODS_ADD';
export const TRANSACTIONS_SCHEDULED_FETCH = 'TRANSACTIONS_SCHEDULED_FETCH';
export const TRANSACTIONS_SCHEDULED_COMPLETE =
  'TRANSACTIONS_SCHEDULED_COMPLETE';
export const TRANSACTIONS_SCHEDULED_ERROR = 'TRANSACTIONS_SCHEDULED_ERROR';
export const TRANSACTIONS_SCHEDULED_DELETE = 'TRANSACTIONS_SCHEDULED_DELETE';
export const TRANSACTIONS_SCHEDULED_DELETE_COMPLETE =
  'TRANSACTIONS_SCHEDULED_DELETE_COMPLETE';
export const TRANSACTIONS_SCHEDULED_DELETE_ERROR =
  'TRANSACTIONS_SCHEDULED_DELETE_ERROR';
export const TRANSACTION_HISTORY_FETCH = 'TRANSACTION_HISTORY_FETCH';
export const TRANSACTION_HISTORY_COMPLETE = 'TRANSACTION_HISTORY_COMPLETE';
export const TRANSACTION_HISTORY_ERROR = 'TRANSACTION_HISTORY_ERROR';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const DEQUEUE_SNACKBAR = 'DEQUEUE_SNACKBAR';
export const ENQUEUE_DIALOG = 'ENQUEUE_DIALOG';
export const DEQUEUE_DIALOG = 'DEQUEUE_DIALOG';
export const SET_HEXEA_OBJECT = 'SET_HEXEA_OBJECT';
export const CSRF_FETCH = 'CSRF_FETCH';
export const CSRF_COMPLETE = 'CSRF_COMPLETE';
export const CSRF_ERROR = 'CSRF_ERROR';
export const FEATURES_FETCH = 'FEATURES_FETCH';
export const FEATURES_COMPLETE = 'FEATURES_COMPLETE';
export const FEATURES_ERROR = 'FEATURES_ERROR';

// Payment actions
export const PAYMENT_ID_FETCH = 'PAYMENT_ID_FETCH';
export const PAYMENT_ID_COMPLETE = 'PAYMENT_ID_COMPLETE';
export const PAYMENT_ID_ERROR = 'PAYMENT_ID_ERROR';
export const PAYMENT_ID_CLEAR = 'PAYMENT_ID_CLEAR';
export const PAYMENT_SUBMIT_BEGIN = 'PAYMENT_SUBMIT_BEGIN';
export const PAYMENT_SUBMIT_COMPLETE = 'PAYMENT_SUBMIT_COMPLETE';
export const PAYMENT_SUBMIT_ERROR = 'PAYMENT_SUBMIT_ERROR';
export const PAYMENT_SUBMITTED_ID_FETCH = 'PAYMENT_SUBMITTED_ID_FETCH';
export const PAYMENT_SUBMITTED_USER_DETAILS_FETCH =
  'PAYMENT_SUBMITTED_USER_DETAILS_FETCH';

// Post Message to Streaming App
export const FRAME_SEND = 'FRAME_SEND';
export const FRAME_COMPLETE = 'FRAME_COMPLETE';
export const FRAME_ERROR = 'FRAME_ERROR';

// Migrate Transactions
export const MIGRATED_TRANSACTIONS_PATCH = 'MIGRATED_TRANSACTIONS_PATCH';
export const MIGRATED_TRANSACTIONS_PATCH_COMPLETE =
  'MIGRATED_TRANSACTIONS_PATCH_COMPLETE';
export const MIGRATED_TRANSACTIONS_PATCH_ERROR =
  'MIGRATED_TRANSACTIONS_PATCH_ERROR';
export const MIGRATED_TRANSACTIONS_PATCH_ABANDON = 'Abandoned';

// Origin
export const ORIGIN_SET = 'ORIGIN_SET';
