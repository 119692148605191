import BaseService from './BaseService';
import { userActionRequiredTransformToFrontend } from './transform/UserActionRequiredTransform';
import {
  userDetailsTransformToBackend,
  userDetailsTransformToFrontend,
} from './transform/UserDetailsTransform';

class UserService extends BaseService {
  async saveUserDetails(userDetails, orgId, allowOnboardUser = false) {
    const response = await this.http.put(
      `users/ensure?organizationId=${orgId}`,
      userDetailsTransformToBackend(userDetails, allowOnboardUser),
    );
    return userDetailsTransformToFrontend(response);
  }

  async getUserActionRequired(locationId) {
    const response = await this.http.get(`users/actionrequired/${locationId}`);
    const transformedData = userActionRequiredTransformToFrontend(response);

    return transformedData;
  }

  async patchActionRequired(recurrenceId, status, locationId) {
    const response = await this.http.patch(
      `users/actionrequired/${recurrenceId}/${status}?locationid=${locationId}`,
    );
    return response;
  }
}

export default new UserService();
