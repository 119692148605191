export default function styles(theme) {
  return {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: '32px',
      paddingLeft: '58px',
      paddingRight: '200px',
      flexDirection: 'column',
    },
    title: {
      letterSpacing: '-0.38px',
      marginBottom: '32px',
    },
    card: {
      marginBottom: '25px',
      width: '100%',
      minWidth: '410px',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    infoIcon: {
      marginRight: '10px',
    },
    progressHolder: {
      width: '100%',
    },
    progress: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      root: {
        paddingRight: '58px',
      },
      card: {
        minWidth: '0',
      },
    },
  };
}
