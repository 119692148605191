export default function styles(theme) {
  return {
    container: {
      padding: '24px 24px 16px',
      justifyContent: 'space-between',
    },
    submitButton: {
      width: '60%',
    },
    submitButtonFull: {
      width: '100%',
    },
    backButton: {
      width: '37%',
    },
    backIcon: {
      fontSize: 20,
      marginRight: theme.spacing(1),
    },
  };
}
