import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import TransactionService from '../../api/TransactionService';
import { enqueueSnackbar } from '../../actions/notifications';
import { getPaymentIdFromStorage } from '../../common/storageUtils';
import { NOTIFICATION_ERROR } from '../../common/globals';
import { getApiErrorMessage } from '../../common/errorUtils';

export const useUpdateScheduledTransaction = (options = {}) => {
  const dispatch = useDispatch();
  const locationId = useSelector(({ location }) => location.id);
  const paymentId = getPaymentIdFromStorage(locationId);
  const { mutateAsync, ...mutation } = useMutation({
    mutationFn: ({ paymentToken, data }) => {
      return TransactionService.updateScheduledTransaction(
        locationId,
        paymentToken,
        paymentId,
        data,
      );
    },
    onError: (error) => {
      const errorMessage = getApiErrorMessage(error);
      dispatch(
        enqueueSnackbar({
          variant: NOTIFICATION_ERROR,
          message: errorMessage,
        }),
      );
    },
    ...options,
  });
  return {
    updateScheduledTransaction: mutateAsync,
    ...mutation,
  };
};

export default { useUpdateScheduledTransaction };
