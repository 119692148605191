import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { getIn } from 'formik';
import CurrencyNumberFormat from './CurrencyNumberFormat';

export default function CurrencyTextField({
  field,
  form,
  helperTextClass,
  'aria-label': ariaLabel,
  ...props
}) {
  const { ...other } = props;
  const { name } = field;
  const { touched, errors } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return (
    <TextField
      id={name}
      name={name}
      error={showError}
      helperText={showError ? fieldError : ''}
      placeholder="$ 0.00"
      value={field.value || ''}
      FormHelperTextProps={{ classes: { root: helperTextClass } }}
      {...other}
      InputProps={{
        inputComponent: CurrencyNumberFormat,
        inputProps: {
          onChange: (event) =>
            form.setFieldValue(name, event.target.value, true),
          onBlur: () => form.setFieldTouched(name, true, false),
          'aria-label': ariaLabel,
        },
      }}
    />
  );
}

CurrencyTextField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  helperTextClass: PropTypes.string,
  'aria-label': PropTypes.string,
};

CurrencyTextField.defaultProps = {
  helperTextClass: '',
  'aria-label': null,
};
