export default function blacklist<
  T extends Record<string, unknown>,
  K extends keyof T
>(obj: T, blacklistedKeys: (K | string)[]): Omit<T, K> {
  if (!obj) return obj;
  return Object.assign(
    {},
    ...Object.keys(obj)
      .filter((key) => blacklistedKeys.indexOf(key) === -1)
      .map((key) => ({ [key]: obj[key] }))
  );
}
