import BaseService from './BaseService';

class FeaturesService extends BaseService {
  featuresUrl = 'features';

  getFeaturesUrl = () => this.featuresUrl;

  getFeatures = (orgId) => {
    return this.http.getWithParams(this.getFeaturesUrl(), {
      organizationid: orgId?.toUpperCase(),
    });
  };
}

export default new FeaturesService();
