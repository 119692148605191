import * as globals from '../../common/globals';
import { getStrippedPhoneNumber } from '../../common/utils';

// eslint-disable-next-line import/prefer-default-export
export const userDetailsTransformToBackend = (
  userDetails,
  allowOnboardUser,
) => {
  return {
    data: {
      payerUser: {
        email: userDetails.email,
        givenName: userDetails.firstName,
        familyName: userDetails.lastName,
        phoneNumber: userDetails.phoneNumber,
        addressA: userDetails.addressLine1,
        addressB: userDetails.addressLine2,
        stateOrProvince: userDetails.state,
        city: userDetails.city,
        postalCode: userDetails.zipCode,
      },
      createUser: allowOnboardUser,
    },
  };
};

export const userDetailsTransformToFrontend = (userDetailsResponse) => {
  return {
    data: {
      id: userDetailsResponse.data.data.id,
    },
    status: userDetailsResponse.status,
  };
};

const getUserInfoByDetails = ({
  given_name: firstName,
  family_name: lastName,
  address,
  phone_number: phoneNumber,
}) => ({
  firstName,
  lastName,
  street: address.street_address,
  city: address.locality,
  state: address.region,
  zipCode: address.postal_code,
  phoneNumber,
});

const getUserInfoByForm = (formValues, userDetails) => {
  const {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    phoneNumber,
  } = formValues.billingInfo;
  const street = `${addressLine1 ?? ''}\n${addressLine2 ?? ''}`;

  return {
    firstName: firstName ?? userDetails?.given_name,
    lastName: lastName ?? userDetails?.family_name,
    street,
    city,
    state,
    zipCode,
    phoneNumber,
  };
};

/**
 * @param userDetails user information
 * @param formValues formik values
 * @param paymentType should be CREDIT_CARD or ACH
 */
export const userDetailsTransformToHexeaBillingInfo = (
  userDetails,
  formValues,
  paymentType,
) => {
  // should use the saved email for a user if available, otherwise it should be in the form
  const email = userDetails.email || formValues.billingInfo.email;
  const userInfo = userDetails.detailsComplete
    ? getUserInfoByDetails(userDetails)
    : getUserInfoByForm(formValues, userDetails);

  const paymentTypeValues = {};
  const streets = userInfo?.street?.split('\n');
  const street1 = streets?.length >= 1 ? streets[0] : '';
  const street2 = streets?.length > 1 ? streets[1] : '';
  const strippedPhone = getStrippedPhoneNumber(userInfo.phoneNumber);

  if (paymentType === globals.CREDIT_CARD) {
    paymentTypeValues.authorizationType = globals.CXP_AUTHORIZATION_TYPE_IDS;
    paymentTypeValues.nameOnCard = `${userInfo.firstName} ${userInfo.lastName}`;
    paymentTypeValues.address = {
      addressLine1: street1,
      addressLine2: street2,
      // this postal code must be the one from the credit card not the user info
      postalCode: formValues[globals.CREDIT_CARD].zipCode,
      state: userInfo.state,
      city: userInfo.city,
    };
    paymentTypeValues.phone = strippedPhone;
  }

  if (paymentType === globals.ACH) {
    paymentTypeValues.routingNumber = formValues[globals.ACH].routingNumber;
    paymentTypeValues.accountNumber = formValues[globals.ACH].accountNumber;
    paymentTypeValues.accountType = formValues[globals.ACH].accountType;
    paymentTypeValues.authorizationType = globals.CXP_AUTHORIZATION_TYPE_IDS;
    paymentTypeValues.firstName = userInfo.firstName;
    paymentTypeValues.lastName = userInfo.lastName;
    paymentTypeValues.address = {
      addressLine1: street1,
      addressLine2: street2,
      postalCode: userInfo.zipCode,
      state: userInfo.state,
      city: userInfo.city,
    };
    paymentTypeValues.phone = strippedPhone;
  }

  return {
    email,
    isSaved: formValues.billingInfo.saveMethod,
    ...paymentTypeValues,
  };
};
