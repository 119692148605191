import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  withStyles,
  Table,
  TableBody,
  Paper,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import HistoryLineItem from '../../../../components/transactions/history/HistoryLineItem';
import MobileHistoryLineItem from '../../../../components/transactions/history/mobile/MobileHistoryLineItem';
import HistoryDialog from '../../../../components/transactions/history/dialog/HistoryDialog';

function TransactionHistory({ transactionHistory, classes }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState({});

  const { t } = useTranslation();

  const handleTransctionClick = (transaction) => {
    setDialogOpen(true);
    setCurrentTransaction(transaction);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setCurrentTransaction({});
  };

  return !transactionHistory.length ? (
    <div
      id="history-missing-root"
      data-testid="history-missing-root"
      className={classes.missingRoot}
    >
      <Typography id="history-missing-title" variant="h5">
        {t('transactions.history.missingTitle')}
      </Typography>
      <Typography id="history-missing-text" className={classes.missingText}>
        {t('transactions.history.missingText')}
      </Typography>
    </div>
  ) : (
    <div className={classes.historyRoot}>
      <Paper className={classes.historyPaper}>
        <Table>
          <TableBody>
            {transactionHistory.map((transaction) =>
              isSmallScreen ? (
                <MobileHistoryLineItem
                  key={
                    transaction.paymentId +
                    transaction.accountCode +
                    transaction.displayName +
                    transaction.transactionDate
                  } // discrepancies between systems such as our backend and TE cause some potential data inconsistencies. I'm keying off a lot here to try and catch all possible scenarios.
                  transaction={transaction}
                  handleTransactionClick={handleTransctionClick}
                />
              ) : (
                <HistoryLineItem
                  key={
                    transaction.paymentId +
                    transaction.accountCode +
                    transaction.displayName +
                    transaction.transactionDate
                  }
                  transaction={transaction}
                  handleTransactionClick={handleTransctionClick}
                />
              ),
            )}
          </TableBody>
        </Table>
      </Paper>
      <HistoryDialog
        open={dialogOpen}
        transaction={currentTransaction}
        handleClose={closeDialog}
        fullScreen={isSmallScreen}
      />
    </div>
  );
}

TransactionHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  transactionHistory: PropTypes.arrayOf(PropTypes.object),
};

TransactionHistory.defaultProps = {
  transactionHistory: [],
};

export default withStyles(styles)(TransactionHistory);
