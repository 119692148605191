import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Divider, Button } from '@material-ui/core';
import { Field } from 'formik';
import moment from 'moment';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  getInitialMonth,
  getLatestAllowedDate,
  getEarliestAllowedDate,
  getLatestAllowedEndDate,
} from '../../../utils/CalendarUtils';
import DatePicker from '../../common/DatePicker';
import * as globals from '../../../common/globals';
import FrequencyDropdown from './FrequencyDropdown';
import CurrencyTextField from '../../common/CurrencyTextField';
import TextFieldWithCharCount from '../../common/TextFieldWithCharCount';
import MemoFieldsDropdown from './MemoFieldsDropdown';
import RecurringOption from './RecurringOption';
import { PAYMENT_FREQUENCY_OPTIONS } from '../../../common/globals';
import RNWebViewHandler from '../../../utils/RNWebViewHandler';
import { useOpenAmountStyles } from './styles';
import InstallmentOverview from './InstallmentOverview';

function OpenAmount({
  transactionDetails,
  formikProps: { values, setFieldValue, setFieldTouched, isSubmitting, errors },
  startDateLabel,
  hideMultiTab,
  features,
  isExpressLayout,
  isFormDisabled,
  isEditModal,
}) {
  const { t } = useTranslation();

  const classes = useOpenAmountStyles({
    layout: isExpressLayout
      ? globals.LOCATION_PAGE_LAYOUTS.EXPRESS
      : globals.LOCATION_PAGE_LAYOUTS.DETAIL,
  });
  // recurringOption is the field name/value of the active option - can be once or recurring
  const { frequency, startDate, endDate, recurringOption } =
    values[transactionDetails?.fundId] || {};

  const [endDateVisible, setEndDateVisible] = useState(Boolean(endDate));
  const [resetDateValues, setResetDateValues] = useState(false);
  const resetDateValuesCallback = () => setResetDateValues(false);
  const [currentFrequencies, setCurrentFrequencies] = useState([]);
  const { isInWebview } = new RNWebViewHandler();
  const allowedDates =
    frequency === globals.PAYMENT_FREQUENCY_TWICE_A_MONTH ? [1, 15] : null;

  useEffect(() => {
    const startDateName = `${transactionDetails.fundId}.startDate`;
    const endDateName = `${transactionDetails.fundId}.endDate`;
    if (
      (frequency === globals.PAYMENT_FREQUENCY_ONE_TIME_NOW ||
        frequency === globals.PAYMENT_FREQUENCY_TWICE_A_MONTH) &&
      startDate
    ) {
      setFieldValue(startDateName, null, true);
      setFieldTouched(startDateName, false, false);
    }

    if (
      (frequency === globals.PAYMENT_FREQUENCY_ONE_TIME_NOW ||
        frequency === globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE ||
        frequency === globals.PAYMENT_FREQUENCY_TWICE_A_MONTH) &&
      endDate
    ) {
      setFieldValue(endDateName, null, true);
      setFieldTouched(endDateName, false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency]);

  const setInitialVisibleMonth = () => {
    return getInitialMonth(startDate, allowedDates, features);
  };

  const memoLineResponseRequired =
    transactionDetails?.memoLine?.responseRequired ?? false;

  const getLabelText = () => {
    let formattedText = transactionDetails.memoLine.textField;
    formattedText += memoLineResponseRequired
      ? ` (${t('addDonation.responseRequired')})`
      : ` (${t('addDonation.responseOptional')})`;
    return formattedText;
  };

  const resetEndDateVisibility = () => {
    setEndDateVisible(false);
  };

  const showMemoFieldsDropDown =
    features?.TileMemoDropdown &&
    !transactionDetails.isTextFieldResponseType &&
    transactionDetails.memoFields?.length > 0;

  const showMemoTextField = transactionDetails.memoLine?.showTextField ?? false;

  const orderedMemoFields = transactionDetails.memoFields?.sort((a, b) =>
    a.ordinal > b.ordinal ? 1 : -1,
  );

  const disabled = isExpressLayout ? isFormDisabled : isSubmitting;
  const isInstallmentOption =
    recurringOption === PAYMENT_FREQUENCY_OPTIONS.INSTALLMENT;
  const isRecurringOption =
    recurringOption === PAYMENT_FREQUENCY_OPTIONS.RECURRING ||
    isInstallmentOption;

  const getDefaultFrequency = (item) => {
    if (!item) {
      return '';
    }

    // recurring and has a recurring default
    if (isRecurringOption) {
      return item.defaultRecurringFrequency
        ? item.defaultRecurringFrequency
        : item.defaultOneTimeFrequency;
    }
    return item.defaultOneTimeFrequency
      ? item.defaultOneTimeFrequency
      : item.defaultRecurringFrequency;
  };

  useEffect(
    () => {
      let frequencyList = [];
      /**
       *  if the page is in a webview only allow 'One time, now' transactions
       *  this will be removed when the guest recurrence feature is implemented
       * TODO: remove this validation when guest recurrence is implemented
       *  */
      if (isInWebview) {
        frequencyList = [globals.PAYMENT_FREQUENCY_ONE_TIME_NOW];
      }
      // frequencies are merged
      else if (hideMultiTab || !features.OnceRecurringTab) {
        // fall back to full listing
        frequencyList = transactionDetails.frequencies;
      } else {
        setResetDateValues(true);
        // you will always have a default value derrived from its corresponding list
        if (!isRecurringOption) {
          frequencyList = transactionDetails.defaultOneTimeFrequency
            ? transactionDetails?.oneTimePaymentFrequencies
            : transactionDetails?.recurringPaymentFrequencies;
        }
        if (isRecurringOption) {
          frequencyList = transactionDetails.defaultRecurringFrequency
            ? transactionDetails?.recurringPaymentFrequencies
            : transactionDetails?.oneTimePaymentFrequencies;
        }
      }

      setCurrentFrequencies(frequencyList || []);
      if (!hideMultiTab) {
        setFieldValue(
          `${transactionDetails.fundId}.frequency`,
          getDefaultFrequency(transactionDetails),
          false,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRecurringOption, transactionDetails],
  );

  const showMultiTab = () => {
    // if is in webview default to full listing
    // TODO: remove this validation when guest recurrence is implemented
    if (isInWebview) {
      return false;
    }
    // if feature is off
    if (hideMultiTab || !features.OnceRecurringTab) {
      return false;
    }
    // both list required
    if (
      !transactionDetails?.oneTimePaymentFrequencies ||
      !transactionDetails?.recurringPaymentFrequencies
    ) {
      return false;
    }
    // least 1 element in each list is required
    return !(
      transactionDetails?.oneTimePaymentFrequencies.length === 0 ||
      transactionDetails?.recurringPaymentFrequencies.length === 0
    );
  };

  return (
    <Fragment>
      <Field
        id="donation-amount"
        name={`${transactionDetails.fundId}.amount`}
        aria-label={t('addDonation.amount')}
        label={!isExpressLayout ? t('addDonation.amount') : null}
        variant={isExpressLayout ? 'standard' : 'outlined'}
        autoComplete="off"
        className={classes?.currencyInput}
        helperTextClass={classes?.inputHelperText}
        component={CurrencyTextField}
        disabled={disabled}
      />
      {showMultiTab() && !disabled && (
        <Field
          id="donation-recurringOption"
          name={`${transactionDetails.fundId}.recurringOption`}
          frequencies={transactionDetails.frequencies}
          component={RecurringOption}
          className={classes?.inputField}
          allowInstallments={transactionDetails.allowInstallments}
          features={features}
        />
      )}
      {transactionDetails.frequencies && (
        <Field
          id="donation-frequency"
          name={`${transactionDetails.fundId}.frequency`}
          label={t(
            isExpressLayout
              ? 'transactions.action.selectFrequency'
              : 'addDonation.frequency',
          )}
          component={FrequencyDropdown}
          frequencies={currentFrequencies}
          className={classes?.inputField}
          helperTextClass={classes?.inputHelperText}
          features={features}
          disabled={
            transactionDetails.frequency ===
              globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE && isEditModal
              ? true
              : disabled
          }
        />
      )}
      {!!frequency && frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_NOW && (
        <Field
          id="donation-start-date"
          data-testid="donation-start-date"
          name={`${transactionDetails.fundId}.startDate`}
          label={startDateLabel ?? t('addDonation.startDate')}
          inputClass={classes?.inputField}
          helperTextClass={classes?.inputHelperText}
          earliestAllowedDate={getEarliestAllowedDate(
            moment(),
            frequency,
            features,
          )}
          latestAllowedDate={getLatestAllowedDate(moment())}
          component={DatePicker}
          initialVisibleMonth={setInitialVisibleMonth}
          allowedDates={allowedDates}
          resetValue={resetDateValues}
          resetCallBack={resetDateValuesCallback}
        />
      )}
      {frequency !== '' &&
        frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_NOW &&
        frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE &&
        values[transactionDetails.fundId]?.showEndDate &&
        !endDateVisible &&
        !isInstallmentOption && (
          <div
            className={[
              classes?.inputField,
              classes?.inputFieldNoBackground,
            ].join(' ')}
          >
            <Button
              data-testid="showEndDateButton"
              id="show-enddate"
              variant="text"
              onClick={() => setEndDateVisible(true)}
            >
              <Typography className={classes?.setEndDateVisible}>
                {t('addDonation.setEndDate')}
              </Typography>
              <Add className={classes?.setEndDateVisible} fontSize="small" />
            </Button>
          </div>
        )}
      {((frequency !== '' &&
        frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_NOW &&
        frequency !== globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE &&
        values[transactionDetails.fundId]?.showEndDate &&
        endDateVisible) ||
        isInstallmentOption) && (
        <Field
          id="donation-end-date"
          data-testid="donation-end-date"
          name={`${transactionDetails.fundId}.endDate`}
          label={t(
            isInstallmentOption
              ? 'addDonation.endDate.required'
              : 'addDonation.endDate',
          )}
          inputClass={classes?.inputField}
          helperTextClass={classes?.inputHelperText}
          earliestAllowedDate={getEarliestAllowedDate(
            moment(),
            frequency,
            features,
          )}
          latestAllowedDate={getLatestAllowedEndDate(moment())}
          component={DatePicker}
          initialVisibleMonth={setInitialVisibleMonth}
          allowedDates={allowedDates}
          resetCallBack={resetEndDateVisibility}
        />
      )}
      {showMemoTextField && !showMemoFieldsDropDown && (
        <>
          <Divider variant="middle" className={classes?.dividerStyleFix} />
          <Typography className={classes?.dateTitle} variant="caption">
            {getLabelText()}
          </Typography>
          <Field
            id="donation-memoline"
            name={`${transactionDetails.fundId}.memoLine`}
            variant="outlined"
            autoComplete="off"
            maxLength={50}
            maxRows={2}
            multiline
            className={classes?.inputField}
            helperTextClass={classes?.inputHelperText}
            component={TextFieldWithCharCount}
          />
        </>
      )}
      {showMemoTextField && showMemoFieldsDropDown && (
        <>
          <Divider variant="middle" className={classes?.dividerStyleFix} />
          <Field
            id="memo-fields"
            textFieldLabel={`${t('accountPayment.select')} ${getLabelText()}`}
            name={`${transactionDetails.fundId}.memoLine`}
            component={MemoFieldsDropdown}
            memoFields={orderedMemoFields}
            className={classes?.inputField}
            helperTextClass={classes?.inputHelperText}
          />
        </>
      )}
      {isInstallmentOption && (
        <InstallmentOverview
          values={values[transactionDetails.fundId]}
          setInstallmentsValid={(value) =>
            setFieldValue(
              `${transactionDetails.fundId}.installmentsValid`,
              value,
            )
          }
          errorMessage={errors[transactionDetails.fundId]?.installmentsValid}
        />
      )}
    </Fragment>
  );
}

OpenAmount.propTypes = {
  transactionDetails: PropTypes.object,
  formikProps: PropTypes.object.isRequired,
  startDateLabel: PropTypes.string,
  features: PropTypes.object.isRequired,
  isExpressLayout: PropTypes.bool,
  hideMultiTab: PropTypes.bool,
  isFormDisabled: PropTypes.bool,
  isEditModal: PropTypes.bool,
};
OpenAmount.defaultProps = {
  startDateLabel: undefined,
  hideMultiTab: false,
  transactionDetails: undefined,
  isFormDisabled: false,
  isExpressLayout: false,
  isEditModal: false,
};

const mapStateToProps = (state) => ({
  features: state.features.features,
});

export default connect(mapStateToProps)(OpenAmount);
