// eslint-disable-next-line import/prefer-default-export
import visa from '../images/visa.svg';
import mastercard from '../images/mastercard.svg';
import discover from '../images/discover.svg';
import amex from '../images/american_express.svg';
import bank from '../images/bank.svg';
import * as globals from './globals';
import RNWebViewHandler from '../utils/RNWebViewHandler';

export function toCapitalized(word) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function renderCardIcon(brand, styles = '') {
  let src;
  switch (brand) {
    case globals.CARD_TYPE_VISA:
      src = visa;
      break;
    case globals.CARD_TYPE_DISCOVER:
      src = discover;
      break;
    case globals.CARD_TYPE_MASTERCARD:
      src = mastercard;
      break;
    case globals.CARD_TYPE_AMEX:
      src = amex;
      break;
    case globals.ACH:
      src = bank;
      break;
    default:
      return null;
  }
  return (
    <img
      key={brand}
      id={`card-${brand}`}
      data-testid={`card-${brand}`}
      src={src}
      alt=""
      className={styles}
    />
  );
}

export function getTransactionCount(basketItems) {
  return basketItems.reduce((sum, item) => {
    if (item.campaignDetails.type === globals.CAMPAIGN_TYPE.GIFT) {
      return sum + Object.keys(item.transactionData).length;
    }
    return (
      sum +
      Object.values(item.transactionData).reduce((total, t) => {
        return total + (t.quantity || 0);
      }, 0)
    );
  }, 0);
}

/**
 * Determines if the browser is IE11
 */
export function isIE11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

export function hasScheduledTransactions(basketItems) {
  let isScheduled = false;
  basketItems.forEach((item) => {
    if (item.transactionData) {
      Object.keys(item.transactionData).forEach((transaction) => {
        if (
          !isScheduled &&
          item.transactionData[transaction].frequency !==
            globals.PAYMENT_FREQUENCY_ONE_TIME_NOW
        ) {
          isScheduled = true;
        }
      });
    }
  });
  return isScheduled;
}

export function getCurrencyFormat(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  }).format(amount);
}

function transactionItemsMatch(transactionItem1, transactionItem2) {
  return (
    transactionItem1.frequency === transactionItem2.frequency &&
    // In case the startDate is a Moment object instead of a string, call stringify on it
    JSON.stringify(transactionItem1.startDate ?? '') ===
      JSON.stringify(transactionItem2.startDate ?? '')
  );
}

function arraysEqual(arr1, arr2) {
  return (
    arr1.length === arr2.length && arr1.every((item, i) => item === arr2[i])
  );
}

/**
 * Returns true if two basketItems have same campaign ID, frequency and startDate.
 */
export function basketItemsMatch(basketItem1, basketItem2) {
  if (basketItem1.campaignDetails.id !== basketItem2.campaignDetails.id) {
    return false;
  }
  const transactionData1 = basketItem1.transactionData;
  const transactionData2 = basketItem2.transactionData;
  if (!transactionData1 || !transactionData2) {
    throw new Error(
      'The basketItem must have a value for the transactionData property',
    );
  }
  const keys1 = Object.keys(transactionData1).sort();
  const keys2 = Object.keys(transactionData2).sort();
  if (!arraysEqual(keys1, keys2)) {
    return false;
  }
  return keys1.every((key) =>
    transactionItemsMatch(transactionData1[key], transactionData2[key]),
  );
}

/**
 * Returns the transaction display name
 */
export function getTransactionDisplayName(transaction) {
  const { displayName, quantity } = transaction;

  if (!quantity) {
    return displayName;
  }

  return `${displayName} x ${quantity}`;
}

export default function formatPhoneNumber(phone) {
  if (phone === null) {
    return null;
  }
  // Some numbers start with +1 but we don't want to show the '+'
  const justTheDigits = (phone.match(/\d/g) ?? []).join('');
  // Important that this ends with a $ so that we only take the last 10 digits
  // and ignore any country code if it starts with one.
  const phoneRegex = /(?<areaCode>\d{3})(?<next3>\d{3})(?<last4>\d{4})$/g;
  const match = phoneRegex.exec(justTheDigits);
  if (!match) {
    // Fall back to just returning the raw string if we can't parse it for some reason
    return phone;
  }
  const { groups } = match;
  return `${groups.areaCode}-${groups.next3}-${groups.last4}`;
}

export function getStrippedPhoneNumber(phone) {
  return phone ? phone.replace(/\D/g, '').slice(-10) : undefined;
}

export function getCurrentPathNameWithParams() {
  let currentUrl = window.location.pathname;
  const queryParams = new URLSearchParams(window.location.search);
  let firstParam = true;
  queryParams.forEach((key, value) => {
    currentUrl = `${currentUrl}${firstParam ? '?' : '&'}${value}=${key}`;
    firstParam = false;
  });
  return currentUrl;
}

export function getSearchParamByKey(key) {
  const query = new URLSearchParams(window.location.search);
  return query.get(key);
}

export function filterFailedTransactions(formValues, transactions) {
  const newFormValues = {};
  const failedRecurrences = transactions
    .map((t) => {
      if (t.error?.failedTransactions) {
        return t.error.failedTransactions.map((ft) => ft.recurrenceId);
      }
      return null;
    })
    .filter((t) => t)
    .flat();

  failedRecurrences.forEach((fr) => {
    newFormValues[fr] = formValues[fr];
  });
  return newFormValues;
}

/**
 * The objective of the function is to determine the selected payment type based on the allowed payment methods and the user's selection.
 * @param {*} paymentMethods: an object containing information about the allowed payment methods (credit card and ACH) and whether they are allowed or not
 * @param {*} selectedPaymentType: a string representing the user's selected payment type (creditCard or ach)
 * @returns
 */
export function getSelectedPaymentType(paymentMethods, selectedPaymentType) {
  // None of the payment methods are allowed
  const { isInWebview } = new RNWebViewHandler();
  const isCardAllowed = paymentMethods?.creditCard?.isAllowed;
  if (!isCardAllowed && !paymentMethods?.ach?.isAllowed) return null;

  if (isCardAllowed && isInWebview && selectedPaymentType === globals.SWIPE) {
    return selectedPaymentType;
  }

  if (paymentMethods?.[selectedPaymentType]?.isAllowed)
    return selectedPaymentType;

  return isCardAllowed ? globals.CREDIT_CARD : globals.ACH;
}

export function getFloatFromText(textValue, decimals = 0) {
  let floatNumber = null;
  if (textValue && !Number.isNaN(Number(textValue))) {
    const dotIndex = textValue.indexOf('.');
    const hasDecimals = dotIndex !== -1;
    floatNumber = parseFloat(
      hasDecimals ? textValue.substring(0, dotIndex + decimals + 1) : textValue,
    );
  }
  return floatNumber;
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * Determines if a given frequency is of future payment frequency type (PAYMENT_FREQUENCY_ONE_TIME_FUTURE)
 * @param {string} frequency: one of the PAYMENT FREQUENCIES
 * @returns true if the given frequency is equal to OneTimeFuture (globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE), otherwise false
 */
export const isFuturePaymentFrequency = (frequency) =>
  frequency === globals.PAYMENT_FREQUENCY_ONE_TIME_FUTURE;

/**
 * Determines if a given frequency is a recurring frequency type
 * @param {string} frequency: one of the PAYMENT FREQUENCIES
 * @returns true if the given frequency is equal to any of the globals.RECURRING_PAYMENT_OPTIONS, otherwise false
 */
export const isRecurringPaymentFrequency = (frequency) =>
  globals.RECURRING_PAYMENT_OPTIONS.includes(frequency);

export const isObjectEmptyOrNull = (obj = null) =>
  !obj || !Object.keys(obj).length;

/**
 * Casts two decimals to float to ensure they are correct and compares them.
 * @param {number} num1: first decimal number
 * @param {number} num2: second decimal number
 * @returns true if they are equal
 */
export const compareDecimals = (num1 = 0.0, num2 = 0.0) =>
  Number.parseFloat(num1) === Number.parseFloat(num2);

/**
 * Safetely converts a DECIMAL NUMBER to a string.
 * @param {number} number: DECIMAL NUMBER to convert.
 * @returns the same number in string, if it is not a number returns '0.00'
 */
export const convertDecimalToString = (number = 0.0) => {
  if (typeof number !== 'number' || Number.isNaN(number)) return '0.00';
  return number.toFixed(2);
};

export const toFixedTruncate = (num, fixed) => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  return Number(num.toString().match(re)[0]);
};

/**
 * Converts the first letter of a string to UpperCase
 * @param {string} str: A string
 * @returns the same string with the first letter in upper case
 */
export const firstLetterToUpperCase = (str = '') => {
  if (!str) {
    return str;
  }
  // Check if the string contains only one letter
  if (str.length < 1) {
    return str.toUpperCase();
  }
  return str[0].toUpperCase() + str.slice(1, str.length);
};

/**
 * Converts all object keys from snake case (snake_case) to camel case (camelCase)
 * @param {object} obj: Object or array of objects
 * @returns the same object with all the keys converted to camelCase
 */
export const objectKeysToCamelCase = (obj) => {
  if (!obj) {
    return null;
  }

  if (Array.isArray(obj)) {
    return obj.map(objectKeysToCamelCase);
  }

  if (typeof obj !== 'object') {
    return obj;
  }

  const newObject = {};

  const objKeys = Object.keys(obj);

  objKeys.forEach((key) => {
    const newKey = key
      .split('_')
      .map((word, index) => (index === 0 ? word : firstLetterToUpperCase(word)))
      .join('');
    newObject[newKey] = objectKeysToCamelCase(obj[key]);
  });

  return newObject;
};

/**
 * Converts an object to query param string
 * @param {object} obj: Object
 * @returns string containing object in query params
 */
export const parseObjectToQueryParams = (obj = {}) => {
  if (typeof obj !== 'object' || !obj) {
    return '';
  }
  return new URLSearchParams(obj).toString();
};
