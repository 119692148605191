import { TRANSACTION_ITEM_TYPES, CAMPAIGN_TYPE } from '../../common/globals';

function getTransactionItem(campaign) {
  if (campaign.type === CAMPAIGN_TYPE.PAYMENT) {
    return {
      type: TRANSACTION_ITEM_TYPES.PAYMENT_FIXED_AMOUNT,
      price: campaign.paymentOptions.price,
    };
  }
  return {
    type: TRANSACTION_ITEM_TYPES.OPEN_AMOUNT,
    frequencies: campaign.donationOptions.paymentFrequencies,
    oneTimePaymentFrequencies:
      campaign.donationOptions?.oneTimePaymentFrequencies,
    recurringPaymentFrequencies:
      campaign.donationOptions?.recurringPaymentFrequencies,
    defaultRecurringFrequency:
      campaign.donationOptions?.defaultRecurringFrequency,
    defaultOneTimeFrequency: campaign.donationOptions?.defaultOneTimeFrequency,
  };
}

export const transformCampaignDetails = (campaign) => {
  // A note about the transactionItems object: this will change when we support multiple funds per tile.
  // Since we're only supporting a single fund per tile currently, all transactionItems are required, are
  // of type "OPEN_AMOUNT", and they get the rest of their properties from the parent campaign.
  return {
    id: campaign.id,
    title: campaign.content.title,
    description: campaign.content.description,
    shortDescription: campaign.content.shortDescription,
    donationOptionsTitle: campaign.content.detail.callToActionText,
    image: campaign.content.detail.image,
    type: campaign.type,
    transactionItems: [
      {
        fundId: campaign.id,
        title: campaign.content.title,
        required: true,
        memoLine: {
          showTextField: campaign.showTextField,
          responseRequired: campaign.responseRequired,
          textField: campaign.textField,
        },
        showEndDate: campaign.showEndDate,
        ...getTransactionItem(campaign),
        isTextFieldResponseType: campaign.isTextFieldResponseType,
        memoFields: campaign.memoFields,
        textField: campaign.textField,
        allowInstallments: campaign.allowInstallments,
      },
    ],
    locationIds: campaign.locationIds,
  };
};

export default transformCampaignDetails;
