import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase, IconButton, Paper, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import theme from './styles';
import CustomTooltip from './CustomTooltip';

function Search(props) {
  const {
    classes,
    onChange,
    labels,
    isInputEmpty,
    onClean,
    changeIsInputEmpty,
    searchOptions,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [target, setTarget] = useState({});
  const [open, setOpen] = useState(false);

  const updateState = (event, newValue) => {
    setInputValue(newValue);
    changeIsInputEmpty(false);
    setTarget(event);
  };

  const handlerOnChange = (e) => {
    e.preventDefault();
    if (!e.target.value) {
      updateState(e, e.target.value);
      onChange(e, e.target.value);
      changeIsInputEmpty(true);
      setOpen(false);
      return;
    }
    updateState(e, e.target.value);
  };

  const handleOpen = (e) => {
    if (e.target.value.length > 0) {
      updateState(e, e.target.value);
      setOpen(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onChange(e, e.target.value);
    }
  };

  const handlerOnCleanFilter = (e) => {
    e.preventDefault();
    onClean();
  };

  const handlerOnClickSearchIcon = () => {
    if (inputValue) {
      onChange(target, inputValue);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.searchContainer} elevation={0}>
        <Autocomplete
          data-testid="autocomplete-search"
          options={searchOptions}
          getOptionLabel={(option) => option}
          renderOption={(option) => (
            <span className={classes.searchOption}>
              {option}
              <Divider className={classes.divider} />
            </span>
          )}
          classes={{
            endAdornment: classes.searchHide,
          }}
          freeSolo
          selectOnFocus
          open={open}
          onOpen={handleOpen}
          onClose={() => {
            setOpen(false);
          }}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          renderInput={(params) => {
            const { InputLabelProps, InputProps, ...rest } = params;
            return (
              <InputBase
                {...params.InputProps}
                {...rest}
                className={classes.searchContent}
                placeholder={labels.search}
                onChange={handlerOnChange}
                type="text"
                data-testid="input"
                onKeyPress={handleKeyPress}
                value={isInputEmpty ? '' : inputValue}
              />
            );
          }}
        />
        {isInputEmpty ? null : (
          <>
            <CustomTooltip title={labels.clearResults}>
              <IconButton
                type="button"
                className={classes.closeIconButton}
                onClick={handlerOnCleanFilter}
                data-testid="clearButton"
              >
                <CloseIcon />
              </IconButton>
            </CustomTooltip>
            <Divider className={classes.divider} orientation="vertical" />
          </>
        )}

        <CustomTooltip title={labels.search}>
          <IconButton
            data-testid="searchButton"
            type="button"
            className={classes.searchIconButton}
            aria-label={labels.search}
            onClick={handlerOnClickSearchIcon}
            role="button"
          >
            <SearchIcon />
          </IconButton>
        </CustomTooltip>
      </Paper>
    </ThemeProvider>
  );
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
  isInputEmpty: PropTypes.bool.isRequired,
  onClean: PropTypes.func.isRequired,
  changeIsInputEmpty: PropTypes.func.isRequired,
  searchOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Search;
