export function parseQueryParams(searchString) {
  const params = new URLSearchParams(searchString);
  const queryObject = {};
  params.forEach((value, key) => {
    queryObject[key] = value;
  });

  return queryObject;
}

function useQueryParams() {
  return parseQueryParams(window.location.search);
}

export default useQueryParams;
