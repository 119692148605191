export default function attribute(
  o: Record<string, unknown>,
  attr: string,
  type: string,
  text: string | undefined
) {
  type = type === 'array' ? 'object' : type;
  if (o && typeof o[attr] !== type) {
    throw new Error(text);
  }
}
