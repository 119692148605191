import { func, objectOf, string } from 'prop-types';
import { withStyles, Button, ButtonGroup } from '@material-ui/core';

import styles from './styles';
import { ReactComponent as DetailLayoutIcon } from '../../../images/detailLayout.svg';
import { ReactComponent as ExpressLayoutIcon } from '../../../images/expressLayout.svg';
import { LOCATION_PAGE_LAYOUTS } from '../../../common/globals';

function LayoutSwitch({ classes, className, onChange, selectedLayout }) {
  return (
    <ButtonGroup className={className}>
      <Button
        onClick={() => onChange(LOCATION_PAGE_LAYOUTS.DETAIL)}
        className={
          selectedLayout === LOCATION_PAGE_LAYOUTS.DETAIL
            ? classes.active
            : classes.inactive
        }
      >
        <DetailLayoutIcon />
      </Button>
      <Button
        onClick={() => onChange(LOCATION_PAGE_LAYOUTS.EXPRESS)}
        className={
          selectedLayout === LOCATION_PAGE_LAYOUTS.EXPRESS
            ? classes.active
            : classes.inactive
        }
      >
        <ExpressLayoutIcon />
      </Button>
    </ButtonGroup>
  );
}

LayoutSwitch.propTypes = {
  classes: objectOf(string).isRequired,
  className: string,
  onChange: func.isRequired,
  selectedLayout: string.isRequired,
};

LayoutSwitch.defaultProps = {
  className: '',
};

export default withStyles(styles)(LayoutSwitch);
