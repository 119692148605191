// import { Redirect } from "react-router-dom";
import {
  USER_DETAILS_COMPLETE,
  USER_DETAILS_ERROR,
  USER_DETAILS_FETCH,
  USER_DETAILS_CLEAR,
  USER_DETAILS_SAVE,
  USER_DETAILS_SAVE_COMPLETE,
  USER_DETAILS_SAVE_ERROR,
  USER_REQUIRED_ACTIONS_FETCH,
  USER_REQUIRED_ACTIONS_COMPLETE,
  USER_REQUIRED_ACTIONS_ERROR,
  USER_REQUIRED_ACTIONS_SUBMITED,
  MIGRATED_TRANSACTIONS_PATCH,
  MIGRATED_TRANSACTIONS_PATCH_COMPLETE,
  USER_REQUIRED_ACTIONS_ADD_PAYMENT_METHOD,
  USER_REQUIRED_ACTIONS_PAYMENT_METHOD_REMOVED,
  USER_REQUIRED_ACTIONS_SUBMIT_ERROR,
  USER_REQUIRED_ACTIONS_SUBMITING,
  USER_REQUIRED_ACTIONS_URL_REMOVED,
} from '../actions/actionTypes';
import {
  TRANSACTIONS_ACTION_NEEDED,
  TRANSACTIONS_THANKS_PATH,
} from '../common/globals';

import { savedPaymentMethodsTransformToFrontend } from '../api/transform/SavedPaymentMethodTransform';

const initialState = {
  address: {
    locality: '',
    postal_code: '',
    region: '',
    street_address: '',
  },
  email: '',
  email_verified: false,
  family_name: '',
  given_name: '',
  password_set: false,
  preferred_username: '',
  status: '',
  uid: '',
  username: '',
  phone_number: '',
  isFetchingUserDetails: false,
  detailsComplete: false,
  isSavingUserDetails: false,
  isFetchingRequiredActions: false,
  isPatchingRequiredAction: false,
  requiredActionsLoaded: false,
  requiredActions: {},
  showActionNeededUI: false,
  hasActionNeededURL: !!new URLSearchParams(window.location.search).get(
    TRANSACTIONS_ACTION_NEEDED,
  ),
  actionNeededSubmited: false,
  actionNeededSubmitError: false,
  isSubmittingActions: false,
};

function checkForComplete(details) {
  return (
    details.managed === 'InternalAzureAD' ||
    !!(
      details.email &&
      details.family_name &&
      details.given_name &&
      details.address &&
      details.address.locality &&
      details.address.postal_code &&
      details.address.region &&
      details.address.street_address
    )
  );
}

export default function user(state = initialState, action = {}) {
  let newPaymentList;
  const hasActionNeededURL = !!new URLSearchParams(window.location.search).get(
    TRANSACTIONS_ACTION_NEEDED,
  );
  const upPendingActions = action.recurrenceId
    ? [
        ...state.requiredActions.pendingActions.filter(
          (c) => c.id !== action.recurrenceId,
        ),
      ]
    : [];
  switch (action.type) {
    case USER_DETAILS_FETCH:
      return { ...state, isFetchingUserDetails: true };
    case USER_DETAILS_COMPLETE:
      return {
        ...state,
        ...action.userDetails,
        detailsComplete: checkForComplete(action.userDetails),
        isFetchingUserDetails: false,
      };
    case USER_DETAILS_CLEAR:
      return initialState;
    case USER_DETAILS_ERROR:
      return initialState;
    case USER_DETAILS_SAVE:
      return { ...state, isSavingUserDetails: true };
    case USER_DETAILS_SAVE_COMPLETE:
      return { ...state, isSavingUserDetails: false };
    case USER_DETAILS_SAVE_ERROR:
      return { ...state, isSavingUserDetails: false };
    case USER_REQUIRED_ACTIONS_FETCH:
      return {
        ...state,
        isFetchingRequiredActions: true,
      };
    case USER_REQUIRED_ACTIONS_COMPLETE:
      return {
        ...state,
        isFetchingRequiredActions: false,
        requiredActionsLoaded: true,
        requiredActions: {
          ...action.requiredActions,
          paymentMethods: action.requiredActions?.paymentMethods
            ? savedPaymentMethodsTransformToFrontend(
                action.requiredActions.paymentMethods,
              )
            : [],
        },
        hasActionNeededURL,
        showActionNeededUI: hasActionNeededURL
          ? action.requiredActions?.pendingActions?.length > 0
          : false,
        actionNeededSubmited:
          action.requiredActions?.pendingActions?.length === 0,
      };
    case USER_REQUIRED_ACTIONS_ERROR:
      return { ...state, isFetchingRequiredActions: false };
    case USER_REQUIRED_ACTIONS_SUBMITING:
      return { ...state, isSubmittingActions: true };
    case USER_REQUIRED_ACTIONS_SUBMITED:
      return {
        ...state,
        isSubmittingActions: false,
        actionNeededSubmited: true,
        showActionNeededUI: false,
      };
    case USER_REQUIRED_ACTIONS_SUBMIT_ERROR:
      return {
        ...state,
        isSubmittingActions: false,
        actionNeededSubmited: false,
        actionNeededSubmitError: true,
      };
    case MIGRATED_TRANSACTIONS_PATCH:
      return { ...state, isPatchingRequiredAction: true };
    case MIGRATED_TRANSACTIONS_PATCH_COMPLETE:
      if (upPendingActions.length === 0) {
        window.location.assign(
          `${window.location.pathname}/${TRANSACTIONS_THANKS_PATH}`,
        );
      }

      return {
        ...state,
        requiredActions: {
          campaigns: [...state.requiredActions.campaigns],
          paymentMethods: [...state.requiredActions.paymentMethods],
          status: state.requiredActions.status,
          pendingActions: upPendingActions,
        },
        isPatchingRequiredAction: false,
      };
    case USER_REQUIRED_ACTIONS_ADD_PAYMENT_METHOD:
      newPaymentList =
        state.requiredActions.paymentMethods?.length > 0
          ? [...state.requiredActions.paymentMethods, action.newPaymentMethod]
          : [action.newPaymentMethod];
      return {
        ...state,
        requiredActions: {
          ...state.requiredActions,
          paymentMethods: newPaymentList,
        },
      };
    case USER_REQUIRED_ACTIONS_PAYMENT_METHOD_REMOVED:
      return { ...state, requiredActionsLoaded: false };
    case USER_REQUIRED_ACTIONS_URL_REMOVED:
      return { ...state, hasActionNeededURL: false };
    default:
      return state;
  }
}
