import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getCurrencyFormat } from '../../../common/utils';

const styles = (theme) => ({
  image: {
    width: '140px',
    height: '100px',
    borderRadius: '4px',
  },
  basketItemRoot: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '1.6',
    letterSpacing: '0.3px',
    marginRight: '-8px',
  },
  itemButtonContainer: {
    display: 'flex',
  },
  itemHeaderLink: {
    color: theme.palette.primary.main,
  },
  processingFee: {
    color: theme.palette.primary.main,
  },
  itemText: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.8',
    letterSpacing: '0.3px',
  },
  quantitySelect: {
    marginLeft: '5px',
    textAlign: 'right',
  },
  leftMemo: {
    textAlign: 'left',
    maxWidth: '50%',
  },
  rightMemo: {
    textAlign: 'right',
    maxWidth: '50%',
  },
});

/**
 * Placeholder component for items in the basket. Will need to be expanded with whatever details we decide to put in the
 * side basket view widget
 */
export function BasketItem(props) {
  const { item, classes, showProcessingFees, processingFee } = props;
  const { t } = useTranslation();
  const { startDate: date, amount } = item;
  let totalAmount = parseFloat(amount, 10);
  if (showProcessingFees) {
    totalAmount += amount * (processingFee / 100);
  }
  const formattedDate = moment(date).format('L');

  return (
    <div>
      <div className={classes.basketItemRoot}>
        <Typography component="div">
          <div className={classes.itemText}>
            {t('mpbasket.date')}
            <span id="date" data-testid="date">
              {formattedDate}
            </span>
          </div>

          <div className={classes.itemText}>
            {t('basket.total')}

            <span data-testid="amount" id="amount">
              {getCurrencyFormat(totalAmount)}
            </span>
          </div>
        </Typography>
      </div>
    </div>
  );
}

BasketItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  showProcessingFees: PropTypes.bool,
  processingFee: PropTypes.number,
};

BasketItem.defaultProps = {
  showProcessingFees: false,
  processingFee: null,
};

export default withStyles(styles)(BasketItem);
