import { WebAuth } from '@vancoplatform/web-auth';
import {
  getCurrentPathNameWithParams,
  parseObjectToQueryParams,
} from '../common/utils';
import store from '../store';
import { Api } from './Api';

/**
 * Wrapper for the IDS auth implementation. I'm excluding this from tests because I can't think of anything meaningful to test here
 */
export class AuthService {
  constructor() {
    /* Creates a second API instance without interceptors to handle fetching org details as part of app initialization.
  This prevents race conditions and complexity with handling csrf tokens and IDS access tokens. */
    this.noAuthHttp = new Api();
  }

  static idsClient = undefined;

  get IDSClient() {
    if (!this.idsClient) {
      this.idsClient = new WebAuth({
        domain: window.env.REACT_APP_IDS_DOMAIN,
        clientId: window.env.REACT_APP_IDS_CLIENT_ID,
        audience: window.env.REACT_APP_IDS_AUDIENCE,
        responseType: 'code',
        scope: 'openid profile email address phone offline_access',
        redirectUri: window.env.REACT_APP_FRONTEND_REDIRECT_URL,
      });
    }

    return this.idsClient;
  }

  parseHash = (options) => {
    return this.IDSClient.parseHash(options);
  };

  embedLogin = (iframeNode, isSignup, returnTo = null) => {
    const { idsPayerTenant } = store.getState().location;
    const options = {
      redirectMode: 'redirect',
      tenant: idsPayerTenant,
      appState: {
        returnTo: returnTo || getCurrentPathNameWithParams(),
        idsPayerTenant,
      },
    };
    if (isSignup) {
      options.prompt = 'create';
    }
    return this.IDSClient.embedded.authorize(options, iframeNode);
  };

  login = (tenant = null, returnTo = null, isSignup = false) => {
    const idsPayerTenant = tenant || store.getState().location.idsPayerTenant;
    const options = {
      redirectMode: 'redirect',
      tenant: idsPayerTenant,
      appState: {
        returnTo: returnTo || window.location.pathname,
        idsPayerTenant,
      },
    };
    if (isSignup) {
      options.prompt = 'create';
    }
    this.IDSClient.authorize(options);
  };

  nonInteractiveLogin = (tenant = null, returnTo = null) => {
    const idsPayerTenant = tenant || store.getState().location.idsPayerTenant;
    const options = {
      tenant: idsPayerTenant,
      appState: {
        returnTo: returnTo || window.location.pathname,
        idsPayerTenant,
      },
      prompt: 'none',
    };
    return this.IDSClient.authorize(options);
  };

  renewAuth = (idTokenHint) => {
    const { idsPayerTenant } = store.getState().location;
    if (!idsPayerTenant) return null;
    return this.IDSClient.renewAuth({ idTokenHint, tenant: idsPayerTenant });
  };

  checkSession = (sessionState) => {
    const { idsPayerTenant } = store.getState().location;
    if (!idsPayerTenant) return null;
    return this.IDSClient.checkSession({ sessionState });
  };

  logout = (queryObject) => {
    // idsPayerTenant (tenant) is required for MP oAuth for logout
    const { idsPayerTenant } = store.getState().location;
    // only redirectId is used within the logout call
    const { idToken: idTokenHint, redirectId } = queryObject;

    // encryption is required - btoa is base64
    this.IDSClient.logout({
      postLogoutRedirectUri: window.env.REACT_APP_FRONTEND_REDIRECT_URL,
      state: btoa(JSON.stringify({ redirectId })),
      idTokenHint,
      tenant: idsPayerTenant,
    });
  };

  getUserInfo = (accessToken) => {
    return this.IDSClient.client.userInfo(accessToken);
  };

  getAccessTokenfromRefreshToken = (refreshToken) => {
    const data = parseObjectToQueryParams({
      grant_type: 'refresh_token',
      client_id: window.env.REACT_APP_IDS_CLIENT_ID,
      refresh_token: refreshToken,
      audience: window.env.REACT_APP_IDS_AUDIENCE,
      scope: 'profile email phone address',
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return this.noAuthHttp.post(
      `https://${window.env.REACT_APP_IDS_DOMAIN}/oauth/token`,
      data,
      config,
    );
  };
}

export default new AuthService();
