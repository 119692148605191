export default theme => ({
  dialog: {
    backgroundColor: theme.palette.overlay,
  },
  dialogRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
    width: '375px',
  },
  backHeader: {
    alignSelf: 'start',
  },
  summary: {
    width: '100%',
    marginTop: '20px',
  },
  amount: {
    marginLeft: '15px',
  },
  summaryCard: {
    padding: '15px',
    boxShadow: 'none',
  },
  itemBreak: {
    borderColor: 'rgba(228, 229, 230, 0.5)',
  },
  lineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
  },
  methodLineItem: {
    display: 'flex',
    marginTop: '15px',
  },
  methodIcon: {
    marginRight: '5px',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerText: {
    margin: '10px 15px 0px 15px',
  },
  closeButton: {
    marginLeft: '25px',
  },
  subtitle: {
    color: theme.palette.secondary.light,
  },
  [theme.breakpoints.down('xs')]: {
    dialogRoot: {
      width: 'auto',
    },
    headerContainer: {
      width: 'auto',
    },
    headerName: {
      marginTop: '20px',
      textAlign: 'center',
    },
    subtitle: {
      textAlign: 'center',
    },
  },
});
