import PropTypes from 'prop-types';
import { withStyles, Typography, Box } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import StateDropdown from '../paymentMethod/StateDropdown';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  formItem: {
    marginBottom: '20px',
  },
  stateField: {
    flexGrow: '3',
    flexBasis: '70%',
  },
  secondFormItem: {
    marginLeft: '10px',
    flexGrow: '1',
  },
  formItemGroup: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
});

export function AdressInfo(props) {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <Typography component="div" className={classes.container}>
      <Typography variant="h6" className={classes.sectionHeading}>
        {t('billingInfo.contactInfo')}
      </Typography>
      <Box sx={{ m: 1 }} />
      <Field
        name="addressInfo.addressLine1"
        autoComplete="section-billing address-line1"
        component={TextField}
        id="address-1"
        className={classes.formItem}
        label={t('billingInfo.addressLine1')}
        variant="outlined"
      />
      <Field
        name="addressInfo.addressLine2"
        autoComplete="section-billing address-line2"
        component={TextField}
        id="address-2"
        className={classes.formItem}
        label={t('billingInfo.addressLine2')}
        variant="outlined"
      />
      <Field
        name="addressInfo.city"
        autoComplete="section-billing address-level2"
        component={TextField}
        id="city"
        className={classes.formItem}
        label={t('billingInfo.city')}
        variant="outlined"
      />
      <span className={classes.formItemGroup}>
        <Field
          name="addressInfo.state"
          component={StateDropdown}
          className={classes.stateField}
        />
        <Field
          name="addressInfo.zipCode"
          autoComplete="section-billing postal-code"
          component={TextField}
          id="zip-code"
          inputProps={{ inputMode: 'numeric' }}
          label={t('billingInfo.zipCode')}
          variant="outlined"
          className={classes.secondFormItem}
        />
      </span>
    </Typography>
  );
}
AdressInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdressInfo);
