import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as globals from '../../common/globals';
import { toCapitalized, renderCardIcon } from '../../common/utils';

const styles = () => ({
  accountDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  accountIcon: {
    marginRight: '10px',
  },
});

export function ACHPaymentSummary(props) {
  const { classes, accountType, last4 } = props;
  const { t } = useTranslation();

  return (
    <Typography
      component="div"
      className={classes.accountDetails}
      data-testid="ach-payment-summary"
    >
      {renderCardIcon(globals.ACH, classes.accountIcon)}
      <span id="account-number" data-testid="account-number">
        {t('paymentMethodSummary.accountNumber', {
          accountType: toCapitalized(accountType),
          accountNumber: last4,
        })}
      </span>
    </Typography>
  );
}

ACHPaymentSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  accountType: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
};

export default withStyles(styles)(ACHPaymentSummary);
