import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as globals from '../../../common/globals';
import MyBasket from '../../../components/basket/MyBasket';
import {
  getTransactionCount,
  hasScheduledTransactions,
} from '../../../common/utils';
import RNWebViewHandler from '../../../utils/RNWebViewHandler';
import ReturnLink from '../../../components/common/ReturnLink';
import usePageTitle from '../../../hooks/common/usePageTitle';

const styles = () => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 32px 16px 32px',
  },
  backToHomeLink: {
    alignSelf: 'flex-start',
  },
  basketRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export function KioskBasket({
  classes,
  match,
  history,
  items,
  loggedIn,
  transactionCount,
}) {
  const {
    params: { locId },
  } = match;
  const { t } = useTranslation();
  usePageTitle(t('titles.basket'));

  const [currentLength, setCurrentLength] = useState(transactionCount);

  useEffect(() => {
    // if the basket had items and all were removed, navigate to the home page
    if (currentLength !== transactionCount && transactionCount === 0) {
      history.push(`/${locId}/home`);
    }
    setCurrentLength(transactionCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionCount]);

  const { isInWebview, sendMessage } = new RNWebViewHandler();

  const onSubmit = (event) => {
    event.preventDefault();
    if (loggedIn || !hasScheduledTransactions(items)) {
      if (isInWebview) {
        sendMessage('basket', items);
      }
      history.push(`/${locId}/${globals.PAYMENT_PATH}`);
    }
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.backToHomeLink}>
        <ReturnLink
          isDisplayed
          link={`/${locId}/home`}
          linkText="Keep exploring"
        />
      </div>
      <div className={classes.basketRoot}>
        <MyBasket
          id="basket-widget"
          locationId={locId}
          onSubmit={onSubmit}
          submitButtonText={t('basket.continueToPayment')}
        />
      </div>
    </div>
  );
}

KioskBasket.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  transactionCount: PropTypes.number.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  classes: PropTypes.object,
};

KioskBasket.defaultProps = {
  classes: {},
};

const mapStateToProps = (state) => {
  return {
    items: state.basket.items,
    loggedIn: state.session.loggedIn,
    iframe: state.frame.inIframe,
    transactionCount: getTransactionCount(state.basket.items),
  };
};
export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(KioskBasket);
