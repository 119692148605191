import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import useDecryptParams from '../../hooks/externalHooks/useDecryptParams';
import ProcessingOverlay from '../common/ProcessingOverlay';

const styles = () => ({
  container: {
    flex: 1,
  },
});

function ExtConfirmation({ classes }) {
  const { isDecrypting } = useDecryptParams();

  return (
    <Grid>
      <ProcessingOverlay className={classes.container} open={isDecrypting} />
    </Grid>
  );
}

ExtConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExtConfirmation);
