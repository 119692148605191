export default function styles(theme) {
  return {
    formHelperText: {
      margin: 0,
      maxWidth: '100%',
      marginBottom: 5,
    },
    warning: {
      color: theme.palette.primary.main,
    },
    left: {
      color: theme.palette.error.main,
    },
    right: { float: 'right' },
  };
}
