import store from '../store';
import {
  loadBasketContents,
  updateSelectedPaymentType,
} from '../actions/basket';
import { fetchLocationDetails } from '../actions/location';
import { fetchCsrfToken, initComplete } from '../actions/session';
import { logError } from './errorUtils';
import * as globals from './globals';
import history from '../history';
import { getFeatures } from '../actions/features';
import { getSelectedPaymentType } from './utils';
import setCurrentOrigin from '../actions/origin';

let initPromise = null;

export default function initializeApplication(locationId) {
  const init = async () => {
    store.dispatch(loadBasketContents(locationId));
    try {
      const { data } = await store.dispatch(fetchLocationDetails(locationId));

      const newSelectedPaymentType = getSelectedPaymentType(
        data?.paymentMethods,
        store?.getState()?.basket?.selectedPaymentType,
      );

      await store.dispatch(
        updateSelectedPaymentType(locationId, newSelectedPaymentType),
      );

      await store.dispatch(getFeatures(data.organizationId));
      await store.dispatch(fetchCsrfToken());
      await store.dispatch(setCurrentOrigin());
      store.dispatch(initComplete());
    } catch (error) {
      logError(error);
      history.push(`/${globals.GENERAL_ERROR_PATH}`);
    }
  };
  initPromise = init();
  return initPromise;
}

export const getInitPromise = () => initPromise;
