import i18next from 'i18next';
import {
  SELECTED_PAYMENT_METHOD_FETCH,
  SELECTED_PAYMENT_METHOD_COMPLETE,
  SELECTED_PAYMENT_METHOD_ERROR,
  PAYMENT_METHOD_SAVE,
  PAYMENT_METHOD_SAVE_COMPLETE,
  PAYMENT_ID_COMPLETE,
  SET_HEXEA_OBJECT,
  PAYMENT_ID_CLEAR,
  PAYMENT_METHOD_SAVE_ERROR,
  SESSION_DATA_FETCH,
  SESSION_DATA_COMPLETE,
  SESSION_DATA_ERROR,
  UPDATED_JWT_FETCH,
  UPDATED_JWT_COMPLETE,
  UPDATED_JWT_ERROR,
} from './actionTypes';
import PaymentService from '../api/PaymentService';
import hexeaPromise from '../../hexea';
import getCxpErrorMessage from '../../utils/ErrorUtils';

export function fetchSessionData(sessionid) {
  return async (dispatch) => {
    dispatch({ type: SESSION_DATA_FETCH });

    let response;
    try {
      response = await PaymentService.getSessionData(sessionid);
    } catch (error) {
      dispatch({ type: SESSION_DATA_ERROR });
      throw error;
    }

    if (response?.status === 200 && response?.data) {
      const data = response?.data;
      return dispatch({ type: SESSION_DATA_COMPLETE, data });
    }

    dispatch({ type: SESSION_DATA_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function fetchUpdatedJWT(url, success, transactionId) {
  return async (dispatch) => {
    dispatch({ type: UPDATED_JWT_FETCH });

    let response;
    try {
      response = await PaymentService.fetchUpdatedJWT(
        url,
        success,
        transactionId,
      );
    } catch (error) {
      dispatch({ type: UPDATED_JWT_ERROR });
      throw error;
    }

    if (response?.status === 200 && response?.data) {
      const data = response?.data;
      dispatch({ type: UPDATED_JWT_COMPLETE });
      return data;
    }

    dispatch({ type: UPDATED_JWT_ERROR });
    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function fetchPaymentID(sessionId) {
  return async (dispatch) => {
    return dispatch({
      type: PAYMENT_ID_COMPLETE,
      payment: { id: sessionId },
    });
  };
}

export function fetchSelectedPaymentMethod(paymentId) {
  return async (dispatch) => {
    dispatch({ type: SELECTED_PAYMENT_METHOD_FETCH });

    if (paymentId) {
      let response;
      try {
        response = await PaymentService.fetchSavedPaymentMethod(paymentId);
      } catch (error) {
        dispatch({ type: SELECTED_PAYMENT_METHOD_ERROR });
        throw error;
      }

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        const paymentMethodDetails = response.data.data;

        return dispatch({
          type: SELECTED_PAYMENT_METHOD_COMPLETE,
          paymentMethodDetails,
        });
      }

      dispatch({ type: SELECTED_PAYMENT_METHOD_ERROR });
      throw new Error(i18next.t('errors.noPaymentMethod'));
    }

    dispatch({ type: SELECTED_PAYMENT_METHOD_ERROR });
    throw new Error(i18next.t('errors.noPaymentId'));
  };
}

export function makePayment(
  paymentMethodDetails,
  paymentId,
  guestId,
  includeProcessingFee,
) {
  return async (dispatch) => {
    dispatch({ type: PAYMENT_METHOD_SAVE });

    let response;
    try {
      response = await PaymentService.makePayment(
        paymentMethodDetails,
        paymentId,
        guestId,
        includeProcessingFee,
      );
    } catch (error) {
      dispatch({
        type: PAYMENT_METHOD_SAVE_ERROR,
      });
      const cxpMessage = getCxpErrorMessage(error);
      throw cxpMessage ? new Error(cxpMessage) : error;
    }

    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: PAYMENT_METHOD_SAVE_COMPLETE,
      });

      return response.data;
    }

    throw new Error(i18next.t('errors.somethingHappened'));
  };
}

export function clearSelectedPaymentMethod() {
  return async (dispatch) => {
    return dispatch({
      type: PAYMENT_ID_CLEAR,
    });
  };
}

export function setHexeaObject(pcct) {
  return async (dispatch) => {
    const hexea = await hexeaPromise;
    dispatch({
      type: SET_HEXEA_OBJECT,
      hexeaObject: hexea(pcct),
    });
  };
}
