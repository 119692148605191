import PropTypes from 'prop-types';
import {
  InputAdornment,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  CARD_TYPE_AMEX,
  CARD_TYPE_DISCOVER,
  CARD_TYPE_MASTERCARD,
  CARD_TYPE_VISA,
} from '../../../../common/globals';
import frontCard from '../../../../images/front_card.png';
import backCard from '../../../../images/back_card.png';
import useStyles from './styles';

const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.tooltip.background,
    position: 'relative',
    color: theme.palette.text.secondary,
    borderRadius: '15px',
  },
  arrow: {
    color: theme.palette.tooltip.background,
    scale: 4,
    transform: 'translateX(-1px)',
  },
}));

function SecurityCodeGuidance({ cardType }) {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const isAmex = Boolean(cardType === CARD_TYPE_AMEX);
  const { t } = useTranslation();

  const img = isAmex ? frontCard : backCard;
  const lineClassNames = clsx({
    [classes.line]: true,
    [classes.noAmex]: !isAmex,
  });
  const tooltipContent = () => (
    <div className={classes.tooltipContent}>
      <div className={classes.header}>
        <Typography variant="body2">
          {t(
            isAmex
              ? 'cardPayment.cvv.frontOfCard'
              : 'cardPayment.cvv.backOfCard',
          )}
        </Typography>
      </div>
      <img src={img} alt="security code guidance" />
      <div className={classes.caption}>
        <Typography variant="body1" className={classes.description}>
          {t(
            isAmex
              ? 'cardPayment.cvv.fourDigitNumber'
              : 'cardPayment.cvv.threeDigitNumber',
          )}
        </Typography>
        <div className={lineClassNames}>
          <div className={classes.ellipsis} />
        </div>
      </div>
    </div>
  );

  return (
    <InputAdornment position="end">
      <Tooltip
        title={tooltipContent()}
        placement="right"
        arrow
        classes={tooltipClasses}
      >
        <HelpOutline className={classes.silentIcon} titleAccess="help icon" />
      </Tooltip>
    </InputAdornment>
  );
}

SecurityCodeGuidance.defaultProps = {
  cardType: null,
};

SecurityCodeGuidance.propTypes = {
  cardType: PropTypes.oneOf([
    CARD_TYPE_AMEX,
    CARD_TYPE_VISA,
    CARD_TYPE_MASTERCARD,
    CARD_TYPE_DISCOVER,
  ]),
};

export default SecurityCodeGuidance;
