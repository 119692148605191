import { number, object, objectOf, shape, string } from 'prop-types';
import {
  Card,
  FormControlLabel,
  Typography,
  withStyles,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import styles from './styles';
import { getCurrencyFormat } from '../../../common/utils';
import IOSSwitch from '../../../components/common/IOSSwitch';

function PaymentSummary({ classes, paymentData, totalAmount, fees }) {
  const { fundName, frequency, startDate, endDate } = paymentData;
  const { t } = useTranslation();
  const formattedStartDate = moment(startDate).format('L');
  const isEndDateIncorrect = !endDate || moment(endDate) < moment(startDate);
  const formattedEndDate = isEndDateIncorrect
    ? undefined
    : moment(endDate).format('L');

  return (
    <Card className={classes.container}>
      <Typography>{t('mpPayment.summary.giving')}</Typography>
      <Typography variant="h5">{`${getCurrencyFormat(
        totalAmount,
      )}`}</Typography>

      <Typography>{t('mpPayment.summary.to')}</Typography>
      <Typography variant="h6">
        {fundName || t('mpbasket.unknown.fund')}
      </Typography>
      <Typography>{frequency}</Typography>
      <Typography component="div">
        <div className={classes.dateText}>
          {t('addDonation.startDate')}
          <span data-testid="start-date">{formattedStartDate}</span>
        </div>
      </Typography>
      {formattedEndDate && (
        <Typography component="div">
          <div className={classes.dateText}>
            {t('transactions.scheduled.endDate')}
            <span data-testid="end-date">{formattedEndDate}</span>
          </div>
        </Typography>
      )}
      {fees.enableProcessingFees && (
        <div className={classes.processingFeesSwitch}>
          <hr
            className={classes.itemBreak}
            style={{ marginTop: '16px', marginBottom: '16px' }}
          />
          <FormControlLabel
            control={
              <IOSSwitch
                color="primary"
                checked={fees.includeProcessingFee}
                onChange={fees.onApplyProcessingFees}
              />
            }
            label={t('basket.processingFee')}
          />
        </div>
      )}
    </Card>
  );
}

PaymentSummary.propTypes = {
  classes: objectOf(string).isRequired,
  paymentData: shape({}).isRequired,
  totalAmount: number.isRequired,
  fees: object.isRequired,
};

export default withStyles(styles)(PaymentSummary);
