import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { enqueueDialog } from '../../actions/notifications';
import {
  getWasIdleFromStorage,
  clearWasIdleFromStorage,
} from '../../common/storageUtils';
import initializeApplication from '../../common/initializeApplication';

/**
 * Renderless component for hydrating basket contents on page load. Could potentially be replaced with plain JS
 * in the main App component, but this implementation allows us to take advantage of the component lifecycle to only
 * update when it makes sense to
 */
export class StorageHandler extends Component {
  async componentDidMount() {
    const { enqueueDialogConnect, locationId } = this.props;

    if (getWasIdleFromStorage()) {
      clearWasIdleFromStorage();
      enqueueDialogConnect({
        title: i18next.t('alert.loggedOut.title'),
        message: i18next.t('alert.loggedOut.body'),
      });
    }
    await initializeApplication(locationId);
  }

  async componentDidUpdate(prevProps) {
    const { locationId } = this.props;

    if (locationId !== prevProps.locationId) {
      await initializeApplication(locationId);
    }
  }

  render() {
    return null;
  }
}

StorageHandler.propTypes = {
  locationId: PropTypes.string.isRequired,
  enqueueDialogConnect: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  enqueueDialogConnect: notification => dispatch(enqueueDialog(notification)),
});

export default connect(null, mapDispatchToProps)(StorageHandler);
