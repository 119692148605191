import { connect } from 'react-redux';
import Expanded from './Expanded';

const mapStateToProps = (state) => {
  const basketIds = state.basket.items.map((item) => item.campaignDetails.id);
  return {
    campaignDetails: state.campaigns.campaignDetails,
    isFetchingCampaignDetails: state.campaigns.isFetchingCampaignDetails,
    basketIds,
    loc: state.location,
    features: state.features.features,
  };
};

export default connect(mapStateToProps, null)(Expanded);
