import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import IDSLoginDialog from '../../components/common/IDSLoginDialog';

const useStyles = makeStyles({
  iconWrapper: {
    marginLeft: '-15px',
  },
  icon: {
    fontSize: 20,
  },
  dialogContentRoot: {
    maxWidth: '340px',
    margin: '10px 30px 0px 30px',
  },
});

export function BasketLoginDialog({ onClose, returnTo, open, closeIDSDialog }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <IDSLoginDialog
      returnTo={returnTo}
      onParentLogin={closeIDSDialog}
      onClose={onClose}
      open={open}
      renderHeader={() => (
        <div id="basket-login-header" className={classes.dialogContentRoot}>
          <Typography variant="h5">
            {t('login.continueWithAccountTitle')}
          </Typography>
          <Typography variant="body1">
            {t('login.continueWithAccountBody')}
          </Typography>
        </div>
      )}
    />
  );
}

BasketLoginDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  returnTo: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closeIDSDialog: PropTypes.func.isRequired,
};

export default BasketLoginDialog;
