import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import styles from './styles';

export function ErrorBody({ classes, title, body }) {
  return (
    <div className={classes.body}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      {body}
    </div>
  );
}

ErrorBody.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
};

ErrorBody.defaultProps = {
  title: '',
  body: '',
};

export default withStyles(styles)(ErrorBody);
