import { node } from 'prop-types';
import { Typography } from '@material-ui/core';

import { useSimpleLayoutContext } from './context/SimpleLayoutContext';

export function SimpleLayoutTitle ({ children }) {
  const { classes } = useSimpleLayoutContext();
  return (
    <Typography variant="h2" className={classes.title}>
      {children}
    </Typography>
  );
}

SimpleLayoutTitle.propTypes = {
  children: node,
};

SimpleLayoutTitle.defaultProps = {
  children: '',
};

export default SimpleLayoutTitle;
