import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  FormControl,
  Button,
  Checkbox,
  FormHelperText,
  withStyles,
} from '@material-ui/core';
import get from 'lodash/get';

const styles = () => ({
  control: {
    '& .MuiFormControlLabel-root': {
      display: 'table',
    },
    '& .MuiCheckbox-root': {
      // color: '#a83242',
      display: 'table-cell',
      verticalAlign: 'top',
      paddingTop: 0,
    },
  },
  checkboxError: {
    '& .MuiSvgIcon-root': {
      fill: '#f44336',
    },
  },
  button: {
    padding: '0 0 2px 0',
    width: 'fit-content',
    fontWeight: 'bold',
    marginLeft: '10px',
    '&:hover': {
      textDecoration: 'underline',
      background: 'transparent',
    },
  },
  errorText: {
    marginTop: '1px',
    marginLeft: '33px',
    width: '290px',
  },
});

export function FormikCheckboxWithLink(props) {
  const {
    label,
    classes,
    linkText,
    onLinkClick,
    field,
    errors,
    touched,
    setFieldValue,
  } = props;

  const [errorText, setErrorText] = useState('');
  useEffect(() => {
    setErrorText(
      get(errors, field.name) && get(touched, field.name)
        ? get(errors, field.name)
        : '',
    );
  }, [errors, touched, field.name]);

  const handleCheckboxChange = name => e => {
    setFieldValue(name, e.target.checked, true);
  };

  const lbl = (
    <span>
      {label}
      <Button
        color="primary"
        disableRipple
        className={classes.button}
        onClick={onLinkClick}
      >
        {linkText}
      </Button>
    </span>
  );

  return (
    <FormControl error={!!errorText} className={classes.control}>
      <FormControlLabel
        control={
          <Checkbox
            name={field.name}
            checked={field.value}
            onChange={handleCheckboxChange(field.name)}
            onBlur={field.onBlur}
            value={field.name}
            className={errorText ? classes.checkboxError : ''}
          />
        }
        label={lbl}
      />
      {errorText && (
        <FormHelperText className={classes.errorText}>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormikCheckboxWithLink.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.object.isRequired,
  linkText: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

FormikCheckboxWithLink.defaultProps = {
  label: null,
};

export default withStyles(styles)(FormikCheckboxWithLink);
